import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const jabatanApi = createApi({
    reducerPath: 'jabatan',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getJabatan: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'Jabatan' }];

                },
                query: () => {
                    return {
                        url: 'Jabatan',
                        method: 'GET',
                    };
                },
            }),
            getListJabatan: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'Jabatan' }];

                },
                query: (id) => {
                    return {
                        url: 'JabatanList',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            addJabatan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Jabatan' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'Jabatan',
                        body: data,
                    };
                },
            }),
            updateJabatan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Jabatan' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `Jabatan/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteJabatan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Jabatan' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `Jabatan/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetJabatanQuery, useGetListJabatanQuery, useAddJabatanMutation, useUpdateJabatanMutation, useDeleteJabatanMutation } = jabatanApi;
export { jabatanApi };