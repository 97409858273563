import React, { Fragment, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { MdDelete } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { deleteKPasar, getListKPasar } from '../../actions/kualitasPasarAction';
import { AddKualitasPasar } from './AddProductDist';
import { EditKualitasPasar } from './EditProductDist';
import { useDeleteKualitasPasarMutation, useGetKualitasPasarQuery } from '../../store/apis/kualitasPasarApi';

const ManageKualitasPasaran = () => {
    const {data, error, isFetching} = useGetKualitasPasarQuery();
    const [deleteKualitasPasar, deleteKualitasPasarResult] = useDeleteKualitasPasarMutation()
    const dispatch = useDispatch();

    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            if (data === "kualitas pasar") {
                deleteKualitasPasar(id)
            }
        }
    }

    const colKP = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idKP = record.id;
                return (
                    <Fragment>
                        <EditKualitasPasar data={record} />

                        <span className='mx-3'>|</span>

                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, idKP, "kualitas pasar")}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]

    return (
        <div className='w-full  p-5  '>
            <div className='w-full bg-white border p-5 rounded-lg '>
                <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                    <div className='col-span-3 self-center'>
                        <h1 className='text-base font-bold text-blue-500 '>Data Kualitas Pasar</h1>
                        <p className='text-slate-400'>Di bawah ini merupakan data semua Kualitas Pasar yang telah terdaftar.</p>
                    </div>
                    <div className='place-self-end'>
                        <AddKualitasPasar />
                    </div>
                    <div className='col-span-3' />

                </div>
                {data ? (
                    <DataTable columns={colKP} data={data} pagination />
                ) : isFetching ? (
                    <p>loading</p>
                ) : (
                    <p>{error ? error : "Data kosong"}</p>
                )}
            </div>
        </div>
    )

}

export default ManageKualitasPasaran