import axios from "axios";
import {alamat_backend} from "../components/api";


export const GET_LIST_PEGAWAI_JABATAN = "GET_LIST_PEGAWAI_JABATAN"
export const ADD_PEGAWAI_JABATAN = "ADD_PEGAWAI_JABATAN"
export const UPDATE_PEGAWAI_JABATAN = "UPDATE_PEGAWAI_JABATAN"
export const DELETE_PEGAWAI_JABATAN = "DELETE_PEGAWAI_JABATAN"


const getListPegawaiJabatan = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_LIST_PEGAWAI_JABATAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'PegawaiJabatanList?id='+id,
            timeout: 5000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_LIST_PEGAWAI_JABATAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_LIST_PEGAWAI_JABATAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const addPegawaiJabatan = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: ADD_PEGAWAI_JABATAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'PegawaiJabatan',
            timeout: 5000,
            data: data,
            headers: {
                "Content-Type": "multipart/form-data",
            }

        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: ADD_PEGAWAI_JABATAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: ADD_PEGAWAI_JABATAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const updatePegawaiJabatan = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: UPDATE_PEGAWAI_JABATAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'PUT',
            url: alamat_backend + 'PegawaiJabatan/' + data.id,
            timeout: 5000,
            data: data
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: UPDATE_PEGAWAI_JABATAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: UPDATE_PEGAWAI_JABATAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message
                    }
                })
            })
    }
}

const deletePegawaiJabatan = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: DELETE_PEGAWAI_JABATAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'DELETE',
            url: alamat_backend + 'PegawaiJabatan/'+ id,
            timeout: 5000,
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: DELETE_PEGAWAI_JABATAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: DELETE_PEGAWAI_JABATAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })
    }
}


export { getListPegawaiJabatan, addPegawaiJabatan, deletePegawaiJabatan, updatePegawaiJabatan }