import React, { useEffect, useState } from 'react'
import { useGetLaporanQuery } from '../../store/apis/laporanApi'
import DataTable from 'react-data-table-component';
import { format } from 'date-fns';
import secureLocalStorage from 'react-secure-storage';

const CetakStrukPengiriman = () => {
    const data = secureLocalStorage.getItem('data')

    useEffect(() => {
        window.print();
        // Function to handle the beforeunload event
        const handleBeforeUnload = () => {
            // Remove the data from local storage
            secureLocalStorage.removeItem('data');

        };

        // Add the beforeunload event listener
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <div id='printArea' className="relative p-6 flex-auto">
            <div className='flex gap-4 font-bold mb-2'>
                <div>
                    Nomor Pesanan: {data[0].penjualan.nomor_invoice}
                </div>
            </div>
            <div className='grid grid-cols-2 justify-between gap-4'>
                <div>
                    <div className='flex gap-1 font-bold '>
                        <div>
                            Penerima:
                        </div>
                        <div>
                            {data[0].retail_alamat_kirim.nama_penerima}
                        </div>
                    </div>
                    <div>
                        {data[0].retail_alamat_kirim.nomor_telp_penerima}
                    </div>
                    <div>
                        {data[0].retail_alamat_kirim.alamat},
                        {' kelurahan ' + data[0].retail_alamat_kirim.lokasi_kelurahan.nama},
                        {' Kecamatan ' + data[0].retail_alamat_kirim.lokasi_kelurahan.lokasi_kecamatan.nama},
                        {' ' + data[0].retail_alamat_kirim.lokasi_kelurahan.lokasi_kecamatan.lokasi_kotakab.nama},
                        {' ' + data[0].retail_alamat_kirim.lokasi_kelurahan.lokasi_kecamatan.lokasi_kotakab.lokasi_propinsi.nama}
                        {' ' + data[0].retail_alamat_kirim.lokasi_kelurahan.kode_pos}
                    </div>
                </div>
                <div>
                    <div className='flex gap-1 font-bold '>
                        <div>
                            Pengirim:
                        </div>
                        <div>
                            {data[0].distributor.nama}
                        </div>
                    </div>
                    <div>
                        {data[0].distributor.nomor_telp}
                    </div>
                    <div>
                        {data[0].distributor.alamat},
                        {' Kecamatan ' + data[0].distributor.lokasi_kelurahan.lokasi_kecamatan.nama},
                        {' ' + data[0].distributor.lokasi_kelurahan.lokasi_kecamatan.lokasi_kota_kab.nama},
                        {' ' + data[0].retail_alamat_kirim.lokasi_kelurahan.lokasi_kecamatan.lokasi_kotakab.lokasi_propinsi.nama}
                        {' ' + data[0].distributor.lokasi_kelurahan.kode_pos}
                    </div>
                </div>
            </div>
            <div className='border-t mt-4 py-4 border-black w-full'>
                <div className='w-full'>
                    <table className='w-full'>
                        <thead className='border-b'>
                            <tr className='text-left'>
                                <th className='pb-4 px-4'>Nama Produk</th>
                                <th className='pb-4 px-4'>Harga Produk</th>
                                <th className='pb-4 px-4'>Jumlah</th>
                                <th className='pb-4 px-4'>Total Harga</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data[0].penjualan_distributor_produks.map((data) => (
                                <tr key={data.id} className='border-t'>
                                    <td className='py-2 px-4'>{data.produk_varian.nama}</td>
                                    <td className='py-2 px-4'> {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data.harga)}</td>
                                    <td className='py-2 px-4'>{data.jumlah}</td>
                                    <td className='py-2 px-4'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data.harga * data.jumlah)}</td>
                                </tr>
                            ))}
                            <tr key={data.id} className='border-t'>
                                <td colSpan={3} className='py-2 px-4'>Biaya Pengiriman</td>
                                <td className='py-2 px-4'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data[0].opsi_pengiriman.harga)}</td>
                            </tr>
                            <tr key={data.id} className='border-t'>
                                <td colSpan={3} className='py-2 px-4 text-right font-bold'>Total Tagihan</td>
                                <td className='py-2 px-4 font-bold'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data[0].totalTagihan + data[0].opsi_pengiriman.harga)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export { CetakStrukPengiriman }