import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const penjualanApi = createApi({
    reducerPath: 'penjualan',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getListPenjualanDistributor: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'penjualan' }];

                },
                query: ({id,status}) => {
                    return {
                        url: 'PenjualanDistributorProdukListDistributor',
                        method: 'GET',
                        params: { id, status }
                    };
                },
            }),
            getListCountPenjualanDistributor: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'penjualan' }];

                },
                query: (id) => {
                    return {
                        url: 'PenjualanDistributorProdukCount',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            getListPenjualanRetail: builder.query({
                query: ({id, status}) => {
                    return {
                        url: 'PenjualanDistributorProdukListRetail',
                        method: 'GET',
                        params: { id, status }
                    };
                },
            }),
            showStokSetting: builder.query({
                query: (id) => {
                    return {
                        url: 'ShowStokSetting',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            showDetailPengiriman: builder.query({
                query: (id) => {
                    return {
                        url: 'showDetailPengiriman',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            addPenjualan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'penjualan' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'PenjualanDistributorProduk',
                        body: data,
                    };
                },
            }),
            updatePenjualan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'penjualan' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `PenjualanDistributorProduk/${data.id}`,
                        body: data,
                    };
                },
            }),
            updatePenjualanStatus: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'penjualan' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `PenjualanDistributorProdukStatus`,
                        body: data,
                    };
                },
            }),
            kirimProduk: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'penjualan' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `KirimProduk`,
                        body: data,
                    };
                },
            }),
            pengirimanSelesai: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'penjualan' }];
                },
                query: (data) => {
                    const formData = new FormData();
                    formData.append('nomor_faktur', data.nomor_faktur);
                    formData.append('tanggal', data.tanggal);
                    formData.append('foto', data.foto);
                    formData.append('status', data.status);
                    formData.append('id', data.penjualan_distributor_id);
                    formData.append('pegawai_id', data.pegawai_id);
                    console.log(formData);
                    return {
                        method: 'POST',
                        url: `PengirimanSelesai`,
                        body: formData,
                    };
                },
            }),
            deletePenjualan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'penjualan' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `penjualan/${id}`,
                    };
                },
            }),
        };
    },
});

export const {  useGetListPenjualanDistributorQuery, useGetListCountPenjualanDistributorQuery, useGetListPenjualanRetailQuery, useShowStokSettingQuery, useShowDetailPengirimanQuery, useAddPenjualanMutation, useUpdatePenjualanMutation, useUpdatePenjualanStatusMutation, useKirimProdukMutation, usePengirimanSelesaiMutation, useDeletePenjualanMutation } = penjualanApi;
export { penjualanApi };