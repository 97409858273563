import React, { useEffect, useState } from 'react'
import { format } from 'date-fns';
import secureLocalStorage from 'react-secure-storage';

const CetakInvoicePembelian = () => {
    const data = secureLocalStorage.getItem('data')
    const pabrik = secureLocalStorage.getItem('pabrik');
    const nomor_invoice = secureLocalStorage.getItem('nomor_invoice');
    const tanggal_transaksi = secureLocalStorage.getItem('tanggal_transaksi');
    const total = secureLocalStorage.getItem('total');

    useEffect(() => {
        window.print();
        // Function to handle the beforeunload event
        const handleBeforeUnload = () => {
            // Remove the data from local storage
            secureLocalStorage.removeItem('data');

        };

        // Add the beforeunload event listener
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <div className='w-full  p-5  '>
            <div className='w-full bg-white p-5 rounded-lg '>
                <div className='flex w-full justify-between'>
                    <div>
                        Pabrik : {pabrik}
                    </div>
                    <div>
                        <div>
                            Invoice #{nomor_invoice}
                        </div>
                        <div className='text-right'>
                            {format(new Date(tanggal_transaksi), 'dd/MM/yyyy')}

                        </div>
                    </div>
                </div>


                <div className='grid grid-cols-4 mt-4'>
                    <div className=' px-4 border-slate-500 py-2 col-span-2 border font-bold'>
                        Produk
                    </div>
                    <div className='px-4 border-slate-500 py-2 border  font-bold'>
                        Jumlah Beli
                    </div>
                    <div className='text-right px-4 border-slate-500 py-2 border font-bold'>
                        Harga Beli
                    </div>

                </div>
                {data.map((item) =>
                    <div className='grid grid-cols-4'>
                        <div className=' px-4 border-slate-500 py-2 col-span-2 border'>
                            {item.varian}
                        </div>
                        <div className='px-4 border-slate-500 py-2 border '>
                            {item.jumlah_beli}
                        </div>
                        <div className='text-right px-4 border-slate-500 py-2 border'>
                            {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.harga_beli)}
                        </div>

                    </div>
                )}


                <div className='flex justify-between w-full p-2 mt-3   text-2xl rounded-lg'>
                    <div>
                        Total Harga
                    </div>
                    <div>
                        {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(total)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export { CetakInvoicePembelian }