import React from 'react'
import ContactPopup from '../../components/ContactPopup'
import { useGetDataDashboardDistributorQuery } from '../../store/apis/laporanApi'
import secureLocalStorage from 'react-secure-storage'
import { Line } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { MdAssignment } from 'react-icons/md'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
const DashboardDistributor = () => {
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const { data, error, isFetching } = useGetDataDashboardDistributorQuery(id);
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Pengeluaran dan Pemasukan Bulanan Tahun Ini',
            },
        },
    };
    const graphData = {
        labels: data ? data.income.map(item => item.month) : [],
        datasets: [
            {
                label: 'Pemasukan',
                data: data ? data.income.map(item => item.nominal) : [],
                borderColor: 'rgb(59, 130, 246)',
                backgroundColor: 'rgb(59, 130, 246, 0.5)',
            },
            {
                label: 'Pengeluaran',
                data: data ? data.outcome.map(item => item.nominal) : [],
                borderColor: 'rgb(239, 68, 68)',
                backgroundColor: 'rgb(239, 68, 68, 0.5)',
            }
        ],
    };

    console.log(graphData)
    return (
        <div className='w-full'>
            <div className='border p-5 rounded-lg '>

                <h1 className='text-xl px-5 pt-10'>Dashboard</h1>

                <div className='p-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2'>
                    <div className='rounded bg-white border p-4 border-slate-300 flex gap-3'>
                        <MdAssignment className='place-self-center text-3xl text-blue-500' />
                        <div>
                            <p className='font-semibold text-lg'>Jumlah Produk</p>
                            <p className='text-blue-500 font-medium text-base'>{data ? data.totalProduk : 0}</p>
                        </div>
                    </div>
                    <div className='rounded bg-white border p-4 border-slate-300 flex gap-3'>
                        <MdAssignment className='place-self-center text-3xl text-blue-500' />
                        <div>
                            <p className='font-semibold text-lg'>Jumlah Varian Produk</p>
                            <p className='text-blue-500 font-medium text-base'>{data ? data.totalVarian ? data.totalVarian : 0 : 0}</p>
                        </div>
                    </div>
                    <div className='rounded bg-white border p-4 border-slate-300 flex gap-3'>
                        <MdAssignment className='place-self-center text-3xl text-blue-500' />
                        <div>
                            <p className='font-semibold text-lg'>Penjualan yang Terjadi</p>
                            <p className='text-blue-500 font-medium text-base'>{data ? data.penjualan : 0}</p>
                        </div>
                    </div>
                </div>
                <div className='px-5 pb-5 grid grid-cols-1 md:grid-cols-2 gap-4'>

                    <div className='rounded bg-white border p-4 border-slate-300 flex gap-3'>
                        <MdAssignment className='place-self-start text-3xl text-blue-500 mt-2' />
                        <div>
                            <p className='font-semibold text-lg'>Produk Terlaku</p>
                            <p className=''>{data ? data.produk ? data.produk.map((item, index) => {
                                return (
                                    <ul>
                                        <li>{index + 1}. {item.nama}: <span className='text-blue-500 font-medium text-base'>{item.jumlah}</span></li>
                                    </ul>
                                )
                            }) : 'Belum ada produk yang terjual' : 0}</p>
                        </div>
                    </div>

                    <div className='rounded bg-white border p-4 border-slate-300 flex gap-3'>
                        <MdAssignment className='place-self-start text-3xl text-blue-500 mt-2' />
                        <div>
                            <p className='font-semibold text-lg'>Produk Diretur</p>
                            <p className=''>{data ? data.retur ? data.retur.map((item, index) => {
                                return (
                                    <ul>
                                        <li>{index + 1}. {item.nama}: <span className='text-blue-500 font-medium text-base'>{item.jumlah}</span></li>
                                    </ul>
                                )
                            }) :  'Belum ada produk yang diretur' : 0}</p>
                        </div>
                    </div>
                </div>
                <div className='px-5 pb-10'>
                    <div className='bg-white border p-5 rounded-lg '>

                        <Line options={options} data={graphData} />
                    </div>
                </div>

            </div>

        </div>
    )
}

export { DashboardDistributor }