import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { produkVarianApi } from "./apis/produkVarianApi";
import DistributorReducer from '../reducers/distributor';
import PropinsiReducer from '../reducers/propinsi';
import KabkotReducer from '../reducers/kabkot';
import KecamatanReducer from '../reducers/kecamatan';
import RetailReducer from '../reducers/retail';
import ProdukKategoriReducer from '../reducers/produkKategori';
import ProdukSubKategoriReducer from '../reducers/produkSubKategori';
import ProdukSubSubKategoriReducer from '../reducers/produkSubSubKategori';
import PegawaiReducer from '../reducers/pegawai';
import JabatanReducer from '../reducers/jabatan';
import PegawaiJabatanReducer from '../reducers/pegawaiJabatan';
import KualitasPasarReducer from '../reducers/kualitasPasar';
import BrandReducer from '../reducers/produkBrand';
import VarianReducer from '../reducers/produkVarian';
import ProdukReducer from '../reducers/produk';
import PabrikReducer from '../reducers/pabrik';
import StokReducer from '../reducers/produkStok';
import StokDetailReducer from '../reducers/produkStokDetail';
import ProdukFotoReducer from '../reducers/produkFoto';
import userReducer from '../reducers/user';
import auth from '../reducers/auth';
import { produkFotoApi } from "./apis/produkFotoApi";
import { retailApi } from "./apis/retailApi";
import { subKategoriProduk } from "./apis/subKategoriProdukApi";
import { produkUkuran } from "./apis/produkUkuranApi";
import { produkWarna } from "./apis/produkWarnaApi";
import { produkApi } from "./apis/produkApi";
import { lokasiKelurahanApi } from "./apis/lokasiKelurahanApi";
import { KategoriProdukApi } from "./apis/kategoriProdukApi";
import { subSubKategoriProdukApi } from "./apis/subSubKategoriProdukApi";
import { pabrikApi } from "./apis/pabrikApi";
import { produkStokApi } from "./apis/produkStokApi";
import { produkStokDetailApi } from "./apis/produkStokDetailApi";
import { opsiPengirimanApi } from "./apis/opsiPengirimanApi";
import { keranjangApi } from "./apis/keranjangApi";
import { produkBrandApi } from "./apis/produkBrandApi";
import { pegawaiApi } from "./apis/pegawaiApi";
import { alamatKirimApi } from "./apis/alamatKirimApi";
import { penjualanApi } from "./apis/penjualanApi";
import { jabatanApi } from "./apis/jabatanApi";
import { pegawaiJabatanApi } from "./apis/pegawaiJabatanApi";
import { pembayaranRetailApi } from "./apis/pembayaranRetailApi";
import { pegawaiDodolanApi } from "./apis/pegawaiDodolanApi";
import { pembayaranDistributorApi } from "./apis/pembayaranDistributorApi";
import { authApi } from "./apis/authApi";
import { kualitasPasarApi } from "./apis/kualitasPasarApi";
import { laporanApi } from "./apis/laporanApi";
import { distributorApi } from "./apis/distributorApi";
import { blacklistApi } from "./apis/blacklistApi";
import { lokasiKecamatanApi } from "./apis/lokasiKecamatanApi";
import { lokasiKotKabApi } from "./apis/lokasiKotKabApi";
import { lokasiPropinsiApi } from "./apis/lokasiPropinsi";
import { penjualanSerahTerimaApi } from "./apis/penjualanSerahTerimaApi";
import { returProdukApi } from "./apis/returProdukApi";
import { ModalTahunanApi } from "./apis/modalTahunan";


const store = configureStore({
    reducer: {
        DistributorReducer: DistributorReducer,
        PropinsiReducer: PropinsiReducer,
        KabkotReducer: KabkotReducer,
        KecamatanReducer: KecamatanReducer,
        RetailReducer: RetailReducer,
        ProdukKategoriReducer: ProdukKategoriReducer,
        ProdukSubKategoriReducer: ProdukSubKategoriReducer,
        ProdukSubSubKategoriReducer: ProdukSubSubKategoriReducer,
        PegawaiReducer: PegawaiReducer,
        JabatanReducer: JabatanReducer,
        PegawaiJabatanReducer: PegawaiJabatanReducer,
        KualitasPasarReducer: KualitasPasarReducer,
        BrandReducer: BrandReducer,
        VarianReducer: VarianReducer,
        ProdukReducer: ProdukReducer,
        PabrikReducer: PabrikReducer,
        StokReducer: StokReducer,
        StokDetailReducer: StokDetailReducer,
        ProdukFotoReducer: ProdukFotoReducer,
        userReducer: userReducer,
        auth: auth,
        [produkVarianApi.reducerPath]: produkVarianApi.reducer,
        [produkFotoApi.reducerPath]: produkFotoApi.reducer,
        [retailApi.reducerPath]: retailApi.reducer,
        [subKategoriProduk.reducerPath]: subKategoriProduk.reducer,
        [produkUkuran.reducerPath]: produkUkuran.reducer,
        [produkWarna.reducerPath]: produkWarna.reducer,
        [produkApi.reducerPath]: produkApi.reducer,
        [lokasiKelurahanApi.reducerPath]: lokasiKelurahanApi.reducer,
        [KategoriProdukApi.reducerPath]: KategoriProdukApi.reducer,
        [subSubKategoriProdukApi.reducerPath]: subSubKategoriProdukApi.reducer,
        [pabrikApi.reducerPath]: pabrikApi.reducer,
        [produkStokApi.reducerPath]: produkStokApi.reducer,
        [produkStokDetailApi.reducerPath]: produkStokDetailApi.reducer,
        [opsiPengirimanApi.reducerPath]: opsiPengirimanApi.reducer,
        [keranjangApi.reducerPath]: keranjangApi.reducer,
        [produkBrandApi.reducerPath]: produkBrandApi.reducer,
        [pegawaiApi.reducerPath]: pegawaiApi.reducer,
        [alamatKirimApi.reducerPath]: alamatKirimApi.reducer,
        [penjualanApi.reducerPath]: penjualanApi.reducer,
        [jabatanApi.reducerPath]: jabatanApi.reducer,
        [pegawaiJabatanApi.reducerPath]: pegawaiJabatanApi.reducer,
        [pembayaranRetailApi.reducerPath]: pembayaranRetailApi.reducer,
        [pegawaiDodolanApi.reducerPath]: pegawaiDodolanApi.reducer,
        [pembayaranDistributorApi.reducerPath]: pembayaranDistributorApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [kualitasPasarApi.reducerPath]: kualitasPasarApi.reducer,
        [laporanApi.reducerPath]: laporanApi.reducer,
        [distributorApi.reducerPath]: distributorApi.reducer,
        [blacklistApi.reducerPath]: blacklistApi.reducer,
        [lokasiKecamatanApi.reducerPath]: lokasiKecamatanApi.reducer,
        [lokasiKotKabApi.reducerPath]: lokasiKotKabApi.reducer,
        [lokasiPropinsiApi.reducerPath]: lokasiPropinsiApi.reducer,
        [penjualanSerahTerimaApi.reducerPath]: penjualanSerahTerimaApi.reducer,
        [returProdukApi.reducerPath]: returProdukApi.reducer,
        [ModalTahunanApi.reducerPath]: ModalTahunanApi.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .concat(produkVarianApi.middleware)
            .concat(produkFotoApi.middleware)
            .concat(retailApi.middleware)
            .concat(subKategoriProduk.middleware)
            .concat(produkUkuran.middleware)
            .concat(produkWarna.middleware)
            .concat(produkApi.middleware)
            .concat(lokasiKelurahanApi.middleware)
            .concat(KategoriProdukApi.middleware)
            .concat(subSubKategoriProdukApi.middleware)
            .concat(pabrikApi.middleware)
            .concat(produkStokApi.middleware)
            .concat(produkStokDetailApi.middleware)
            .concat(opsiPengirimanApi.middleware)
            .concat(keranjangApi.middleware)
            .concat(produkBrandApi.middleware)
            .concat(pegawaiApi.middleware)
            .concat(alamatKirimApi.middleware)
            .concat(penjualanApi.middleware)
            .concat(jabatanApi.middleware)
            .concat(pegawaiJabatanApi.middleware)
            .concat(pembayaranRetailApi.middleware)
            .concat(pegawaiDodolanApi.middleware)
            .concat(pembayaranDistributorApi.middleware)
            .concat(authApi.middleware)
            .concat(kualitasPasarApi.middleware)
            .concat(laporanApi.middleware)
            .concat(distributorApi.middleware)
            .concat(blacklistApi.middleware)
            .concat(lokasiKecamatanApi.middleware)
            .concat(lokasiKotKabApi.middleware)
            .concat(lokasiPropinsiApi.middleware)
            .concat(penjualanSerahTerimaApi.middleware)
            .concat(returProdukApi.middleware)
            .concat(ModalTahunanApi.middleware)

    },

});

setupListeners(store.dispatch);

export default store;