import axios from "axios";
import {alamat_backend} from "../components/api";


export const GET_LIST_JABATAN = "GET_LIST_JABATAN"
export const ADD_JABATAN = "ADD_JABATAN"
export const UPDATE_JABATAN = "UPDATE_JABATAN"
export const DELETE_JABATAN = "DELETE_JABATAN"


const getListJabatan = (id) => {
    return (dispatch) => {
        //loading
        dispatch({
            type: GET_LIST_JABATAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })
        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'JabatanList?id=' + id,
            timeout: 5000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_LIST_JABATAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_LIST_JABATAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })
    }
}

const addJabatan = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: ADD_JABATAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'Jabatan',
            timeout: 5000,
            data: data,

        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: ADD_JABATAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: ADD_JABATAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const updateJabatan = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: UPDATE_JABATAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'PUT',
            url: alamat_backend + 'Jabatan/' + data.id,
            timeout: 5000,
            data: data
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: UPDATE_JABATAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: UPDATE_JABATAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message
                    }
                })
            })
    }
}

const deleteJabatan = (id) => {
    
    return (dispatch) => {

        //loading
        dispatch({
            type: DELETE_JABATAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'DELETE',
            url: alamat_backend + 'Jabatan/'+ id,
            timeout: 5000,
        })
            .then((Response) => {
                
                //berhasil get api
                dispatch({
                    type: DELETE_JABATAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                
                dispatch({
                    type: DELETE_JABATAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })
    }
}


export { getListJabatan, addJabatan, deleteJabatan, updateJabatan }