import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const penjualanSerahTerimaApi = createApi({
    reducerPath: 'PenjualanSerahTerima',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getPenjualanSerahTerima: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'PenjualanSerahTerima' }];

                },
                query: (id) => {
                    return {
                        url: 'PenjualanSerahTerima',
                        method: 'GET',
                        params: {id}
                    };
                },
            }),
        };
    },
});

export const { useGetPenjualanSerahTerimaQuery } = penjualanSerahTerimaApi;
export { penjualanSerahTerimaApi };