import { ADD_PRODUK_STOK, DELETE_PRODUK_STOK, GET_LIST_PRODUK_STOK, UPDATE_PRODUK_STOK } from "../../actions/produkStokAction"

const initialState = {
    getListStokResult: false,
    getListStokLoading: false,
    getListStokError: false,

    addStokResult: false,
    addStokLoading: false,
    addStokError: false,

    deleteStokResult: false,
    deleteStokLoading: false,
    deleteStokError: false,

    updateStokResult: false,
    updateStokLoading: false,
    updateStokError: false,
}


const produkStok = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_PRODUK_STOK:
            return {
                ...state,
                getListStokResult: action.payload.data,
                getListStokLoading: action.payload.loading,
                getListStokError: action.payload.errorMessage,
            }

        case ADD_PRODUK_STOK:
            return {
                ...state,
                addStokResult: action.payload.data,
                addStokLoading: action.payload.loading,
                addStokError: action.payload.errorMessage,
            }
        case DELETE_PRODUK_STOK:
            return {
                ...state,
                deleteStokResult: action.payload.data,
                deleteStokLoading: action.payload.loading,
                deleteStokError: action.payload.errorMessage,
            }
        case UPDATE_PRODUK_STOK:
            return {
                ...state,
                updateStokResult: action.payload.data,
                updateStokLoading: action.payload.loading,
                updateStokError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default produkStok;