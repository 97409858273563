import { GET_LIST_DISTRIBUTOR, ADD_DISTRIBUTOR, GET_DETAIL_DISTRIBUTOR } from "../../actions/distributorAction";

const initialState = {
    getListDistributorResult: false,
    getListDistributorLoading: false,
    getListDistributorError: false,

    getDetailDistributorResult: false,
    getDetailDistributorLoading: false,
    getDetailDistributorError: false,

    addDistributorResult: false,
    addDistributorLoading: false,
    addDistributorError: false,


}


const distributor = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_DISTRIBUTOR:
            return {
                ...state,
                getListDistributorResult: action.payload.data,
                getListDistributorLoading: action.payload.loading,
                getListDistributorError: action.payload.errorMessage,
            }
        case ADD_DISTRIBUTOR:
            return {
                ...state,
                addDistributorResult: action.payload.data,
                addDistributorLoading: action.payload.loading,
                addDistributorError: action.payload.errorMessage,
            }
        case GET_DETAIL_DISTRIBUTOR:
            return {
                ...state,
                getDetailDistributorResult: action.payload.data,
                getDetailDistributorLoading: action.payload.loading,
                getDetailDistributorError: action.payload.errorMessage,
            }

        default:
            return state;
    }
}

export default distributor;