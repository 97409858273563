import { ADD_KECAMATAN, DELETE_KECAMATAN, GET_DATA_LIST_KECAMATAN, GET_LIST_KECAMATAN, UPDATE_KECAMATAN } from "../../actions/kecamatanAction";

const initialState = {
    getListKecamatanResult: false,
    getListKecamatanLoading: false,
    getListKecamatanError: false,

    getDataListKecamatanResult: false,
    getDataListKecamatanLoading: false,
    getDataListKecamatanError: false,

    addKecamatanResult: false,
    addKecamatanLoading: false,
    addKecamatanError: false,

    updateKecamatanResult: false,
    updateKecamatanLoading: false,
    updateKecamatanError: false,

    deleteKecamatanResult: false,
    deleteKecamatanLoading: false,
    deleteKecamatanError: false,


}


const kecamatan = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_KECAMATAN:
            return {
                ...state,
                getListKecamatanResult: action.payload.data,
                getListKecamatanLoading: action.payload.loading,
                getListKecamatanError: action.payload.errorMessage,
            }
        case GET_DATA_LIST_KECAMATAN:
            return {
                ...state,
                getDataListKecamatanResult: action.payload.data,
                getDataListKecamatanLoading: action.payload.loading,
                getDataListKecamatanError: action.payload.errorMessage,
            }
        case ADD_KECAMATAN:
            return {
                ...state,
                addKecamatanResult: action.payload.data,
                addKecamatanLoading: action.payload.loading,
                addKecamatanError: action.payload.errorMessage,
            }
        case DELETE_KECAMATAN:
            return {
                ...state,
                deleteKecamatanResult: action.payload.data,
                deleteKecamatanLoading: action.payload.loading,
                deleteKecamatanError: action.payload.errorMessage,
            }
        case UPDATE_KECAMATAN:
            return {
                ...state,
                updateKecamatanResult: action.payload.data,
                updateKecamatanLoading: action.payload.loading,
                updateKecamatanError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default kecamatan;