import { ADD_SUB_SUB_KATEGORI_PRODUK, DELETE_SUB_SUB_KATEGORI_PRODUK, GET_LIST_SUB_SUB_KATEGORI_PRODUK, UPDATE_SUB_SUB_KATEGORI_PRODUK } from "../../actions/subSubKategoriProdukAction";

const initialState = {
    getListSubSubKategoriResult: false,
    getListSubSubKategoriLoading: false,
    getListSubSubKategoriError: false,

    addSubSubKategoriResult: false,
    addSubSubKategoriLoading: false,
    addSubSubKategoriError: false,

    updateSubSubKategoriResult: false,
    updateSubSubKategoriLoading: false,
    updateSubSubKategoriError: false,

    deleteSubSubKategoriResult: false,
    deleteSubSubKategoriLoading: false,
    deleteSubSubKategoriError: false,
}


const subSubKategori = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_SUB_SUB_KATEGORI_PRODUK:
            return {
                ...state,
                getListSubSubKategoriResult: action.payload.data,
                getListSubSubKategoriLoading: action.payload.loading,
                getListSubSubKategoriError: action.payload.errorMessage,
            }

        case ADD_SUB_SUB_KATEGORI_PRODUK:
            return {
                ...state,
                addSubSubKategoriResult: action.payload.data,
                addSubSubKategoriLoading: action.payload.loading,
                addSubSubKategoriError: action.payload.errorMessage,
            }
        case UPDATE_SUB_SUB_KATEGORI_PRODUK:
            return {
                ...state,
                updateSubSubKategoriResult: action.payload.data,
                updateSubSubKategoriLoading: action.payload.loading,
                updateSubSubKategoriError: action.payload.errorMessage,
            }
        case DELETE_SUB_SUB_KATEGORI_PRODUK:
            return {
                ...state,
                deleteSubSubKategoriResult: action.payload.data,
                deleteSubSubKategoriLoading: action.payload.loading,
                deleteSubSubKategoriError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default subSubKategori;