import React, { Fragment, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { MdDelete } from 'react-icons/md';
import { getUserData } from '../../actions/auth';
import { AddProduk } from './AddProductDist';
import { EditProduk } from './EditProductDist';
import Varian from './Varian';
import { useDeleteProductMutation, useGetProductListQuery } from '../../store/apis/produkApi';

const ManageProductDist = () => {
    const [id, setId] = useState(null);
    const [addProductForm, setAddProductForm] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const userData = await getUserData();
            setId(userData.id)
        };
        fetchData();
    }, []);
    const { data, error, isFetching } = useGetProductListQuery(id)
    const [deleteProduct] = useDeleteProductMutation();

    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            deleteProduct(id)
        }
    }

    
    const ShowModal = (e) => {
        if(addProductForm){
            setAddProductForm(false);
        }else{
            setAddProductForm(true);
        }
    }
    
    const colProduk = [
        {
            name: 'Produk',
            selector: row => row.nama,
            sortable: true,
        },
        {
            name: 'Kualitas Pasaran',
            selector: row => row.produk_kualitas_pasaran.nama,
            sortable: true,
        },
        {
            name: 'Label Harga',
            selector: row => row.label_harga,
            sortable: true,
        },
        {
            name: 'Sub Sub Kategori Produk',
            selector: row => row.produk_sub_sub_kategori.nama,
            sortable: true,
        },
        {
            key: "Varian",
            name: "Varian",
            sortable: false,
            cell: (record) => {
                return (
                    <Fragment>
                        <Varian data={record} />
                    </Fragment>
                );
            },
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idKP = record.id;
                return (
                    <Fragment>
                        <EditProduk data={record} />

                        <span className='mx-3'>|</span>

                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, idKP, "produk")}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]

    return (
        <div className='w-full  p-5  '>
            {addProductForm && <AddProduk tipe='password' setShowModal={ShowModal}/> }
            <div className='w-full bg-white border p-5 rounded-lg '>
                <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                    <div className='col-span-3 self-center'>
                        <h1 className='text-base font-bold text-blue-500 '>Data Produk</h1>
                        <p className='text-slate-400'>Di bawah ini merupakan data semua Produk yang telah terdaftar.</p>
                    </div>
                    <div className='place-self-end'>
                        <button
                            className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={(e) => ShowModal(e)}
                        >
                            Tambah Data
                        </button>
                    </div>
                    <div className='col-span-3' />

                </div>
                {data ? (
                    <DataTable columns={colProduk} data={data} pagination />
                ) : isFetching ? (
                    <p>loading</p>
                ) : (
                    <p>{error ? error : "Data kosong"}</p>
                )}
            </div>


        </div>
    )

}

export default ManageProductDist