import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const produkWarna = createApi({
    reducerPath: 'produkWarna',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getWarna: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'warna' }];

                },
                query: () => {
                    return {
                        url: 'ProdukWarna',
                        method: 'GET',
                    };
                },
            }),
            getListWarna: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'warna' }];

                },
                query: (id) => {
                    return {
                        url: 'ProdukWarnaList',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            addWarna: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'warna' }];
                },
                query: (data) => {
                    const formData = new FormData();
                    formData.append('nama', data.nama)
                    formData.append('kode', data.kode)
                    formData.append('kode_RGB', data.status)
                    formData.append('path_foto_warna', data.path_foto_warna)
                    formData.append('status', data.status)
                    formData.append('distributor_id', data.distributor_id)

                    return {
                        method: 'POST',
                        url: 'ProdukWarna',
                        body: formData,
                    };
                },
            }),
            updateWarna: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'warna' }];
                },
                query: (data) => {
                    const formData = new FormData();

                    formData.append('nama', data.nama)
                    formData.append('kode', data.kode)
                    formData.append('kode_RGB', data.status)
                    formData.append('path_foto_warna', data.path_foto_warna)
                    formData.append('status', data.status)
                    formData.append('id', data.id)
                    return {
                        method: 'POST',
                        url: 'ProdukWarnaUpdate',
                        body: data,
                    };
                },
            }),
            deleteWarna: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'warna' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `ProdukWarna/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetWarnaQuery, useGetListWarnaQuery, useAddWarnaMutation, useUpdateWarnaMutation, useDeleteWarnaMutation } = produkWarna;
export { produkWarna };