import axios from "axios";
import {alamat_backend} from "../components/api";


export const GET_LIST_KECAMATAN = "GET_LIST_KECAMATAN"
export const GET_DATA_LIST_KECAMATAN = "GET_DATA_LIST_KECAMATAN"
export const UPDATE_KECAMATAN = "UPDATE_KECAMATAN"
export const DELETE_KECAMATAN = "DELETE_KECAMATAN"

export const ADD_KECAMATAN = "ADD_KECAMATAN"


const getListKecamatan = () => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_LIST_KECAMATAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'LokasiKecamatanList',
            timeout: 120000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_LIST_KECAMATAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                
                dispatch({
                    type: GET_LIST_KECAMATAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const getDataListKecamatan = () => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_DATA_LIST_KECAMATAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'LokasiKecamatanDataList',
            timeout: 120000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_DATA_LIST_KECAMATAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                
                dispatch({
                    type: GET_DATA_LIST_KECAMATAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const addKecamatan = (data) => {
    
    return (dispatch) => {

        //loading
        dispatch({
            type: ADD_KECAMATAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'LokasiKecamatan',
            timeout: 120000,
            data: data
        })
            .then((Response) => {
                
                //berhasil get api
                dispatch({
                    type: ADD_KECAMATAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                
                dispatch({
                    type: ADD_KECAMATAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })
    }
}

const updateKecamatan = (data) => {
    console.log("action")
    return (dispatch) => {

        //loading
        dispatch({
            type: UPDATE_KECAMATAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'PUT',
            url: alamat_backend + 'LokasiKecamatan/' + data.id,
            timeout: 120000,
            data: data
        })
            .then((Response) => {
                console.log(Response.data)
                //berhasil get api
                dispatch({
                    type: UPDATE_KECAMATAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                console.log("gagal", error.message)
                dispatch({
                    type: UPDATE_KECAMATAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const deleteKecamatan = (id) => {
    
    return (dispatch) => {

        //loading
        dispatch({
            type: DELETE_KECAMATAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'DELETE',
            url: alamat_backend + 'LokasiKecamatan/'+id,
            timeout: 120000,
        })
            .then((Response) => {
                
                //berhasil get api
                dispatch({
                    type: DELETE_KECAMATAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                
                dispatch({
                    type: DELETE_KECAMATAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}


export { getListKecamatan, addKecamatan, getDataListKecamatan, deleteKecamatan, updateKecamatan }