import React from 'react'
import ContactPopup from '../../components/ContactPopup'
import { useGetDataDashboardAdminQuery, useGetDataDashboardDistributorQuery } from '../../store/apis/laporanApi'
import secureLocalStorage from 'react-secure-storage'
import { Line } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { MdAssignment } from 'react-icons/md'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
const DashboardAdmin = () => {
    const { data, error, isFetching } = useGetDataDashboardAdminQuery();
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Pemasukan Bulanan Tahun Ini',
            },
        },
    };
    const graphData = {
        labels: data ? data.pemasukan.map(item => item.month) : [],
        datasets: [
            {
                label: 'Pemasukan',
                data: data ? data.pemasukan.map(item => item.nominal) : [],
                borderColor: 'rgb(59, 130, 246)',
                backgroundColor: 'rgb(59, 130, 246, 0.5)',
            },
        ],
    };

    return (
        <div className='w-full'>
            <div className='border p-5 rounded-lg '>

                <h1 className='text-xl px-5 pt-10'>Dashboard</h1>

                <div className='p-5 grid grid-cols-1 md:grid-cols-2 gap-2'>
                    <div className='rounded bg-white border p-4 border-slate-300 flex gap-3'>
                        <MdAssignment className='place-self-center text-3xl text-blue-500' />
                        <div>
                            <p className='font-semibold text-lg'>Jumlah Distributor</p>
                            <p className='text-blue-500 font-medium text-base'>{data ? data.distributor? data.distributor : 0 : 0}</p>
                        </div>
                    </div>
                    <div className='rounded bg-white border p-4 border-slate-300 flex gap-3'>
                        <MdAssignment className='place-self-center text-3xl text-blue-500' />
                        <div>
                            <p className='font-semibold text-lg'>Jumlah Retail</p>
                            <p className='text-blue-500 font-medium text-base'>{data ? data.retail ? data.retail : 0 : 0}</p>
                        </div>
                    </div>
                </div>
                <div className='px-5 pb-10'>
                    <div className='bg-white border p-5 rounded-lg '>

                        <Line options={options} data={graphData} />
                    </div>
                </div>

            </div>

        </div>
    )
}

export { DashboardAdmin }