import { Combobox, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react'
import { MdCheck, MdEdit, MdUnfoldMore } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { updateKabkot } from '../../actions/kabkotAction';
import { updateKecamatan } from '../../actions/kecamatanAction';
import { updatePropinsi } from '../../actions/propinsiAction';
import { useUpdateKelurahanMutation } from '../../store/apis/lokasiKelurahanApi';
import { useGetListKecamatanQuery, useUpdateKecamatanMutation } from '../../store/apis/lokasiKecamatanApi';
import { useGetListKotKabQuery, useUpdateKotKabMutation } from '../../store/apis/lokasiKotKabApi';
import { useGetPropinsiQuery } from '../../store/apis/lokasiPropinsi';

const EditPropinsi = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [nama, setNama] = useState(props.data.nama);
    const id = props.data.id


    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();

        dispatch(updatePropinsi({ id: id, nama: nama }))
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    return (
        <div>
            <button
                type="submit"
                onClick={() => setShowModal(true)}
                className="text-blue-500 text-lg cursor-pointer"
            >
                <MdEdit />
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Update Provinsi
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            id="floating_standard"
                                            placeholder=' '
                                            value={nama}
                                            onChange={(event) => setNama(event.target.value)}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nama Provinsi</label>
                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

const EditKabkot = (props) => {

    const [showModal, setShowModal] = useState(false);
    const [nama, setNama] = useState(props.data.nama);
    const getListPropinsiResult = useGetPropinsiQuery().data;
    const [selected, setSelected] = useState({ 'nama': props.data.provinsi, 'id': props.data.lokasi_propinsi_id })
    const id = props.data.id;
    const [updateKotKab, updateKotKabResult] = useUpdateKotKabMutation();

    const [query, setQuery] = useState('')

    //Redux

    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();

        updateKotKab({ id: id, nama: nama, lokasi_propinsi_id: selected.id })
        setShowModal(false)
    }

    const filteredProvinsi =
    getListPropinsiResult?(
        query === ''
            ? getListPropinsiResult
            : getListPropinsiResult.filter((provinsi) =>
                provinsi.nama
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )):[]

    return (
        <div>
            <button
                type="submit"
                onClick={() => setShowModal(true)}
                className="text-blue-500 text-lg cursor-pointer"
            >
                <MdEdit />
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Tambah Provinsi
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            id="floating_standard"
                                            value={nama}
                                            onChange={(event) => setNama(event.target.value)}
                                            placeholder=' '
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nama Kabupaten / Kota</label>
                                    </div>
                                    <p className=" text-slate-500 text-xs mt-5">Provinsi</p>

                                    <Combobox value={selected} onChange={setSelected}>

                                        <div className="relative mt-1">
                                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                <Combobox.Input
                                                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                    displayValue={(provinsi) => provinsi.nama}
                                                    onChange={(event) => setQuery(event.target.value)}
                                                />
                                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <MdUnfoldMore />

                                                </Combobox.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                afterLeave={() => setQuery('')}
                                            >
                                                <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {filteredProvinsi.length === 0 && query !== '' ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        filteredProvinsi.map((provinsi) => (
                                                            <Combobox.Option
                                                                key={provinsi.id}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                    }`
                                                                }
                                                                value={provinsi}
                                                            >
                                                                {({ selected, active }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                                                }`}
                                                                        >
                                                                            {provinsi.nama}
                                                                        </span>
                                                                        {selected ? (
                                                                            <span
                                                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                    }`}
                                                                            >
                                                                                <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </Combobox.Options>

                                            </Transition>
                                        </div>
                                    </Combobox>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

const EditKecamatan = (props) => {

    const [showModal, setShowModal] = useState(false);
    const [query, setQuery] = useState('')
    const [nama, setNama] = useState(props.data.nama)
    const [kode_pos, setKode_pos] = useState(props.data.kode_pos)
    const id = props.data.id;

    //Redux
    const getListKabkotResult = useGetListKotKabQuery().data;
    const [updateKecamatan, updateKecamatanResult] = useUpdateKecamatanMutation();

    const handleSubmit = (event) => {
        event.preventDefault();

        updateKecamatan({ id: id, nama: nama, lokasi_kotakab_id: selected.id })
        setShowModal(false)
    }

    const [selected, setSelected] = useState({ 'nama': props.data.kabkot, 'id': props.data.lokasi_kotakab_id })


    const filteredKecamatan =
        getListKabkotResult?(
            query === ''
                ? getListKabkotResult
                : getListKabkotResult.filter((kecamatan) =>
                    kecamatan.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )): []


    return (
        <div>
            <button
                type="submit"
                onClick={() => setShowModal(true)}
                className="text-blue-500 text-lg cursor-pointer"
            >
                <MdEdit />
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Tambah kecamatan
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            id="floating_standard"
                                            value={nama}
                                            onChange={(event) => setNama(event.target.value)}
                                            placeholder=' '
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nama Kecamatan</label>
                                    </div>
                                    <p className=" text-slate-500 text-xs mt-5">Kabupaten / Kota</p>

                                    <Combobox value={selected} onChange={setSelected}>

                                        <div className="relative mt-1">
                                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                <Combobox.Input
                                                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                    displayValue={(kecamatan) => kecamatan.nama}
                                                    onChange={(event) => setQuery(event.target.value)}
                                                />
                                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <MdUnfoldMore />

                                                </Combobox.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                afterLeave={() => setQuery('')}
                                            >
                                                <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {filteredKecamatan.length === 0 && query !== '' ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        filteredKecamatan.map((kecamatan) => (
                                                            <Combobox.Option
                                                                key={kecamatan.id}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                    }`
                                                                }
                                                                value={kecamatan}
                                                            >
                                                                {({ selected, active }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                                                }`}
                                                                        >
                                                                            {kecamatan.nama}
                                                                        </span>
                                                                        {selected ? (
                                                                            <span
                                                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                    }`}
                                                                            >
                                                                                <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </Combobox.Options>

                                            </Transition>
                                        </div>
                                    </Combobox>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

const EditKelurahan = (props) => {

    const [showModal, setShowModal] = useState(false);
    const [nama, setNama] = useState(props.data.nama);
    const [query, setQuery] = useState('')
    const id = props.data.id;
    const [updateKelurahan, updateKelurahanResult] = useUpdateKelurahanMutation();

    const getListKecamatanResult = useGetListKecamatanQuery().data


    const handleSubmit = (event) => {
        event.preventDefault();

        updateKelurahan({ id: id, nama: nama, lokasi_kecamatan_id: selected.id })
        setShowModal(false)
    }




    const [selected, setSelected] = useState({ 'nama': props.data.lokasi_kecamatan.nama, 'id': props.data.lokasi_kecamatan_id })


    const filteredkecamatan =
        query === ''
            ? getListKecamatanResult
            : getListKecamatanResult.filter((kecamatan) =>
                kecamatan.nama
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )


    return (
        <div>
            <button
                type="submit"
                onClick={() => setShowModal(true)}
                className="text-blue-500 text-lg cursor-pointer"
            >
                <MdEdit />
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Tambah kecamatan
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            id="floating_standard"
                                            value={nama}
                                            onChange={(event) => setNama(event.target.value)}
                                            placeholder=' '
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nama Kelurahan</label>
                                    </div>
                                    <p className=" text-slate-500 text-xs mt-5">Kecamatan</p>

                                    <Combobox value={selected} onChange={setSelected}>

                                        <div className="relative mt-1">
                                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                <Combobox.Input
                                                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                    displayValue={(kecamatan) => kecamatan.nama}
                                                    onChange={(event) => setQuery(event.target.value)}
                                                />
                                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <MdUnfoldMore />

                                                </Combobox.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                afterLeave={() => setQuery('')}
                                            >
                                                <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {filteredkecamatan.length === 0 && query !== '' ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        filteredkecamatan.map((kecamatan) => (
                                                            <Combobox.Option
                                                                key={kecamatan.id}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                    }`
                                                                }
                                                                value={kecamatan}
                                                            >
                                                                {({ selected, active }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                                                }`}
                                                                        >
                                                                            {kecamatan.nama}
                                                                        </span>
                                                                        {selected ? (
                                                                            <span
                                                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                    }`}
                                                                            >
                                                                                <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </Combobox.Options>

                                            </Transition>
                                        </div>
                                    </Combobox>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

export { EditPropinsi, EditKabkot, EditKecamatan, EditKelurahan }