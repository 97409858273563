import { Disclosure, Tab } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react'
import secureLocalStorage from 'react-secure-storage';
import { MdCheck, MdClear, MdKeyboardArrowDown, } from 'react-icons/md';
import { format } from 'date-fns';
import DataTable from 'react-data-table-component';
import { useDetailPembayaranQuery, useGetListPembayaranRetailDistributorQuery, useUpdatePembayaranRetailMutation } from '../../store/apis/pembayaranRetailApi';
import { FilterPembayaran, FilterPembayaranJatuhTempo } from '../../components/FilterTable';
import { useLaporMutation } from '../../store/apis/blacklistApi';
import { alamat_storage } from '../../components/api';

const PembayaranRetailDist = () => {
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    return (
        <div className='w-full p-5'>
            <div className='w-full bg-white border pb-5 rounded-lg '>

                <Tab.Group>

                    <Tab.List className='bg-blue-100 rounded-t-lg'>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Belum Lunas
                                </div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Lunas
                                </div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Jatuh Tempo
                                </div>
                            )}
                        </Tab>
                    </Tab.List>

                    <Tab.Panels className={`mt-5 px-5`}>
                        <Tab.Panel >
                            <BelumLunas />
                        </Tab.Panel>
                        <Tab.Panel >
                            <Lunas />
                        </Tab.Panel>
                        <Tab.Panel >
                            <JatuhTempo />
                        </Tab.Panel>
                    </Tab.Panels>

                </Tab.Group>
            </div>


        </div>
    )
}

const BelumLunas = () => {
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const { data, isFetching, error } = useGetListPembayaranRetailDistributorQuery(id)
    const currentDate = new Date();
    const [retur, setRetur] = useState('');

    const [detail, setDetail] = useState(false);
    const [total, setTotal] = useState('');
    const [bayar, setBayar] = useState('');
    const [ongkir, setOngkir] = useState('');
    const [dataId, setDataId] = useState('')
    const [invoice, setInvoice] = useState('')

    const HandleSubmit = (id, invoice, total, ongkir, bayar, retur) => {
        if (detail) {
            setDataId('');
            setDetail(false);
            setTotal('');
            setOngkir('');
            setRetur('');
            setBayar('');
        } else {
            setDataId(id);
            setDetail(true);
            setTotal(total);
            setOngkir(ongkir);
            setBayar(bayar);
            setRetur(retur);
        }
    }

    const col = [
        {
            name: 'Nama Produk',
            selector: row => row.produk_varian.nama,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Jumlah',
            selector: row => row.jumlah,
            sortable: true,
            width: 'auto',
        },
        {
            key: "Harga Produk",
            name: "Harga Produk",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.harga),

        },
        {
            key: "Total Harga",
            name: "Total Harga",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.harga * row.jumlah),

        },
    ]
    const colRetur = [
        {
            name: 'Nama Produk',
            selector: row => row.retur_produks[0].produk_varian.nama,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Jumlah',
            selector: row => row.retur_produks[0].jumlah,
            sortable: true,
            width: 'auto',
        },
        {
            key: "Harga Produk",
            name: "Harga Produk",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.retur_produks[0].nominal),

        },
        {
            key: "Total Harga",
            name: "Total Harga",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.retur_produks[0].nominal * row.retur_produks[0].jumlah),

        },
    ]
    return (
        <>
            <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Pembayaran</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Pembayaran yang telah belum lunas.</p>
                </div>
                <div className='place-self-end'>
                </div>
                <div className='col-span-3' />

            </div>
            {data && data.length > 0 ? (
                <>
                    {FilterPembayaran(data, 'Belum Lunas').map((item) => (
                        <div key={item.id}>
                            {detail && (
                                <DetailPembayaran modalShow={HandleSubmit} id={dataId} invoice={invoice} total={total} bayar={bayar} ongkir={ongkir} retur={retur} />
                            )}
                            <Disclosure key={item.id} as="div" defaultOpen="true">

                                {({ open }) => (
                                    <>
                                        <div className="w-full flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">


                                            <div>
                                                <Disclosure.Button className="text-base font-bold mr-4 text-left">
                                                    {item.penjualan.retail.nama} | No: {item.penjualan.nomor_invoice}
                                                </Disclosure.Button>
                                                {Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) <= 7 && Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) >= 0 ? (
                                                    <div>
                                                        Jatuh Tempo: <span className='text-amber-500'>{format(new Date(item.tanggal_jatuh_tempo), 'dd-MM-yyyy')} ({Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24))} hari lagi)</span>
                                                    </div>
                                                ) : Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) < 0 ? (
                                                    <div>
                                                        Jatuh Tempo: <span className='text-red-500'>{format(new Date(item.tanggal_jatuh_tempo), 'dd-MM-yyyy')} ({Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) * -1} hari melewati jatuh tempo)</span>
                                                    </div>
                                                ) :
                                                    <div>
                                                        Jatuh Tempo: {format(new Date(item.tanggal_jatuh_tempo), 'dd-MM-yyyy')} ({Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24))} hari lagi)
                                                    </div>
                                                }
                                                <div>
                                                    Status Pengiriman: {item.status === 'Diterima' ? <>Belum Dikirim</> : <>{item.status}</>}
                                                </div>

                                            </div>
                                            <div>
                                                <div className='flex items-center'>
                                                    {item.totalTerbayar ?
                                                        <>
                                                            {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.totalTerbayar)}
                                                            /
                                                            {item.totalReturs ? Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.totalTagihan + item.opsi_pengiriman.harga - item.totalReturs) : Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.totalTagihan + item.opsi_pengiriman.harga)}
                                                        </> :
                                                        <>
                                                            {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(0)}
                                                            /
                                                            {item.totalReturs ? Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.totalTagihan + item.opsi_pengiriman.harga - item.totalReturs) : Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.totalTagihan + item.opsi_pengiriman.harga)}
                                                        </>}
                                                    <Disclosure.Button >
                                                        <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                    </Disclosure.Button>

                                                </div>
                                                <div className=' flex'>
                                                    <button onClick={() => HandleSubmit(item.id, item.penjualan.nomor_invoice, item.totalTagihan, item.opsi_pengiriman.harga, item.totalTerbayar, item.totalReturs)} className='py-1 px-4 bg-blue-500 text-white rounded mx-auto mt-2' type="">Detail Pembayaran</button>
                                                </div>
                                            </div>
                                        </div>

                                        <Disclosure.Panel className="p-5">
                                            <p className='font-semibold'>Detail pembelian</p>

                                            <div className='border border-slate-300'>
                                                {data ? (
                                                    <DataTable columns={col} data={item.penjualan_distributor_produks} />
                                                ) : (
                                                    <p> Data kosong</p>
                                                )}
                                            </div>
                                            {item.returs.length > 0 &&
                                                <>
                                                    <p className='font-semibold mt-5'>Detail Retur</p>

                                                    <div className='border border-slate-300 mt-5'>
                                                        {data ? (
                                                            <DataTable columns={colRetur} data={item.returs} />
                                                        ) : (
                                                            <p> Data kosong</p>
                                                        )}
                                                    </div>
                                                </>
                                            }
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>


                    ))}
                </>) : <p className='text-lg text-center'>Belum ada transaksi</p>}
        </>
    )
}

const Lunas = () => {
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const { data, isFetching, error } = useGetListPembayaranRetailDistributorQuery(id)
    const currentDate = new Date();
    const [retur, setRetur] = useState('');

    const [detail, setDetail] = useState(false);
    const [total, setTotal] = useState('');
    const [bayar, setBayar] = useState('');
    const [ongkir, setOngkir] = useState('');
    const [dataId, setDataId] = useState('')
    const [invoice, setInvoice] = useState('')

    const HandleSubmit = (id, invoice, total, ongkir, bayar, retur) => {
        if (detail) {
            setDataId('');
            setDetail(false);
            setTotal('');
            setOngkir('');
            setRetur('');
            setBayar('');
        } else {
            setDataId(id);
            setDetail(true);
            setTotal(total);
            setOngkir(ongkir);
            setBayar(bayar);
            setRetur(retur);
        }
    }

    const col = [
        {
            name: 'Nama Produk',
            selector: row => row.produk_varian.nama,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Jumlah',
            selector: row => row.jumlah,
            sortable: true,
            width: 'auto',
        },
        {
            key: "Harga Produk",
            name: "Harga Produk",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.harga),

        },
        {
            key: "Total Harga",
            name: "Total Harga",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.harga * row.jumlah),

        },
    ]
    const colRetur = [
        {
            name: 'Nama Produk',
            selector: row => row.retur_produks[0].produk_varian.nama,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Jumlah',
            selector: row => row.retur_produks[0].jumlah,
            sortable: true,
            width: 'auto',
        },
        {
            key: "Harga Produk",
            name: "Harga Produk",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.retur_produks[0].nominal),

        },
        {
            key: "Total Harga",
            name: "Total Harga",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.retur_produks[0].nominal * row.retur_produks[0].jumlah),

        },
    ]
    return (
        <>
            <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Pembayaran</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Pembayaran yang telah belum lunas.</p>
                </div>
                <div className='place-self-end'>
                </div>
                <div className='col-span-3' />

            </div>
            {data && data.length > 0 ? (
                <>
                    {FilterPembayaran(data, 'Lunas').map((item) => (
                        <div key={item.id}>
                            {detail && (
                                <DetailPembayaran modalShow={HandleSubmit} id={dataId} invoice={invoice} total={total} bayar={bayar} ongkir={ongkir} retur={retur} />
                            )}
                            <Disclosure key={item.id} as="div" defaultOpen="true">

                                {({ open }) => (
                                    <>
                                        <div className="w-full flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">


                                            <div>
                                                <Disclosure.Button className="text-base font-bold mr-4 text-left">
                                                    {item.penjualan.retail.nama} | No: {item.penjualan.nomor_invoice}
                                                </Disclosure.Button>
                                                {Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) <= 7 && Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) >= 0 ? (
                                                    <div className='text-amber-500'>
                                                        Jatuh Tempo: {format(new Date(item.tanggal_jatuh_tempo), 'dd-MM-yyyy')}
                                                    </div>
                                                ) : Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) < 0 ? (
                                                    <div className='text-red-500'>
                                                        Jatuh Tempo: {format(new Date(item.tanggal_jatuh_tempo), 'dd-MM-yyyy')}
                                                    </div>
                                                ) :
                                                    <div>
                                                        Jatuh Tempo: {format(new Date(item.tanggal_jatuh_tempo), 'dd-MM-yyyy')}
                                                    </div>
                                                }
                                                <div>
                                                    Status Pengiriman: {item.status === 'Diterima' ? <span className='text-yellow-500'>Belum Dikirim</span> : item.status === 'Selesai' ? <span className='text-green-500'>{item.status}</span> : <>{item.status}</>}
                                                </div>

                                            </div>
                                            <div>
                                                <div className='flex items-center'>
                                                    {item.totalTerbayar ?
                                                        <>
                                                            {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.totalTerbayar)}
                                                            /
                                                            {item.totalReturs ? Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.totalTagihan + item.opsi_pengiriman.harga - item.totalReturs) : Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.totalTagihan + item.opsi_pengiriman.harga)}
                                                        </> :
                                                        <>
                                                            {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(0)}
                                                            /
                                                            {item.totalReturs ? Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.totalTagihan + item.opsi_pengiriman.harga - item.totalReturs) : Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.totalTagihan + item.opsi_pengiriman.harga)}
                                                        </>}
                                                    <Disclosure.Button >
                                                        <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                    </Disclosure.Button>

                                                </div>
                                                <div className=' flex'>
                                                    <button onClick={() => HandleSubmit(item.id, item.penjualan.nomor_invoice, item.totalTagihan, item.opsi_pengiriman.harga, item.totalTerbayar, item.totalReturs)} className='py-1 px-4 bg-blue-500 text-white rounded mx-auto mt-2' type="">Detail Pembayaran</button>
                                                </div>
                                            </div>
                                        </div>

                                        <Disclosure.Panel className="p-5">
                                            <p className='font-semibold'>Detail Pembelian</p>
                                            <div className='border border-slate-300'>
                                                {data ? (
                                                    <DataTable columns={col} data={item.penjualan_distributor_produks} />
                                                ) : (
                                                    <p> Data kosong</p>
                                                )}
                                            </div>
                                            {item.returs.length > 0 &&
                                                <>
                                                    <p className='font-semibold mt-5'>Detail Retur</p>

                                                    <div className='border border-slate-300'>
                                                        {data ? (
                                                            <DataTable columns={colRetur} data={item.returs} />
                                                        ) : (
                                                            <p> Data kosong</p>
                                                        )}
                                                    </div>
                                                </>
                                            }
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>


                    ))}
                </>) : <p className='text-lg text-center'>Belum ada transaksi</p>}
        </>
    )
}

const JatuhTempo = () => {
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const { data, isFetching, error } = useGetListPembayaranRetailDistributorQuery(id)
    const currentDate = new Date();
    const [lapor, laporResult] = useLaporMutation();
    const [retur, setRetur] = useState('');

    const [detail, setDetail] = useState(false);
    const [total, setTotal] = useState('');
    const [bayar, setBayar] = useState('');
    const [ongkir, setOngkir] = useState('');
    const [dataId, setDataId] = useState('')
    const [invoice, setInvoice] = useState('')

    useEffect(() => {
        if (laporResult.isSuccess) {
            window.alert('Laporan dikirim! Admin dodolan akan segera mengevaluasi laporan anda!');
        }
    }, [laporResult])

    const HandleSubmit = (id, invoice, total, ongkir, bayar, retur) => {
        if (detail) {
            setDataId('');
            setDetail(false);
            setTotal('');
            setOngkir('');
            setBayar('');
        } else {
            setDataId(id);
            setDetail(true);
            setTotal(total);
            setOngkir(ongkir);
            setBayar(bayar);
            setRetur(retur);

        }
    }

    const HandleLapor = (idR) => {
        if (window.confirm('Laporkan retail ini? Pastikan anda telah mencoba menghubungi retail terkait terlebih dahulu sebelum melapor!')) {
            lapor({ retail_id: idR, distributor_id: id, alasan: 'Tidak menyelesaikan pembayaran kepada distributor ' + userData.nama, status: 'Dikirim', datetime: format(new Date(), 'yyyy-MM-dd HH:mm:ss') })

        }
    }

    const col = [
        {
            name: 'Nama Produk',
            selector: row => row.produk_varian.nama,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Jumlah',
            selector: row => row.jumlah,
            sortable: true,
            width: 'auto',
        },
        {
            key: "Harga Produk",
            name: "Harga Produk",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.harga),

        },
        {
            key: "Total Harga",
            name: "Total Harga",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.harga * row.jumlah),

        },
    ]
    return (
        <>
            <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Pembayaran</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Pembayaran yang telah belum lunas.</p>
                </div>
                <div className='place-self-end'>
                </div>
                <div className='col-span-3' />

            </div>
            {data && data.length > 0 ? (
                <>
                    {FilterPembayaranJatuhTempo(data).map((item) => (
                        <div key={item.id}>
                            {detail && (
                                <DetailPembayaran modalShow={HandleSubmit} id={dataId} invoice={invoice} total={total} bayar={bayar} ongkir={ongkir} retur={retur} />
                            )}
                            <Disclosure key={item.id} as="div" defaultOpen="true">

                                {({ open }) => (
                                    <>
                                        <div className="w-full flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">

                                            <div>
                                                <Disclosure.Button className="text-base font-bold mr-4 text-left">
                                                    {item.penjualan.retail.nama} | No: {item.penjualan.nomor_invoice}
                                                </Disclosure.Button>

                                                {Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) <= 7 && Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) >= 0 ? (
                                                    <div className='text-amber-500'>
                                                        Jatuh Tempo: {format(new Date(item.tanggal_jatuh_tempo), 'dd-MM-yyyy')}
                                                    </div>
                                                ) : Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) < 0 ? (
                                                    <div className='text-red-500'>
                                                        Jatuh Tempo: {format(new Date(item.tanggal_jatuh_tempo), 'dd-MM-yyyy')}
                                                    </div>
                                                ) :
                                                    <div>
                                                        Jatuh Tempo: {format(new Date(item.tanggal_jatuh_tempo), 'dd-MM-yyyy')}
                                                    </div>
                                                }
                                                <div>
                                                    Status Pengiriman: {item.status === 'Diterima' ? <>Belum Dikirim</> : <>{item.status}</>}
                                                </div>

                                            </div>
                                            <div>
                                                <div className='flex place-content-end'>
                                                    {item.totalTerbayar ?
                                                        <>
                                                            {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.totalTerbayar)} / {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.totalTagihan + item.opsi_pengiriman.harga)}
                                                        </> :
                                                        <>
                                                            {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(0)} / {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.totalTagihan + item.opsi_pengiriman.harga)}
                                                        </>}
                                                    <Disclosure.Button >
                                                        <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                    </Disclosure.Button>

                                                </div>
                                                <div className=' flex gap-4'>
                                                    <button onClick={() => HandleSubmit(item.id, item.penjualan.nomor_invoice, item.totalTagihan, item.opsi_pengiriman.harga, item.totalTerbayar, item.totalReturs)} className='py-1 px-4 bg-blue-500 text-white rounded mx-auto mt-2' type="">Detail Pembayaran</button>
                                                    <button onClick={() => HandleLapor(item.penjualan.retail_id)} className='py-1 px-4 bg-red-500 text-white rounded mx-auto mt-2' type="">Lapor</button>


                                                </div>
                                            </div>
                                        </div>

                                        <Disclosure.Panel className="p-5">
                                            {data ? (
                                                <DataTable columns={col} data={item.penjualan_distributor_produks} />
                                            ) : (
                                                <p> Data kosong</p>
                                            )}

                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>


                    ))}
                </>) : <p className='text-lg text-center'>Belum ada transaksi</p>}
        </>
    )
}

const DetailPembayaran = ({ modalShow, id, invoice, total, ongkir, bayar, retur }) => {
    const { data, isFetching, error } = useDetailPembayaranQuery(id);
    const [updateStatus, updateStatusResult] = useUpdatePembayaranRetailMutation();

    const HandleClick = (event, status, idR, idD, idP, nominal) => {
        if (status === 'Diterima') {
            updateStatus({ status: status, idR: idR, idD: idD, idP: idP, nominal: 0.2 / 100 * nominal, datetime: format(new Date(), 'yyyy-MM-dd') });
        } else if (status === 'Ditolak') {
            updateStatus({ status: status, idR: idR, idD: idD, idP: idP });
        }
    }

    const col = [
        {
            name: 'Tgl Upload',
            selector: row => format(new Date(row.datetime), 'dd-MM-yyyy'),
            sortable: true,
        },
        {
            name: 'Nomor Faktur',
            selector: row => row.pembayaran_retail_details[0].nomor_faktur,
            sortable: true,
        },
        {
            name: 'Tgl Bayar',
            selector: row => format(new Date(row.pembayaran_retail_details[0].tanggal), 'dd-MM-yyyy'),
            sortable: true,
        },
        {
            key: "Nominal",
            name: "Nominal",
            sortable: false,
            cell: (record) => {
                return (
                    <div>
                        {Intl.NumberFormat("id-ID").format(record.pembayaran_retail_details[0].nominal)}
                    </div>
                );
            },

        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Bukti',
            selector: row => row.status,
            cell: (record) => {
                return (
                    <div>
                        <a target='_BLANK' className='text-blue-500' href={`${alamat_storage}fotoFakturRetail/${record.pembayaran_retail_details[0].path_bukti_faktur}`}>Link</a>
                    </div>
                );
            },
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                if (record.status === "Diterima" || record.status === "Ditolak") {
                    return (
                        <Fragment>
                            <button
                                disabled
                                type="submit"
                                onClick={(e) => HandleClick(e, 'Diterima', record.id, record.pembayaran_retail_details[0].id, record.penjualan_distributor_id, record.pembayaran_retail_details[0].nominal)}
                                className="text-blue-300 text-lg cursor-not-allowed"
                            >
                                <MdCheck />
                            </button>
                            <span className='mx-3'>|</span>

                            <button
                                disabled
                                type="submit"
                                onClick={(e) => HandleClick(e, 'Ditolak', record.id, record.pembayaran_retail_details[0].id, record.penjualan_distributor_id)}
                                className="text-red-300 text-lg cursor-not-allowed"
                            >
                                <MdClear />
                            </button>
                        </Fragment>
                    );
                } else {
                    return (
                        <Fragment>
                            <button
                                type="submit"
                                onClick={(e) => HandleClick(e, 'Diterima', record.id, record.pembayaran_retail_details[0].id, record.penjualan_distributor_id, record.pembayaran_retail_details[0].nominal)}
                                className="text-blue-500 text-lg cursor-pointer"
                            >
                                <MdCheck />
                            </button>
                            <span className='mx-3'>|</span>

                            <button
                                type="submit"
                                onClick={(e) => HandleClick(e, 'Ditolak', record.id, record.pembayaran_retail_details[0].id, record.penjualan_distributor_id)}
                                className="text-red-500 text-lg cursor-pointer"
                            >
                                <MdClear />
                            </button>
                        </Fragment>
                    );
                }

            },
        },
    ]


    return (
        <div
            className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
            {data && (
                <div className="relative h-full mx-auto max-w-full">
                    {/*content*/}
                    <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Detail Pengiriman Produk
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => modalShow()}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <div className='flex justify-between gap-6'>
                                <div>
                                    <div>
                                        Pembayaran yang telah diterima:
                                    </div>
                                    <div>
                                        {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(bayar)}
                                    </div>

                                </div>
                                <div>
                                    <div>
                                        Biaya pengiriman:
                                    </div>
                                    <div>
                                        {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(ongkir)}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        Nominal Retur:
                                    </div>
                                    <div>
                                        {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(retur)}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        Total tagihan:
                                    </div>
                                    <div>
                                        {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(total + ongkir - retur)}
                                    </div>
                                </div>
                            </div>
                            {data ? (
                                <DataTable columns={col} data={data} />
                            ) : isFetching ? (
                                <p>loading</p>
                            ) : (
                                <p>{error ? error : "Data kosong"}</p>
                            )}
                        </div>
                        {/* body
                        <div className="relative p-6 flex-auto">
                            <div className='flex justify-between gap-6'>
                                <div>
                                    <div>
                                        Pembayaran yang telah diterima:
                                    </div>
                                    <div>
                                        {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(bayar)}
                                    </div>

                                </div>
                                <div>
                                    <div>
                                        Total penjualan:
                                    </div>
                                    <div>
                                        {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(total)}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        Biaya pengiriman:
                                    </div>
                                    <div>
                                        {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(ongkir)}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        Total tagihan:
                                    </div>
                                    <div>
                                        {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(total + ongkir)}
                                    </div>
                                </div>
                            </div>
                            {data ? (
                                <DataTable columns={col} data={data} />
                            ) : isFetching ? (
                                <p>loading</p>
                            ) : (
                                <p>{error ? error : "Data kosong"}</p>
                            )}
                        </div> */}
                    </div>
                </div>
            )}

        </div>
    )
}
export default PembayaranRetailDist
