import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const lokasiKecamatanApi = createApi({
    reducerPath: 'lokasiKecamatan',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getKecamatan: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'Kecamatan' }];

                },
                query: () => {
                    return {
                        url: 'LokasiKecamatan',
                        method: 'GET',
                    };
                },
            }),
            getListKecamatan: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'Kecamatan' }];

                },
                query: (id) => {
                    return {
                        url: 'LokasiKecamatanList',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            addKecamatan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Kecamatan' }];
                },
                query: (data) => {
                   
                    return {
                        method: 'POST',
                        url: 'LokasiKecamatan',
                        body: data,
                    };
                },
            }),
            updateKecamatan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Kecamatan' }];
                },
                query: (data) => {
                    
                    return {
                        method: 'PUT',
                        url: `LokasiKecamatan/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteKecamatan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Kecamatan' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `LokasiKecamatan/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetKecamatanQuery, useGetListKecamatanQuery, useAddKecamatanMutation, useUpdateKecamatanMutation, useDeleteKecamatanMutation } = lokasiKecamatanApi;
export { lokasiKecamatanApi };