import { ADD_KATEGORI_PRODUK, DELETE_KATEGORI_PRODUK, GET_LIST_KATEGORI_PRODUK, UPDATE_KATEGORI_PRODUK } from "../../actions/kategoriProdukAction";

const initialState = {
    getListKategoriResult: false,
    getListKategoriLoading: false,
    getListKategoriError: false,

    addKategoriResult: false,
    addKategoriLoading: false,
    addKategoriError: false,

    updateKategoriResult: false,
    updateKategoriLoading: false,
    updateKategoriError: false,

    deleteKategoriResult: false,
    deleteKategoriLoading: false,
    deleteKategoriError: false,
}


const kategori = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_KATEGORI_PRODUK:
            return {
                ...state,
                getListKategoriResult: action.payload.data,
                getListKategoriLoading: action.payload.loading,
                getListKategoriError: action.payload.errorMessage,
            }

        case ADD_KATEGORI_PRODUK:
            return {
                ...state,
                addKategoriResult: action.payload.data,
                addKategoriLoading: action.payload.loading,
                addKategoriError: action.payload.errorMessage,
            }
        case UPDATE_KATEGORI_PRODUK:
            return {
                ...state,
                updateKategoriResult: action.payload.data,
                updateKategoriLoading: action.payload.loading,
                updateKategoriError: action.payload.errorMessage,
            }
        case DELETE_KATEGORI_PRODUK:
            return {
                ...state,
                deleteKategoriResult: action.payload.data,
                deleteKategoriLoading: action.payload.loading,
                deleteKategoriError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default kategori;