import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { alamat_backend } from '../../components/api';

const produkFotoApi = createApi({
    reducerPath: 'produkFoto',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            addFoto: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'foto'}];
                },
                query: (data) => {
                    const formData = new FormData();
                    formData.append('label', data.label)
                    formData.append('path', data.path)
                    formData.append('status', data.status)
                    formData.append('produk_varian_id', data.produk_varian_id)
                    return {
                        method: 'POST',
                        url: 'ProdukFoto',
                        body: formData,
                    };
                },
            }),
            getListFoto: builder.query({
                providesTags: (result, error, id) => {
                    const tags = result.map((foto) => {
                        return { type: 'foto', id: foto.id };
                    });
                    tags.push({ type: 'varian', id: id });
                    return tags;
                },
                query: (id) => {
                    return {
                        url: 'ProdukFotoList',
                        params: {
                            id: id,
                        },
                        method: 'GET',
                    };
                },
            }),
            getVarianFoto: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'foto' }];
                },
                query: (id) => {
                    return {
                        url: 'VarianFoto',
                        params: {
                            id: id,
                        },
                        method: 'GET',
                    };
                },
            }),
            getRestrictedListFoto: builder.query({
                query: () => {
                    return {
                        url: 'RestrictedProdukFoto',
                        method: 'GET',
                    };
                },
            }),
            getUnrestrictedListFoto: builder.query({
                query: () => {
                    return {
                        url: 'unRestrictedProdukFoto',
                        method: 'GET',
                    };
                },
            }),

            // updateFoto: builder.mutation({}),
            deleteFoto: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'foto'}];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `ProdukFoto/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetListFotoQuery, useGetVarianFotoQuery, useAddFotoMutation, useDeleteFotoMutation, useGetRestrictedListFotoQuery, useGetUnrestrictedListFotoQuery } = produkFotoApi;
export { produkFotoApi };