import axios from "axios";
import {alamat_backend} from "../components/api";


export const GET_LIST_PABRIK = "GET_LIST_PABRIK"
export const DELETE_PABRIK = "DELETE_PABRIK"
export const ADD_PABRIK = "ADD_PABRIK"
export const UPDATE_PABRIK = "UPDATE_PABRIK"


const getListPabrik = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_LIST_PABRIK,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'PabrikList?id=' + id,
            timeout: 120000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_LIST_PABRIK,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_LIST_PABRIK,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}


const addPabrik = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: ADD_PABRIK,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'Pabrik',
            timeout: 120000,
            data: data
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: ADD_PABRIK,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: ADD_PABRIK,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.response.data,
                    }
                })
            })
    }
}

const updatePabrik = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: UPDATE_PABRIK,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'PUT',
            url: alamat_backend + 'Pabrik/' + data.id,
            timeout: 120000,
            data: data
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: UPDATE_PABRIK,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: UPDATE_PABRIK,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message
                    }
                })
            })
    }
}

const deletePabrik = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: DELETE_PABRIK,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'DELETE',
            url: alamat_backend + 'Pabrik/'+ id,
            timeout: 120000,
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: DELETE_PABRIK,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: DELETE_PABRIK,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })
    }
}


export { getListPabrik, addPabrik, deletePabrik, updatePabrik }