import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const pembayaranRetailApi = createApi({
    reducerPath: 'pembayaranRetail',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getListPembayaranRetailDistributor: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'PembayaranRetail' }];

                },
                query: (id) => {
                    return {
                        url: 'PembayaranRetailDetailListDistributor',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            getListPembayaranRetailRetail: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'PembayaranRetail' }];

                },
                query: (id) => {
                    return {
                        url: 'PembayaranRetailDetailListRetail',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            detailPembayaran: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'PembayaranRetail' }];

                },
                query: (id) => {
                    return {
                        url: 'DetailPembayaran',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            addPembayaranRetail: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'PembayaranRetail' }];
                },
                query: (data) => {
                    const formData = new FormData();
                    formData.append('tanggal', data.tanggal)
                    formData.append('datetime', data.datetime)
                    formData.append('nomor_faktur', data.nomor_faktur)
                    formData.append('nominal', data.nominal)
                    formData.append('path_bukti_faktur', data.path_bukti_faktur)
                    formData.append('penjualan_distributor_id', data.penjualan_distributor_id)

                    return {
                        method: 'POST',
                        url: 'PembayaranRetailDetail',
                        body: formData,
                    };
                },
            }),
            updatePembayaranRetail: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'PembayaranRetail' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `PembayaranRetailDetail/${data.idR}`,
                        body: data,
                    };
                },
            }),
            deletePembayaranRetail: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'PembayaranRetail' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `PembayaranRetailDetail/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetListPembayaranRetailDistributorQuery, useGetListPembayaranRetailRetailQuery, useDetailPembayaranQuery, useAddPembayaranRetailMutation, useUpdatePembayaranRetailMutation, useDeletePembayaranRetailMutation } = pembayaranRetailApi;
export { pembayaranRetailApi };