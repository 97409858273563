import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const pabrikApi = createApi({
    reducerPath: 'pabrik',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getListPabrik: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'Pabrik' }];

                },
                query: (id) => {
                    return {
                        url: 'PabrikList',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            addPabrik: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Pabrik' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'Pabrik',
                        body: data,
                    };
                },
            }),
            updatePabrik: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Pabrik' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `Pabrik/${data.id}`,
                        body: data,
                    };
                },
            }),
            deletePabrik: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Pabrik' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `Pabrik/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetListPabrikQuery, useAddPabrikMutation, useUpdatePabrikMutation, useDeletePabrikMutation } = pabrikApi;
export { pabrikApi };