import { ADD_KABKOT, DELETE_KABKOT, GET_DATA_LIST_KABKOT, GET_LIST_KABKOT, UPDATE_KABKOT } from "../../actions/kabkotAction";

const initialState = {
    getListKabkotResult: false,
    getListKabkotLoading: false,
    getListKabkotError: false,

    getDataListKabkotResult: false,
    getDataListKabkotLoading: false,
    getDataListKabkotError: false,

    addKabkotResult: false,
    addKabkotLoading: false,
    addKabkotError: false,

    updateKabkotResult: false,
    updateKabkotLoading: false,
    updateKabkotError: false,

    deleteKabkotResult: false,
    deleteKabkotLoading: false,
    deleteKabkotError: false,


}


const kabkot = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_KABKOT:
            return {
                ...state,
                getListKabkotResult: action.payload.data,
                getListKabkotLoading: action.payload.loading,
                getListKabkotError: action.payload.errorMessage,
            }
        case GET_DATA_LIST_KABKOT:
            return {
                ...state,
                getDataListKabkotResult: action.payload.data,
                getDataListKabkotLoading: action.payload.loading,
                getDataListKabkotError: action.payload.errorMessage,
            }
        case ADD_KABKOT:
            return {
                ...state,
                addKabkotResult: action.payload.data,
                addKabkotLoading: action.payload.loading,
                addKabkotError: action.payload.errorMessage,
            }
        case UPDATE_KABKOT:
            return {
                ...state,
                updateKabkotResult: action.payload.data,
                updateKabkotLoading: action.payload.loading,
                updateKabkotError: action.payload.errorMessage,
            }
        case DELETE_KABKOT:
            return {
                ...state,
                deleteKabkotResult: action.payload.data,
                deleteKabkotLoading: action.payload.loading,
                deleteKabkotError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default kabkot;