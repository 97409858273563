import { Combobox, Tab, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react'
import { useGetDropdownListKelurahanQuery, useGetListKelurahanQuery } from '../store/apis/lokasiKelurahanApi';
import { useDispatch, useSelector } from 'react-redux';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import { FileUploader } from 'react-drag-drop-files';
import { MdCheck, MdRotateRight, MdUnfoldMore } from 'react-icons/md';
import { register } from '../actions/userAction';
import { useGetListRetailQuery } from '../store/apis/retailApi';

const Register = ({ regisModal }) => {

    return (
        <div >

            <div
                className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >

                <div className="relative h-full mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">

                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Registrasi Akun
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => regisModal()}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <Tab.Group>

                            <Tab.List className='bg-blue-100 rounded-t-lg'>
                                <Tab>
                                    {({ selected }) => (
                                        <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>Distributor</div>
                                    )}
                                </Tab>
                                <Tab>
                                    {({ selected }) => (
                                        <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>Retail</div>
                                    )}
                                </Tab>

                            </Tab.List>

                            <Tab.Panels className={`mt-5 px-5`}>
                                <Tab.Panel>
                                    <Distributor regisModal={regisModal} />
                                </Tab.Panel>
                                <Tab.Panel>
                                    <Retail regisModal={regisModal} />
                                </Tab.Panel>
                            </Tab.Panels>

                        </Tab.Group>


                    </div>
                </div>
            </div>
        </div>
    )
}

const Distributor = ({ regisModal }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [namaPemilik, setNamaPemilik] = useState('');
    const [email, setEmail] = useState('');
    const [nohp, setNohp] = useState('');
    const [noktp, setNoktp] = useState('');
    const [fotoKTPPemilik, setFotoKTPPemilik] = useState('');
    const [namaPerusahaan, setNamaPerusahaan] = useState('');
    const [noTelpPerusahaan, setNoTelpPerusahaan] = useState('');
    const [alamat, setAlamat] = useState('');
    const [fotoProfil, setFotoProfil] = useState('');
    const [deskripsi, setDeskripsi] = useState('');
    const [catatan, setCatatan] = useState('');
    const [query, setQuery] = useState('')
    const getListKelurahanResult = useGetDropdownListKelurahanQuery(query).data;
    const [selected, setSelected] = useState('')
    const [lokasi, setLokasi] = useState([0.5336, 101.4474]);

    const { registerResult, registerLoading } = useSelector((state) => state.userReducer)

    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(register({ username: username, password: password, name: namaPemilik, email: email, user_role: 'Distributor', alamat: alamat, nomor_KTP: noktp, nomor_telp: noTelpPerusahaan, deskripsi: deskripsi, catatan: catatan, nama: namaPerusahaan, nomor_telp_cp: nohp, foto_KTP: fotoKTPPemilik, foto_profil: fotoProfil, x: lokasi[0], y: lokasi[1], status: 'Diajukan', lokasi_kelurahan_id: selected.id }))
    }

    const handleFotoKTP = (file) => {
        setFotoKTPPemilik(file);
    };
    const handleFotoProfil = (file) => {
        setFotoProfil(file);
    };

    const Markers = () => {

        const map = useMapEvents({
            click(e) {
                setLokasi([
                    e.latlng.lat,
                    e.latlng.lng
                ]);
            },
        })

        return (
            lokasi ?
                <Marker
                    key={lokasi[0]}
                    position={lokasi}
                    draggable={true}
                />
                : null
        )

    }

    useEffect(() => {
        if (registerResult) {
            // dispatch(getListDistributor())
            window.alert('Pendaftaran anda telah diajukan! Mohon menunggu verifikasi dari admin')
            window.location.reload()
        }
    }, [registerResult])


    const filteredKelurahan =
        getListKelurahanResult ?
            (query === ''
                ? getListKelurahanResult
                : getListKelurahanResult.filter((kelurahan) =>
                    kelurahan.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )) : []


    return (
        <form onSubmit={handleSubmit} >

            <div className="relative p-6 flex-auto">
                <p className="my-2 text-slate-500 text-lg leading-relaxed text-center font-bold">
                    -- Data Login --
                </p>
                <div className='grid grid-cols-2 gap-4'>
                    <div className="relative z-0 mt-2">
                        <input
                            type="text"
                            required
                            value={username}
                            onChange={(event) => setUsername(event.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Username</label>
                    </div>
                    <div className="relative z-0 mt-2">
                        <input
                            type="password"
                            required
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password</label>
                    </div>
                </div>
                <p className="mb-2 mt-4 text-slate-500 text-lg leading-relaxed text-center font-bold">
                    -- Data Pemilik --
                </p>
                <div className='grid grid-cols-2 gap-4'>
                    <div className="relative z-0 mt-2">
                        <input
                            type="text"
                            required
                            value={namaPemilik}
                            onChange={(event) => setNamaPemilik(event.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nama Pemilik</label>
                    </div>
                    <div className="relative z-0 mt-2">
                        <input
                            type="email"
                            required
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email Pemilik</label>
                    </div>
                    <div className="relative z-0 mt-2">
                        <input
                            type="tel"
                            required
                            value={nohp}
                            pattern="^62\d*"
                            title="Masukkan nomor HP dengan format angka yang diawali dengan 628"
                            onChange={(event) => setNohp(event.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">No Telepon/HP Pemilik</label>
                    </div>
                    <div className="relative z-0 mt-2">
                        <input
                            type="text"
                            required
                            value={noktp}
                            pattern="\d{16}"
                            title="Solahkan masukkan 16 digit nomor KTP anda"
                            onChange={(event) => setNoktp(event.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">No KTP Pemilik</label>
                    </div>
                </div>
                <div>
                    <p className=" text-slate-500 text-xs mt-5 mb-1">Foto KTP Pemilik</p>
                    <FileUploader required={true} label={"Tambahkan atau Letakkan foto Disini"} handleChange={handleFotoKTP} name="file" types={["JPG", "PNG"]} multiple={false} />
                    <p>{fotoKTPPemilik ? `File name: ${fotoKTPPemilik.name}` : "no files uploaded yet"}</p>
                </div>
                <p className="my-2 text-slate-500 text-lg leading-relaxed text-center font-bold">
                    -- Data Perusahaan --
                </p>

                <div className='grid grid-cols-2 gap-4'>
                    <div className="relative z-0 mt-2">
                        <input
                            type="text"
                            required
                            value={namaPerusahaan}
                            onChange={(event) => setNamaPerusahaan(event.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nama Perusahaan</label>
                    </div>
                    <div className="relative z-0 mt-2">
                        <input
                            type="text"
                            required
                            pattern="^62\d*"
                            title="Masukkan nomor HP dengan format angka yang diawali dengan 628"
                            value={noTelpPerusahaan}
                            onChange={(event) => setNoTelpPerusahaan(event.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">No HP / CP perusahaan</label>
                    </div>
                    <div className="relative z-0 mt-4">
                        <textarea rows="" cols=""
                            required
                            value={alamat}
                            onChange={(event) => setAlamat(event.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer">
                        </textarea>
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Alamat Perusahaan</label>
                    </div>
                    <div>
                        <p className=" text-slate-500 text-xs mt-5">Kelurahan</p>

                        <Combobox value={selected} onChange={setSelected}>

                            <div className="relative mt-1">
                                <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                    <Combobox.Input
                                        required
                                        placeholder='Masukkan nama kelurahan anda'
                                        className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                        displayValue={(kelurahan) => kelurahan.nama}
                                        onChange={(event) => setQuery(event.target.value)}
                                    />
                                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                        <MdUnfoldMore />

                                    </Combobox.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    afterLeave={() => setQuery('')}
                                >
                                    <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {filteredKelurahan.length === 0 && query !== '' ? (
                                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                Nothing found.
                                            </div>
                                        ) : (
                                            filteredKelurahan.map((kelurahan) => (
                                                <Combobox.Option
                                                    key={kelurahan.id}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                        }`
                                                    }
                                                    value={kelurahan}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <span
                                                                className={`block text-xs  ${selected ? 'font-medium' : 'font-normal'
                                                                    }`}
                                                            >
                                                                Kelurahan {kelurahan.nama}, Kecamatan {kelurahan.lokasi_kecamatan.nama}, {kelurahan.lokasi_kecamatan.lokasi_kotakab.nama}
                                                            </span>
                                                            {selected ? (
                                                                <span
                                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                        }`}
                                                                >
                                                                    <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))
                                        )}
                                    </Combobox.Options>

                                </Transition>
                            </div>
                        </Combobox>
                    </div>
                </div>


                <div className='mt-4'>
                    <p className=" text-slate-500 text-xs mt-5 mb-1">Tambah Lokasi Peta</p>

                    <MapContainer
                        center={lokasi}
                        zoom={13}
                        scrollWheelZoom={true}
                        className="h-72 z-0">
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Markers />
                    </MapContainer>
                </div>
                <div>
                    <p className=" text-slate-500 text-xs mt-5 mb-1">Foto Profil Perusahaan</p>
                    <FileUploader required label={"Tambahkan atau Letakkan foto Disini"} handleChange={handleFotoProfil} name="file" types={["JPG", "PNG"]} multiple={false} />
                    <p>{fotoProfil ? `File name: ${fotoProfil.name}` : "no files uploaded yet"}</p>
                </div>
                <div className='grid grid-cols-2 gap-4 mt-2'>
                    <div className="relative z-0 mt-2">
                        <textarea rows="" cols=""
                            placeholder=' '
                            required
                            value={deskripsi}
                            onChange={(event) => setDeskripsi(event.target.value)}
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer">
                        </textarea>
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Deskripsi</label>
                    </div>
                    <div className="relative z-0 mt-2">
                        <textarea rows="" cols=""
                            value={catatan}
                            required
                            onChange={(event) => setCatatan(event.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer">
                        </textarea>
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Catatan</label>
                    </div>
                </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                {registerLoading ? (
                    <button
                        className="bg-blue-300 flex text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                    >
                        Save Changes
                        <MdRotateRight className='animate-spin text-xl' />
                    </button>
                ) :
                    <button
                        className="bg-blue-500 flex text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                    >
                        Save Changes
                    </button>
                }
            </div>
        </form>
    )
}


const Retail = ({ regisModal }) => {
    const [showModal, setShowModal] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [alamat, setAlamat] = useState('');
    const [nomor_KTP, setNomor_KTP] = useState('');
    const [nomor_telp, setNomor_telp] = useState('');
    const [nomor_izin, setNomor_izin] = useState('');
    const [npwp, setNpwp] = useState('');
    const [status_kepemilikan_toko, setStatus_kepemilikan_toko] = useState('');
    const [deskripsi, setDeskripsi] = useState('');
    const [catatan, setCatatan] = useState('');
    const [namaPerusahaan, setNamaPerusahaan] = useState('');
    const [no_telp_pemilik, setNo_telp_pemilik] = useState('');
    const [foto_KTP, setFoto_KTP] = useState('');
    const [foto_profil, setFoto_profil] = useState('');
    const [foto_izin, setFoto_izin] = useState('');
    const [query, setQuery] = useState('')
    const getListKelurahanResult = useGetDropdownListKelurahanQuery(query).data
    const [selected, setSelected] = useState('')
    const [lokasi, setLokasi] = useState([0.5336, 101.4474]);


    const { registerResult, registerLoading } = useSelector((state) => state.userReducer);

    const handleKTPChange = (file) => {
        setFoto_KTP(file);
    };
    const handleProfilChange = (file) => {
        setFoto_profil(file);
    };
    const handleIzinChange = (file) => {
        setFoto_izin(file);
    };

    const handleOptionChange = (event) => {
        setStatus_kepemilikan_toko(event.target.value);
    };

    const Markers = () => {

        const map = useMapEvents({
            click(e) {
                setLokasi([
                    e.latlng.lat,
                    e.latlng.lng
                ]);
            },
        })

        return (
            lokasi ?
                <Marker
                    key={lokasi[0]}
                    position={lokasi}
                    draggable={true}
                />
                : null
        )

    }

    const dispatch = useDispatch();
    const handleSubmit = (event) => {
        event.preventDefault();

        dispatch(register({ username: username, password: password, name: name, email: email, user_role: 'Retail', alamat: alamat, nomor_KTP: nomor_KTP, nomor_telp: nomor_telp, nomor_izin: nomor_izin, npwp: npwp, status_kepemilikan_toko: status_kepemilikan_toko, deskripsi: deskripsi, catatan: catatan, nama: namaPerusahaan, nomor_telp_pemilik: no_telp_pemilik, foto_KTP: foto_KTP, foto_profil: foto_profil, foto_izin: foto_izin, x: lokasi[0], y: lokasi[1], status: 'Diajukan', lokasi_kelurahan_id: selected.id }))

    }

    useEffect(() => {
        if (registerResult) {
            window.alert('Pendaftaran anda telah diajukan! Mohon menunggu verifikasi dari admin')
            window.location.reload();
        }
    }, [registerResult])

    const filteredKelurahan =
        getListKelurahanResult ? (
            query === ''
                ? getListKelurahanResult
                : getListKelurahanResult.filter((kelurahan) =>
                    kelurahan.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )) : []
    return (
        <form onSubmit={handleSubmit}>
            <div className="relative p-6 flex-auto">
                <p className="my-2 text-slate-500 text-lg leading-relaxed text-center font-bold">
                    -- Data Login --
                </p>
                <div className='grid grid-cols-2 gap-4'>

                    <div className="relative z-0 mt-2">
                        <input
                            type="text"
                            value={username}
                            required
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Username
                        </label>
                    </div>
                    <div className="relative z-0 mt-2">
                        <input
                            type="password"
                            value={password}
                            required
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Password
                        </label>
                    </div>
                </div>
                <p className="mb-2 mt-4 text-slate-500 text-lg leading-relaxed text-center font-bold">
                    -- Data Pemilik --
                </p>
                <div className='grid grid-cols-2 gap-4'>

                    <div className="relative z-0 mt-2">
                        <input
                            type="text"
                            value={name}
                            required
                            onChange={(e) => setName(e.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Nama Pemilik
                        </label>
                    </div>
                    <div className="relative z-0 mt-2">
                        <input
                            type="email"
                            value={email}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Email Pemilik
                        </label>
                    </div>
                    <div className="relative z-0 mt-2">
                        <input
                            type="text"
                            value={no_telp_pemilik}
                            onChange={(e) => setNo_telp_pemilik(e.target.value)}
                            pattern="^62\d*"
                            title="Masukkan nomor HP dengan format angka yang diawali dengan 628"
                            required
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            No Telepon/HP Pemilik
                        </label>
                    </div>
                    <div className="relative z-0 mt-2">
                        <input
                            type="text"
                            value={nomor_KTP}
                            onChange={(e) => setNomor_KTP(e.target.value)}
                            pattern="\d{16}"
                            title="Solahkan masukkan 16 digit nomor KTP anda"
                            required
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            No KTP Pemilik
                        </label>
                    </div>
                </div>
                <div>
                    <p className=" text-slate-500 text-xs mt-5 mb-1">Foto KTP</p>
                    <FileUploader required label={"Tambahkan atau Letakkan foto Disini"} handleChange={handleKTPChange} name="file" types={['PNG', 'JPG']} multiple={false} />
                    <p>{foto_KTP ? `File name: ${foto_KTP.name}` : "no files uploaded yet"}</p>
                </div>
            </div>
            <div className="relative p-6 flex-auto">
                <p className="my-2 text-slate-500 text-lg leading-relaxed text-center font-bold">
                    -- Data Retail --
                </p>
                <div className='grid grid-cols-2 gap-4'>

                    <div className="relative z-0 mt-2">
                        <input
                            type="text"
                            value={namaPerusahaan}
                            required
                            onChange={(e) => setNamaPerusahaan(e.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Nama Toko
                        </label>
                    </div>
                    <div className="relative z-0 mt-2">
                        <input
                            type="text"
                            value={nomor_telp}
                            onChange={(e) => setNomor_telp(e.target.value)}
                            placeholder=' '
                            pattern="^62\d*"
                            title="Masukkan nomor HP dengan format angka yang diawali dengan 628"
                            required
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Nomor Telepon Perusahaan
                        </label>
                    </div>
                    <div className="relative z-0 mt-4">
                        <textarea rows="" cols=""
                            value={alamat}
                            required
                            onChange={(e) => setAlamat(e.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer">
                        </textarea>
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Alamat Toko
                        </label>
                    </div>
                    <div>
                        <p className=" text-slate-500 text-xs mt-5">Kelurahan</p>

                        <Combobox value={selected} onChange={setSelected}>

                            <div className="relative mt-1">
                                <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                    <Combobox.Input
                                        required
                                        placeholder='Masukkan Kelurahan'
                                        className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                        displayValue={(kelurahan) => kelurahan.nama}
                                        onChange={(event) => setQuery(event.target.value)}
                                    />
                                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                        <MdUnfoldMore />

                                    </Combobox.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    afterLeave={() => setQuery('')}
                                >
                                    <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {filteredKelurahan.length === 0 && query !== '' ? (
                                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                Nothing found.
                                            </div>
                                        ) : (
                                            filteredKelurahan.map((kelurahan) => (
                                                <Combobox.Option
                                                    key={kelurahan.id}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                        }`
                                                    }
                                                    value={kelurahan}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <span
                                                                className={`block text-xs ${selected ? 'font-medium' : 'font-normal'
                                                                    }`}
                                                            >
                                                                Kelurahan {kelurahan.nama}, Kecamatan {kelurahan.lokasi_kecamatan.nama}, {kelurahan.lokasi_kecamatan.lokasi_kotakab.nama}
                                                            </span>
                                                            {selected ? (
                                                                <span
                                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                        }`}
                                                                >
                                                                    <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))
                                        )}
                                    </Combobox.Options>

                                </Transition>
                            </div>
                        </Combobox>
                    </div>
                </div>
                <div className='mt-4'>
                    <p className=" text-slate-500 text-xs mt-5 mb-1">Tambah Lokasi Peta</p>

                    <MapContainer
                        center={lokasi}
                        zoom={13}
                        required
                        scrollWheelZoom={true}
                        className="h-72 z-0">
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Markers />
                    </MapContainer>
                </div>
                <div className='grid grid-cols-2 gap-4 mt-4'>

                    <div className="relative z-0 ">
                        <input
                            type="text"
                            required
                            value={nomor_izin}
                            onChange={(e) => setNomor_izin(e.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Nomor Izin Toko
                        </label>
                    </div>
                    <div className="relative z-0">
                        <input
                            type="text"
                            required
                            value={npwp}
                            onChange={(e) => setNpwp(e.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            NPWP
                        </label>
                    </div>
                </div>
                <div className='mt-4'>
                    <p className=" text-slate-500 text-xs mt-5 mb-1">Status Kepemilikan toko</p>
                    <div className='flex gap-8'>
                        <label>
                            <input
                                type="radio"
                                value="Milik sendiri"
                                checked={status_kepemilikan_toko === 'Milik sendiri'}
                                onChange={handleOptionChange}
                                required
                            />
                            Milik sendiri
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="Sewa"
                                checked={status_kepemilikan_toko === 'Sewa'}
                                onChange={handleOptionChange}
                            />
                            Sewa
                        </label>
                    </div>

                </div>
                <div>
                    <p className=" text-slate-500 text-xs mt-5 mb-1">Foto Izin</p>
                    <FileUploader required label={"Tambahkan atau Letakkan foto Disini"} handleChange={handleIzinChange} name="file" types={['PNG', 'JPG']} multiple={false} />
                    <p>{foto_izin ? `File name: ${foto_izin.name}` : "no files uploaded yet"}</p>
                </div>
                <div className='grid grid-cols-2 gap-4'>

                    <div className="relative z-0 mt-2">
                        <textarea rows="" cols=""
                            value={deskripsi}
                            required
                            onChange={(e) => setDeskripsi(e.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer">
                        </textarea>
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Deskripsi
                        </label>
                    </div>
                    <div className="relative z-0 mt-2">
                        <textarea rows="" cols=""
                            value={catatan}
                            required
                            onChange={(e) => setCatatan(e.target.value)}
                            placeholder=' '
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer">
                        </textarea>
                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Catatan
                        </label>
                    </div>
                </div>
                <div>
                    <p className=" text-slate-500 text-xs mt-5 mb-1">Foto Profil</p>
                    <FileUploader required label={"Tambahkan atau Letakkan foto Disini"} handleChange={handleProfilChange} name="file" types={['PNG', 'JPG']} multiple={false} />
                    <p>{foto_profil ? `File name: ${foto_profil.name}` : "no files uploaded yet"}</p>
                </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                {registerLoading ? (
                    <button
                        className="bg-blue-300 flex text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                    >
                        Save Changes
                        <MdRotateRight className='animate-spin text-xl' />
                    </button>
                ) :
                    <button
                        className="bg-blue-500 flex text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                    >
                        Save Changes
                    </button>
                }
            </div>
        </form>
    )
}


export default Register