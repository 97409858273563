import React, { useState } from 'react'
import { useGetLaporanPajakQuery, useGetLaporanQuery } from '../../store/apis/laporanApi'
import DataTable from 'react-data-table-component';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

const LaporanPerhitunganPajak = () => {
    const [start, setStart] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [end, setEnd] = useState(format(new Date(), 'yyyy-MM-dd'));
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;

    const { data, isFetching, error } = useGetLaporanPajakQuery({ start: start, end: end, id: id });

    const handleClick = (e) => {
        e.preventDefault();
        secureLocalStorage.setItem('data', data);
        secureLocalStorage.setItem('start', start);
        secureLocalStorage.setItem('end', end);
        const newTab = window.open('/cetakLaporanPerhitunganPajak', '_blank');
        if (newTab) {
            newTab.focus();
        }

    }

    const colKP = [
        {
            name: 'Tanggal',
            selector: row => format(new Date(row.penjualan.tanggal_transaksi), 'dd-MM-yyyy'),
        },
        {
            name: 'Nama Transaksi',
            selector: row => 'Penjualan kepada retail ' + row.penjualan.retail.nama,
            grow: 2,
        },
        {
            name: 'Total Penjualan - Retur',
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.TotalPenjualan - row.totalReturs),
            right: true,
        },
        {
            name: 'Perhitungan Pajak (11%)',
            selector: row =>Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format((row.TotalPenjualan - row.totalReturs)*11/100),
            right: true,
        },
    ]

    return (
        <div className='w-full  p-5  '>
            <div className='w-full bg-white border p-5 rounded-lg '>

                <div className='grid grid-cols-2 gap-4 mb-8'>
                    <div className="relative z-0 mt-4">
                        <input
                            type="date"
                            id="floating_standard"
                            placeholder=' '
                            value={start}
                            onChange={(e) => setStart(e.target.value)}
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Dari Tanggal
                        </label>
                    </div>
                    <div className="relative z-0 mt-4">
                        <input
                            type="date"
                            id="floating_standard"
                            placeholder=''
                            value={end}
                            onChange={(e) => setEnd(e.target.value)}
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                            Sampai Tanggal
                        </label>
                    </div>
                    <div className='h-full w-full flex col-span-2'>
                        <button onClick={(e) => handleClick(e)} className='p-2 rounded bg-blue-500 w-full place-content-center text-white'>Cetak</button>
                    </div>
                </div>
                <div className='border-b border-slate-300 pb-2 text-center'>
                    <div className=' self-center'>
                        <h1 className='text-base font-bold text-slate-500'>Laporan Perhitungan Pajak</h1>
                        <p className='text-slate-400'>{format(new Date(start), 'dd-MM-yyyy')} - {format(new Date(end), 'dd-MM-yyyy')}</p>
                    </div>
                </div>
                {data ? (
                    <DataTable columns={colKP} data={data} noDataComponent='Tidak ada transaksi' />
                ) : isFetching ? (
                    <p>loading</p>
                ) : (
                    <p>{error ? error : "Data kosong"}</p>
                )}

                {data &&
                    data.length > 0 ?
                    <div className='grid grid-cols-5'>
                        <div className='col-span-3 px-4 text-right text-lg'>
                            Total
                        </div>
                        <div className='text-right px-4 text-lg'>
                            {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data.reduce((sum, item) => sum + item.TotalPenjualan - item.totalReturs, 0)) }
                        </div>
                        <div className='text-right px-4 text-lg'>
                            {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data.reduce((sum, item) => sum + item.TotalPenjualan - item.totalReturs, 0) * 11/100)}
                        </div>

                    </div> : ''
                }


            </div>
        </div>
    )
}

export { LaporanPerhitunganPajak }