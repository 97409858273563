import React, { Fragment, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { MdDelete } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../actions/auth';
import { deleteBrand, getListBrand } from '../../actions/produkBrandAction';
import { AddBrand } from './AddProductDist';
import { EditBrand } from './EditProductDist';
import { useDeleteBrandMutation, useGetListBrandQuery } from '../../store/apis/produkBrandApi';
import secureLocalStorage from 'react-secure-storage';

const ManageBrand = () => {
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const [addBrandForm, setAddBrandForm] = useState(false);

    const ShowBrandModal = (e) => {
        if (addBrandForm) {
            setAddBrandForm(false);
        } else {
            setAddBrandForm(true);
        }
    }

    const { data, isFetching, error } = useGetListBrandQuery(id);
    const [deleteBrand, deleteBrandResult] = useDeleteBrandMutation();

    console.log(deleteBrandResult)

    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            deleteBrand(id)
        }
    }


    const colBrand = [
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            name: 'Sub Sub Kategori Produk',
            selector: row => row.subSubKategori,
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idKP = record.id;
                return (
                    <Fragment>
                        <EditBrand data={record} />

                        <span className='mx-3'>|</span>

                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, idKP, "brand")}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]
    return (
        <>
            {addBrandForm && <AddBrand brandModal={ShowBrandModal} />}
            <div className='w-full  p-5  '>
                <div className='w-full bg-white border p-5 rounded-lg '>

                    <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                        <div className='col-span-3 self-center'>
                            <h1 className='text-base font-bold text-blue-500 '>Data Brand</h1>
                            <p className='text-slate-400'>Di bawah ini merupakan data semua brand yang telah terdaftar.</p>
                        </div>
                        <div className='place-self-end'>
                        <button
                            className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={(e) => ShowBrandModal(e)}
                        >
                            Tambah Data
                        </button>
                        </div>
                        <div className='col-span-3' />
                    </div>

                    {data ? (
                        <DataTable columns={colBrand} data={data} pagination />
                    ) : isFetching ? (
                        <p>loading</p>
                    ) : (
                        <p>{error ? error : "Data kosong"}</p>
                    )}
                </div>


            </div>
        </>

    )
}

export default ManageBrand