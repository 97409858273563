import { Combobox, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files';
import { MdCheck, MdUnfoldMore } from 'react-icons/md';
import { useAddSubKategoriProdukMutation, useGetListSubKategoriProdukQuery } from '../../store/apis/subKategoriProdukApi';
import { useAddKategoriProdukMutation, useGetListKategoriProdukQuery } from '../../store/apis/kategoriProdukApi';
import { useAddSubSubKategoriProdukMutation } from '../../store/apis/subSubKategoriProdukApi';

const AddCategory = () => {
    const [showModal, setShowModal] = useState(false);
    const nama = useRef('');
    const [addKategori, addKategoriResult] = useAddKategoriProdukMutation();

    const handleSubmit = (event) => {
        event.preventDefault();

        addKategori({ nama: nama.current.value, status: 'Aktif' })
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    return (
        <div>
            <button
                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Tambah Data
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <form onSubmit={handleSubmit} className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Tambah Kategori Produk
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            id="floating_standard"
                                            placeholder=' '
                                            ref={nama}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Kategori Produk
                                        </label>
                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="submit"
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

const AddSubCategory = () => {
    const [showModal, setShowModal] = useState(false);
    const nama = useRef('');
    const getListKategoriResult = useGetListKategoriProdukQuery().data;
    const [selected, setSelected] = useState({ 'nama': 'Pilih Kategori' })
    const [query, setQuery] = useState('')
    const [addSubKategori, addSubKategoriResult] = useAddSubKategoriProdukMutation();



    const handleSubmit = (event) => {
        event.preventDefault();

        addSubKategori({ nama: nama.current.value, status: '1', produk_kategori_id: selected.id })
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const filteredKategori =
        query === ''
            ? getListKategoriResult
            : getListKategoriResult.filter((kategori) =>
                kategori.nama
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    return (
        <div>
            <button
                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Tambah Data
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <form onSubmit={handleSubmit} className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Tambah Sub Kategori Produk
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            id="floating_standard"
                                            placeholder=' '
                                            ref={nama}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Sub Kategori Produk
                                        </label>
                                    </div>
                                    <p className=" text-slate-500 text-xs mt-5">Kategori</p>

                                    <Combobox value={selected} onChange={setSelected}>

                                        <div className="relative mt-1">
                                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                <Combobox.Input
                                                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                    displayValue={(kategori) => kategori.nama}
                                                    onChange={(event) => setQuery(event.target.value)}
                                                />
                                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <MdUnfoldMore />

                                                </Combobox.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                afterLeave={() => setQuery('')}
                                            >
                                                <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {filteredKategori.length === 0 && query !== '' ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        filteredKategori.map((kategori) => (
                                                            <Combobox.Option
                                                                key={kategori.id}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                    }`
                                                                }
                                                                value={kategori}
                                                            >
                                                                {({ selected, active }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                                                }`}
                                                                        >
                                                                            {kategori.nama}
                                                                        </span>
                                                                        {selected ? (
                                                                            <span
                                                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                    }`}
                                                                            >
                                                                                <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </Combobox.Options>

                                            </Transition>
                                        </div>
                                    </Combobox>
                                    

                                </div>

                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="submit"
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

const AddSubSubCategory = () => {
    const [showModal, setShowModal] = useState(false);
    const nama = useRef('');
    const [gambar, setGambar] = useState('')
    const getListSubKategoriResult = useGetListSubKategoriProdukQuery().data;
    const [selected, setSelected] = useState({ 'nama': 'Pilih Sub Kategori' })
    const [query, setQuery] = useState('')
    const [addSubSubKategori, addSubSubKategoriResult] = useAddSubSubKategoriProdukMutation();

    const handleChange = (file) => {
        setGambar(file);
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        addSubSubKategori({ nama: nama.current.value, status: '1', produk_sub_kategori_id: selected.id, gambar: gambar })
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const filteredSubKategori =
        query === ''
            ? getListSubKategoriResult
            : getListSubKategoriResult.filter((subKategori) =>
                subKategori.nama
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    return (
        <div>
            <button
                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Tambah Data
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <form onSubmit={handleSubmit} className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Tambah Kategori Produk
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            id="floating_standard"
                                            placeholder=' '
                                            ref={nama}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Kategori Produk
                                        </label>
                                    </div>
                                    <p className=" text-slate-500 text-xs mt-5">Kategori</p>

                                    <Combobox value={selected} onChange={setSelected}>

                                        <div className="relative mt-1">
                                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                <Combobox.Input
                                                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                    displayValue={(subKategori) => subKategori.nama}
                                                    onChange={(event) => setQuery(event.target.value)}
                                                />
                                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <MdUnfoldMore />

                                                </Combobox.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                afterLeave={() => setQuery('')}
                                            >
                                                <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {filteredSubKategori.length === 0 && query !== '' ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        filteredSubKategori.map((subKategori) => (
                                                            <Combobox.Option
                                                                key={subKategori.id}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                    }`
                                                                }
                                                                value={subKategori}
                                                            >
                                                                {({ selected, active }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                                                }`}
                                                                        >
                                                                            {subKategori.nama}
                                                                        </span>
                                                                        {selected ? (
                                                                            <span
                                                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                    }`}
                                                                            >
                                                                                <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </Combobox.Options>

                                            </Transition>
                                        </div>
                                    </Combobox>
                                    <div>
                                        <p className=" text-slate-500 text-xs mt-5 mb-1">Gambar Kategori</p>
                                        <FileUploader label={"Tambahkan atau Letakkan foto Disini"} handleChange={handleChange} name="file" types={['PNG', 'JPG']} multiple={false} />
                                        <p>{gambar ? `File name: ${gambar.name}` : "no files uploaded yet"}</p>
                                    </div>
                                </div>

                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="submit"
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

export { AddCategory, AddSubCategory, AddSubSubCategory }