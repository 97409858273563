import axios from "axios";
import {alamat_backend} from "../components/api";


export const GET_LIST_KUALITAS_PASAR = "GET_LIST_KUALITAS_PASAR"
export const ADD_KUALITAS_PASAR = "ADD_KUALITAS_PASAR"
export const DELETE_KUALITAS_PASAR = "DELETE_KUALITAS_PASAR"
export const UPDATE_KUALITAS_PASAR = "UPDATE_KUALITAS_PASAR"



const getListKPasar = () => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_LIST_KUALITAS_PASAR,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'ProdukKualitasPasaranList',
            timeout: 5000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_LIST_KUALITAS_PASAR,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_LIST_KUALITAS_PASAR,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const addKPasar = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: ADD_KUALITAS_PASAR,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'ProdukKualitasPasaran',
            timeout: 5000,
            data: data,
            headers: {
                "Content-Type": "multipart/form-data",
            }

        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: ADD_KUALITAS_PASAR,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                console.log(error)
                dispatch({
                    type: ADD_KUALITAS_PASAR,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const deleteKPasar = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: DELETE_KUALITAS_PASAR,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'DELETE',
            url: alamat_backend + 'ProdukKualitasPasaran/'+ id,
            timeout: 5000,
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: DELETE_KUALITAS_PASAR,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: DELETE_KUALITAS_PASAR,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })
    }
}

const updateKPasar = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: UPDATE_KUALITAS_PASAR,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'PUT',
            url: alamat_backend + 'ProdukKualitasPasaran/' + data.id,
            timeout: 5000,
            data: data
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: UPDATE_KUALITAS_PASAR,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: UPDATE_KUALITAS_PASAR,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message
                    }
                })
            })
    }
}


export { getListKPasar, addKPasar, deleteKPasar, updateKPasar }