import axios from "axios";
import {alamat_backend} from "../components/api";


export const GET_LIST_PRODUK_STOK_DETAIL = "GET_LIST_PRODUK_STOK_DETAIL"
export const GET_LIST_PRODUK_STOK_DETAIL_SUM = "GET_LIST_PRODUK_STOK_DETAIL_SUM"
export const DELETE_PRODUK_STOK_DETAIL = "DELETE_PRODUK_STOK_DETAIL"
export const ADD_PRODUK_STOK_DETAIL = "ADD_PRODUK_STOK_DETAIL"
export const UPDATE_PRODUK_STOK_DETAIL = "UPDATE_PRODUK_STOK_DETAIL"


const getListStokDetail = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_LIST_PRODUK_STOK_DETAIL,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'ProdukStokDetailList' ,
            timeout: 120000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_LIST_PRODUK_STOK_DETAIL,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_LIST_PRODUK_STOK_DETAIL,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.response.data,
                    }
                })
            })


    }
}

const getListStokDetailSum = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_LIST_PRODUK_STOK_DETAIL_SUM,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'ProdukStokDetailListSum?id=' + id,
            timeout: 5000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_LIST_PRODUK_STOK_DETAIL_SUM,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_LIST_PRODUK_STOK_DETAIL_SUM,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.response.data,
                    }
                })
            })


    }
}


const addStokDetail = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: ADD_PRODUK_STOK_DETAIL,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'ProdukStokDetail',
            timeout: 120000,
            data: data,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: ADD_PRODUK_STOK_DETAIL,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: ADD_PRODUK_STOK_DETAIL,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.response.data,
                    }
                })
            })
    }
}

const updateStokDetail = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: UPDATE_PRODUK_STOK_DETAIL,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'ProdukStokDetailUpdate',
            timeout: 120000,
            data: data,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: UPDATE_PRODUK_STOK_DETAIL,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: UPDATE_PRODUK_STOK_DETAIL,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error
                    }
                })
            })
    }
}

const deleteStokDetail = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: DELETE_PRODUK_STOK_DETAIL,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'DELETE',
            url: alamat_backend + 'ProdukStokDetail/'+ id,
            timeout: 120000,
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: DELETE_PRODUK_STOK_DETAIL,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: DELETE_PRODUK_STOK_DETAIL,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })
    }
}


export { getListStokDetail, addStokDetail, deleteStokDetail, updateStokDetail, getListStokDetailSum }