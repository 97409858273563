import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const produkUkuran = createApi({
    reducerPath: 'produkUkuran',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getUkuran: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'ukuran' }];

                },
                query: () => {
                    return {
                        url: 'ProdukUkuran',
                        method: 'GET',
                    };
                },
            }),
            getListUkuran: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'ukuran' }];

                },
                query: (id) => {
                    return {
                        url: 'ProdukUkuranList',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            addUkuran: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'ukuran' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'ProdukUkuran',
                        body: data,
                    };
                },
            }),
            updateUkuran: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'ukuran' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `ProdukUkuran/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteUkuran: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'ukuran' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `ProdukUkuran/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetUkuranQuery, useGetListUkuranQuery, useAddUkuranMutation, useUpdateUkuranMutation, useDeleteUkuranMutation } = produkUkuran;
export { produkUkuran };