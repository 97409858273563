import { Tab } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { AddRetail, AddSupplier } from './AddAccount'
import { useDispatch, useSelector } from 'react-redux';
import { getListDistributor } from '../../actions/distributorAction';
import DataTable from 'react-data-table-component';
import { useGetListRetailQuery, useUpdateRetailStatusMutation } from '../../store/apis/retailApi';
import { useGetListDistributorQuery, useUpdateDistributorStatusMutation } from '../../store/apis/distributorApi';
import { MdInfo, MdOutlineVerified, MdUnpublished } from 'react-icons/md';
import { alamat_storage } from '../../components/api';
import ContactPopup from '../../components/ContactPopup';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import marker from '../../components/marker.png'

const ManageAccount = () => {

    const [status, setStatus] = useState('Aktif');
    const [akun, setAkun] = useState('Distributor');
    const [tel, setTel] = useState('');
    const [contactPopup, setcontactPopup] = useState(false);
    const [detailPopup, setdetailPopup] = useState(false);
    const [dataDetail, setDataDetail] = useState('');

    const { data, error, isFetching } = useGetListRetailQuery(status)
    const distributor = useGetListDistributorQuery(status)

    const HandleContact = (tel) => {
        if (contactPopup) {
            setTel('');
            setcontactPopup(false);
        } else {
            setTel(tel);
            setcontactPopup(true);
        }
    }

    const HandleClick = (data) => {
        if (detailPopup) {
            setDataDetail('');
            setdetailPopup(false)
        } else {
            setDataDetail(data);
            setdetailPopup(true);
        }
    }



    const handleChange = (index) => {
        switch (index) {
            case 0:
                setStatus('Aktif')
                break;
            case 1:
                setStatus('Diajukan')
                break;
            case 2:
                setStatus('Ditolak')
                break;
            default:
                break;
        }
    }

    const handleAkun = (index) => {
        switch (index) {
            case 0:
                setAkun('Distributor')
                break;
            case 1:
                setAkun('Retail')
                break;
            default:
                break;
        }
    }

    const colDist = [
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
            cell: (record) => {
                return (
                    <div>
                        {record.nama}
                    </div>
                )
            }
        },
        {
            name: 'Alamat',
            selector: row => row.nomor_KTP,
            sortable: true,
            cell: (record) => {
                return (
                    <div>
                        {record.alamat}
                    </div>
                )
            }
        },
        {
            name: 'Kabupaten / Kota',
            selector: row => row.lokasi_kelurahan.lokasi_kecamatan.lokasi_kota_kab.nama,
            sortable: true,

        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                return (
                    <Fragment>
                        <button onClick={() => HandleClick(record)} className='rounded-lg px-2 py-1 bg-blue-500 text-white text-xl' type="button"><MdInfo /></button>
                    </Fragment>
                );
            },
        },
    ]

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getListDistributor());
    }, [dispatch])


    return (
        <div className='w-full h-auto p-5 '>
            {contactPopup && <ContactPopup tel={tel} contactModal={HandleContact} />}
            {detailPopup && <AccountDetail data={dataDetail} status={status} akun={akun} detailModal={HandleClick} />}
            <div className='w-full bg-white border pb-5 rounded-lg '>
                <Tab.Group onChange={(index) => handleChange(index)}>

                    <Tab.List className='bg-blue-100 rounded-t-lg border border-blue-100'>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>Aktif</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>Diajukan</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>Ditolak</div>
                            )}
                        </Tab>

                    </Tab.List>
                    <Tab.Panels className='p-4'>
                        <div className=' border border-blue-100'>
                            <Tab.Group onChange={(index) => handleAkun(index)}>

                                <Tab.List className='bg-blue-100 rounded-t-lg border border-blue-100'>
                                    <Tab>
                                        {({ selected }) => (
                                            <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>Kelola Distributor</div>
                                        )}
                                    </Tab>
                                    <Tab>
                                        {({ selected }) => (
                                            <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>Kelola Retail</div>
                                        )}
                                    </Tab>

                                </Tab.List>

                                <Tab.Panels className={`mt-5 px-5`}>
                                    <Tab.Panel >
                                        <div className='grid grid-cols-4 mb-5'>
                                            <div className='col-span-3 self-center'>
                                                <h1 className='text-base font-bold text-blue-500 '>Data Distributor</h1>
                                                <p className='text-slate-400'>Di bawah ini merupakan data semua Distributor yang telah terdaftar.</p>
                                            </div>
                                            {status === 'Aktif' && (
                                                <div className='place-self-end'>
                                                    <AddSupplier />
                                                </div>
                                            )}
                                        </div>
                                        {distributor.data ? (
                                            <DataTable
                                                columns={colDist}
                                                data={distributor.data}
                                                pagination />
                                        ) : distributor.isFetching ? (
                                            <p>loading</p>
                                        ) : (
                                            <p>{distributor.error ? distributor.error : "data kosong"}</p>
                                        )
                                        }

                                    </Tab.Panel>
                                    <Tab.Panel >
                                        <div className='grid grid-cols-4 mb-5'>
                                            <div className='col-span-3 self-center'>
                                                <h1 className='text-base font-bold text-blue-500 '>Data Retail</h1>
                                                <p className='text-slate-400'>Di bawah ini merupakan data semua Retail yang telah terdaftar.</p>
                                            </div>
                                            {status === 'Aktif' && (
                                                <div className='place-self-end'>
                                                    <AddRetail />
                                                </div>
                                            )}

                                        </div>
                                        {data ? (
                                            <DataTable columns={colDist} data={data} pagination />
                                        ) : isFetching ? (
                                            <p>loading</p>
                                        ) : (
                                            <p>{error ? error : "data kosong"}</p>
                                        )
                                        }


                                    </Tab.Panel>

                                </Tab.Panels>

                            </Tab.Group>
                        </div>
                    </Tab.Panels>
                </Tab.Group>
            </div>


        </div>
    )
}


const AccountDetail = ({ data, status, akun, detailModal }) => {
    const [updateDistributor, updateDistributorResult] = useUpdateDistributorStatusMutation();
    const [updateRetail, updateRetailResult] = useUpdateRetailStatusMutation();
    const customIcon = L.icon({
        iconUrl: marker, // Replace with your custom marker icon
        iconSize: [32, 32], // Adjust the size of the marker icon
        iconAnchor: [16, 32], // Adjust the anchor point of the marker icon
    });
    const Markers = () => {
        return data ? (
            <Marker position={[data.x, data.y]} icon={customIcon} />
        ) : null;
    };

    const HandleClick = (status) => {
        if (akun === "Distributor") {
            updateDistributor({id: data.id, status: status})
        } else {
            updateRetail({id: data.id, status: status});
        }
        detailModal();
    }
    return (
        <div>
            <div
                className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative h-full mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Detail {akun}
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => detailModal()}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <p className="my-2 text-slate-500 text-lg leading-relaxed text-center font-bold">
                                -- Data Akun --
                            </p>
                            <div className='grid  grid-cols-2 w-full text-center'>

                                <div className='border-r border-b p-5'>
                                    <h1 className='font-bold'>Nama Pemilik</h1>
                                    <p>{data.user.name}</p>
                                </div>
                                <div className='border-b p-5'>
                                    <h1 className='font-bold'>Email Pemilik</h1>
                                    <p>{data.user.email}</p>

                                </div>
                                <div className='border-b border-r p-5'>
                                    <h1 className='font-bold'>Nomor KTP Pemilik</h1>
                                    <p>{data.nomor_KTP}</p>
                                </div>
                                <div className='border-b p-5'>
                                    <h1 className='font-bold'>No Telp Pemilik</h1>
                                    <p>{data.nomor_telp_cp}</p>
                                </div>
                                <div className='border-b p-5 col-span-2'>
                                    <h1 className='font-bold'>Foto KTP Pemilik</h1>
                                    <img src={`${alamat_storage}distributorKTP/${data.foto_KTP}`} alt="" className='h-40 mx-auto' />
                                </div>
                            </div>

                            <p className="my-2 text-slate-500 text-lg leading-relaxed text-center font-bold">
                                -- Data Perusahaan --
                            </p>
                            <div className='grid  grid-cols-2 w-full text-center'>

                                <div className='border-r border-b p-5'>
                                    <h1 className='font-bold'>Nama Perusahaan</h1>
                                    <p>{data.nama}</p>
                                </div>
                                <div className='border-b p-5'>
                                    <h1 className='font-bold'>Nomor Telpon CP Perusahaan</h1>
                                    <p>{data.nomor_telp}</p>
                                </div>
                                <div className='border-b border-r p-5'>
                                    <h1 className='font-bold'>Alamat Perusahaan</h1>
                                    <p>
                                        {data.alamat},
                                        Kelurahan {data.lokasi_kelurahan.nama},
                                        {' Kecamatan ' + data.lokasi_kelurahan.lokasi_kecamatan.nama},
                                        {' ' + data.lokasi_kelurahan.lokasi_kecamatan.lokasi_kota_kab.nama},
                                        {' ' + data.lokasi_kelurahan.kode_pos}</p>
                                </div>
                                <div className='border-b p-5'>
                                    <h1 className='font-bold'>Catatan</h1>
                                    <p>{data.catatan}</p>
                                </div>
                                <div className='border-b p-5 col-span-2'>
                                    <h1 className='font-bold'>Deskripsi Perusahaan</h1>
                                    <p>{data.deskripsi}</p>
                                </div>
                                <div className='border-b p-5 col-span-2'>
                                    <h1 className='font-bold'>Foto Profil Perusahaan</h1>
                                    <img src={`${alamat_storage}distributorProfil/${data.foto_profil}`} alt="" className='h-40 mx-auto' />
                                </div>
                                <div className='border-b p-5 col-span-2'>
                                    <h1 className='font-bold mb-2'>Lokasi Peta Perusahaan</h1>
                                    <MapContainer
                                        center={[data.x, data.y]}
                                        zoom={13}
                                        scrollWheelZoom={true}
                                        className="h-72 z-0">
                                        <TileLayer
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        <Markers />
                                    </MapContainer>
                                </div>
                            </div>
                        </div>
                        {/*footer*/}
                        {status === "Diajukan" && (
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b gap-x-4">
                                <button onClick={()=>HandleClick('Ditolak')} type="btn" className='bg-red-500 text-white px-3 py-2 rounded-lg'>Tolak</button>
                                <button onClick={()=>HandleClick('Aktif')} type="btn" className='bg-blue-500 text-white px-3 py-2 rounded-lg'>Terima</button>
                            </div>

                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}


export { ManageAccount, AccountDetail }