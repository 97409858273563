import {
  Route,
  Routes,
} from 'react-router-dom';
import Footer from './components/Footer';
import NavbarRetail from './components/Navbar';
import { SidebarAdmin, SidebarAkun, SidebarDistributor } from './components/Sidebar';
import {ManageAccount} from './Pages/akun/ManageAccount';
import { Profile } from './Pages/akun/Profile';
import LandingPage from './Pages/LandingPage';
import Login from './Pages/Login';
import ManageLocation from './Pages/lokasi/ManageLocation';
import ManagePegawai from './Pages/pegawai/ManagePegawai';
import ManageProductCategory from './Pages/product/ManageProductCategory';
import { ProductList } from './Pages/product/Product';
import ProductDetail from './Pages/product/ProductDetail';
import UserRedirect from './components/UserRedirect';
import TransactionRetail from './Pages/transaction/TransactionRetail';
import { AdminRoleCheck, AuthRequirement, DistRoleCheck, RetailRoleCheck, StatusCheck } from './components/AuthRequirement';
import ManageProductDist from './Pages/product/ManageProductDist';
import KelolaPembelian from './Pages/pembelian/KelolaPembelian';
import ManageJabatan from './Pages/pegawai/ManageJabatan';
import ManagePegawaiJabatan from './Pages/pegawai/ManagePegawaiJabatan';
import ManageBrand from './Pages/product/ManageBrand';
import ManageKualitasPasaran from './Pages/product/ManageKualitasPasaran';
import ManageWarnaUkuran from './Pages/product/ManageWarnaUkuran';
import ManageProdukFoto from './Pages/product/ManageProdukFoto';
import OpsiPengiriman from './Pages/penjualan/OpsiPengiriman';
import Keranjang from './Pages/transaction/Keranjang';
import Checkout from './Pages/transaction/Checkout';
import KelolaPenjualan from './Pages/penjualan/KelolaPenjualan';
import PembayaranRetail from './Pages/transaction/PembayaranRetail';
import PegawaiDodolan from './Pages/pegawai/PegawaiDodolan';
import PembayaranRetailDist from './Pages/penjualan/PembayaranRetailDist';
import PembayaranDistributor from './Pages/penjualan/PembayaranDistributor';
import PembayaranDistributorAdmin from './Pages/penjualan/PembayaranDistributorAdmin';
import { LaporanTransaksiDistributor } from './Pages/laporan/LaporanTransaksiDistributor';
import { CetakLaporanTransaksiDistributor } from './Pages/laporan/CetakLaporanTransaksiDistributor';
import BlackListRetail from './Pages/akun/BlackListRetail';
import { CetakStrukPengiriman } from './Pages/laporan/CetakStrukPengiriman';
import { ReturProduk } from './Pages/penjualan/ReturProduk';
import { LaporanPerhitunganPajak } from './Pages/laporan/LaporanPerhitunganPajak';
import { CetakLaporanPerhitunganPajak } from './Pages/laporan/CetakLaporanPerhitunganPajak';
import Distributor from './Pages/akun/Distributor';
import ProductRetail from './Pages/product/ProductRetail';
import { DashboardDistributor } from './Pages/akun/Dashboard';
import { LaporanOmset } from './Pages/laporan/LaporanOmset';
import { CetakLaporanOmset } from './Pages/laporan/CetakLaporanOmset';
import { LaporanPemasukanAdmin } from './Pages/laporan/LaporanPemasukanAdmin';
import { CetakLaporanPemasukan } from './Pages/laporan/CetakLaporanPemasukanAdmin';
import { DashboardAdmin } from './Pages/akun/DashboardAdmin';
import DistributorProfile from './Pages/akun/DistributorProfile';
import { CetakInvoicePembelian } from './Pages/laporan/CetakInvoicePembelian';
import ManageJabatan1 from './Pages/pegawai/ManageJabatan1';

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route exact path='/Login' element={<Login />} />
        <Route exact path='/LogingIn' element={<UserRedirect />} />
        <Route exact path='/cetakLaporanTransaksiDistributor' element={<CetakLaporanTransaksiDistributor />} />
        <Route exact path='/cetakLaporanPerhitunganPajak' element={<CetakLaporanPerhitunganPajak />} />
        <Route exact path='/cetakLaporanOmset' element={<CetakLaporanOmset />} />
        <Route exact path='/cetakLaporanPemasukan' element={<CetakLaporanPemasukan />} />
        <Route exact path='/cetakStrukPengiriman' element={<CetakStrukPengiriman />} />
        <Route exact path='/cetakInvoicePembelian' element={<CetakInvoicePembelian />} />


        <Route element={<Footer />}>
          <Route element={<NavbarRetail />}>
            <Route exact path='/' element={< LandingPage />}></Route>
          </Route>
        </Route>
        <Route element={<AuthRequirement />}>

          {/* Retail Page */}
          <Route element={<RetailRoleCheck role="Retail" />} >

            <Route element={<Footer />}>
              <Route element={<NavbarRetail />}>
                <Route exact path='/product' element={< ProductRetail />}></Route>
                <Route exact path='/productDetail/:id' element={< ProductDetail />}></Route>
                <Route exact path='/distributor/:id' element={< Distributor />}></Route>

                <Route element={<SidebarAkun />}>
                  <Route exact path='/profile' element={< Profile />}></Route>
                  <Route exact path='/keranjang' element={< Keranjang />}></Route>
                  <Route exact path='/transaksiRetail' element={< TransactionRetail />}></Route>
                  <Route exact path='/PembayaranRetail' element={< PembayaranRetail />}></Route>
                </Route>
                <Route exact path='/checkout' element={< Checkout />}></Route>

              </Route>
            </Route>
          </Route>
          {/* Retail Page End */}

          {/* Admin Page */}
          <Route element={<AdminRoleCheck role="Admin" />} >

            <Route element={<SidebarAdmin />}>
              <Route exact path='/adminHome' element={<DashboardAdmin />}></Route>
              <Route exact path='/kelolaAkun' element={< ManageAccount />}></Route>
              <Route exact path='/pegawaiDodolan' element={< PegawaiDodolan />}></Route>
              <Route exact path='/kelolaLokasi' element={< ManageLocation />}></Route>
              <Route exact path='/kelolaKelompokProduk' element={< ManageProductCategory />}></Route>
              <Route exact path='/kelolaKualitasPasaran' element={< ManageKualitasPasaran />}></Route>
              <Route exact path='/pembayaranDistributorAdmin' element={< PembayaranDistributorAdmin />}></Route>
              <Route exact path='/blacklistRetail' element={< BlackListRetail />}></Route>
              <Route exact path='/laporanPemasukan' element={< LaporanPemasukanAdmin />}></Route>

            </Route>
          </Route>
          {/* Admin Page End */}

          {/* Distributor Page */}
          <Route element={<DistRoleCheck role="Distributor" />} >
            <Route element={<SidebarDistributor />}>
              <Route exact path='/DistributorHome' element={<DashboardDistributor />}></Route>
              <Route exact path='/kelolaPegawai' element={<ManagePegawai />}></Route>
              <Route exact path='/kelolaJabatan' element={<ManageJabatan />}></Route>
              <Route exact path='/kelolaJabatanPegawai' element={<ManagePegawaiJabatan />}></Route>
              <Route exact path='/kelolaProduk' element={< ManageProductDist />}></Route>
              <Route exact path='/kelolaBrand' element={< ManageBrand />}></Route>
              <Route exact path='/kelolaWarnaUkuran' element={< ManageWarnaUkuran />}></Route>
              <Route exact path='/KelolaPembelian' element={< KelolaPembelian />}></Route>
              <Route exact path='/KelolaPenjualan' element={< KelolaPenjualan />}></Route>
              <Route exact path='/KelolaFotoProduk' element={< ManageProdukFoto />}></Route>
              <Route exact path='/KelolaOpsiPengiriman' element={< OpsiPengiriman />}></Route>
              <Route exact path='/PembayaranRetailDist' element={< PembayaranRetailDist />}></Route>
              <Route exact path='/pembayaranDistributor' element={< PembayaranDistributor />}></Route>
              <Route exact path='/laporanTransaksiDistributor' element={< LaporanTransaksiDistributor />}></Route>
              <Route exact path='/laporanPerhitunganPajak' element={< LaporanPerhitunganPajak />}></Route>
              <Route exact path='/laporanOmset' element={< LaporanOmset />}></Route>
              <Route exact path='/returProduk' element={< ReturProduk />}></Route>
              <Route exact path='/distributorProfile' element={< DistributorProfile />}></Route>

            </Route>
          </Route>
          {/* Distributor Page End*/}
        </Route>

      </Routes>
    </div >

  );
}

export default App;
