import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const lokasiKotKabApi = createApi({
    reducerPath: 'lokasiKotKab',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getKotKab: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'KotKab' }];

                },
                query: () => {
                    return {
                        url: 'LokasiKotKab',
                        method: 'GET',
                    };
                },
            }),
            getListKotKab: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'KotKab' }];

                },
                query: (id) => {
                    return {
                        url: 'LokasiKotKabList',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            addKotKab: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'KotKab' }];
                },
                query: (data) => {
                   
                    return {
                        method: 'POST',
                        url: 'LokasiKotKab',
                        body: data,
                    };
                },
            }),
            updateKotKab: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'KotKab' }];
                },
                query: (data) => {
                    
                    return {
                        method: 'PUT',
                        url: `LokasiKotKab/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteKotKab: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'KotKab' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `LokasiKotKab/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetKotKabQuery, useGetListKotKabQuery, useAddKotKabMutation, useUpdateKotKabMutation, useDeleteKotKabMutation } = lokasiKotKabApi;
export { lokasiKotKabApi };