import React, { useEffect, useState } from 'react'
import { useGetLaporanQuery } from '../../store/apis/laporanApi'
import DataTable from 'react-data-table-component';
import { format } from 'date-fns';
import secureLocalStorage from 'react-secure-storage';

const CetakLaporanPerhitunganPajak = () => {
    const data = secureLocalStorage.getItem('data')
    const start = secureLocalStorage.getItem('start');
    const end = secureLocalStorage.getItem('end');

    useEffect(() => {
        window.print();
        // Function to handle the beforeunload event
        const handleBeforeUnload = () => {
            // Remove the data from local storage
            secureLocalStorage.removeItem('data');
            secureLocalStorage.removeItem('start');
            secureLocalStorage.removeItem('end');

        };

        // Add the beforeunload event listener
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <div className='w-full  p-5  '>
            <div className='w-full bg-white p-5 rounded-lg '>
                <div className='border-b border-slate-300 pb-2 text-center'>
                    <div className=' self-center'>
                        <h1 className='text-base font-bold text-slate-500'>Laporan Perhitungan Pajak</h1>
                        <p className='text-slate-400'>{format(new Date(start), 'dd-MM-yyyy')} - {format(new Date(end), 'dd-MM-yyyy')}</p>
                    </div>
                </div>

                <div className='grid grid-cols-5'>
                    <div className=' px-4 border-slate-500 py-2 border font-bold'>
                        Tanggal
                    </div>
                    <div className='px-4 border-slate-500 py-2 border col-span-2 font-bold'>
                        Deskripsi
                    </div>
                    <div className='text-right px-4 border-slate-500 py-2 border font-bold'>
                        Total Penjualan
                    </div>
                    <div className='text-right px-4 border-slate-500 py-2 border font-bold'>
                        Perhitungan Pajak (11%)
                    </div>

                </div>
                {data.map((item) =>
                    <div className='grid grid-cols-5'>
                        <div className=' px-4 border-slate-500 py-2 border'>
                            {format(new Date(item.penjualan.tanggal_transaksi), 'dd-MM-yyyy')}
                        </div>
                        <div className='px-4 border-slate-500 py-2 border col-span-2'>
                            Penjualan kepada retail {item.penjualan.retail.nama}
                        </div>
                        <div className='text-right px-4 border-slate-500 py-2 border'>
                            Rp {Intl.NumberFormat().format(item.TotalPenjualan - item.totalReturs)}
                        </div>
                        <div className='text-right px-4 border-slate-500 py-2 border'>
                            Rp {Intl.NumberFormat().format((item.TotalPenjualan - item.totalReturs) * 11 / 100)}
                        </div>

                    </div>
                )}

                <div className='grid grid-cols-5'>
                    <div className='col-span-3 px-4 text-right'>
                        Total
                    </div>
                    <div className='text-right px-4'>
                        Rp {Intl.NumberFormat().format(data.reduce((sum, item) => sum + item.TotalPenjualan - item.totalReturs, 0))}
                    </div>
                    <div className='text-right px-4'>
                        Rp {Intl.NumberFormat().format(data.reduce((sum, item) => sum + item.TotalPenjualan - item.totalReturs, 0) * 11 / 100)}
                    </div>

                </div>
            </div>
        </div>
    )
}

export { CetakLaporanPerhitunganPajak }