import { ADD_PEGAWAI_JABATAN, DELETE_PEGAWAI_JABATAN, GET_LIST_PEGAWAI_JABATAN, UPDATE_PEGAWAI_JABATAN } from "../../actions/pegawaiJabatan";

const initialState = {
    getListPegawaiJabatanResult: false,
    getListPegawaiJabatanLoading: false,
    getListPegawaiJabatanError: false,

    addPegawaiJabatanResult: false,
    addPegawaiJabatanLoading: false,
    addPegawaiJabatanError: false,

    updatePegawaiJabatanResult: false,
    updatePegawaiJabatanLoading: false,
    updatePegawaiJabatanError: false,

    deletePegawaiJabatanResult: false,
    deletePegawaiJabatanLoading: false,
    deletePegawaiJabatanError: false,
}


const pegawaiJabatan = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_PEGAWAI_JABATAN:
            return {
                ...state,
                getListPegawaiJabatanResult: action.payload.data,
                getListPegawaiJabatanLoading: action.payload.loading,
                getListPegawaiJabatanError: action.payload.errorMessage,
            }

        case ADD_PEGAWAI_JABATAN:
            return {
                ...state,
                addPegawaiJabatanResult: action.payload.data,
                addPegawaiJabatanLoading: action.payload.loading,
                addPegawaiJabatanError: action.payload.errorMessage,
            }

        case UPDATE_PEGAWAI_JABATAN:
            return {
                ...state,
                updatePegawaiJabatanResult: action.payload.data,
                updatePegawaiJabatanLoading: action.payload.loading,
                updatePegawaiJabatanError: action.payload.errorMessage,
            }
        case DELETE_PEGAWAI_JABATAN:
            return {
                ...state,
                deletePegawaiJabatanResult: action.payload.data,
                deletePegawaiJabatanLoading: action.payload.loading,
                deletePegawaiJabatanError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default pegawaiJabatan;