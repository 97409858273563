import { Combobox, Transition } from '@headlessui/react';
import { format } from 'date-fns';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { MdCheck, MdUnfoldMore } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../actions/auth';
import { addPabrik, getListPabrik } from '../../actions/pabrikAction';
import { addStok, getListStok } from '../../actions/produkStokAction';
import { addStokDetail, getListStokDetail } from '../../actions/produkStokDetail';
import { getListVarian } from '../../actions/produkVarianAction';
import { useAddPabrikMutation, useGetListPabrikQuery } from '../../store/apis/pabrikApi';
import { useAddStokMutation, useGetStokQuery } from '../../store/apis/produkStokApi';
import { useAddStokDetailMutation } from '../../store/apis/produkStokDetailApi';
import { useGetListAllVarianQuery, useGetListVarianDropdownQuery, useGetListVarianQuery } from '../../store/apis/produkVarianApi';
import secureLocalStorage from 'react-secure-storage';
import CurrencyInput from 'react-currency-input-field';

const TambahPabrik = ({ pabrikModal }) => {
    const [nama, setNama] = useState('');
    const [kota, setKota] = useState('');
    const [negara, setNegara] = useState('');
    const [addPabrik, addPabrikResult] = useAddPabrikMutation()
    const [id, setId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const userData = await getUserData();
            setId(userData.id)
        };
        fetchData();
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();

        addPabrik({ nama: nama, kota: kota, negara: negara, x: 0, y: 0, distributor_id: id, status: 1 })
        pabrikModal();
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                pabrikModal()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])


    return (
        <div>
            <div
                className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[51] outline-none focus:outline-none"
            >
                <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                    {/*content*/}
                    <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Tambah Pabrik
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => pabrikModal()}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <div className="relative z-0 mt-5">
                                <input
                                    type="text"
                                    id="floating_standard"
                                    placeholder=' '
                                    value={nama}
                                    onChange={(event) => setNama(event.target.value)}
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Nama Pabrik
                                </label>
                            </div>
                            <div className="relative z-0 mt-5">
                                <input
                                    type="text"
                                    id="floating_standard"
                                    placeholder=' '
                                    value={kota}
                                    onChange={(event) => setKota(event.target.value)}
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Lokasi Kota Pabrik
                                </label>
                            </div>
                            <div className="relative z-0 mt-5">
                                <input
                                    type="text"
                                    id="floating_standard"
                                    placeholder=' '
                                    value={negara}
                                    onChange={(event) => setNegara(event.target.value)}
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Asal Negara Pabrik
                                </label>
                            </div>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(event) => handleSubmit(event)}
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const TambahStok = ({ pembelianModal }) => {
    const [nomor_invoice, setNomor_invoice] = useState('')
    const tgl = useRef('');
    const [selectedP, setSelectedP] = useState({ 'nama': 'Pilih Pabrik' })
    const [query, setQuery] = useState('')
    const [addPabrikForm, setAddPabrikForm] = useState(false);
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const [addStok, addStokResult] = useAddStokMutation();
    const getListPabrikResult = useGetListPabrikQuery(id).data;

    const ShowPabrikModal = (e) => {
        if (addPabrikForm) {
            setAddPabrikForm(false);
        } else {
            setAddPabrikForm(true);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const date = new Date(tgl.current.value)
        addStok({ tanggal_transaksi: format(date, 'yyyy-MM-dd'), status: '1', pabrik_id: selectedP.id, nomor_invoice: nomor_invoice, distributor_id: id })
        pembelianModal();
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                pembelianModal()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const filteredPabrik =
        getListPabrikResult ? (
            query === ''
                ? getListPabrikResult
                : getListPabrikResult.filter((pabrik) =>
                    pabrik.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )) : []

    return (
        <div>
            {addPabrikForm && <TambahPabrik pabrikModal={ShowPabrikModal} />}

            <div
                className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                    {/*content*/}
                    <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Tambah Stok Produk
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => pembelianModal()}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <div className="relative z-0 mt-5">
                                <input
                                    type="date"
                                    id="floating_standard"
                                    placeholder=' '
                                    ref={tgl}
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Tanggal Transaksi
                                </label>
                            </div>

                            <div className="relative z-0 mt-5">
                                <input
                                    type="text"
                                    id="floating_standard"
                                    placeholder=' '
                                    value={nomor_invoice}
                                    onChange={(event) => setNomor_invoice(event.target.value)}
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Nomor Invoice
                                </label>
                            </div>

                            <div className='flex'>
                                <div className='flex-grow'>
                                    <p className=" text-slate-500 text-xs mt-5">Pabrik</p>

                                    <Combobox value={selectedP} onChange={setSelectedP}>

                                        <div className="relative mt-1">
                                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                <Combobox.Input
                                                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                    displayValue={(pabrik) => pabrik.nama}
                                                    onChange={(event) => setQuery(event.target.value)}
                                                />
                                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <MdUnfoldMore />

                                                </Combobox.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                afterLeave={() => setQuery('')}
                                            >
                                                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {filteredPabrik.length === 0 && query !== '' ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        filteredPabrik.map((pabrik) => (
                                                            <Combobox.Option
                                                                key={pabrik.id}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                    }`
                                                                }
                                                                value={pabrik}
                                                            >
                                                                {({ selectedP, active }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block truncate ${selectedP ? 'font-medium' : 'font-normal'
                                                                                }`}
                                                                        >
                                                                            {pabrik.nama}
                                                                        </span>
                                                                        {selectedP ? (
                                                                            <span
                                                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                    }`}
                                                                            >
                                                                                <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </Combobox.Options>

                                            </Transition>
                                        </div>
                                    </Combobox>
                                </div>
                                <button type='button' className='mt-10 rounded-lg p-2 text-white  bg-blue-500' onClick={() => ShowPabrikModal()}>Tambah</button>

                            </div>




                        </div>

                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(event) => handleSubmit(event)}
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const TambahStokDetail = (props) => {
    const [showModal, setShowModal] = useState(false);
    const nomor_invoice = props.data.nomor_invoice
    const [selectedV, setSelectedV] = useState('')
    const [query, setQuery] = useState('')
    const idProdukStok = props.data.id
    const [jumlah_beli, setJumlah_beli] = useState('')
    const [harga_beli, setHarga_beli] = useState('')
    const [harga_jual, setHarga_jual] = useState('')
    const [addStokDetail, addStokDetailResult] = useAddStokDetailMutation();
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const getListVarianResult = useGetListVarianDropdownQuery(id).data;
    const stokRefetch = useGetStokQuery(id);


    const handleSubmit = (event) => {
        event.preventDefault();

        addStokDetail({ jumlah_beli: jumlah_beli, jumlah_terkini: jumlah_beli, harga_beli: harga_beli, harga_jual: harga_jual, produk_stok_id: idProdukStok, produk_varian_id: selectedV.id, status: 1 }).unwrap().then(() => { stokRefetch.refetch() });
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])


    const filteredVarian =
        getListVarianResult ? (
            query === ''
                ? getListVarianResult
                : getListVarianResult.filter((varian) =>
                    varian.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, '')) ||
                    varian.produk.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )) : []

    return (
        <div>
            <button
                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Tambah Data
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Tambah Stok Produk
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            id="floating_standard"
                                            placeholder=' '
                                            value={nomor_invoice}
                                            readOnly
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Nomor Invoice
                                        </label>
                                    </div>

                                    <p className=" text-slate-500 text-xs mt-5">Varian Produk</p>

                                    <Combobox value={selectedV} onChange={setSelectedV}>

                                        <div className="relative mt-1">
                                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                <Combobox.Input
                                                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                    displayValue={(varian) => varian.nama}
                                                    onChange={(event) => setQuery(event.target.value)}
                                                    placeholder='Pilih atau ketikkan varian produk'
                                                />
                                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <MdUnfoldMore />

                                                </Combobox.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                afterLeave={() => setQuery('')}
                                            >
                                                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {filteredVarian.length === 0 && query !== '' ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        filteredVarian.map((pabrik) => (
                                                            <Combobox.Option
                                                                key={pabrik.id}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                    }`
                                                                }
                                                                value={pabrik}
                                                            >
                                                                {({ selectedV, active }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block ${selectedV ? 'font-medium' : 'font-normal'
                                                                                }`}
                                                                        >
                                                                            {pabrik.produk.nama} <br /> Varian: {pabrik.nama}
                                                                        </span>
                                                                        {selectedV ? (
                                                                            <span
                                                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                    }`}
                                                                            >
                                                                                <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </Combobox.Options>

                                            </Transition>
                                        </div>
                                    </Combobox>

                                    <div className='flex gap-x-4'>
                                        <div className="relative z-0 mt-5">
                                            <input
                                                type="number"
                                                id="floating_standard"
                                                placeholder=' '
                                                value={jumlah_beli}
                                                onChange={(e) => setJumlah_beli(e.target.value)}
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                Jumlah Beli
                                            </label>
                                        </div>

                                        <div className="relative z-0 mt-5">
                                            <CurrencyInput
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                prefix='Rp'
                                                placeholder=' '
                                                defaultValue={harga_beli}
                                                required
                                                decimalsLimit={2}
                                                onValueChange={(value) => setHarga_beli(value)}
                                            />
                                            <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                Harga Beli
                                            </label>
                                        </div>

                                    </div>

                                    <div className="relative z-0 mt-5">
                                        <CurrencyInput
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            prefix='Rp'
                                            placeholder=' '
                                            defaultValue={harga_jual}
                                            required
                                            decimalsLimit={2}
                                            onValueChange={(value) => setHarga_jual(value)}
                                        />
                                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Harga Jual
                                        </label>
                                    </div>



                                </div>

                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}


export { TambahPabrik, TambahStok, TambahStokDetail }