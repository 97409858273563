import React, { Fragment, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { MdDelete } from 'react-icons/md';
import { AddProdukVarian } from './AddProductDist';
import { EditProdukVarian } from './EditProductDist';
import { useDeleteVarianMutation } from '../../store/apis/produkApi';

const Varian = (props) => {
    const [showModal, setShowModal] = useState(false);
    const data = props.data.produk_varians;
    const [addVarianForm, setAddVarianForm] = useState(false);

    const ShowVarianModal = (e) => {
        if (addVarianForm) {
            setAddVarianForm(false);
        } else {
            setAddVarianForm(true);
        }
    }

    const [deleteVarian] = useDeleteVarianMutation();

    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            deleteVarian(id)
        }
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const colVar = [
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        // {
        //     name: 'Deskripsi',
        //     cell: (record) => {
        //         return (
        //             <div>
        //                 {record.deskripsi}
        //             </div>
        //         );
        //     },
        //     sortable: true,
        // },
        {
            name: 'Warna',
            selector: row => row.produk_warna.nama,
            sortable: true,
        },
        {
            name: 'Ukuran',
            selector: row => row.produk_ukuran.nama,
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idKP = record.id;
                return (
                    <Fragment>
                        <EditProdukVarian data={record} />
                        <span className='mx-3'>|</span>

                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, idKP)}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]

    return (
        <div>
            {addVarianForm && <AddProdukVarian data={props.data} varianModal = {ShowVarianModal}/>}
            <button
                className="bg-blue-500 text-white active:bg-blue-600 uppercase rounded p-2 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:bg-blue-400"
                type="button"
                onClick={() => setShowModal(true)}
            >
                {data.length}
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-11/12 mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Varian Produk
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative max-h-80 overflow-y-scroll p-6 flex-auto">
                                    {data ? (
                                        <DataTable columns={colVar} data={data} pagination />
                                    ) : (
                                        <p>"Data kosong"</p>
                                    )}
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(e) => ShowVarianModal(e)}
                                    >
                                        Tambah Data
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )

}

export default Varian