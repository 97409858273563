import React from 'react'
import { Product, ProductCategory, ProductRes } from './product/Product'
import 'react-multi-carousel/lib/styles.css';
import CarouselCom from '../components/Carousel';
import { useSelector } from 'react-redux';
import { getUser } from '../actions/auth';
import { NavLink, Navigate } from 'react-router-dom';




function LandingPage() {
    const id = useSelector(getUser)
    return (
        <div className='content'>
            <CarouselCom />

            <div className='mt-10'>
                <h1 className='text-2xl text-blue-500 mb-4'>
                    Kategori Produk
                </h1>
                <ProductCategory />
            </div>
            <div className=' mt-10 '>
                <h1 className='text-2xl text-blue-500 mb-4'>
                    Produk
                </h1>
                <div className=' grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grod-cols-5 gap-x-4 gap-y-8 rounded-lg'>
                    {id? ( id.user_role === 'Retail' && id[0]?.status==='Aktif'? <Product /> : <ProductRes /> )
                        : <ProductRes />}
                </div>
            </div>
            <NavLink to='/product' className='mt-4 border rounded-lg p-2 w-full bg-white text-blue-500 text-center font-bold' >Lihat Semua Produk</NavLink>

        </div>
    )
}

export default LandingPage