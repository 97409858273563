import { Disclosure, Tab } from '@headlessui/react';
import React, { useEffect, useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md';
import secureLocalStorage from 'react-secure-storage';
import { useAddPembayaranRetailMutation, useDetailPembayaranQuery, useGetListPembayaranRetailRetailQuery } from '../../store/apis/pembayaranRetailApi';
import { format } from 'date-fns';
import DataTable from 'react-data-table-component';
import { FileUploader } from 'react-drag-drop-files';
import { FilterPembayaran } from '../../components/FilterTable';
import { alamat_storage } from '../../components/api';
import CurrencyInput from 'react-currency-input-field';

const PembayaranRetail = () => {
    const [status, setStatus] = useState('Belum Lunas')
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'));
    const id = userData ? userData.id : null;
    const { data } = useGetListPembayaranRetailRetailQuery(id)
    const currentDate = new Date();

    const [detail, setDetail] = useState(false);
    const [total, setTotal] = useState('');
    const [ongkir, setOngkir] = useState('');
    const [bayar, setBayar] = useState('');
    const [retur, setRetur] = useState('');
    const [dataId, setDataId] = useState('')
    const [invoice, setInvoice] = useState('')
    const col = [
        {
            name: 'Nama Produk',
            selector: row => row.produk_varian.nama,
            sortable: true,
        },
        {
            name: 'Jumlah',
            selector: row => row.jumlah,
            sortable: true,
            width: 'auto',
        },
        {
            key: "Harga Produk",
            name: "Harga Produk",
            sortable: false,
            selector: row => Intl.NumberFormat().format(row.harga),

        },
        {
            key: "Total Harga",
            name: "Total Harga",
            sortable: false,
            selector: row => Intl.NumberFormat().format(row.harga * row.jumlah),

        },
    ]
    const colRetur = [
        {
            name: 'Nama Produk',
            selector: row => row.retur_produks[0].produk_varian.nama,
            sortable: true,
        },
        {
            name: 'Jumlah',
            selector: row => row.retur_produks[0].jumlah,
            sortable: true,
            width: 'auto',
        },
        {
            key: "Harga Produk",
            name: "Harga Produk",
            sortable: false,
            selector: row => Intl.NumberFormat().format(row.retur_produks[0].nominal),

        },
        {
            key: "Total Harga",
            name: "Total Harga",
            sortable: false,
            selector: row => Intl.NumberFormat().format(row.retur_produks[0].nominal * row.retur_produks[0].jumlah),

        },
    ]

    const handleChange = (index) => {
        switch (index) {
            case 0:
                setStatus('Belum Lunas')
                break;
            case 1:
                setStatus('Lunas')
                break;
            default:
                break;
        }
    }

    const HandleSubmit = (id, invoice, total, ongkir, bayar, retur) => {
        if (detail) {
            setDataId('');
            setInvoice('');
            setDetail(false);
            setTotal('');
            setOngkir('');
            setBayar('');
            setRetur('');
        } else {
            setDataId(id);
            setInvoice(invoice);
            setDetail(true);
            setTotal(total);
            setOngkir(ongkir);
            setBayar(bayar);
            setRetur(retur);

        }
        // updatePenjualan({ id: id, status: status })
    }
    return (
        <div className='w-full '>
            <div className='w-full bg-white p-5 shadow-md'>
                <Tab.Group onChange={(index) => handleChange(index)}>

                    <Tab.List>

                        <Tab>
                            {({ selected }) => (
                                <div className={`relative border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Belum Lunas   {data && data.length > 0 ? (<span className='w-6 h-6 absolute z-50 -top-2.5 -right-2.5 text-white rounded-full bg-red-400'>{FilterPembayaran(data, 'Belum Lunas').length}</span>):(<></>)}</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Lunas</div>
                            )}
                        </Tab>

                    </Tab.List>
                    <Tab.Panels className={`mt-5`}>
                        {data && data.length > 0 ? (
                            <>
                                {FilterPembayaran(data, status).map((item) => (
                                    <div key={item.id}>
                                        {detail && (
                                            <DetailPembayaran modalShow={HandleSubmit} id={dataId} invoice={invoice} total={total} bayar={bayar} ongkir={ongkir} retur={retur} />
                                        )}
                                        <Disclosure key={item.id} as="div" defaultOpen="true">

                                            {({ open }) => (
                                                <>
                                                    <div className="w-full flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">


                                                        <div>
                                                            <Disclosure.Button className="text-base font-bold mr-4 text-left">
                                                                Invoice Pembayaran: {item.nomor_invoice_retail}
                                                            </Disclosure.Button>
                                                            {status === 'Belum Lunas' ? (
                                                                Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) <= 7 && Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) >= 0 ? (
                                                                    <div>
                                                                        Jatuh Tempo: <span className='text-amber-500'>{format(new Date(item.tanggal_jatuh_tempo), 'dd-MM-yyyy')} ({Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24))} hari lagi)</span>
                                                                    </div>
                                                                ) : Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) < 0 ? (
                                                                    <div>
                                                                        Jatuh Tempo: <span className='text-red-500'>{format(new Date(item.tanggal_jatuh_tempo), 'dd-MM-yyyy')} ({Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) * -1} hari melewati jatuh tempo)</span>
                                                                    </div>
                                                                ) :
                                                                    <div>
                                                                        Jatuh Tempo: {format(new Date(item.tanggal_jatuh_tempo), 'dd-MM-yyyy')} ({Math.round((new Date(item.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24))} hari lagi)
                                                                    </div>
                                                            ) : (
                                                                <div>
                                                                    Jatuh Tempo: {format(new Date(item.tanggal_jatuh_tempo), 'dd-MM-yyyy')} 
                                                                </div>
                                                            )
                                                            }
                                                            <div>
                                                                Status Pengiriman: {item.status === 'Diterima' ? <span className='text-yellow-500'>Belum Dikirim</span> :item.status === 'Selesai' ? <span className='text-green-500'>{item.status}</span> : <>{item.status}</> }
                                                            </div>

                                                        </div>
                                                        <div>
                                                            <div className='flex items-center'>
                                                                {item.totalTerbayar ?
                                                                    <>
                                                                        {Intl.NumberFormat().format(item.totalTerbayar)}
                                                                        /
                                                                        {item.totalReturs ? Intl.NumberFormat().format(item.totalTagihan + item.opsi_pengiriman.harga - item.totalReturs) : Intl.NumberFormat().format(item.totalTagihan + item.opsi_pengiriman.harga)}
                                                                    </> :
                                                                    <>
                                                                        {Intl.NumberFormat().format(0)}
                                                                        /
                                                                        {item.totalReturs ? Intl.NumberFormat().format(item.totalTagihan + item.opsi_pengiriman.harga - item.totalReturs) : Intl.NumberFormat().format(item.totalTagihan + item.opsi_pengiriman.harga)}
                                                                    </>}
                                                                <Disclosure.Button >
                                                                    <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                                </Disclosure.Button>

                                                            </div>
                                                            <div className=' flex'>
                                                                <button onClick={() => HandleSubmit(item.id, item.penjualan.nomor_invoice, item.totalTagihan, item.opsi_pengiriman.harga, item.totalTerbayar, item.totalReturs)} className='py-1 px-4 bg-blue-500 text-white rounded mx-auto mt-2' type="">Detail Pembayaran</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <Disclosure.Panel className="p-5">
                                                        <div className='border border-slate-300'>
                                                            <p className='text-blue-500'>Detail pembelian</p>
                                                            {data ? (
                                                                <DataTable columns={col} data={item.penjualan_distributor_produks} />
                                                            ) : (
                                                                <p> Data kosong</p>
                                                            )}
                                                        </div>
                                                        {item.returs.length > 0 &&
                                                            <div className='border border-slate-300 mt-5'>
                                                                <p className='text-blue-500'>Produk Retur</p>
                                                                {data ? (
                                                                    <DataTable columns={colRetur} data={item.returs} />
                                                                ) : (
                                                                    <p> Data kosong</p>
                                                                )}
                                                            </div>
                                                        }

                                                    </Disclosure.Panel>

                                                </>
                                            )}
                                        </Disclosure>
                                    </div>


                                ))}
                            </>) : <p className='text-lg text-center'>Belum ada transaksi</p>}

                    </Tab.Panels>

                </Tab.Group>
            </div>
        </div>
    )
}

const DetailPembayaran = ({ modalShow, id, invoice, total, bayar, ongkir, retur }) => {
    const { data, isFetching, error } = useDetailPembayaranQuery(id);
    const [addBayar, setAddBayar] = useState(false);

    const HandleSubmit = () => {
        if (addBayar) {
            setAddBayar(false);
        } else {
            setAddBayar(true);
        }
    }

    const col = [
        {
            name: 'Tgl Upload',
            selector: row => row.datetime,
            sortable: true,
        },
        {
            name: 'Nomor Faktur',
            selector: row => row.pembayaran_retail_details[0].nomor_faktur,
            sortable: true,
        },
        {
            name: 'Tgl Bayar',
            selector: row => format(new Date(row.pembayaran_retail_details[0].tanggal), 'dd-MM-yyyy'),
            sortable: true,
        },
        {
            key: "Nominal",
            name: "Nominal",
            sortable: false,
            cell: (record) => {
                return (
                    <div>
                        {Intl.NumberFormat("id-ID").format(record.pembayaran_retail_details[0].nominal)}
                    </div>
                );
            },

        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Bukti',
            selector: row => row.status,
            cell: (record) => {
                return (
                    <div>
                        <a rel="noreferrer" target='_BLANK' className='text-blue-500' href={`${alamat_storage}fotoFakturRetail/${record.pembayaran_retail_details[0].path_bukti_faktur}`}>Link</a>
                    </div>
                );
            },
            sortable: true,
        },
    ]

    return (
        <div
            className="bg-slate-500/25 h-screen justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[5000] outline-none focus:outline-none"
        >
            {addBayar && (
                <AddPembayaran modalAdd={HandleSubmit} id={id} />
            )}
            <div className="relative h-full mx-auto">
                {/*content*/}
                <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-xl font-semibold">
                            Tagihan {invoice}
                        </h3>
                        <button
                            className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => modalShow()}
                        >
                            <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                x
                            </span>
                        </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                        <div className='flex justify-between gap-6'>
                            <div>
                                <div>
                                    Pembayaran yang telah diterima:
                                </div>
                                <div>
                                    {Intl.NumberFormat().format(bayar)}
                                </div>

                            </div>
                            <div>
                                <div>
                                    Total pembelian:
                                </div>
                                <div>
                                    {Intl.NumberFormat().format(total)}
                                </div>
                            </div>
                            <div>
                                <div>
                                    Biaya pengiriman:
                                </div>
                                <div>
                                    {Intl.NumberFormat().format(ongkir)}
                                </div>
                            </div>
                            <div>
                                <div>
                                    Nominal Retur:
                                </div>
                                <div>
                                    {Intl.NumberFormat().format(retur)}
                                </div>
                            </div>
                            <div>
                                <div>
                                    Total tagihan:
                                </div>
                                <div>
                                    {Intl.NumberFormat().format(total + ongkir - retur)}
                                </div>
                            </div>
                        </div>
                        {data ? (
                            <DataTable columns={col} data={data} />
                        ) : isFetching ? (
                            <p>loading</p>
                        ) : (
                            <p>{error ? error : "Data kosong"}</p>
                        )}
                    </div>
                    {/* footer */}
                    <div className="flex gap-2 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        {bayar >= total + ongkir - retur ? (
                            <button
                                className="bg-blue-300 cursor-not-allowed text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                disabled
                                onClick={(event) => HandleSubmit(event)}
                            >
                                Tambah Pembayaran
                            </button>
                        ) :
                            <button
                                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(event) => HandleSubmit(event)}
                            >
                                Tambah Pembayaran
                            </button>}
                    </div>
                </div>
            </div>

        </div>
    )
}



const AddPembayaran = ({ modalAdd, id }) => {
    const [nomor_faktur, setNomor_faktur] = useState('')
    const [tanggal, setTanggal] = useState('')
    const [nominal, setNominal] = useState('')
    const [path_bukti_faktur, setPath_bukti_faktur] = useState('')
    const [addBayar, addBayarResult] = useAddPembayaranRetailMutation('')

    const handleSubmit = (event) => {
        event.preventDefault();
        addBayar({ datetime: format(Date.now(), 'yyyy-MM-dd'), nomor_faktur: nomor_faktur, tanggal: format(new Date(tanggal), 'yyyy-MM-dd'), nominal: nominal, path_bukti_faktur: path_bukti_faktur, penjualan_distributor_id: id })
        modalAdd();
    }

    const handleChange = (file) => {
        setPath_bukti_faktur(file);
    };

    useEffect(() => {
        if (addBayarResult.isSuccess) {
            window.alert("Pembayaran telah dikirim. Mohon menunggu pihak distributor untuk memverifikasi pembayaran");
        }

    }, [addBayarResult.isSuccess])
    return (
        <div
            className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
            <div className="relative h-full mx-auto max-w-full">
                {/*content*/}
                <form
                    onSubmit={(event) => handleSubmit(event)}
                    className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-xl font-semibold">
                            Tambah Pembayaran
                        </h3>
                        <button
                            className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => modalAdd()}
                        >
                            <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                x
                            </span>
                        </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                        <div className="relative z-0">
                            <input
                                type="text"
                                id="floating_standard"
                                placeholder=' '
                                value={nomor_faktur}
                                onChange={(event) => setNomor_faktur(event.target.value)}
                                required
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                            <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                Nomor Faktur
                            </label>
                        </div>
                        <div className="relative z-0 mt-5">
                            <input
                                type="date"
                                id="floating_standard"
                                placeholder=' '
                                value={tanggal}
                                required
                                onChange={(event) => setTanggal(event.target.value)}
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                            <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                Tanggal Bayar
                            </label>
                        </div>
                        <div className="relative z-0 mt-5">
                            <CurrencyInput
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                prefix='Rp'
                                placeholder=' '
                                defaultValue={nominal}
                                required
                                decimalsLimit={2}
                                onValueChange={(value) => setNominal(value)}
                            />
                            <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                Nominal
                            </label>
                        </div>
                        <div>
                            <p className=" text-slate-500 text-xs mt-5 mb-1">Foto Warna</p>
                            <FileUploader required label={"Tambahkan atau Letakkan foto Disini"} handleChange={handleChange} name="file" types={['PNG', 'JPG']} multiple={false} />
                            <p>{path_bukti_faktur ? `File name: ${path_bukti_faktur.name}` : "no files uploaded yet"}</p>
                        </div>
                    </div>
                    {/* footer */}
                    <div className="flex gap-2 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                            className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="submit"
                        >
                            Tambah
                        </button>
                    </div>
                </form>
            </div>

        </div>
    )
}

export default PembayaranRetail