import { Disclosure } from '@headlessui/react'
import React, { useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import secureLocalStorage from 'react-secure-storage'
import { useDeleteKeranjangMutation, useGetAllListKeranjangQuery, useUpdateKeranjangMutation } from '../../store/apis/keranjangApi'
import { FilterByDistributor } from '../../components/FilterTable'
import { Link } from 'react-router-dom'
import { useGetRetailQuery } from '../../store/apis/blacklistApi'
import ContactPopup from '../../components/ContactPopup'
import { alamat_storage } from '../../components/api'

const Keranjang = () => {
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'));
    const id = userData ? userData.id : null;
    const { data, isFetching, error } = useGetAllListKeranjangQuery(id);
    const [updateKeranjang, updateKeranjangResult] = useUpdateKeranjangMutation();
    const [deleteKeranjang, deleteKeranjangResult] = useDeleteKeranjangMutation();
    const blacklist = useGetRetailQuery(id).data;
    const [tel, setTel] = useState('');
    const [contactPopup, setcontactPopup] = useState(false);

    const HandleContact = (tel) => {
        if (contactPopup) {
            setTel('');
            setcontactPopup(false);
        } else {
            setTel(tel);
            setcontactPopup(true);
        }
    }

    const deleteData = (event, id) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus produk?")) {
            deleteKeranjang(id)
        }
    }

    const handleChange = (event, id) => {
        console.log(event.target.value, id);

        updateKeranjang({ jumlah: event.target.value, id: id })
    }

    return (
        <div className='bg-white p-4 w-full'>
            {isFetching && <>Loading</>}
            {error && <>Error</>}
            {data && (
                data.data.length > 0 ? (
                    <>
                        {contactPopup && <ContactPopup tel={tel} contactModal={HandleContact} />}

                        <h1 className='text-2xl text-blue-500 font-bold mb-4'>Keranjang</h1>
                        <div className='grid grid-cols-8 p-5 text-center bg-blue-100'>
                            <div className='col-span-4 text-left'>
                                Produk
                            </div>
                            <p>Harga Produk</p>
                            <p>Jumlah Barang</p>
                            <p>Harga Total</p>
                            <p>Aksi</p>
                        </div>

                        {data.distributor.map((distributor) => (
                            <Disclosure key={distributor.distributor_id} as="div" defaultOpen="true">
                                {({ open }) => (
                                    <>
                                        <div className="w-full flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">


                                            <div>
                                                <Link to={`/distributor/${distributor.distributor_id}`} className="text-base font-bold mr-4">
                                                    {distributor.nama}
                                                </Link>
                                                <div>
                                                    <button type="btn" className='text-blue-500' onClick={() => HandleContact(data.distributor.nomor_telp_cp)}>Hubungi Distributor</button>
                                                </div>
                                            </div>
                                            <div className='flex items-center'>
                                                {blacklist && (
                                                    blacklist.length > 0 ?
                                                        <button onClick={(e) => (window.alert('Anda telah diblacklist dari sistem karena tidak menyelesaikan pembayaran pada transaksi sebelumnya sebelum jatuh tempo. Silahkan selesaikan pembayaran dan menghubungi distributor terkait dan admin dodolan untuk membuka blacklist sistem dan melakukan transaksi baru'))} state={{ idD: distributor.distributor_id, idR: id }} className='p-3 bg-blue-500 text-white mr-4'>Checkout</button>
                                                        :
                                                        <Link to='/checkout' state={{ idD: distributor.distributor_id, idR: id }} className='p-3 bg-blue-500 text-white mr-4'>Checkout
                                                        </Link>
                                                )}
                                                <Disclosure.Button >
                                                    <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                </Disclosure.Button>

                                            </div>
                                        </div>

                                        <Disclosure.Panel className="p-5">
                                            {FilterByDistributor(data.data, distributor.distributor_id).map((data) => (
                                                <div key={data.id} className='h-32 grid grid-cols-8 justify-between text-center items-center border-b'>

                                                    <div className='flex space-x-2 items-center h-32 col-span-4'>
                                                        {/* <input type="checkbox"
                                                        // checked={checkedData.includes(data.id)}
                                                        // onChange={() => handleDataChange(data.id)}
                                                        className='accent-blue-500 w-4 h-4' /> */}
                                                        <img src={`${alamat_storage}fotoProduk/${data.foto}`} className='h-4/5' />
                                                        <p>{data.nama}</p>
                                                    </div>

                                                    <p>Rp{Intl.NumberFormat("id-ID").format(data.min_jual)}</p>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            min={1}
                                                            defaultValue={data.jumlah}
                                                            onChange={(event) => handleChange(event, data.id)}
                                                            className='h-8 w-16 self-center rounded-md bg-blue-100 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 text-center' />
                                                    </div>
                                                    <p>Rp{Intl.NumberFormat("id-ID").format(data.min_jual * data.jumlah)}</p>
                                                    <button type='button' onClick={(e) => deleteData(e, data.id)} className='text-red-500' >Hapus</button>


                                                </div>
                                            ))}


                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </>
                ) :
                    <h1 className='text-xl text-blue-500 mb-4'>Keranjang kosong, silahkan lihat tambahkan produk yang anda inginkan terlebih dahulu</h1>
            )}
        </div>
    )
}

export default Keranjang
