import { ADD_PABRIK, DELETE_PABRIK, GET_LIST_PABRIK, UPDATE_PABRIK } from "../../actions/pabrikAction"

const initialState = {
    getListPabrikResult: false,
    getListPabrikLoading: false,
    getListPabrikError: false,

    addPabrikResult: false,
    addPabrikLoading: false,
    addPabrikError: false,

    deletePabrikResult: false,
    deletePabrikLoading: false,
    deletePabrikError: false,

    updatePabrikResult: false,
    updatePabrikLoading: false,
    updatePabrikError: false,
}


const pabrik = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_PABRIK:
            return {
                ...state,
                getListPabrikResult: action.payload.data,
                getListPabrikLoading: action.payload.loading,
                getListPabrikError: action.payload.errorMessage,
            }

        case ADD_PABRIK:
            return {
                ...state,
                addPabrikResult: action.payload.data,
                addPabrikLoading: action.payload.loading,
                addPabrikError: action.payload.errorMessage,
            }
        case DELETE_PABRIK:
            return {
                ...state,
                deletePabrikResult: action.payload.data,
                deletePabrikLoading: action.payload.loading,
                deletePabrikError: action.payload.errorMessage,
            }
        case UPDATE_PABRIK:
            return {
                ...state,
                updatePabrikResult: action.payload.data,
                updatePabrikLoading: action.payload.loading,
                updatePabrikError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default pabrik;