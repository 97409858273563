import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const opsiPengirimanApi = createApi({
    reducerPath: 'opsiPengiriman',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getOpsiPengirimanList: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'opsiPengiriman' }];

                },
                query: (id) => {
                    return {
                        url: 'OpsiPengirimanList',
                        method: 'GET',
                        params: {id}
                    };
                },
            }),
            addOpsiPengiriman: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'opsiPengiriman' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'OpsiPengiriman',
                        body: data,
                    };
                },
            }),
            updateOpsiPengiriman: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'opsiPengiriman' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `OpsiPengiriman/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteOpsiPengiriman: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'opsiPengiriman' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `OpsiPengiriman/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetOpsiPengirimanListQuery, useAddOpsiPengirimanMutation, useUpdateOpsiPengirimanMutation, useDeleteOpsiPengirimanMutation } = opsiPengirimanApi;
export { opsiPengirimanApi };