import axios from "axios";
import {alamat_backend} from "../components/api";
import secureLocalStorage from "react-secure-storage";

export const AUTHENTICATE = 'AUTHENTICATE'

const setToken = (data) => {
    secureLocalStorage.setItem("token", data.token);
    secureLocalStorage.setItem("userData", JSON.stringify(data.user));
    secureLocalStorage.setItem("lastLoginTime", new Date(Date.now()).getTime());
};

export const getToken = () => {
        return secureLocalStorage.getItem('token');
};

export const getUser = () => {
    return JSON.parse(secureLocalStorage.getItem('userData'));
};

export const getUserData = async () => {
    const userData = secureLocalStorage.getItem('distRetData');
    return JSON.parse(userData);
};

const deleteToken = () => {
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("userData");
    secureLocalStorage.removeItem("distRetData");
    secureLocalStorage.removeItem("lastLoginTime");
}

const loginUser = (credentials) => {
    return (dispatch) => {
        dispatch({
            type: AUTHENTICATE,
            payload: {
                data: false,
                message: false,
                loading: true,
            }
        })
        axios({
            method: 'POST',
            url: alamat_backend + 'login',
            timeout: 120000,
            data: credentials,
        })
            .then((Response) => {
                setToken(Response.data);
                dispatch({
                    type: AUTHENTICATE,
                    payload: {
                        data: Response.data,
                        message: false,
                        loading: true,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: AUTHENTICATE,
                    payload: {
                        data: false,
                        message: error.response.status,
                        loading: false
                    }
                })
            })
    }
}

const logoutUser = () => {
    return (dispatch) => {
        dispatch({
            type: AUTHENTICATE,
            payload: {
                data: false,
                message: false,
                loginStatus: false
            }
        })
        axios({
            method: 'POST',
            url: alamat_backend + 'logout',
            timeout: 120000,
            headers: {
                "Authorization": `bearer ${getToken()}`,
            }
        }).then((Response) => {
            deleteToken()
            dispatch({
                type: AUTHENTICATE,
                payload: {
                    data: false,
                    message: "Logout Succesful",
                    loginStatus: false,
                }
            })
        })
            .catch((error) => {
                //gagal get api
                deleteToken()

                dispatch({
                    type: AUTHENTICATE,
                    payload: {
                        data: false,
                        Message: error,
                        loginStatus: false,
                    }
                })
            })

    }
}

export { loginUser, logoutUser }