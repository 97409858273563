import { Tab } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { AddKabkot, AddKecamatan, AddKelurahan, AddProvinsi } from './AddLocation'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { deletePropinsi, getListPropinsi } from '../../actions/propinsiAction';
import { deleteKabkot, getListKabkot } from '../../actions/kabkotAction';
import { deleteKecamatan, getListKecamatan } from '../../actions/kecamatanAction';
import { MdDelete, MdEdit } from 'react-icons/md';
import { FilterTable } from '../../components/FilterTable';
import { EditKabkot, EditKecamatan, EditKelurahan, EditPropinsi } from './EditLocation';
import { useDeleteKelurahanMutation, useGetListKelurahanQuery } from '../../store/apis/lokasiKelurahanApi';
import { useDeleteKecamatanMutation, useGetListKecamatanQuery } from '../../store/apis/lokasiKecamatanApi';
import { useDeleteKotKabMutation, useGetListKotKabQuery } from '../../store/apis/lokasiKotKabApi';
import { useDeletePropinsiMutation, useGetPropinsiQuery } from '../../store/apis/lokasiPropinsi';


const ManageLocation = () => {
    const {data, error, isFetching} = useGetPropinsiQuery();
    const [qp, setQp] = useState("");

    const [deletePropinsi, deletePropinsiResult] = useDeletePropinsiMutation();

    const colProv = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idProv = record.id;
                return (
                    <Fragment>
                        <EditPropinsi data={record} />

                        <span className='mx-3'>|</span>

                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, idProv, "provinsi")}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]


    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            if (data === "provinsi") {
                deletePropinsi(id)
            }
        }
    }
    return (
        <div className='w-full  p-5  '>
            <div className='w-full bg-white border pb-5 rounded-lg '>

                <Tab.Group>

                    <Tab.List className='bg-blue-100 rounded-t-lg'>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>Provinsi</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>Kabupaten / Kota</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>Kecamatan</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>Kelurahan</div>
                            )}
                        </Tab>
                    </Tab.List>

                    <Tab.Panels className={`mt-5 px-5`}>
                        <Tab.Panel >
                            <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                                <div className='col-span-3 self-center'>
                                    <h1 className='text-base font-bold text-blue-500 '>Data Distributor</h1>
                                    <p className='text-slate-400'>Di bawah ini merupakan data semua Distributor yang telah terdaftar.</p>
                                </div>
                                <div className='place-self-end'>
                                    <AddProvinsi />
                                </div>
                                <div className='col-span-3' />
                                <input type="text" className='border border-slate-300 p-2 h-10 w-full' value={qp} onChange={((e) => setQp(e.target.value))} placeholder="Masukkan Kata Kunci" />

                            </div>
                            {data ? (
                                <DataTable columns={colProv} data={FilterTable(data, qp)} pagination />
                            ) : isFetching ? (
                                <p>loading</p>
                            ) : (
                                <p>{error ? error : "Data kosong"}</p>
                            )}


                        </Tab.Panel>
                        <Tab.Panel >
                            <KotKab />

                        </Tab.Panel>

                        <Tab.Panel >
                            <Kecamatan />

                        </Tab.Panel>
                        <Tab.Panel >
                            <Kelurahan />

                        </Tab.Panel>

                    </Tab.Panels>

                </Tab.Group>
            </div>


        </div>
    )

}

const Kelurahan = () => {
    const { data, error, isFetching } = useGetListKelurahanQuery();
    const [deleteKelurahan, results] = useDeleteKelurahanMutation();
    const [ql, setQl] = useState("");


    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            deleteKelurahan(id);
        }
    }
    const colKel = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            name: 'Kode Pos',
            selector: row => row.kode_pos,
            sortable: true,
        },
        {
            name: 'Nama Kecamatan',
            selector: row => row.lokasi_kecamatan.nama,
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idKel = record.id;
                return (
                    <Fragment>
                        <EditKelurahan data={record} />
                        |
                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, idKel, "kelurahan")}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]
    return (
        <>
            <div className='grid grid-cols-4 gap-y-4'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Retail</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Retail yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                    <AddKelurahan />
                </div>
                <div className='col-span-3' />
                <input type="text" className='border border-slate-300 p-2 h-10 w-full' value={ql} onChange={((e) => setQl(e.target.value))} placeholder="Masukkan Kata Kunci" />
            </div>
            {data ? (
                <DataTable columns={colKel} data={FilterTable(data, ql)} pagination />
            ) : isFetching ? (
                <p>loading</p>
            ) : (
                <p>{error ? error : "Data kosong"}</p>
            )}
        </>
    )
}
const Kecamatan = () => {
    const { data, error, isFetching } = useGetListKecamatanQuery();
    const [deleteKecamatan, results] = useDeleteKecamatanMutation();
    const [qc, setQc] = useState("");


    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            deleteKecamatan(id);
        }
    }


    const colKect = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            name: 'Nama Kota',
            selector: row => row.kabkot,
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idKec = record.id;
                return (
                    <Fragment>
                        <EditKecamatan data={record} />
                        |
                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, idKec, "kecamatan")}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]
    return (
        <>
            <div className='grid grid-cols-4 gap-y-4'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Retail</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Retail yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                    <AddKecamatan />
                </div>
                <div className='col-span-3' />
                <input type="text" className='border border-slate-300 p-2 h-10 w-full' value={qc} onChange={((e) => setQc(e.target.value))} placeholder="Masukkan Kata Kunci" />
            </div>
            {data ? (
                <DataTable columns={colKect} data={FilterTable(data, qc)} pagination />
            ) : isFetching ? (
                <p>loading</p>
            ) : (
                <p>{error ? error : "Data kosong"}</p>
            )}
        </>
    )
}
const KotKab = () => {
    const { data, error, isFetching } = useGetListKotKabQuery();
    const [deleteKotKab, results] = useDeleteKotKabMutation();
    const [qk, setQk] = useState("");


    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            deleteKotKab(id);
        }
    }
    const colKabkot = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            name: 'Provinsi',
            selector: row => row.provinsi,
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idKabkot = record.id;
                return (
                    <Fragment>
                        <EditKabkot data={record} />
                        |
                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, idKabkot, "kabkot")}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]
    return (
        <>
            <div className='grid grid-cols-4 gap-y-4'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Retail</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Retail yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                    <AddKabkot />
                </div>
                <div className='col-span-3' />
                <input type="text" className='border border-slate-300 p-2 h-10 w-full' value={qk} onChange={((e) => setQk(e.target.value))} placeholder="Masukkan Kata Kunci" />
            </div>
            {data ? (
                <DataTable columns={colKabkot} data={FilterTable(data, qk)} pagination />
            ) : isFetching ? (
                <p>loading</p>
            ) : (
                <p>{error ? error : "Data kosong"}</p>
            )}
        </>
    )
}




export default ManageLocation