import React, { useEffect, useState } from 'react'
import { RadioGroup, Tab } from '@headlessui/react'
import { Link, useParams } from 'react-router-dom';
import { useGetProductDetailQuery } from '../../store/apis/produkApi';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useAddKeranjangMutation } from '../../store/apis/keranjangApi';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { getUserData } from '../../actions/auth';
import secureLocalStorage from 'react-secure-storage';
import { MdAddShoppingCart, MdCall } from 'react-icons/md';
import { alamat_storage } from '../../components/api';
import ContactPopup from '../../components/ContactPopup';

const ProductDetail = () => {
    const [total, setTotal] = useState(1);
    const [selectedVarian, setSelectedVarian] = useState('')
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const idr = userData ? userData.id : null;
    const { id } = useParams();
    const [tel, setTel] = useState('');
    const [contactPopup, setcontactPopup] = useState(false);

    const HandleContact = (tel) => {
        if (contactPopup) {
            setTel('');
            setcontactPopup(false);
        } else {
            setTel(tel);
            setcontactPopup(true);
        }
    }


    const { data, isFetching, error } = useGetProductDetailQuery(id);
    const [addKeranjang, { isSuccess }] = useAddKeranjangMutation();

    const handleSubmit = (event) => {

        event.preventDefault();
        const currentDateTime = new Date();

        addKeranjang({ datetime: format(currentDateTime, 'yyyy-MM-dd HH:mm:ss'), retail_id: idr, status: 'Aktif', distributor_id: data.produk.idD, keterangan: 'tes', jumlah: total, produk_varian_id: selectedVarian.id });
    }

    useEffect(() => {
        if (isSuccess) {
            window.alert('Produk Dimasukkan Ke keranjang');
        }
    }, [isSuccess]);

    return (
        <div className='content'>
            {data ? (
                <>
                    {contactPopup && <ContactPopup tel={tel} contactModal={HandleContact} />}

                    <div className='w-full '>
                        <div className='block sm:flex bg-white'>
                            <div className='w-full sm:w-2/3'>
                                <Carousel className='w-full' showThumbs={false} >

                                    {data.foto.map((foto) => {
                                        return (
                                            <div key={foto.id}>
                                                <p className='bg-slate-500/75 text-white py-2'>{foto.label}</p>

                                                <img className='w-30' src={alamat_storage + "fotoProduk/" + foto.path} />
                                            </div>
                                        )
                                    })}

                                </Carousel>
                            </div>
                            <div className='p-5 border-l w-full space-y-5'>
                                <h1 className='text-xl font-bold'>{data.produk.nama}</h1>
                                <h1 className={`text-3xl text-blue-500 font-bold p-5 bg-slate-100 w-full ${selectedVarian.id ? 'hidden' : ''}`}>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data.produk.harga)}</h1>
                                {data.varian.map((varian) => {
                                    return (
                                        <h1 key={varian.id} className={`text-3xl text-blue-500 font-bold p-5 bg-slate-100 w-full ${varian.id === selectedVarian.id ? '' : 'hidden'}`}>Rp{Intl.NumberFormat("id-ID").format(varian.max_jual)}</h1>
                                    )
                                })}
                                <div className='grid gap-4 grid-cols-6'>
                                    <div>
                                        Varian
                                    </div>
                                    <div className='col-span-5'>
                                        <RadioGroup value={selectedVarian} onChange={setSelectedVarian}>
                                            <div className='grid grid-cols-2 md:grid-cols-3 gap-2'>
                                                {data.varian.map((varian) => {
                                                    return (
                                                        <RadioGroup.Option disabled={varian.stok <= 0 || varian.stok === null ? true : false} key={varian.id} className={`col-autp`} value={varian}>
                                                            {({ checked }) => (
                                                                <div className={` ${varian.stok <= 0 || varian.stok === null ? 'text-slate-300 cursor-not-allowed' : 'text-slate-500'} w-full bg-slate-100 p-2 rounded-lg border-2 border-slate-300 cursor-pointer ${checked ? 'ring-blue-500 ring-2 border-0 text-blue-500' : ''}`}>{varian.nama}</div>
                                                            )}
                                                        </RadioGroup.Option>)
                                                })}

                                            </div>

                                        </RadioGroup>
                                    </div>
                                    <div>
                                        Kuantitas
                                    </div>
                                    <div className='col-span-5'>
                                        <input type="number"
                                            value={total}
                                            onChange={(e) => setTotal(e.target.value)}
                                            className={`p-2 w-20 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg bg-slate-100 ${selectedVarian ? 'hidden' : ''}`}
                                        />
                                        {data.varian.map((varian) => {
                                            return (
                                                <div className='flex gap-2' key={varian.id}>
                                                    <input type="number"
                                                        value={total}
                                                        onChange={(e) => setTotal(e.target.value)}
                                                        min={0}
                                                        max={varian.stok}
                                                        className={`p-2 w-20 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg bg-slate-100 ${varian.id === selectedVarian.id ? '' : 'hidden'}`}
                                                    />
                                                    <p className={`${varian.id === selectedVarian.id ? '' : 'hidden'}`}>sisa stok: {varian.stok}</p>
                                                </ div>
                                            )
                                        })}
                                    </div>
                                    {selectedVarian ? (
                                        selectedVarian.stok <= 0 || selectedVarian.stok === null ? (
                                            <button disabled className='col-span-3 cursor-not-allowed  w-full p-3 rounded-lg bg-blue-300 text-white text-xl ' type="btn" onClick={(event) => handleSubmit(event)}>
                                                <MdAddShoppingCart className='mx-auto font-bold' />
                                            </button>
                                        ) : (
                                            <button className='col-span-3 w-full p-3 rounded-lg bg-blue-500 text-white text-xl ' type="btn" onClick={(event) => handleSubmit(event)}>
                                                <MdAddShoppingCart className='mx-auto font-bold' />
                                            </button>
                                        )
                                    ) :
                                        <button disabled className='col-span-3 cursor-not-allowed  w-full p-3 rounded-lg bg-blue-300 text-white text-xl ' type="btn" onClick={(event) => handleSubmit(event)}>
                                            <MdAddShoppingCart className='mx-auto font-bold' />
                                        </button>
                                    }
                                    <button className='col-span-3 w-full p-3 rounded-lg bg-blue-500 text-white text-xl ' type="btn" onClick={() => HandleContact(data.distributor.nomor_telp_cp)}>
                                        <MdCall className='mx-auto font-bold' />
                                    </button>
                                </div>

                            </div>

                        </div>
                        <div className='mt-5 w-full bg-white p-5'>
                            <Tab.Group>
                                <Tab.List>
                                    <Tab>
                                        {({ selected }) => (
                                            <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Informasi Produk</div>
                                        )}
                                    </Tab>
                                    <Tab>
                                        {({ selected }) => (
                                            <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Informasi Supplier</div>
                                        )}
                                    </Tab>
                                </Tab.List>
                                <Tab.Panels className={`mt-5`}>
                                    <Tab.Panel>
                                        <h1 className='text-base font-bold text-blue-500'>Dekripsi Produk</h1>
                                        <p className='text-justify'>
                                            {data.produk.deskripsi}
                                        </p>
                                        {data.varian.map((varian) => {
                                            return (
                                                <div key={varian.id}>
                                                    <h1 className='text-base font-bold text-blue-500'>Dekripsi Varian {varian.nama}</h1>
                                                    <p className='text-justify'>
                                                        {varian.deskripsi}
                                                    </p>
                                                </div>
                                            )
                                        })}
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        <h1 className='text-base font-bold text-blue-500'>Informasi Supplier</h1>
                                        <div className='block md:flex mt-6'>
                                            <div className='w-32 h-32 rounded-full overflow-hidden'>
                                                <img src={`${alamat_storage}/distributorProfil/${data.distributor.foto_profil}`} alt="" className='h-full w-full overflow-hidden' />
                                            </div>
                                            <div className='ml-4'>
                                                <p className='text-blue-500 text-xl  font-bold'>{data.distributor.nama}</p>
                                                <table>
                                                    <tbody>
                                                        <tr className='bg-blue-100'>
                                                            <td className='px-4'>Alamat</td>
                                                            <td className='px-2'>:</td>
                                                            <td className='px-4'>{data.distributor.alamat},
                                                                {' Kecamatan ' + data.distributor.lokasi_kelurahan.lokasi_kecamatan.nama},
                                                                {' ' + data.distributor.lokasi_kelurahan.lokasi_kecamatan.lokasi_kotakab.nama},
                                                                {' ' + data.distributor.lokasi_kelurahan.lokasi_kecamatan.lokasi_kotakab.lokasi_propinsi.nama}
                                                                {' ' + data.distributor.lokasi_kelurahan.kode_pos}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='px-4'>Nomor Telepon</td>
                                                            <td className='px-2'>:</td>
                                                            <td className='px-4'>{data.distributor.nomor_telp_cp}</td>
                                                        </tr>
                                                        <tr className='bg-blue-100'>
                                                            <td className='px-4'>Deskripsi</td>
                                                            <td className='px-2'>:</td>
                                                            <td className='px-4'>{data.distributor.deskripsi}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className='py-4'>
                                                    <Link to={`/distributor/${data.distributor.id}`} className='px-4 py-2 bg-blue-500 text-white rounded-lg'>Lihat Profil</Link>

                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </div>

                    </div>
                </>)
                : isFetching ? (
                    <div>
                        Loading
                    </div>)
                    : <div>Terjadi Kesalahan</div>
            }

        </div>

    )
}

export default ProductDetail
