import { AUTHENTICATE } from "../../actions/auth";

const initialState = {
    data: false,
    message: false,
    loading: false,
}


const auth = (state = initialState, action) => {
    switch (action.type) {
        case AUTHENTICATE:
            return {
                ...state,
                data: action.payload.data,
                message: action.payload.message,
                loading: action.payload.loading,
            }
        default:
            return state;
    }
}

export default auth;