import { Combobox, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react'
import { RgbColorPicker } from 'react-colorful';
import { MdCheck, MdEdit, MdUnfoldMore } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { updateBrand } from '../../actions/produkBrandAction';
import { useGetListUkuranQuery, useUpdateUkuranMutation } from '../../store/apis/produkUkuranApi';
import { useGetListWarnaQuery, useUpdateWarnaMutation } from '../../store/apis/produkWarnaApi';
import { useUpdateProductMutation, useUpdateVarianMutation } from '../../store/apis/produkApi';
import { useGetListSubSubKategoriProdukQuery } from '../../store/apis/subSubKategoriProdukApi';
import secureLocalStorage from 'react-secure-storage';
import { useGetKualitasPasarQuery, useUpdateKualitasPasarMutation } from '../../store/apis/kualitasPasarApi';
import { useGetListBrandQuery } from '../../store/apis/produkBrandApi';
import CurrencyInput from 'react-currency-input-field';

const EditKualitasPasar = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [nama, setNama] = useState(props.data.nama);
    const id = props.data.id
    const [EditKualitasPasar] = useUpdateKualitasPasarMutation();



    const handleSubmit = (event) => {
        event.preventDefault();

        EditKualitasPasar({ id: id, nama: nama })
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    return (
        <div>
            <button
                type="submit"
                onClick={() => setShowModal(true)}
                className="text-blue-500 text-lg cursor-pointer"
            >
                <MdEdit />
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Update Kualitas Pasar
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <form>

                                    <div className="relative p-6 flex-auto">
                                        <div className="relative z-0 mt-5">
                                            <input
                                                type="text"
                                                id="floating_standard"
                                                placeholder=' '
                                                value={nama}
                                                onChange={(event) => setNama(event.target.value)}
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                Nama Kualitas Pasar
                                            </label>
                                        </div>
                                    </div>
                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="submit"
                                            onClick={(event) => handleSubmit(event)}
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

const EditBrand = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [nama, setNama] = useState(props.data.nama);
    const getListSubSubKategoriResult = useGetListSubSubKategoriProdukQuery().data;
    const [selected, setSelected] = useState({ 'nama': props.data.subSubKategori, 'id': props.data.produk_sub_sub_kategori_id })
    const [query, setQuery] = useState('')
    const id = props.data.id

    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();

        dispatch(updateBrand({ nama: nama, status: '1', produk_sub_sub_kategori_id: selected.id, id: id }))
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const filteredSubSubKategori =
        getListSubSubKategoriResult ? (
            query === ''
                ? getListSubSubKategoriResult
                : getListSubSubKategoriResult.filter((subSubKategori) =>
                    subSubKategori.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )) : []

    return (
        <div>
            <button
                type="submit"
                onClick={() => setShowModal(true)}
                className="text-blue-500 text-lg cursor-pointer"
            >
                <MdEdit />
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Edit Brand
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            id="floating_standard"
                                            placeholder=' '
                                            value={nama}
                                            onChange={(e) => setNama(e.target.value)}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Nama Brand
                                        </label>
                                    </div>
                                    <p className=" text-slate-500 text-xs mt-5">Pegawai</p>

                                    <Combobox value={selected} onChange={setSelected}>

                                        <div className="relative mt-1">
                                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                <Combobox.Input
                                                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                    displayValue={(pegawai) => pegawai.nama}
                                                    onChange={(event) => setQuery(event.target.value)}
                                                />
                                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <MdUnfoldMore />

                                                </Combobox.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                afterLeave={() => setQuery('')}
                                            >
                                                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {filteredSubSubKategori.length === 0 && query !== '' ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        filteredSubSubKategori.map((pegawai) => (
                                                            <Combobox.Option
                                                                key={pegawai.id}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                    }`
                                                                }
                                                                value={pegawai}
                                                            >
                                                                {({ selectedP, active }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block truncate ${selectedP ? 'font-medium' : 'font-normal'
                                                                                }`}
                                                                        >
                                                                            {pegawai.nama}
                                                                        </span>
                                                                        {selectedP ? (
                                                                            <span
                                                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                    }`}
                                                                            >
                                                                                <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </Combobox.Options>

                                            </Transition>
                                        </div>
                                    </Combobox>
                                </div>

                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

const EditWarna = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [nama, setNama] = useState(props.data.nama);
    const rgb = props.data.kode_RGB.split(",");
    const [kode_RGB, setKode_RGB] = useState({ r: rgb[0], g: rgb[1], b: rgb[2] })
    const [path_foto_warna, setPath_foto_warna] = useState('')
    const [kode, setKode] = useState(props.data.kode);
    const id = props.data.id
    const [updateWarna] = useUpdateWarnaMutation();

    function handleRChange(e) {
        setKode_RGB({
            ...kode_RGB,
            r: e.target.value
        });
    }
    function handleGChange(e) {
        setKode_RGB({
            ...kode_RGB,
            g: e.target.value
        });
    }
    function handleBChange(e) {
        setKode_RGB({
            ...kode_RGB,
            b: e.target.value
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const kodeRGB = kode_RGB.r + ', ' + kode_RGB.g + ', ' + kode_RGB.b
        updateWarna({ nama: nama, kode: kode, kode_RGB: kodeRGB, path_foto_warna: path_foto_warna, status: '1', id: id })
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])



    return (
        <div>
            <button
                type="submit"
                onClick={() => setShowModal(true)}
                className="text-blue-500 text-lg cursor-pointer"
            >
                <MdEdit />
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative max-h-full w-auto mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Edit Warna
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className='grid grid-cols-2 gap-4'>

                                        <div className="relative z-0 mt-5">
                                            <input
                                                type="text"
                                                id="floating_standard"
                                                placeholder=' '
                                                value={nama}
                                                onChange={(event) => setNama(event.target.value)}
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                Nama Warna
                                            </label>
                                        </div>
                                        <div className="relative z-0 mt-5">
                                            <input
                                                type="text"
                                                id="floating_standard"
                                                placeholder=' '
                                                value={kode}
                                                onChange={(event) => setKode(event.target.value)}
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                Kode Warna
                                            </label>
                                        </div>
                                    </div>

                                    <div className=' grid grid-cols-3 gap-4'>
                                        <div className="relative z-0 my-5 w-20">
                                            <input
                                                type="text"
                                                id="floating_standard"
                                                placeholder=' '
                                                value={kode_RGB.r}
                                                onChange={handleRChange}
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                R
                                            </label>
                                        </div>
                                        <div className="relative z-0 my-5 w-20">
                                            <input
                                                type="text"
                                                id="floating_standard"
                                                placeholder=' '
                                                value={kode_RGB.g}
                                                onChange={handleGChange}
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                G
                                            </label>
                                        </div>
                                        <div className="relative z-0 my-5 w-20">
                                            <input
                                                type="text"
                                                id="floating_standard"
                                                placeholder=' '
                                                value={kode_RGB.b}
                                                onChange={handleBChange}
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                B
                                            </label>
                                        </div>
                                    </div>

                                    <div className='flex justify-center'>
                                        <RgbColorPicker color={kode_RGB} onChange={setKode_RGB} />
                                    </div>

                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="file"
                                            id="floating_standard"
                                            placeholder=' '
                                            onChange={(event) => setPath_foto_warna(event.target.files[0])}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Foto Warna
                                        </label>
                                    </div>

                                </div>

                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="bg-blue-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

const EditUkuran = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [nama, setNama] = useState(props.data.nama);
    const id = props.data.id

    const [updateUkuran] = useUpdateUkuranMutation();


    const handleSubmit = (event) => {
        event.preventDefault();

        updateUkuran({ id: id, nama: nama })
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    return (
        <div>
            <button
                type="submit"
                onClick={() => setShowModal(true)}
                className="text-blue-500 text-lg cursor-pointer"
            >
                <MdEdit />
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Edit Ukuran
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <form>

                                    <div className="relative p-6 flex-auto">
                                        <div className="relative z-0 mt-5">
                                            <input
                                                type="text"
                                                id="floating_standard"
                                                placeholder=' '
                                                value={nama}
                                                onChange={(event) => setNama(event.target.value)}
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                Nama Kualitas Pasar
                                            </label>
                                        </div>
                                    </div>
                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => setShowModal(false)}
                                        >
                                            Close
                                        </button>
                                        <button
                                            className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="submit"
                                            onClick={(event) => handleSubmit(event)}
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

const EditProduk = (props) => {
    const [showModal, setShowModal] = useState(false);
    const id = props.data.id
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const idD = userData ? userData.id : null;
    const [nama, setNama] = useState(props.data.nama);
    const [deskripsi, setDeskripsi] = useState(props.data.deskripsi);
    const [label_harga, setLabel_harga] = useState(props.data.label_harga);
    const [nilaiTOP, setNilaiTOP] = useState(props.data.nilai_top);
    const [query, setQuery] = useState('')
    const getListSubSubKategoriResult = useGetListSubSubKategoriProdukQuery().data;
    const getListKualitasPasarResult = useGetKualitasPasarQuery().data
    const getListBrandResult = useGetListBrandQuery(idD).data;
    const [selectedSK, setSelectedSK] = useState({ 'id': props.data.produk_sub_sub_kategori_id, 'nama': props.data.produk_sub_sub_kategori.nama })
    const [selectedKP, setSelectedKP] = useState({ 'nama': props.data.produk_kualitas_pasaran.nama, 'id': props.data.produk_kualitas_pasaran_id })
    const [selectedB, setSelectedB] = useState({ 'nama': props.data.produk_brand.nama, 'id': props.data.produk_brand_id })
    const [updateProduk] = useUpdateProductMutation();

    const handleSubmit = (event) => {
        event.preventDefault();

        updateProduk({ nama: nama, deskripsi: deskripsi, nilai_top: nilaiTOP, status: '1', produk_sub_sub_kategori_id: selectedSK.id, id: id, produk_kualitas_pasaran_id: selectedKP.id, produk_brand_id: selectedB.id })
        setShowModal(false);
    }


    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const filteredSubSubKategori =
        getListSubSubKategoriResult ? (
            query === ''
                ? getListSubSubKategoriResult
                : getListSubSubKategoriResult.filter((subSubKategori) =>
                    subSubKategori.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )) : []

    const filteredKualitasPasar =
        getListKualitasPasarResult ? (
            query === ''
                ? getListKualitasPasarResult
                : getListKualitasPasarResult.filter((kualitasPasar) =>
                    kualitasPasar.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )) : []

    const filteredBrand =
        getListBrandResult ? (
            query === ''
                ? getListBrandResult
                : getListBrandResult.filter((brand) =>
                    brand.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )) : []

    return (
        <div>
            {getListSubSubKategoriResult ? (
                <button
                    type="submit"
                    onClick={() => setShowModal(true)}
                    className="text-blue-500 text-lg cursor-pointer"
                >
                    <MdEdit />
                </button>
            ) : (
                <button
                    type="submit"
                    onClick={() => setShowModal(true)}
                    className="text-blue-500 text-lg cursor-pointer cursor-not-allowed"
                    disabled
                >
                    <MdEdit />
                </button>
            )
            }
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Edit Produk
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            id="floating_standard"
                                            placeholder=' '
                                            value={nama}
                                            onChange={(e) => setNama(e.target.value)}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Nama Produk
                                        </label>
                                    </div>

                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            id="floating_standard"
                                            placeholder=' '
                                            value={deskripsi}
                                            onChange={(e) => setDeskripsi(e.target.value)}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Deskripsi
                                        </label>
                                    </div>
                                    <div className="relative z-0 mt-5">
                                        <CurrencyInput
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            prefix='Rp'
                                            placeholder=' '
                                            defaultValue={label_harga}
                                            required
                                            decimalsLimit={2}
                                            onValueChange={(value) => setLabel_harga(value)}
                                        />
                                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Label Harga
                                        </label>
                                    </div>

                                    <div className='flex gap-x-4'>
                                        <div>
                                            <p className=" text-slate-500 text-xs mt-5">Kualitas Pasar</p>

                                            <Combobox value={selectedKP} onChange={setSelectedKP}>

                                                <div className="relative mt-1">
                                                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                        <Combobox.Input
                                                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                            displayValue={(kualitasPasar) => kualitasPasar.nama}
                                                            onChange={(event) => setQuery(event.target.value)}
                                                        />
                                                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                            <MdUnfoldMore />

                                                        </Combobox.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                        afterLeave={() => setQuery('')}
                                                    >
                                                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            {filteredKualitasPasar.length === 0 && query !== '' ? (
                                                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                                    Nothing found.
                                                                </div>
                                                            ) : (
                                                                filteredKualitasPasar.map((kualitasPasar) => (
                                                                    <Combobox.Option
                                                                        key={kualitasPasar.id}
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                            }`
                                                                        }
                                                                        value={kualitasPasar}
                                                                    >
                                                                        {({ selectedP, active }) => (
                                                                            <>
                                                                                <span
                                                                                    className={`block truncate ${selectedP ? 'font-medium' : 'font-normal'
                                                                                        }`}
                                                                                >
                                                                                    {kualitasPasar.nama}
                                                                                </span>
                                                                                {selectedP ? (
                                                                                    <span
                                                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                            }`}
                                                                                    >
                                                                                        <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                                    </span>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                    </Combobox.Option>
                                                                ))
                                                            )}
                                                        </Combobox.Options>

                                                    </Transition>
                                                </div>
                                            </Combobox>
                                        </div>

                                        <div>
                                            <p className=" text-slate-500 text-xs mt-5">Sub Sub Kategori Produk</p>

                                            <Combobox value={selectedSK} onChange={setSelectedSK}>

                                                <div className="relative mt-1">
                                                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                        <Combobox.Input
                                                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                            displayValue={(subKategori) => subKategori.nama}
                                                            onChange={(event) => setQuery(event.target.value)}
                                                        />
                                                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                            <MdUnfoldMore />

                                                        </Combobox.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                        afterLeave={() => setQuery('')}
                                                    >
                                                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            {filteredSubSubKategori.length === 0 && query !== '' ? (
                                                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                                    Nothing found.
                                                                </div>
                                                            ) : (
                                                                filteredSubSubKategori.map((subKategori) => (
                                                                    <Combobox.Option
                                                                        key={subKategori.id}
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                            }`
                                                                        }
                                                                        value={subKategori}
                                                                    >
                                                                        {({ selectedP, active }) => (
                                                                            <>
                                                                                <span
                                                                                    className={`block truncate ${selectedP ? 'font-medium' : 'font-normal'
                                                                                        }`}
                                                                                >
                                                                                    {subKategori.nama}
                                                                                </span>
                                                                                {selectedP ? (
                                                                                    <span
                                                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                            }`}
                                                                                    >
                                                                                        <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                                    </span>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                    </Combobox.Option>
                                                                ))
                                                            )}
                                                        </Combobox.Options>

                                                    </Transition>
                                                </div>
                                            </Combobox>
                                        </div>
                                    </div>
                                    <div className='flex gap-x-4'>
                                        <div>
                                            <p className=" text-slate-500 text-xs mt-5">Brand</p>

                                            <Combobox value={selectedB} onChange={setSelectedB}>

                                                <div className="relative mt-1">
                                                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                        <Combobox.Input
                                                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                            displayValue={(brand) => brand.nama}
                                                            onChange={(event) => setQuery(event.target.value)}
                                                        />
                                                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                            <MdUnfoldMore />

                                                        </Combobox.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                        afterLeave={() => setQuery('')}
                                                    >
                                                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            {filteredBrand.length === 0 && query !== '' ? (
                                                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                                    Nothing found.
                                                                </div>
                                                            ) : (
                                                                filteredBrand.map((brand) => (
                                                                    <Combobox.Option
                                                                        key={brand.id}
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                            }`
                                                                        }
                                                                        value={brand}
                                                                    >
                                                                        {({ selectedP, active }) => (
                                                                            <>
                                                                                <span
                                                                                    className={`block truncate ${selectedP ? 'font-medium' : 'font-normal'
                                                                                        }`}
                                                                                >
                                                                                    {brand.nama}
                                                                                </span>
                                                                                {selectedP ? (
                                                                                    <span
                                                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                            }`}
                                                                                    >
                                                                                        <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                                    </span>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                    </Combobox.Option>
                                                                ))
                                                            )}
                                                        </Combobox.Options>

                                                    </Transition>
                                                </div>
                                            </Combobox>
                                        </div>
                                        <div>
                                            <div className="relative z-0 mt-8">
                                                <input
                                                    type="text"
                                                    id="floating_standard"
                                                    placeholder=' '
                                                    value={nilaiTOP}
                                                    onChange={(e) => setNilaiTOP(e.target.value)}
                                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                                <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                    Nilai TOP
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

const EditProdukVarian = (props) => {
    const [showModal, setShowModal] = useState(false);
    const idP = props.data.id
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null
    const [nama, setNama] = useState(props.data.nama);
    const [deskripsi, setDeskripsi] = useState(props.data.deskripsi);
    const [query, setQuery] = useState('')
    const getListWarnaResult = useGetListWarnaQuery(id).data

    const getListUkuranResult = useGetListUkuranQuery(id).data

    const [editVarian] = useUpdateVarianMutation();
    const [selectedW, setSelectedW] = useState({ 'nama': props.data.produk_warna.nama, 'id': props.data.produk_warna.id })
    const [selectedU, setSelectedU] = useState({ 'nama': props.data.produk_ukuran.nama, 'id': props.data.produk_ukuran.id })



    const handleSubmit = (event) => {
        event.preventDefault();
        editVarian({ nama: nama, deskripsi: deskripsi, status: '1', produk_warna_id: selectedW.id, id: idP, produk_ukuran_id: selectedU.id })
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const filteredWarna =
        query === ''
            ? getListWarnaResult
            : getListWarnaResult.filter((Warna) =>
                Warna.nama
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    const filteredUkuran =
        query === ''
            ? getListUkuranResult
            : getListUkuranResult.filter((Ukuran) =>
                Ukuran.nama
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    return (
        <div>
            <button
                type="submit"
                onClick={() => setShowModal(true)}
                className="text-blue-500 text-lg cursor-pointer"
            >
                <MdEdit />
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Edit Varian Produk
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            id="floating_standard"
                                            placeholder=' '
                                            value={nama}
                                            onChange={(e) => setNama(e.target.value)}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Nama Produk
                                        </label>
                                    </div>

                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            id="floating_standard"
                                            placeholder=' '
                                            value={deskripsi}
                                            onChange={(e) => setDeskripsi(e.target.value)}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Deskripsi
                                        </label>
                                    </div>

                                    <div className='flex gap-x-4'>
                                        <div>
                                            <p className=" text-slate-500 text-xs mt-5">Warna Produk</p>

                                            <Combobox value={selectedW} onChange={setSelectedW}>

                                                <div className="relative mt-1">
                                                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                        <Combobox.Input
                                                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                            displayValue={(Warna) => Warna.nama}
                                                            onChange={(event) => setQuery(event.target.value)}
                                                        />
                                                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                            <MdUnfoldMore />

                                                        </Combobox.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                        afterLeave={() => setQuery('')}
                                                    >
                                                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            {filteredWarna.length === 0 && query !== '' ? (
                                                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                                    Nothing found.
                                                                </div>
                                                            ) : (
                                                                filteredWarna.map((Warna) => (
                                                                    <Combobox.Option
                                                                        key={Warna.id}
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                            }`
                                                                        }
                                                                        value={Warna}
                                                                    >
                                                                        {({ selectedW, active }) => (
                                                                            <>
                                                                                <span
                                                                                    className={`block truncate ${selectedW ? 'font-medium' : 'font-normal'
                                                                                        }`}
                                                                                >
                                                                                    {Warna.nama}
                                                                                </span>
                                                                                {selectedW ? (
                                                                                    <span
                                                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                            }`}
                                                                                    >
                                                                                        <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                                    </span>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                    </Combobox.Option>
                                                                ))
                                                            )}
                                                        </Combobox.Options>

                                                    </Transition>
                                                </div>
                                            </Combobox>
                                        </div>

                                        <div>
                                            <p className=" text-slate-500 text-xs mt-5">Ukuran Produk</p>

                                            <Combobox value={selectedU} onChange={setSelectedU}>

                                                <div className="relative mt-1">
                                                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                        <Combobox.Input
                                                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                            displayValue={(Ukuran) => Ukuran.nama}
                                                            onChange={(event) => setQuery(event.target.value)}
                                                        />
                                                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                            <MdUnfoldMore />

                                                        </Combobox.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                        afterLeave={() => setQuery('')}
                                                    >
                                                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            {filteredUkuran.length === 0 && query !== '' ? (
                                                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                                    Nothing found.
                                                                </div>
                                                            ) : (
                                                                filteredUkuran.map((Ukuran) => (
                                                                    <Combobox.Option
                                                                        key={Ukuran.id}
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                            }`
                                                                        }
                                                                        value={Ukuran}
                                                                    >
                                                                        {({ selectedU, active }) => (
                                                                            <>
                                                                                <span
                                                                                    className={`block truncate ${selectedU ? 'font-medium' : 'font-normal'
                                                                                        }`}
                                                                                >
                                                                                    {Ukuran.nama}
                                                                                </span>
                                                                                {selectedU ? (
                                                                                    <span
                                                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                            }`}
                                                                                    >
                                                                                        <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                                    </span>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                    </Combobox.Option>
                                                                ))
                                                            )}
                                                        </Combobox.Options>

                                                    </Transition>
                                                </div>
                                            </Combobox>
                                        </div>
                                    </div>
                                </div>

                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}



export { EditKualitasPasar, EditBrand, EditWarna, EditUkuran, EditProduk, EditProdukVarian }