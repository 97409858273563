import { Popover, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import {
  Link,
  NavLink,
  Outlet,
} from 'react-router-dom';
import { MdOutlineShoppingCart, MdSearch, MdOutlineMessage, MdOutlinePeople, MdViewHeadline } from "react-icons/md";
import { useGetListKeranjangQuery } from '../store/apis/keranjangApi';
import secureLocalStorage from 'react-secure-storage';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, getUser, logoutUser } from '../actions/auth';
import { alamat_storage } from './api';



const NavbarRetail = () => {
  const userData = useSelector(getUser)
  const id = userData && userData.user_role === 'Retail' ? userData[0].id : null
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [q, setQ] = useState('');

  const HandleSubmit = (e) => {
    const newTab = window.open(`/product?q=${q}`, '_blank');
    if (newTab) {
      newTab.focus();
    }
  }

  return (
    <div className='relative'>
      <nav className="sticky drop-shadow-md z-[4000] top-0 left-0 right-0 flex flex-wrap items-center justify-between px-10 md:px-24 py-1 bg-white mb-3">
        <div className="container mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link to="/"
              className="text-3xl font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-blue-500"
            >
              KONDITAIL
            </Link>
            <button
              className="text-blue-500 cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <MdViewHeadline />
            </button>
          </div>


          <div
            className={
              "lg:flex items-center flex-grow" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-danger"
          >
            <form onSubmit={(e) => HandleSubmit(e)} className='flex place-content-center lg:mx-auto lg:w-1/2'>
              <input
                value={q}
                onChange={(e) => setQ(e.target.value)}
                placeholder='Cari Produk'
                className='p-2 w-full rounded-l-md border border-slate-300 focus:outline-none focus:border-blue-500 ' type="text"
              />
              <button className='p-2 w-12 rounded-r-md bg-blue-500 ' type="submit"><MdSearch className='text-center text-white w-full text-lg' /></button>
            </form>
            <ul className="flex flex-col lg:flex-row list-none ">
              {id ?
                (<>
                  <li className="nav-item">
                    <CartDropdown />
                  </li>
                  <li className="nav-item">
                    <ProfileDropdown />
                  </li>
                </>) : <button onClick={() => window.location.replace('/Login')} className='bg-blue-500 px-4 py-2 rounded-lg text-white'>Login</button>
              }
            </ul>
          </div>
        </div>
      </nav>
      <Outlet />
    </div>
  );

}

const CartDropdown = () => {
  const userData = useSelector(getUser)
  const id = userData && userData.user_role === 'Retail' ? userData[0].id : null
  const { data, isFetching, error } = useGetListKeranjangQuery(id);

  return (
    <div>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center rounded-md px-3 py-2 text-base font-medium text-blue-500 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <MdOutlineShoppingCart className='text-xl' />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-0 z-50 mt-6 -translate-x-1/2 transform px-4">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative bg-white w-80 justify between p-2">
                    {data ? (
                      <>
                        {data.map((keranjang) =>
                          <div key={keranjang.id} className='flex border-b h-14'>
                            <div className='flex '>
                              <img src={`${alamat_storage}fotoProduk/${keranjang.foto}`} alt="" className='' />
                              <div>
                                {keranjang.nama}
                              </div>
                            </div>

                            <div className='grow text-right text-blue-500'>
                              Rp{Intl.NumberFormat("id-ID").format(keranjang.min_jual)}
                            </div>
                          </div>)}
                        <div className='w-full place-content-end grid'>
                          <NavLink to='/keranjang' className='bg-blue-500 text-white p-1 mt-2'>Tampilkan keranjang belanja</NavLink>
                        </div>
                      </>
                    ) : <></>}
                  </div>

                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
};

const ProfileDropdown = () => {
  const userData = useSelector(getUser)
  const id = userData && userData.user_role === 'Retail' ? userData[0].id : null
  const token = useSelector(getToken);

  const dispatch = useDispatch();

  const logout = (e) => {
    e.preventDefault();

    dispatch(logoutUser(token));
  }
  return (
    <div>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center rounded-md px-3 py-2 text-base font-medium text-blue-500 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <MdOutlinePeople className='text-xl' />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute  z-10 mt-6 w-auto -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid bg-white w-40">
                    <NavLink to={'/profile'} className='text-blue-500 text-center p-2'>Profil</NavLink>
                    <NavLink to={'/transaksiRetail'} className='text-blue-500 text-center p-2 border-t'>Transaksi</NavLink>
                    <button onClick={(e) => logout(e)} className='text-blue-500 text-center p-2 border-t'>Logout</button>
                  </div>

                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
};

export default NavbarRetail
