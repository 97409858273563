import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const alamatKirimApi = createApi({
    reducerPath: 'AlamatKirim',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getListAlamatKirim: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'AlamatKirim' }];

                },
                query: (id) => {
                    return {
                        url: 'RetailAlamatKirimList',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            addAlamatKirim: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'AlamatKirim' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'RetailAlamatKirim',
                        body: data,
                    };
                },
            }),
            updateAlamatKirim: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'AlamatKirim' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `RetailAlamatKirim/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteAlamatKirim: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'AlamatKirim' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `RetailAlamatKirim/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetListAlamatKirimQuery, useAddAlamatKirimMutation, useUpdateAlamatKirimMutation, useDeleteAlamatKirimMutation } = alamatKirimApi;
export { alamatKirimApi };