import React from 'react'
import { FaWhatsapp } from "react-icons/fa";
import { MdContactPhone } from 'react-icons/md';

const ContactPopup = ({ tel, contactModal }) => {
    return (
        <div>
            <div
                className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[5000] outline-none focus:outline-none"
            >
                <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                    {/*content*/}
                    <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Contact Person
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => contactModal()}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto ">
                            <p>Jika whatsapp tidak tersedia, silahkan hubungi melalui nomor telepon yang tersedia: +{tel}</p>
                            <div className='grid grid-cols-2 mt-4 gap-4 '>
                                <a href={`https://wa.me/${tel}`} target='_blank' className='px-6 py-3 bg-green-500 text-white text-3xl rounded-lg '>
                                    <FaWhatsapp className='mx-auto'/>
                                </a>
                                <a href={`tel:+${tel}`} target='_blank' className='px-6 py-3 bg-blue-500 text-white text-3xl rounded-lg'>
                                    <MdContactPhone className='mx-auto'/>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactPopup