import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const lokasiKelurahanApi = createApi({
    reducerPath: 'lokasiKelurahan',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getKelurahan: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'kelurahan' }];

                },
                query: () => {
                    return {
                        url: 'LokasiKelurahan',
                        method: 'GET',
                    };
                },
            }),
            getListKelurahan: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'kelurahan' }];

                },
                query: () => {
                    return {
                        url: 'LokasiKelurahanList',
                        method: 'GET',
                    };
                },
            }),
            getDropdownListKelurahan: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'kelurahan' }];

                },
                query: (nama) => {
                    return {
                        url: 'LokasiKelurahanDropdownList',
                        method: 'GET',
                        params: {nama}
                    };
                },
            }),
            addKelurahan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'kelurahan' }];
                },
                query: (data) => {
                   
                    return {
                        method: 'POST',
                        url: 'LokasiKelurahan',
                        body: data,
                    };
                },
            }),
            updateKelurahan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'kelurahan' }];
                },
                query: (data) => {
                    
                    return {
                        method: 'PUT',
                        url: `LokasiKelurahan/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteKelurahan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'kelurahan' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `LokasiKelurahan/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetKelurahanQuery, useGetListKelurahanQuery, useGetDropdownListKelurahanQuery, useAddKelurahanMutation, useUpdateKelurahanMutation, useDeleteKelurahanMutation } = lokasiKelurahanApi;
export { lokasiKelurahanApi };