import React from 'react'

const RetailPopup = ({ data, retailModal }) => {
    return (
        <div>
            <div
                className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[5000] outline-none focus:outline-none"
            >
                <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                    {/*content*/}
                    <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Data Retail
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => retailModal()}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto ">
                            <table>
                                <tbody>
                                    <tr className=''>
                                        <td className='px-4'>Nama Pemilik</td>
                                        <td className='px-2'>:</td>
                                        <td className='px-4'>{data.user.name}</td>
                                    </tr>
                                    <tr className='bg-blue-100'>
                                        <td className='px-4'>Nomor Telepon Pemilik</td>
                                        <td className='px-2'>:</td>
                                        <td className='px-4'>{data.nomor_telp_pemilik}</td>
                                    </tr>
                                    <tr className=''>
                                        <td className='px-4'>Nama Toko</td>
                                        <td className='px-2'>:</td>
                                        <td className='px-4'>{data.nama}</td>
                                    </tr>
                                    <tr className='bg-blue-100'>
                                        <td className='px-4'>Alamat</td>
                                        <td className='px-2'>:</td>
                                        <td className='px-4'>{data.alamat},
                                            {' Kecamatan ' + data.lokasi_kelurahan.lokasi_kecamatan.nama},
                                            {' ' + data.lokasi_kelurahan.lokasi_kecamatan.lokasi_kota_kab.nama},
                                            {' ' + data.lokasi_kelurahan.lokasi_kecamatan.lokasi_kota_kab.lokasi_propinsi.nama}
                                            {' ' + data.lokasi_kelurahan.kode_pos}</td>
                                    </tr>
                                    <tr>
                                        <td className='px-4'>Nomor Telepon</td>
                                        <td className='px-2'>:</td>
                                        <td className='px-4'>{data.nomor_telp}</td>
                                    </tr>
                                    <tr className='bg-blue-100'>
                                        <td className='px-4'>NPWP</td>
                                        <td className='px-2'>:</td>
                                        <td className='px-4'>{data.npwp}</td>
                                    </tr>
                                    <tr>
                                        <td className='px-4'>Status Kepemilikan Toko</td>
                                        <td className='px-2'>:</td>
                                        <td className='px-4'>{data.status_kepemilikan_toko}</td>
                                    </tr>
                                    <tr className='bg-blue-100'>
                                        <td className='px-4'>Deskripsi</td>
                                        <td className='px-2'>:</td>
                                        <td className='px-4'>{data.deskripsi}</td>
                                    </tr>
                                </tbody>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RetailPopup