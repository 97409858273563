import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const pembayaranDistributorApi = createApi({
    reducerPath: 'pembayaranDistributor',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getListPembayaranDistributorDistributor: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'PembayaranDistributor' }];

                },
                query: (id) => {
                    return {
                        url: 'PembayaranDistributorDetailListDistributor',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            getListPembayaranDistributorAdmin: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'PembayaranDistributor' }];

                },
                query: (id) => {
                    return {
                        url: 'PembayaranDistributorDetailList',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            detailPembayaran: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'PembayaranDistributor' }];

                },
                query: (id) => {
                    return {
                        url: 'DetailPembayaran',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            addPembayaranDistributor: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'PembayaranDistributor' }];
                },
                query: (data) => {
                    const formData = new FormData();
                    formData.append('tanggal', data.tanggal)
                    formData.append('datetime', data.datetime)
                    formData.append('nomor_faktur', data.nomor_faktur)
                    formData.append('nominal', data.nominal)
                    formData.append('path_bukti_faktur', data.path_bukti_faktur)
                    formData.append('penjualan_distributor_id', data.penjualan_distributor_id)

                    return {
                        method: 'POST',
                        url: 'PembayaranDistributorDetail',
                        body: formData,
                    };
                },
            }),
            updatePembayaranDistributor: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'PembayaranDistributor' }];
                },
                query: (data) => {
                    const formData = new FormData();
                    const json = JSON.stringify(data.ids);

                    formData.append('ids', json);
                    formData.append('tanggal', data.tanggal);
                    formData.append('nomor_faktur', data.nomor_faktur);
                    formData.append('path_bukti_faktur', data.path_bukti_faktur);
                    return {
                        method: 'POST',
                        url: `PembayaranDistributorDetailUpdate`,
                        body: formData,
                    };
                },
            }),
            terimaPembayaranDistributor: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'PembayaranDistributor' }];
                },
                query: (data) => {
                    const formData = new FormData();
                    const json = JSON.stringify(data.ids);
                    formData.append('ids', json);
                    formData.append('status', data.status);
                    return {
                        method: 'POST',
                        url: `PembayaranDistributorDetailTerima`,
                        body: formData,
                    };
                },
            }),
            deletePembayaranDistributor: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'PembayaranDistributor' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `PembayaranDistributorDetail/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetListPembayaranDistributorDistributorQuery, useGetListPembayaranDistributorAdminQuery, useDetailPembayaranQuery, useAddPembayaranDistributorMutation, useUpdatePembayaranDistributorMutation, useTerimaPembayaranDistributorMutation, useDeletePembayaranDistributorMutation } = pembayaranDistributorApi;
export { pembayaranDistributorApi };