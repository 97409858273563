import { ADD_PRODUK, DELETE_PRODUK, GET_LIST_PRODUK, UPDATE_PRODUK } from "../../actions/produkAction"

const initialState = {
    getListProdukResult: false,
    getListProdukLoading: false,
    getListProdukError: false,

    addProdukResult: false,
    addProdukLoading: false,
    addProdukError: false,

    deleteProdukResult: false,
    deleteProdukLoading: false,
    deleteProdukError: false,

    updateProdukResult: false,
    updateProdukLoading: false,
    updateProdukError: false,
}


const produk = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_PRODUK:
            return {
                ...state,
                getListProdukResult: action.payload.data,
                getListProdukLoading: action.payload.loading,
                getListProdukError: action.payload.errorMessage,
            }

        case ADD_PRODUK:
            return {
                ...state,
                addProdukResult: action.payload.data,
                addProdukLoading: action.payload.loading,
                addProdukError: action.payload.errorMessage,
            }
        case DELETE_PRODUK:
            return {
                ...state,
                deleteProdukResult: action.payload.data,
                deleteProdukLoading: action.payload.loading,
                deleteProdukError: action.payload.errorMessage,
            }
        case UPDATE_PRODUK:
            return {
                ...state,
                updateProdukResult: action.payload.data,
                updateProdukLoading: action.payload.loading,
                updateProdukError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default produk;