import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { getToken, loginUser } from '../actions/auth';
import Register from './Register';
import { MdRotateRight } from 'react-icons/md';

const Login = () => {
  const userRef = useRef();

  const { loading, message } = useSelector((state) => state.auth)
  const token = useSelector(getToken);
  const [register, setRegister] = useState(false);

  const HandleClick = () => {
    if (register) {
      setRegister(false);
    } else {
      setRegister(true);
    }
  }

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(loginUser({ username: user, password: pwd }));
  }


  return (
    token ? <Navigate to="/LogingIn" /> :
      <div className='w-screen h-screen flex justify-center items-center'>
        {register && <Register regisModal={HandleClick} />}
        <div className=' w-80 bg-white drop-shadow-xl '>
          {message && message === 400 ?
            (<div className='w-full bg-red-400 text-center text-white p-2'>
              Username atau Password Salah
            </div>) : (<></>)
          }

          <div className=" p-8">
            <Link to="/"
              className="text-3xl text-center w-full font-bold leading-relaxed inline-block py-2 whitespace-nowrap uppercase text-blue-500"
            >
              KONDITAIL
            </Link>
            <h1 className='text-3xl text-blue-500 font-bold text-center'>Login</h1>
            <form>


              <div className="relative z-0 mt-8">
                <input
                  type="text"
                  ref={userRef}
                  autoComplete="off"
                  onChange={(e) => setUser(e.target.value)}
                  required
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" " />
                <label htmlFor="floating_standard" className=" absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Username</label>
              </div>
              <div className="relative z-0 mt-4">
                <input
                  type="password"
                  autoComplete="on"
                  onChange={(e) => setPwd(e.target.value)}
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" " />
                <label htmlFor="floating_standard" className=" absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password</label>
              </div>
              Belum memiliki akun? <button type="button" onClick={() => HandleClick()} className='text-blue-500'> daftar</button>
              {loading ? (
                <button onClick={(e) => handleSubmit(e)} className='flex place-content-center p-3 w-full bg-blue-400 text-white mt-8 rounded-lg cursor-wait' disabled >
                  Login
                  <MdRotateRight className='animate-spin text-xl' />
                </button>) : (
                <button onClick={(e) => handleSubmit(e)} className='p-3 w-full bg-blue-500 text-white mt-8 rounded-lg' >
                  Login
                </button>)}
            </form>
          </div>


        </div>

      </div >
  )
}

export default Login