import { REGISTER } from "../../actions/userAction";

const initialState = {
    registerResult: false,
    registerLoading: false,
    registerError: false,

}


const user = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER:
            return {
                ...state,
                registerResult: action.payload.data,
                registerLoading: action.payload.loading,
                reportError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default user;