import { ADD_KUALITAS_PASAR, DELETE_KUALITAS_PASAR, GET_LIST_KUALITAS_PASAR, UPDATE_KUALITAS_PASAR } from "../../actions/kualitasPasarAction";

const initialState = {
    getListKualitasPasarResult: false,
    getListKualitasPasarLoading: false,
    getListKualitasPasarError: false,

    addKualitasPasarResult: false,
    addKualitasPasarLoading: false,
    addKualitasPasarError: false,

    deleteKualitasPasarResult: false,
    deleteKualitasPasarLoading: false,
    deleteKualitasPasarError: false,

    updateKualitasPasarResult: false,
    updateKualitasPasarLoading: false,
    updateKualitasPasarError: false,


}


const kualitasPasar = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_KUALITAS_PASAR:
            return {
                ...state,
                getListKualitasPasarResult: action.payload.data,
                getListKualitasPasarLoading: action.payload.loading,
                getListKualitasPasarError: action.payload.errorMessage,
            }
        case ADD_KUALITAS_PASAR:
            return {
                ...state,
                addKualitasPasarResult: action.payload.data,
                addKualitasPasarLoading: action.payload.loading,
                addKualitasPasarError: action.payload.errorMessage,
            }
        case DELETE_KUALITAS_PASAR:
            return {
                ...state,
                deleteKualitasPasarResult: action.payload.data,
                deleteKualitasPasarLoading: action.payload.loading,
                deleteKualitasPasarError: action.payload.errorMessage,
            }
        case UPDATE_KUALITAS_PASAR:
            return {
                ...state,
                updateKualitasPasarResult: action.payload.data,
                updateKualitasPasarLoading: action.payload.loading,
                updateKualitasPasarError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default kualitasPasar;