function FilterTable(rows, q) {
    const columns = rows[0] && Object.keys(rows[0]);
    return rows.filter((row) =>
        columns.some(
            (column) => row[column]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        )
    );
}

function FilterTableVarian(rows, q) {
    return rows.filter((row) =>
        row.produk_id?.toString().indexOf(q) > -1
    );
}

function FilterTableStok(rows, q) {
    return rows.filter((row) =>
        row.produk_stok_id?.toString().indexOf(q) > -1
    );
}


function FilterTableStokVarian(rows, q) {
    return rows.filter((row) =>
        row.produk_varian_id?.toString().indexOf(q) > -1
    );
}

function FilterByDistributor(rows, q) {
    return rows.filter((row) =>
        row.distributor_id?.toString().indexOf(q) > -1
    );
}

function FilterByStatus(rows, q) {
    return rows.filter((row) =>
        row.status?.toString().indexOf(q) > -1
    );
}

function FilterPembayaran(rows, status) {
    if (status === 'Lunas') {
        return rows.filter((row) =>
            row.totalTagihan + row.opsi_pengiriman.harga - row.totalReturs <= row.totalTerbayar
        );
    } else if (status === 'Belum Lunas') {
        return rows.filter((row) =>
            row.totalTagihan + row.opsi_pengiriman.harga - row.totalReturs >  row.totalTerbayar
        );
    }
}

function FilterPembayaranJatuhTempo(rows) {
    const currentDate = new Date();

    return rows.filter((row) =>
        Math.round((new Date(row.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) < 0 && row.totalTagihan + row.opsi_pengiriman.harga - row.totalReturs !==  row.totalTerbayar
    );
}




export { FilterTable, FilterTableVarian, FilterTableStok, FilterTableStokVarian, FilterByDistributor, FilterByStatus, FilterPembayaran, FilterPembayaranJatuhTempo}