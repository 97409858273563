import { Combobox, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react'
import { MdCheck, MdUnfoldMore } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../actions/auth';
import { addKPasar, getListKPasar } from '../../actions/kualitasPasarAction';
import { RgbColorPicker } from "react-colorful"
import { FileUploader } from "react-drag-drop-files";
import { useAddUkuranMutation, useGetListUkuranQuery } from '../../store/apis/produkUkuranApi';
import { useAddWarnaMutation, useGetListWarnaQuery } from '../../store/apis/produkWarnaApi';
import { useGetListSubSubKategoriProdukQuery } from '../../store/apis/subSubKategoriProdukApi';
import { useAddProductMutation, useAddVarianMutation, useGetProductListQuery } from '../../store/apis/produkApi';
import { useAddBrandMutation, useGetListBrandQuery } from '../../store/apis/produkBrandApi';
import secureLocalStorage from 'react-secure-storage';
import { useAddKualitasPasarMutation, useGetKualitasPasarQuery } from '../../store/apis/kualitasPasarApi';
import CurrencyInput from 'react-currency-input-field';


const AddKualitasPasar = () => {
    const [showModal, setShowModal] = useState(false);
    const [nama, setNama] = useState('');
    const [addKualitasPasar, addKualitasPasarApi] = useAddKualitasPasarMutation();

    const handleSubmit = () => {
        addKualitasPasar({ nama: nama, status: 'Aktif' })
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    return (
        <div>
            <button
                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Tambah Data
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <form onSubmit={handleSubmit} className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Tambah Kualitas Pasar
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            placeholder=' '
                                            value={nama}
                                            onChange={(event) => setNama(event.target.value)}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Kualitas Pasar
                                        </label>
                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="submit"
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

const AddBrand = ({ brandModal }) => {
    const [nama, setNama] = useState('');
    const [selected, setSelected] = useState({ 'nama': 'Pilih Sub Sub Kategori Produk' })
    const [query, setQuery] = useState('')
    const [addBrand] = useAddBrandMutation()
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const getListSubSubKategoriResult = useGetListSubSubKategoriProdukQuery(id).data;

    const handleSubmit = (event) => {
        event.preventDefault();

        addBrand({ nama: nama, status: 'Aktif', produk_sub_sub_kategori_id: selected.id, distributor_id: id })
        brandModal();
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                brandModal()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [brandModal])

    const filteredSubSubKategori =
        getListSubSubKategoriResult ? (query === ''
            ? getListSubSubKategoriResult
            : getListSubSubKategoriResult.filter((subSubKategori) =>
                subSubKategori.nama
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )) : []

    return (
        <div
            className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[51] outline-none focus:outline-none"
        >
            <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                {/*content*/}
                <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-xl font-semibold">
                            Tambah Brand Produk
                        </h3>
                        <button
                            className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => brandModal()}
                        >
                            <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                x
                            </span>
                        </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                        <div className="relative z-0 mt-5">
                            <input
                                type="text"
                                placeholder=' '
                                value={nama}
                                onChange={(e) => setNama(e.target.value)}
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                            <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                Nama Brand
                            </label>
                        </div>
                        <p className=" text-slate-500 text-xs mt-5">Sub sub kategori</p>

                        <Combobox value={selected} onChange={setSelected}>

                            <div className="relative mt-1">
                                <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                    <Combobox.Input
                                        className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                        displayValue={(subSubKategori) => subSubKategori.nama}
                                        onChange={(event) => setQuery(event.target.value)}
                                    />
                                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                        <MdUnfoldMore />

                                    </Combobox.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    afterLeave={() => setQuery('')}
                                >
                                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {filteredSubSubKategori.length === 0 && query !== '' ? (
                                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                Nothing found.
                                            </div>
                                        ) : (
                                            filteredSubSubKategori.map((subSubKategori) => (
                                                <Combobox.Option
                                                    key={subSubKategori.id}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                        }`
                                                    }
                                                    value={subSubKategori}
                                                >
                                                    {({ selectedP, active }) => (
                                                        <>
                                                            <span
                                                                className={`block truncate ${selectedP ? 'font-medium' : 'font-normal'
                                                                    }`}
                                                            >
                                                                {subSubKategori.nama}
                                                            </span>
                                                            {selectedP ? (
                                                                <span
                                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                        }`}
                                                                >
                                                                    <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))
                                        )}
                                    </Combobox.Options>

                                </Transition>
                            </div>
                        </Combobox>
                    </div>

                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                            className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={(event) => handleSubmit(event)}
                        >
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AddProduk = ({ setShowModal }) => {
    const [nama, setNama] = useState('');
    const [deskrispi, setDeskripsi] = useState('');
    const [label_harga, setLabel_harga] = useState('');
    const [nilaiTOP, setNilaiTOP] = useState('');
    const [query, setQuery] = useState('')
    const getListSubSubKategoriResult = useGetListSubSubKategoriProdukQuery().data
    const getListKualitasPasarResult = useGetKualitasPasarQuery().data
    const [addProduct] = useAddProductMutation();
    const [selectedSK, setSelectedSK] = useState('')
    const [selectedKP, setSelectedKP] = useState('')
    const [selectedB, setSelectedB] = useState('')
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const [addBrandForm, setAddBrandForm] = useState(false);

    const ShowBrandModal = (e) => {
        if (addBrandForm) {
            setAddBrandForm(false);
        } else {
            setAddBrandForm(true);
        }
    }

    const getListBrandResult = useGetListBrandQuery(id).data

    const handleSubmit = (event) => {
        event.preventDefault();

        addProduct({ nama: nama, deskripsi: deskrispi, label_harga: label_harga, nilai_top: nilaiTOP, status: '1', produk_sub_sub_kategori_id: selectedSK.id, distributor_id: id, produk_kualitas_pasaran_id: selectedKP.id, produk_brand_id: selectedB.id })
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    })

    const filteredSubSubKategori =
        getListSubSubKategoriResult ?
            query === ''
                ? getListSubSubKategoriResult
                : getListSubSubKategoriResult.filter((subSubKategori) =>
                    subSubKategori.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                ) : ([])

    const filteredKualitasPasar =
        getListKualitasPasarResult ?
            (query === ''
                ? getListKualitasPasarResult
                : getListKualitasPasarResult.filter((kualitasPasar) =>
                    kualitasPasar.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )) : [];

    const filteredBrand =
        getListBrandResult ?
            (query === ''
                ? getListBrandResult
                : getListBrandResult.filter((brand) =>
                    brand.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )) : [];

    return (
        <>
            {addBrandForm && <AddBrand brandModal={ShowBrandModal} />}
            <div>
                <div
                    className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                    <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                        {/*content*/}
                        <form onSubmit={(event) => handleSubmit(event)} className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-xl font-semibold">
                                    Tambah Produk
                                </h3>
                                <button
                                    className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => setShowModal()}
                                >
                                    <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        x
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto">
                                <div className="relative z-0 mt-5">
                                    <input
                                        type="text"
                                        placeholder=' '
                                        value={nama}
                                        required
                                        onChange={(e) => setNama(e.target.value)}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                    <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                        Nama Produk
                                    </label>
                                </div>

                                <div className="relative z-0 mt-5">
                                    <input
                                        type="text"
                                        placeholder=' '
                                        required
                                        value={deskrispi}
                                        onChange={(e) => setDeskripsi(e.target.value)}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                    <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                        Deskripsi
                                    </label>
                                </div>

                                <div className="relative z-0 mt-5">
                                    <CurrencyInput
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        prefix='Rp'
                                        placeholder=' '
                                        defaultValue={label_harga}
                                        required
                                        decimalsLimit={2}
                                        onValueChange={(value) => setLabel_harga(value)}
                                    />
                                    <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                        Label Harga
                                    </label>
                                </div>

                                <div className='flex gap-x-4'>
                                    <div>
                                        <p className=" text-slate-500 text-xs mt-5">Kualitas Pasar</p>

                                        <Combobox required value={selectedKP} onChange={setSelectedKP}>

                                            <div className="relative mt-1">
                                                <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                    <Combobox.Input
                                                        required
                                                        placeholder='Pilih Kualitas Pasar'
                                                        className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                        displayValue={(kualitasPasar) => kualitasPasar.nama}
                                                        onChange={(event) => setQuery(event.target.value)}
                                                    />
                                                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <MdUnfoldMore />

                                                    </Combobox.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                    afterLeave={() => setQuery('')}
                                                >
                                                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {filteredKualitasPasar.length === 0 && query !== '' ? (
                                                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                                Nothing found.
                                                            </div>
                                                        ) : (
                                                            filteredKualitasPasar.map((kualitasPasar) => (
                                                                <Combobox.Option
                                                                    key={kualitasPasar.id}
                                                                    className={({ active }) =>
                                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                        }`
                                                                    }
                                                                    value={kualitasPasar}
                                                                >
                                                                    {({ selectedP, active }) => (
                                                                        <>
                                                                            <span
                                                                                className={`block truncate ${selectedP ? 'font-medium' : 'font-normal'
                                                                                    }`}
                                                                            >
                                                                                {kualitasPasar.nama}
                                                                            </span>
                                                                            {selectedP ? (
                                                                                <span
                                                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                        }`}
                                                                                >
                                                                                    <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                                </span>
                                                                            ) : null}
                                                                        </>
                                                                    )}
                                                                </Combobox.Option>
                                                            ))
                                                        )}
                                                    </Combobox.Options>

                                                </Transition>
                                            </div>
                                        </Combobox>
                                    </div>

                                    <div>
                                        <p className=" text-slate-500 text-xs mt-5">Sub Sub Kategori Produk</p>

                                        <Combobox required value={selectedSK} onChange={setSelectedSK}>

                                            <div className="relative mt-1">
                                                <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                    <Combobox.Input
                                                        required
                                                        placeholder='Pilih Kategori'
                                                        className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                        displayValue={(subKategori) => subKategori.nama}
                                                        onChange={(event) => setQuery(event.target.value)}
                                                    />
                                                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <MdUnfoldMore />

                                                    </Combobox.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                    afterLeave={() => setQuery('')}
                                                >
                                                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {filteredSubSubKategori.length === 0 && query !== '' ? (
                                                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                                Nothing found.
                                                            </div>
                                                        ) : (
                                                            filteredSubSubKategori.map((subKategori) => (
                                                                <Combobox.Option
                                                                    key={subKategori.id}
                                                                    className={({ active }) =>
                                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                        }`
                                                                    }
                                                                    value={subKategori}
                                                                >
                                                                    {({ selectedP, active }) => (
                                                                        <>
                                                                            <span
                                                                                className={`block truncate ${selectedP ? 'font-medium' : 'font-normal'
                                                                                    }`}
                                                                            >
                                                                                {subKategori.nama}
                                                                            </span>
                                                                            {selectedP ? (
                                                                                <span
                                                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                        }`}
                                                                                >
                                                                                    <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                                </span>
                                                                            ) : null}
                                                                        </>
                                                                    )}
                                                                </Combobox.Option>
                                                            ))
                                                        )}
                                                    </Combobox.Options>

                                                </Transition>
                                            </div>
                                        </Combobox>
                                    </div>
                                </div>
                                <div className='flex'>
                                    <div className='flex-grow'>
                                        <p className=" text-slate-500 text-xs mt-5">Brand</p>

                                        <Combobox required value={selectedB} onChange={setSelectedB}>

                                            <div className="relative mt-1">
                                                <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                    <Combobox.Input
                                                        required
                                                        placeholder='Pilih atau tambah brand'
                                                        className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                        displayValue={(brand) => brand.nama}
                                                        onChange={(event) => setQuery(event.target.value)}
                                                    />
                                                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <MdUnfoldMore />

                                                    </Combobox.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                    afterLeave={() => setQuery('')}
                                                >
                                                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {filteredBrand.length === 0 && query !== '' ? (
                                                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                                Nothing found.
                                                            </div>
                                                        ) : (
                                                            filteredBrand.map((brand) => (
                                                                <Combobox.Option
                                                                    key={brand.id}
                                                                    className={({ active }) =>
                                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                        }`
                                                                    }
                                                                    value={brand}
                                                                >
                                                                    {({ selectedP, active }) => (
                                                                        <>
                                                                            <span
                                                                                className={`block truncate ${selectedP ? 'font-medium' : 'font-normal'
                                                                                    }`}
                                                                            >
                                                                                {brand.nama}
                                                                            </span>
                                                                            {selectedP ? (
                                                                                <span
                                                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                        }`}
                                                                                >
                                                                                    <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                                </span>
                                                                            ) : null}
                                                                        </>
                                                                    )}
                                                                </Combobox.Option>
                                                            ))
                                                        )}
                                                    </Combobox.Options>

                                                </Transition>
                                            </div>
                                        </Combobox>
                                    </div>
                                    <button type='button' className='mt-10 rounded-lg p-2 text-white  bg-blue-500' onClick={(e) => ShowBrandModal(e)}>Tambah</button>

                                </div>
                                <div>
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="number"
                                            placeholder=' '
                                            required
                                            value={nilaiTOP}
                                            onChange={(e) => setNilaiTOP(e.target.value)}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Nilai TOP
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {/*footer*/}
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="submit"
                                >
                                    Save Changes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    )
}

const AddProdukVarian = ({ data, varianModal }) => {
    const idP = data.id
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const [nama, setNama] = useState('');
    const [path, setPath] = useState('')
    const [deskrispi, setDeskripsi] = useState('');
    const [query, setQuery] = useState('')
    const getListWarnaResult = useGetListWarnaQuery(id).data
    const getListUkuranResult = useGetListUkuranQuery(id).data
    const [addWarnaForm, setAddWarnaForm] = useState(false);
    const [addUkuranForm, setAddUkuranForm] = useState(false);

    const ShowWarnaModal = (e) => {
        if (addWarnaForm) {
            setAddWarnaForm(false);
        } else {
            setAddWarnaForm(true);
        }
    }

    const handleChange = (file) => {
        setPath(file);
    };

    const ShowUkuranModal = (e) => {
        if (addUkuranForm) {
            setAddUkuranForm(false);
        } else {
            setAddUkuranForm(true);
        }
    }

    const [addVarian, addVarianResult] = useAddVarianMutation();
    const [selectedW, setSelectedW] = useState('')
    const [selectedU, setSelectedU] = useState('')

    const { refetch } = useGetProductListQuery(id);

    const handleSubmit = (event) => {
        event.preventDefault();
        addVarian({ nama: nama, deskripsi: deskrispi, status: 'Aktif', produk_warna_id: selectedW.id, produk_id: idP, produk_ukuran_id: selectedU.id, path: path })
        varianModal();
    }
    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                varianModal()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [varianModal])

    const filteredWarna =
        getListWarnaResult ? (
            query === ''
                ? getListWarnaResult
                : getListWarnaResult.filter((Warna) =>
                    Warna.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )) : []

    const filteredUkuran =
        getListUkuranResult ? (
            query === ''
                ? getListUkuranResult
                : getListUkuranResult.filter((Ukuran) =>
                    Ukuran.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )) : []

    return (
        <div>
            {addWarnaForm && <AddWarna warnaModal={ShowWarnaModal} />}
            {addUkuranForm && <AddUkuran ukuranModal={ShowUkuranModal} />}
            <div
                className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[51] outline-none focus:outline-none"
            >
                <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                    {/*content*/}
                    <form onSubmit={handleSubmit} className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Tambah Varian Produk
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => varianModal()}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <div className="relative z-0 mt-5">
                                <input
                                    type="text"
                                    placeholder=' '
                                    required
                                    value={nama}
                                    onChange={(e) => setNama(e.target.value)}
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Nama Produk
                                </label>
                            </div>

                            <div className="relative z-0 mt-5">
                                <input
                                    type="text"
                                    placeholder=' '
                                    required
                                    value={deskrispi}
                                    onChange={(e) => setDeskripsi(e.target.value)}
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Deskripsi
                                </label>
                            </div>

                            <div className='flex '>
                                <div className='flex-grow'>
                                    <p className=" text-slate-500 text-xs mt-5">Warna Produk</p>

                                    <Combobox value={selectedW} onChange={setSelectedW}>

                                        <div className="relative mt-1">
                                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                <Combobox.Input
                                                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                    displayValue={(Warna) => Warna.nama}
                                                    placeholder='Ketikkan warna yang telah dimasukkan atau tambahkan warna produk jika belum ada'
                                                    required
                                                    onChange={(event) => setQuery(event.target.value)}
                                                />
                                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <MdUnfoldMore />

                                                </Combobox.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                afterLeave={() => setQuery('')}
                                            >
                                                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {filteredWarna.length === 0 && query !== '' ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        filteredWarna.map((Warna) => (
                                                            <Combobox.Option
                                                                key={Warna.id}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                    }`
                                                                }
                                                                value={Warna}
                                                            >
                                                                {({ selectedW, active }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block truncate ${selectedW ? 'font-medium' : 'font-normal'
                                                                                }`}
                                                                        >
                                                                            {Warna.nama}
                                                                        </span>
                                                                        {selectedW ? (
                                                                            <span
                                                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                    }`}
                                                                            >
                                                                                <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </Combobox.Options>

                                            </Transition>
                                        </div>
                                    </Combobox>
                                </div>
                                <button type='button' className='mt-10 rounded-lg p-2 text-white  bg-blue-500' onClick={(e) => ShowWarnaModal(e)}>Tambah</button>


                            </div>
                            <div className='flex '>
                                <div className='flex-grow'>
                                    <p className=" text-slate-500 text-xs mt-5">Ukuran Produk</p>

                                    <Combobox value={selectedU} onChange={setSelectedU}>

                                        <div className="relative mt-1">
                                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                <Combobox.Input
                                                    required
                                                    placeholder='Silahkan pilih ukuran yang tersedia atau tambahkan jika belum ada'
                                                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                    displayValue={(Ukuran) => Ukuran.nama}
                                                    onChange={(event) => setQuery(event.target.value)}
                                                />
                                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <MdUnfoldMore />

                                                </Combobox.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                afterLeave={() => setQuery('')}
                                            >
                                                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {filteredUkuran.length === 0 && query !== '' ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        filteredUkuran.map((Ukuran) => (
                                                            <Combobox.Option
                                                                key={Ukuran.id}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                    }`
                                                                }
                                                                value={Ukuran}
                                                            >
                                                                {({ selectedU, active }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block truncate ${selectedU ? 'font-medium' : 'font-normal'
                                                                                }`}
                                                                        >
                                                                            {Ukuran.nama}
                                                                        </span>
                                                                        {selectedU ? (
                                                                            <span
                                                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                    }`}
                                                                            >
                                                                                <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </Combobox.Options>

                                            </Transition>
                                        </div>
                                    </Combobox>
                                </div>

                                <button type='button' className='mt-10 rounded-lg p-2 text-white  bg-blue-500' onClick={(e) => ShowUkuranModal(e)}>Tambah</button>

                            </div>
                            <div>
                                <p className=" text-slate-500 text-xs mt-5 mb-1">Foto Warna</p>
                                <FileUploader required label={"Tambahkan atau Letakkan foto Disini"} handleChange={handleChange} name="file" types={['PNG', 'JPG']} multiple={false} />
                                <p>{path ? `File name: ${path.name}` : "no files uploaded yet"}</p>
                            </div>
                        </div>

                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="submit"
                            >
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


const AddUkuran = ({ ukuranModal }) => {
    const [nama, setNama] = useState('');
    const [addUkuran] = useAddUkuranMutation();
    const [id, setId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const userData = await getUserData();
            setId(userData.id)
        };
        fetchData();
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        addUkuran({ nama: nama, status: 'Aktif', distributor_id: id })
        setNama('')
        ukuranModal();
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                ukuranModal()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [ukuranModal])

    return (
        <div>

            <div
                className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[52] outline-none focus:outline-none"
            >
                <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                    {/*content*/}
                    <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Tambah Ukuran
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => ukuranModal()}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <div className="relative z-0 mt-5">
                                <input
                                    type="text"
                                    placeholder=' '
                                    value={nama}
                                    onChange={(event) => setNama(event.target.value)}
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Ukuran
                                </label>
                            </div>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(event) => handleSubmit(event)}
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AddWarna = ({ warnaModal }) => {
    const fileTypes = ["JPG", "PNG"];
    const [nama, setNama] = useState('');
    const [kode_RGB, setKode_RGB] = useState({ r: 255, g: 255, b: 255 })
    const [path_foto_warna, setPath_foto_warna] = useState('')
    const [kode, setKode] = useState('');
    const [addWarna] = useAddWarnaMutation();
    const [id, setId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const userData = await getUserData();
            setId(userData.id)
        };
        fetchData();
    }, []);

    const handleChange = (file) => {
        setPath_foto_warna(file);
    };

    function handleRChange(e) {
        setKode_RGB({
            ...kode_RGB,
            r: e.target.value
        });
    }
    function handleGChange(e) {
        setKode_RGB({
            ...kode_RGB,
            g: e.target.value
        });
    }
    function handleBChange(e) {
        setKode_RGB({
            ...kode_RGB,
            b: e.target.value
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const kodeRGB = kode_RGB.r + ', ' + kode_RGB.g + ', ' + kode_RGB.b
        addWarna({ nama: nama, kode: kode, kode_RGB: kodeRGB, path_foto_warna: path_foto_warna, status: '1', distributor_id: id })
        warnaModal();
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                warnaModal()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [warnaModal])

    return (
        <div>
            <div
                className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[52] outline-none focus:outline-none"
            >
                <div className="relative max-h-full w-auto mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Tambah Warna
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => warnaModal(false)}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <div className='grid grid-cols-2 gap-4'>
                                <div className="relative z-0 mt-5">
                                    <input
                                        type="text"
                                        placeholder=' '
                                        value={nama}
                                        onChange={(event) => setNama(event.target.value)}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                    <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                        Nama Warna
                                    </label>
                                </div>
                                <div className="relative z-0 mt-5">
                                    <input
                                        type="text"
                                        placeholder=' '
                                        value={kode}
                                        onChange={(event) => setKode(event.target.value)}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                    <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                        Kode Warna
                                    </label>
                                </div>
                            </div>

                            <div className=' grid grid-cols-3 gap-4'>
                                <div className="relative z-0 my-5 w-20">
                                    <input
                                        type="text"
                                        placeholder=' '
                                        value={kode_RGB.r}
                                        onChange={handleRChange}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                    <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                        R
                                    </label>
                                </div>
                                <div className="relative z-0 my-5 w-20">
                                    <input
                                        type="text"
                                        placeholder=' '
                                        value={kode_RGB.g}
                                        onChange={handleGChange}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                    <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                        G
                                    </label>
                                </div>
                                <div className="relative z-0 my-5 w-20">
                                    <input
                                        type="text"
                                        placeholder=' '
                                        value={kode_RGB.b}
                                        onChange={handleBChange}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                    <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                        B
                                    </label>
                                </div>
                            </div>

                            <div className='flex justify-center'>
                                <RgbColorPicker color={kode_RGB} onChange={setKode_RGB} />
                            </div>

                            <div>
                                <p className=" text-slate-500 text-xs mt-5 mb-1">Foto Warna</p>
                                <FileUploader label={"Tambahkan atau Letakkan foto Disini"} handleChange={handleChange} name="file" types={fileTypes} multiple={false} />
                                <p>{path_foto_warna ? `File name: ${path_foto_warna.name}` : "no files uploaded yet"}</p>
                            </div>



                        </div>

                        {/*footer*/}
                        <div className="flex items-center justify-end px-6 py-3 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="submit"
                                onClick={(event) => handleSubmit(event)}
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export { AddKualitasPasar, AddBrand, AddProduk, AddUkuran, AddWarna, AddProdukVarian }