import axios from "axios";
import {alamat_backend} from "../components/api";


export const GET_LIST_PRODUK_BRAND = "GET_LIST_PRODUK_BRAND"
export const DELETE_PRODUK_BRAND = "DELETE_PRODUK_BRAND"
export const ADD_PRODUK_BRAND = "ADD_PRODUK_BRAND"
export const UPDATE_PRODUK_BRAND = "UPDATE_PRODUK_BRAND"


const getListBrand = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_LIST_PRODUK_BRAND,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'ProdukBrandList?id=' + id,
            timeout: 5000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_LIST_PRODUK_BRAND,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_LIST_PRODUK_BRAND,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}


const addBrand = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: ADD_PRODUK_BRAND,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'ProdukBrand',
            timeout: 5000,
            data: data
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: ADD_PRODUK_BRAND,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: ADD_PRODUK_BRAND,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })
    }
}

const updateBrand = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: UPDATE_PRODUK_BRAND,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'PUT',
            url: alamat_backend + 'ProdukBrand/' + data.id,
            timeout: 5000,
            data: data
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: UPDATE_PRODUK_BRAND,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: UPDATE_PRODUK_BRAND,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message
                    }
                })
            })
    }
}

const deleteBrand = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: DELETE_PRODUK_BRAND,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'DELETE',
            url: alamat_backend + 'ProdukBrand/'+ id,
            timeout: 5000,
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: DELETE_PRODUK_BRAND,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: DELETE_PRODUK_BRAND,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })
    }
}


export { getListBrand, addBrand, deleteBrand, updateBrand }