import { ADD_PEGAWAI, DELETE_PEGAWAI, GET_LIST_PEGAWAI, UPDATE_PEGAWAI } from "../../actions/pegawaiAction";

const initialState = {
    getListPegawaiResult: false,
    getListPegawaiLoading: false,
    getListPegawaiError: false,

    addPegawaiResult: false,
    addPegawaiLoading: false,
    addPegawaiError: false,

    updatePegawaiResult: false,
    updatePegawaiLoading: false,
    updatePegawaiError: false,

    deletePegawaiResult: false,
    deletePegawaiLoading: false,
    deletePegawaiError: false,
}


const pegawai = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_PEGAWAI:
            return {
                ...state,
                getListPegawaiResult: action.payload.data,
                getListPegawaiLoading: action.payload.loading,
                getListPegawaiError: action.payload.errorMessage,
            }

        case ADD_PEGAWAI:
            return {
                ...state,
                addPegawaiResult: action.payload.data,
                addPegawaiLoading: action.payload.loading,
                addPegawaiError: action.payload.errorMessage,
            }
        case UPDATE_PEGAWAI:
            return {
                ...state,
                updatePegawaiResult: action.payload.data,
                updatePegawaiLoading: action.payload.loading,
                updatePegawaiError: action.payload.errorMessage,
            }
        case DELETE_PEGAWAI:
            return {
                ...state,
                deletePegawaiResult: action.payload.data,
                deletePegawaiLoading: action.payload.loading,
                deletePegawaiError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default pegawai;