import React from 'react'
import { ProductList } from './Product'

const ProductRetail = () => {
  return (
    <div className='content'>
        <ProductList/>
    </div>
  )
}

export default ProductRetail