import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { alamat_backend } from '../../components/api';

const retailApi = createApi({
    reducerPath: 'retailApi',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            addRetail: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'retail' }];
                },
                query: (data) => {
                    const formData = new FormData();
                    formData.append('user_id', data.user_id)
                    formData.append('lokasi_kelurahan_id', data.lokasi_kelurahan_id)
                    formData.append('alamat', data.alamat)
                    formData.append('nomor_KTP', data.nomor_KTP)
                    formData.append('nomor_telp', data.nomor_telp)
                    formData.append('nomor_izin', data.nomor_izin)
                    formData.append('npwp', data.npwp)
                    formData.append('status_kepemilikan_toko', data.status_kepemilikan_toko)
                    formData.append('deskripsi', data.deskripsi)
                    formData.append('catatan', data.catatan)
                    formData.append('nama', data.nama)
                    formData.append('nomor_telp_pemilik', data.nomor_telp_pemilik)
                    formData.append('x', data.x)
                    formData.append('y', data.y)
                    formData.append('status', data.status)
                    formData.append('foto_KTP', data.foto_KTP)
                    formData.append('foto_profil', data.foto_profil)
                    formData.append('foto_izin', data.foto_izin)
                    console.log(data)
                    return {
                        method: 'POST',
                        url: 'Retail',
                        body: formData,
                    };
                },
            }),
            getListRetail: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'retail' }];

                },
                query: (status) => {
                    return {
                        url: 'RetailList',
                        method: 'GET',
                        params: { status }
                    };
                },
            }),
            getRetailDetail: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'retail' }];

                },
                query: (id) => {
                    return {
                        url: 'RetailDetail',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            updateRetail: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'retail' }];
                },
                query: (data) => {
                    const formData = new FormData();
                    formData.append('lokasi_kelurahan_id', data.lokasi_kelurahan_id)
                    formData.append('alamat', data.alamat)
                    formData.append('nomor_telp', data.nomor_telp)
                    formData.append('deskripsi', data.deskripsi)
                    formData.append('nama', data.nama)
                    formData.append('nomor_telp_pemilik', data.nomor_telp_pemilik)
                    formData.append('x', data.x)
                    formData.append('y', data.y)
                    formData.append('foto_profil', data.foto_profil)
                    formData.append('id', data.id)
                    return {
                        method: 'POST',
                        url: `RetailUpdate`,
                        body: formData,
                    };
                },
            }),

            updateRetailStatus: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'retail' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `RetailUpdateStatus`,
                        body: data,
                    };
                },
            }),

            // updateFoto: builder.mutation({}),
            deleteRetail: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'retail' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `Retail/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetListRetailQuery, useGetRetailDetailQuery, useAddRetailMutation, useDeleteRetailMutation, useUpdateRetailMutation, useUpdateRetailStatusMutation } = retailApi;
export { retailApi };