import { Combobox, Tab, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { MdDelete, MdUnfoldMore, MdCheck } from "react-icons/md";
import { useAddAlamatKirimMutation, useGetListAlamatKirimQuery } from '../../store/apis/alamatKirimApi';
import secureLocalStorage from 'react-secure-storage';
import DataTable from 'react-data-table-component';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import { useGetDropdownListKelurahanQuery, useGetListKelurahanQuery } from '../../store/apis/lokasiKelurahanApi';
import { useKeranjangCheckoutQuery } from '../../store/apis/keranjangApi';
import { useGetRetailDetailQuery, useUpdateRetailMutation } from '../../store/apis/retailApi';
import { alamat_storage } from '../../components/api';
import { FileUploader } from 'react-drag-drop-files';

const Profile = () => {

    //redux
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'));
    const id = userData ? userData.id : null;
    const { data, isFetching, error } = useGetRetailDetailQuery(id);

    const [alamat, setAlamat] = useState( '');
    const [nomor_telp, setNomor_telp] = useState( '');
    const [nomor_telp_pemilik, setNomor_telp_pemilik] = useState( '');
    const [deskripsi, setDeskripsi] = useState('');
    const [lokasi, setLokasi] = useState([0.5336, 101.4474]);
    const [query, setQuery] = useState('')
    const getListKelurahanResult = useGetDropdownListKelurahanQuery(query).data
    const [selectedKelurahan, setSelectedKelurahan] = useState('')
    const [fotoProfil, setFotoProfil] = useState('');
    const [updateRetail, updateRetailResult] = useUpdateRetailMutation();
    useEffect(() => {
        if (data) {
            setAlamat(data.alamat);
            setNomor_telp(data.nomor_telp);
            setNomor_telp_pemilik(data.nomor_telp_pemilik);
            setDeskripsi(data.deskripsi);
            setLokasi([data.x, data.y]);
            setSelectedKelurahan({ id: data.lokasi_kelurahan_id, nama: data.lokasi_kelurahan.nama });
        }

        if (updateRetailResult.isSuccess){
            window.alert('Update data berhasil!')
        }
    }, [data, updateRetailResult.isSuccess])
    const Markers = () => {

        const map = useMapEvents({
            click(e) {
                setLokasi([
                    e.latlng.lat,
                    e.latlng.lng
                ]);
            },
        })

        return (
            lokasi ?
                <Marker
                    key={lokasi[0]}
                    position={lokasi}
                    draggable={true}
                />
                : null
        )

    }

    const handleFotoProfil = (file) => {
        setFotoProfil(file);
    };

    const filteredKelurahan =
        getListKelurahanResult ? (
            query === ''
                ? getListKelurahanResult
                : getListKelurahanResult.filter((kelurahan) =>
                    kelurahan.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )) : []

    const HandleSubmit = (e) => {
        e.preventDefault();
        updateRetail({ lokasi_kelurahan_id: selectedKelurahan.id, alamat: alamat, nomor_telp: nomor_telp, deskripsi: deskripsi, nomor_telp_pemilik: nomor_telp_pemilik, x: lokasi[0], y: lokasi[1], foto_profil: fotoProfil, id: id });
    }
    return (
        <div className='w-full '>
            <div className='w-full bg-white p-5 shadow-md'>

                <Tab.Group>
                    {data && (
                        <div>

                            <div className='grid  grid-cols-2 w-full text-center'>

                                <div className='border-r border-b p-5'>
                                    <h1 className='font-bold'>Nama</h1>
                                    <p>{data.nama}</p>
                                </div>
                                <div className='border-b p-5'>
                                    <h1 className='font-bold'>Kota</h1>
                                    <p>{data.lokasi_kelurahan.lokasi_kecamatan.lokasi_kota_kab.nama}</p>
                                </div>
                                <div className='border-b border-r p-5'>
                                    <h1 className='font-bold'>Email</h1>
                                    <p>{data.user.email}</p>
                                </div>
                                <div className='border-b p-5'>
                                    <h1 className='font-bold'>No Telp</h1>
                                    <p>{data.nomor_telp}</p>
                                </div>
                                <div className='col-span-2 p-5 border-b'>
                                    <h1 className='font-bold'>Alamat</h1>
                                    <p>{data.alamat} kelurahan {data.lokasi_kelurahan.nama} kecamatan {data.lokasi_kelurahan.lokasi_kecamatan.nama} {data.lokasi_kelurahan.lokasi_kecamatan.lokasi_kota_kab.nama}, Provinsi {data.lokasi_kelurahan.lokasi_kecamatan.lokasi_kota_kab.lokasi_propinsi.nama} {data.lokasi_kelurahan.kode_pos}</p>
                                </div>
                            </div>
                        </div>
                    )}
                    <Tab.List>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Informasi Perusahaan</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Pengaturan Alamat</div>
                            )}
                        </Tab>
                    </Tab.List>
                    <Tab.Panels className={`mt-5 px-4`}>
                        <Tab.Panel>
                            <div className='grid grid-cols-0 md:grid-cols-2 gap-16'>
                                <div className='mx-auto'>
                                    {data &&
                                        <img src={`${alamat_storage}/retailProfil/${data.foto_profil}`} alt="" className=' w-full overflow-hidden' />
                                    }
                                    <div className="relative z-0 mt-4">
                                        <input
                                            type="text"
                                            required
                                            value={data ? data.nama : ''}
                                            readOnly
                                            placeholder=' '
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nama Perusahaan</label>
                                    </div>
                                    <div className='grid grid-cols-2 gap-4 mt-5'>
                                        <div className="relative z-0">
                                            <input
                                                type="text"
                                                required
                                                value={data ? data.user.name : ''}
                                                readOnly
                                                placeholder=' '
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nama Pemilik</label>
                                        </div>
                                        <div className="relative z-0">
                                            <input
                                                type="text"
                                                required
                                                value={data ? data.nomor_KTP : ''}
                                                readOnly
                                                placeholder=' '
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nomor KTP</label>
                                        </div>
                                        <div className="relative z-0">
                                            <input
                                                type="text"
                                                required
                                                value={data ? data.npwp : ''}
                                                readOnly
                                                placeholder=' '
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">NPWP</label>
                                        </div>
                                        <div className="relative z-0">
                                            <input
                                                type="text"
                                                required
                                                value={data ? data.nomor_izin : ''}
                                                readOnly
                                                placeholder=' '
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nomor Izin</label>
                                        </div>
                                    </div>

                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            required
                                            value={data ? data.status_kepemilikan_toko : ''}
                                            readOnly
                                            placeholder=' '
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Status Kepemilikan</label>
                                    </div>

                                </div>
                                <form onSubmit={(e) => HandleSubmit(e)}>
                                    <p className="my-2 text-slate-500 text-lg leading-relaxed text-center font-bold">
                                        -- Edit Informasi --
                                    </p>
                                    <div className='grid grid-cols-2 gap-4'>
                                        <div className="relative z-0 mt-5">
                                            <input
                                                type="text"
                                                required
                                                value={alamat}
                                                onChange={(event) => setAlamat(event.target.value)}
                                                placeholder=' '
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Alamat*</label>
                                        </div>
                                        <div className="relative z-0 mt-5">
                                            <input
                                                type="text"
                                                required
                                                value={nomor_telp}
                                                onChange={(event) => setNomor_telp(event.target.value)}
                                                placeholder=' '
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nomor Telepon*</label>
                                        </div>
                                        <div className="relative z-0 mt-5">
                                            <input
                                                type="text"
                                                required
                                                value={nomor_telp_pemilik}
                                                onChange={(event) => setNomor_telp_pemilik(event.target.value)}
                                                placeholder=' '
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nomor Telepon Pemilik*</label>
                                        </div>

                                        <div>
                                            <p className=" text-slate-500 text-xs mt-2">Kelurahan*</p>

                                            <Combobox required value={selectedKelurahan} onChange={setSelectedKelurahan}>

                                                <div className="relative mt-1">
                                                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                        <Combobox.Input
                                                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                            displayValue={(kelurahan) => kelurahan.nama}
                                                            onChange={(event) => setQuery(event.target.value)}
                                                        />
                                                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                            <MdUnfoldMore />

                                                        </Combobox.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                        afterLeave={() => setQuery('')}
                                                    >
                                                        <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            {filteredKelurahan.length === 0 && query !== '' ? (
                                                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                                    Nothing found.
                                                                </div>
                                                            ) : (
                                                                filteredKelurahan.map((kelurahan) => (
                                                                    <Combobox.Option
                                                                        key={kelurahan.id}
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                            }`
                                                                        }
                                                                        value={kelurahan}
                                                                    >
                                                                        {({ selectedKelurahan, active }) => (
                                                                            <>
                                                                                <span
                                                                                    className={`block text-xs  ${selectedKelurahan ? 'font-medium' : 'font-normal'
                                                                                        }`}
                                                                                >
                                                                                    Kelurahan {kelurahan.nama}, Kecamatan {kelurahan.lokasi_kecamatan.nama}, {kelurahan.lokasi_kecamatan.lokasi_kotakab.nama}
                                                                                </span>
                                                                                {selectedKelurahan ? (
                                                                                    <span
                                                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                            }`}
                                                                                    >
                                                                                        <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                                    </span>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                    </Combobox.Option>
                                                                ))
                                                            )}
                                                        </Combobox.Options>

                                                    </Transition>
                                                </div>
                                            </Combobox>
                                        </div>
                                    </div>

                                    <div className="relative z-0 mt-5">
                                        <textarea
                                            type="text"
                                            required
                                            value={deskripsi}
                                            onChange={(event) => setDeskripsi(event.target.value)}
                                            placeholder=' '
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Deskripsi*</label>
                                    </div>
                                    <div>
                                        <p className=" text-slate-500 text-xs mt-5 mb-1">Foto Profil Perusahaan (kosongkan jika tidak ingin merubah)</p>
                                        <FileUploader label={"Tambahkan atau Letakkan foto Disini"} handleChange={handleFotoProfil} name="file" types={["JPG", "PNG"]} multiple={false} />
                                        <p>{fotoProfil ? `File name: ${fotoProfil.name}` : "no files uploaded yet"}</p>
                                    </div>

                                    <div className='mt-2'>
                                        <p className=" text-slate-500 text-xs mb-1">Tambah Lokasi Peta</p>

                                        <MapContainer
                                            center={lokasi}
                                            zoom={13}
                                            scrollWheelZoom={true}
                                            className="h-72 z-0">
                                            <TileLayer
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            />
                                            <Markers />
                                        </MapContainer>
                                    </div>

                                    <div >
                                        <button type="submit" className='p-2 bg-blue-500 text-white w-full mt-4 rounded-lg'>Edit</button>
                                    </div>



                                </form>

                            </div>
                        </Tab.Panel>
                        <Tab.Panel>
                            <AlamatKirim />
                        </Tab.Panel>

                    </Tab.Panels>

                </Tab.Group>
            </div>


        </div>
    )
}

const AlamatKirim = () => {
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const { data, isFetching, error } = useGetListAlamatKirimQuery(id);
    const [addAlamatForm, setAddAlamatForm] = useState(false);
    const ShowAlamatModal = (e) => {
        if (addAlamatForm) {
            setAddAlamatForm(false);
        } else {
            setAddAlamatForm(true);
        }
    }

    const col = [
        {
            name: 'Label',
            selector: row => row.nama,
            sortable: true,
        },
        {
            name: 'Nama Penerima',
            selector: row => row.nama_penerima,
            sortable: true,
        },
        {
            name: 'Alamat',
            selector: row => row.alamat,
            sortable: true,
        },
        {
            name: 'Nomor Telepon',
            selector: row => row.nomor_telp_penerima,
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idKP = record.id;
                return (
                    <Fragment>
                        {/* <EditUkuran data={record} /> */}

                        <span className='mx-3'>|</span>

                        <button
                            type="submit"
                            // onClick={(e) => deleteData(e, idKP)}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]
    return (
        <>
            {addAlamatForm && <AddAlamat alamatModal={ShowAlamatModal} />}

            <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Alamat Pengiriman</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Alamat yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                    <button
                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => ShowAlamatModal()}
                    >
                        Tambah Data
                    </button>

                </div>
                <div className='col-span-3' />

            </div>
            {data ? (
                <DataTable columns={col} data={data} pagination />
            ) : isFetching ? (
                <p>loading</p>
            ) : (
                <p>{error ? error : "Data kosong"}</p>
            )}
        </>
    )
}


const AddAlamat = ({ alamatModal, idC }) => {
    const [nama, setNama] = useState('');
    const [alamat, setAlamat] = useState('');
    const [nama_penerima, setNama_penerima] = useState('');
    const [nomor_telp_penerima, setNomor_telp_penerima] = useState('');
    const [lokasi, setLokasi] = useState([0.5336, 101.4474]);
    const [query, setQuery] = useState('')
    const getListKelurahanResult = useGetDropdownListKelurahanQuery(query).data
    const [selected, setSelected] = useState('')
    const refetch = useKeranjangCheckoutQuery(idC)

    const [addAlamat, addAlamatResult] = useAddAlamatKirimMutation();
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const Markers = () => {

        const map = useMapEvents({
            click(e) {
                setLokasi([
                    e.latlng.lat,
                    e.latlng.lng
                ]);
            },
        })

        return (
            lokasi ?
                <Marker
                    key={lokasi[0]}
                    position={lokasi}
                    draggable={true}
                />
                : null
        )

    }

    const handleSubmit = (event) => {
        event.preventDefault();

        addAlamat({ nama: nama, alamat: alamat, nama_penerima: nama_penerima, nomor_telp_penerima: nomor_telp_penerima, x: lokasi[0], y: lokasi[1], status: 'Aktif', lokasi_kelurahan_id: selected.id, retail_id: id }).unwrap().then(() => {
            if (idC) {
                window.location.reload();
            }
        });

        alamatModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                alamatModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const filteredKelurahan =
        getListKelurahanResult ? (
            query === ''
                ? getListKelurahanResult
                : getListKelurahanResult.filter((kelurahan) =>
                    kelurahan.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )) : [];
    return (
        <div>
            <div
                className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[5000] outline-none focus:outline-none"
            >
                <div className="relative h-full mx-auto max-w-3xl">
                    <form onSubmit={handleSubmit} className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Tambah Alamat Pengiriman
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => alamatModal(false)}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <div className='grid grid-cols-2 gap-4'>
                                <div className="relative z-0 mt-5">
                                    <input
                                        type="text"
                                        placeholder=' '
                                        required
                                        value={nama}
                                        onChange={(event) => setNama(event.target.value)}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                    <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                        Label Lokasi
                                    </label>
                                </div>
                                <div className="relative z-0 mt-5">
                                    <input
                                        type="text"
                                        placeholder=' '
                                        required
                                        value={nama_penerima}
                                        onChange={(event) => setNama_penerima(event.target.value)}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                    <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                        Nama Penerima
                                    </label>
                                </div>
                            </div>

                            <div className="relative z-0 mt-4">
                                <textarea rows="" cols=""
                                    value={alamat}
                                    required
                                    onChange={(event) => setAlamat(event.target.value)}
                                    placeholder=' '
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer">
                                </textarea>
                                <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Alamat
                                </label>
                            </div>
                            <div className='grid grid-cols-2 gap-4'>
                                <div className="relative z-0 mt-5">
                                    <input
                                        type="text"
                                        required
                                        placeholder=' '
                                        pattern="^62\d*"
                                        title="Masukkan nomor HP dengan format angka yang diawali dengan 628"
                                        value={nomor_telp_penerima}
                                        onChange={(event) => setNomor_telp_penerima(event.target.value)}
                                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                    <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                        Nomor Telepon Penerima
                                    </label>
                                </div>
                                <div>
                                    <p className=" text-slate-500 text-xs mt-2">Kelurahan</p>

                                    <Combobox value={selected} onChange={setSelected}>

                                        <div className="relative mt-1">
                                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                <Combobox.Input
                                                    required
                                                    placeholder='Input Kelurahan'
                                                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                    displayValue={(kelurahan) => kelurahan.nama}
                                                    onChange={(event) => setQuery(event.target.value)}
                                                />
                                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <MdUnfoldMore />

                                                </Combobox.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                afterLeave={() => setQuery('')}
                                            >
                                                <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {filteredKelurahan.length === 0 && query !== '' ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        filteredKelurahan.map((kelurahan) => (
                                                            <Combobox.Option
                                                                key={kelurahan.id}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                    }`
                                                                }
                                                                value={kelurahan}
                                                            >
                                                                {({ selected, active }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block text-xs ${selected ? 'font-medium' : 'font-normal'
                                                                                }`}
                                                                        >
                                                                            Kelurahan {kelurahan.nama}, Kecamatan {kelurahan.lokasi_kecamatan.nama}, {kelurahan.lokasi_kecamatan.lokasi_kotakab.nama}
                                                                        </span>
                                                                        {selected ? (
                                                                            <span
                                                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                    }`}
                                                                            >
                                                                                <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </Combobox.Options>

                                            </Transition>
                                        </div>
                                    </Combobox>
                                </div>
                            </div>

                            <div className='mt-4'>
                                <p className=" text-slate-500 text-xs mt-5 mb-1">Tambah Lokasi Peta</p>

                                <MapContainer
                                    center={lokasi}
                                    zoom={13}
                                    scrollWheelZoom={true}
                                    className="h-72 z-0">
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Markers />
                                </MapContainer>
                            </div>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="submit"
                            >
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


export { Profile, AddAlamat }