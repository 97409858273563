import { Disclosure, Tab } from '@headlessui/react'
import React, { useState } from 'react'
import { useGetListPenjualanRetailQuery } from '../../store/apis/penjualanApi'
import secureLocalStorage from 'react-secure-storage'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import { useGetListReturProdukRetailQuery } from '../../store/apis/returProdukApi'
import { FormRetur } from '../penjualan/ReturProduk'
import ContactPopup from '../../components/ContactPopup'
import { alamat_storage } from '../../components/api'

const TransactionRetail = () => {
    const [status, setStatus] = useState('Dipesan')
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'));
    const id = userData ? userData.id : null;
    const { data } = useGetListPenjualanRetailQuery({ id: id, status: status })
    const [returStatus, setReturStatus] = useState('');
    const retur = useGetListReturProdukRetailQuery({ id: id, status: returStatus });
    const [returForm, setReturForm] = useState(false);
    const [dataId, setDataId] = useState('');
    const [tel, setTel] = useState('');
    const [contactPopup, setcontactPopup] = useState(false);

    const HandleContact = (tel) => {
        if (contactPopup) {
            setTel('');
            setcontactPopup(false);
        } else {
            setTel(tel);
            setcontactPopup(true);
        }
    }

    const HandleSubmit = (id) => {
        if (returForm) {
            setDataId('');
            setReturForm(false)
        } else {
            setDataId(id);
            setReturForm(true);
        }
    }

    const handleChange = (index) => {
        switch (index) {
            case 0:
                setStatus('Dipesan')
                break;
            case 1:
                setStatus('Diterima')
                break;
            case 2:
                setStatus('Dikirim')
                break;
            case 3:
                setStatus('Selesai')
                break;
            case 4:
                setStatus('Retur')
                break;

            default:
                break;
        }
    }

    const handleReturChange = (index) => {
        switch (index) {
            case 0:
                setReturStatus('')
                break;
            case 1:
                setReturStatus('Diajukan')
                break;
            case 2:
                setReturStatus('Diterima')
                break;
            case 3:
                setReturStatus('Selesai')
                break;
            case 4:
                setReturStatus('Ditolak')
                break;
            default:
                break;
        }
    }


    return (
        <div className='w-full '>
            <div className='w-full bg-white p-5 shadow-md'>
                {contactPopup && <ContactPopup tel={tel} contactModal={HandleContact} />}


                <Tab.Group onChange={(index) => handleChange(index)}>

                    <Tab.List>

                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Dipesan</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Diterima</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Dikirim</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Selesai</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Retur Produk</div>
                            )}
                        </Tab>

                    </Tab.List>
                    {status !== 'Retur' ? (
                        <Tab.Panels className={`mt-5`}>
                            {data && data.length > 0 ? data.map((item) => (
                                <div key={item.id}>
                                    {returForm && <FormRetur returModal={HandleSubmit} id={dataId} success={null} />}

                                    <Disclosure as="div" defaultOpen="true">
                                        {({ open }) => (
                                            <>
                                                <div className="w-full block  md:flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">


                                                    <div>
                                                        <Disclosure.Button className="text-base font-bold mr-4">
                                                            {item.distributor.nama} ({item.penjualan.tanggal_transaksi})
                                                        </Disclosure.Button>
                                                        <div>
                                                            <NavLink to={'/PembayaranRetail'} className='text-blue-500'>Lihat Status Pembayaran</NavLink>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>

                                                            <Disclosure.Button >
                                                                <div className='flex items-center'>

                                                                    <p>{item.penjualan.nomor_invoice}</p>

                                                                    <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                                </div>
                                                            </Disclosure.Button>
                                                        </div>
                                                        {status === 'Selesai' &&
                                                            <div className='flex w-full place-content-end p-2'>
                                                                <button onClick={() => HandleSubmit(item.id)} type="btn" className='rounded bg-blue-500 text-white px-2 py-1'>Ajukan Retur</button>

                                                            </div>
                                                        }
                                                    </div>


                                                </div>


                                                <Disclosure.Panel>
                                                    {item.penjualan_distributor_produks.map((detail) => (
                                                        <div key={detail.id} className='p-5 h-32 block md:flex justify-between  items-center border-b'>

                                                            <div className='grid grid-cols-2 md:flex space-x-2 items-center h-32 '>
                                                                <img alt='foto produk' src={`${alamat_storage}fotoProduk/${detail.produk_varian.produk_fotos[0].path}`} className='h-3/5 md:h-4/5 object-cover' />
                                                                <div>
                                                                    <p className='truncate'> {detail.produk_varian.nama}</p>
                                                                    <p className='text-xs text-slate-500'>x{detail.jumlah}</p>
                                                                    <p className='text-left'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(detail.harga)}</p>

                                                                </div>
                                                            </div>
                                                            <p className='hidden md:block'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(detail.harga * detail.jumlah)}</p>
                                                        </div>
                                                    ))}
                                                    <div className='p-5 h-32 border-b bg-blue-100 grid grid-cols-1 content-beetween mt-4 md:mt-0'>
                                                        <div className='flex justify-between text-center items-center'>
                                                            <div >
                                                            </div>
                                                            <p className='text-lg text-blue-500'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.penjualan_distributor_produks.reduce((sum, item) => sum + item.jumlah * item.harga, 0))}</p>
                                                        </div>
                                                        <div className='flex justify-between text-center items-center bottom-0'>
                                                            <div >
                                                            </div>
                                                            <button onClick={() => HandleContact(item.distributor.nomor_telp_cp)} className='p-2 bg-blue-500 text-white mt-4'>Hubungi Penjual</button>
                                                        </div>


                                                    </div>

                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure >
                                </div>
                            )) : (<>Tidak Ada Pesanan</>)}

                        </Tab.Panels>
                    ) : (
                        <div className='p-4'>
                            <Tab.Group onChange={(index) => handleReturChange(index)}>

                                <Tab.List className='bg-blue-100 rounded-t-lg'>
                                    <Tab>
                                        {({ selected }) => (
                                            <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                                Semua
                                            </div>
                                        )}
                                    </Tab>
                                    <Tab>
                                        {({ selected }) => (
                                            <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                                Diajukan
                                            </div>
                                        )}
                                    </Tab>
                                    <Tab>
                                        {({ selected }) => (
                                            <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                                Diterima
                                            </div>
                                        )}
                                    </Tab>
                                    <Tab>
                                        {({ selected }) => (
                                            <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                                Selesai
                                            </div>
                                        )}
                                    </Tab><Tab>
                                        {({ selected }) => (
                                            <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                                Ditolak
                                            </div>
                                        )}
                                    </Tab>
                                </Tab.List>

                                <Tab.Panels className={`mt-5 px-5`}>
                                    {retur.data && (
                                        <>
                                            {retur.data.map((item) => (
                                                <div key={item.id}>
                                                    <Disclosure as="div" defaultOpen="true">
                                                        {({ open }) => (
                                                            <>
                                                                <div className="w-full  flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">


                                                                    <div>
                                                                        <Disclosure.Button className="text-base font-bold mr-4">
                                                                            {item.distributor.nama}
                                                                        </Disclosure.Button>
                                                                        <div>
                                                                            <NavLink to={'/PembayaranRetail'} className='text-blue-500'>Lihat Status Pembayaran</NavLink>
                                                                        </div>
                                                                    </div>
                                                                    <Disclosure.Button >
                                                                        <div className='flex items-center'>

                                                                            <p>{item.penjualan.nomor_invoice}</p>

                                                                            <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                                        </div>
                                                                    </Disclosure.Button>

                                                                </div>


                                                                <Disclosure.Panel>
                                                                    {item.returs.map((detail) => (
                                                                        <div key={detail.id} className='p-5 h-32 flex justify-between text-center items-center border-b'>

                                                                            <div className='flex space-x-2 items-center h-32 '>
                                                                                <img alt='foto produk' src={`${alamat_storage}fotoProduk/${detail.retur_produks[0].produk_varian.produk_fotos[0].path}`} className='h-4/5' />
                                                                                <div>
                                                                                    <span>{detail.retur_produks[0].produk_varian.nama}</span>
                                                                                    <span className='text-xs text-slate-500'>x{detail.retur_produks[0].jumlah}</span>
                                                                                    <p className='text-left'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(detail.retur_produks[0].nominal)}</p>

                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <p className='text-blue-500 text-right'>{detail.status}</p>
                                                                                <p className='text-right'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(detail.retur_produks[0].nominal * detail.retur_produks[0].jumlah)}</p>

                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                    <div className='mt-4 flex justify-between text-center items-center'>
                                                                        <div >
                                                                        </div>
                                                                        <button onClick={() => HandleContact(item.distributor.nomor_telp_cp)} className='p-2 bg-blue-500 text-white'>Hubungi Penjual</button>
                                                                    </div>

                                                                </Disclosure.Panel>
                                                            </>
                                                        )}
                                                    </Disclosure >
                                                </div>
                                            ))}
                                        </>)}
                                </Tab.Panels>

                            </Tab.Group>
                        </div>
                    )}

                </Tab.Group>
            </div>


        </div >
    )
}

export default TransactionRetail
