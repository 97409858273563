import { Combobox, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { MdCheck, MdUnfoldMore } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { addJabatan, getListJabatan } from '../../actions/jabatanAction';
import { addPegawai, getListPegawai } from '../../actions/pegawaiAction';
import { addPegawaiJabatan, getListPegawaiJabatan } from '../../actions/pegawaiJabatan';
import { format } from 'date-fns'
import { getUserData } from '../../actions/auth';
import secureLocalStorage from 'react-secure-storage';
import { useAddPegawaiMutation, useGetListPegawaiQuery } from '../../store/apis/pegawaiApi';
import { useAddJabatanMutation, useGetListJabatanQuery } from '../../store/apis/jabatanApi';
import { useAddPegawaiJabatanMutation } from '../../store/apis/pegawaiJabatanApi';
import { useAddPegawaiDodolanMutation } from '../../store/apis/pegawaiDodolanApi';


const AddPegawaiDodolan = () => {
    const [showModal, setShowModal] = useState(false);
    const nama = useRef();
    const nip = useRef();
    const nomor_hp = useRef();
    const [addPegawai, addPegawaiResult] = useAddPegawaiDodolanMutation()

    const handleSubmit = (event) => {
        event.preventDefault();

        addPegawai({ nama: nama.current.value, nip: nip.current.value, nomor_hp: nomor_hp.current.value, status: 'Aktif' })
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    return (
        <div>
            <button
                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Tambah Data
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <form onSubmit={handleSubmit} className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Tambah Pegawai
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            required
                                            placeholder=' '
                                            ref={nama}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label  className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Nama Pegawai
                                        </label>
                                    </div>
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="tel"
                                            required
                                            placeholder=' '
                                            ref={nomor_hp}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label  className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            No HP Pegawai
                                        </label>
                                    </div>
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            required
                                            placeholder=' '
                                            ref={nip}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label  className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            NIP Pegawai
                                        </label>
                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="submit"
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

const AddPegawai = () => {
    const [showModal, setShowModal] = useState(false);
    const nama = useRef();
    const nip = useRef();
    const nomor_hp = useRef();
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const [addPegawai, addPegawaiResult] = useAddPegawaiMutation()



    const handleSubmit = (event) => {
        event.preventDefault();

        addPegawai({ nama: nama.current.value, nip: nip.current.value, nomor_hp: nomor_hp.current.value, status: 'Aktif', distributor_id: id })
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    return (
        <div>
            <button
                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Tambah Data
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <form onSubmit={handleSubmit} className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Tambah Pegawai
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            placeholder=' '
                                            ref={nama}
                                            required
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label  className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Nama Pegawai
                                        </label>
                                    </div>
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            placeholder=' '
                                            ref={nomor_hp}
                                            required
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label  className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            No HP Pegawai
                                        </label>
                                    </div>
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            placeholder=' '
                                            ref={nip}
                                            required
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label  className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            NIP Pegawai
                                        </label>
                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="submit"
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

const AddJabatan = () => {
    const [showModal, setShowModal] = useState(false);
    const nama = useRef();
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;

    const  [addJabatan, addJabatanResult]  = useAddJabatanMutation();


    const handleSubmit = (event) => {
        event.preventDefault();

        addJabatan({ nama: nama.current.value, status: 'Aktif', distributor_id: id })
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    return (
        <div>
            <button
                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Tambah Data
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <form onSubmit={handleSubmit} className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Tambah Jabatan
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="text"
                                            placeholder=' '
                                            ref={nama}
                                            required
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label  className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Nama Jabatan
                                        </label>
                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <input
                                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="submit"
                                        value="Tambah Data"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

const AddPegawaiJabatan = () => {
    const [showModal, setShowModal] = useState(false);
    const tgl = useRef('');
    const [selectedP, setSelectedP] = useState({ 'nama': 'Pilih Pegawai' })
    const [selectedJ, setSelectedJ] = useState({ 'nama': 'Pilih Jabatan' })
    const [query, setQuery] = useState('')
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;

    const getListPegawaiResult = useGetListPegawaiQuery(id).data

    const getListJabatanResult = useGetListJabatanQuery(id).data

    const [addPegawaiJabatan, addPegawaiJabatanResult] = useAddPegawaiJabatanMutation()

    const handleSubmit = (event) => {
        event.preventDefault();
        const date = new Date(tgl.current.value)
        addPegawaiJabatan({ tanggal_mulai: format(date, 'yyyy-MM-dd'), status: '1', pegawai_id: selectedP.id, jabatan_id: selectedJ.id })
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const filteredPegawai =
        query === ''
            ? getListPegawaiResult
            : getListPegawaiResult.filter((pegawai) =>
                pegawai.nama
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    const filteredJabatan =
        query === ''
            ? getListJabatanResult
            : getListJabatanResult.filter((jabatan) =>
                jabatan.nama
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    return (
        <div>
            <button
                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Tambah Data
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <form onSubmit={handleSubmit} className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Tambah Jabatan Pegawai
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0 mt-5">
                                        <input
                                            type="date"
                                            placeholder=' '
                                            required
                                            ref={tgl}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label  className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Tanggal Mulai
                                        </label>
                                    </div>
                                    <p className=" text-slate-500 text-xs mt-5">Pegawai</p>

                                    <Combobox required value={selectedP} onChange={setSelectedP}>

                                        <div className="relative mt-1">
                                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                <Combobox.Input
                                                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                    displayValue={(pegawai) => pegawai.nama}
                                                    onChange={(event) => setQuery(event.target.value)}
                                                />
                                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <MdUnfoldMore />

                                                </Combobox.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                afterLeave={() => setQuery('')}
                                            >
                                                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {filteredPegawai.length === 0 && query !== '' ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        filteredPegawai.map((pegawai) => (
                                                            <Combobox.Option
                                                                key={pegawai.id}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                    }`
                                                                }
                                                                value={pegawai}
                                                            >
                                                                {({ selectedP, active }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block truncate ${selectedP ? 'font-medium' : 'font-normal'
                                                                                }`}
                                                                        >
                                                                            {pegawai.nama}
                                                                        </span>
                                                                        {selectedP ? (
                                                                            <span
                                                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                    }`}
                                                                            >
                                                                                <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </Combobox.Options>

                                            </Transition>
                                        </div>
                                    </Combobox>

                                    <p className=" text-slate-500 text-xs mt-5">Jabatan</p>

                                    <Combobox required value={selectedJ} onChange={setSelectedJ}>

                                        <div className="relative mt-1">
                                            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                <Combobox.Input
                                                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                    displayValue={(jabatan) => jabatan.nama}
                                                    onChange={(event) => setQuery(event.target.value)}
                                                />
                                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <MdUnfoldMore />

                                                </Combobox.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                afterLeave={() => setQuery('')}
                                            >
                                                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {filteredJabatan.length === 0 && query !== '' ? (
                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                            Nothing found.
                                                        </div>
                                                    ) : (
                                                        filteredJabatan.map((jabatan) => (
                                                            <Combobox.Option
                                                                key={jabatan.id}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                    }`
                                                                }
                                                                value={jabatan}
                                                            >
                                                                {({ selectedP, active }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block truncate ${selectedP ? 'font-medium' : 'font-normal'
                                                                                }`}
                                                                        >
                                                                            {jabatan.nama}
                                                                        </span>
                                                                        {selectedP ? (
                                                                            <span
                                                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                    }`}
                                                                            >
                                                                                <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ))
                                                    )}
                                                </Combobox.Options>

                                            </Transition>
                                        </div>
                                    </Combobox>
                                </div>

                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="submit"
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}



export { AddPegawaiDodolan, AddJabatan, AddPegawai, AddPegawaiJabatan }