import axios from "axios";
import {alamat_backend} from "../components/api";
import secureLocalStorage from "react-secure-storage";


export const GET_LIST_RETAIL = "GET_LIST_RETAIL"
export const GET_DETAIL_RETAIL = "GET_DETAIL_RETAIL"
export const ADD_RETAIL = "ADD_RETAIL"

const setUserData = (data) => {
    secureLocalStorage.setItem("distRetData", JSON.stringify(data));
};


const getListRetail = () => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_LIST_RETAIL,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'Retail',
            timeout: 120000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_LIST_RETAIL,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_LIST_RETAIL,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const getDetailRetail = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_DETAIL_RETAIL,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'Retail/' + id,
            timeout: 120000
        })
            .then((Response) => {
                //berhasil get api
                setUserData(Response.data);
                dispatch({
                    type: GET_DETAIL_RETAIL,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_DETAIL_RETAIL,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const addRetail = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: ADD_RETAIL,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'Retail',
            timeout: 120000,
            data: data,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: ADD_RETAIL,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: ADD_RETAIL,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}


export { getListRetail, addRetail, getDetailRetail }