import React from 'react'
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getUser } from '../actions/auth';
import { getDetailDistributor } from '../actions/distributorAction';
import { getDetailRetail } from '../actions/retailAction';
import UserMessage from '../Pages/UserMessage';

const UserRedirect = () => {
    const dispatch = useDispatch();
    const userData = getUser();

    return (
        userData.user_role === "Admin" ?
            <Navigate to="/adminHome" />
            : userData.user_role === "Distributor" && userData[0].status === 'Aktif' ?
                <Navigate to="/DistributorHome" />
                : userData.user_role === "Distributor" && userData[0].status !== 'Aktif' ?
                    <UserMessage role={userData.user_role} status={userData[0].status} nama={userData[0].nama} />
                    : userData.user_role === "Retail" ?
                        <Navigate to="/" />
                        : <Navigate to="/login" />




    )
}

export default UserRedirect