import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const produkStokDetailApi = createApi({
    reducerPath: 'produkStokDetail',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getStokDetailVarian: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'StokDetail' }];

                },
                query: (id) => {
                    return {
                        url: 'ProdukStokDetailListVarian',
                        method: 'GET',
                        params: { id: id },
                    };
                },
            }),
            getStokDetailStok: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'StokDetail' }];

                },
                query: (id) => {
                    return {
                        url: 'ProdukStokDetailListStok',
                        method: 'GET',
                        params: { id : id },
                    };
                },
            }),
            getStokDetailSum: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'StokDetail' }];

                },
                query: (id) => {
                    return {
                        url: 'ProdukStokDetailListSum',
                        method: 'GET',
                        params: { id : id },
                    };
                },
            }),
            addStokDetail: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'StokDetail' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'ProdukStokDetail',
                        body: data,
                    };
                },
            }),
            updateStokDetail: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'StokDetail' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `ProdukStokDetail/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteStokDetail: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'StokDetail' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `ProdukStokDetail/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetStokDetailStokQuery,useGetStokDetailVarianQuery, useGetStokDetailSumQuery, useAddStokDetailMutation, useUpdateStokDetailMutation, useDeleteStokDetailMutation } = produkStokDetailApi;
export { produkStokDetailApi };