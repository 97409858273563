import React, { Fragment, useEffect, useState } from 'react'
import secureLocalStorage from 'react-secure-storage';
import { useGetDropdownListKelurahanQuery } from '../../store/apis/lokasiKelurahanApi';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import { useGetDistributorDetailQuery, useUpdateDistributorMutation } from '../../store/apis/distributorApi';
import { Combobox, Tab, Transition } from '@headlessui/react';
import { alamat_storage } from '../../components/api';
import { FileUploader } from 'react-drag-drop-files';
import { MdCheck, MdUnfoldMore } from 'react-icons/md';

const DistributorProfile = () => {
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'));
    const id = userData ? userData.id : null;
    const { data, isFetching, error } = useGetDistributorDetailQuery(id);

    const [alamat, setAlamat] = useState('');
    const [nomor_telp, setNomor_telp] = useState( '');
    const [nomor_telp_pemilik, setNomor_telp_pemilik] = useState( '');
    const [deskripsi, setDeskripsi] = useState('');
    const [lokasi, setLokasi] = useState([0.5336, 101.4474]);
    const [query, setQuery] = useState('')
    const getListKelurahanResult = useGetDropdownListKelurahanQuery(query).data
    const [selectedKelurahan, setSelectedKelurahan] = useState('')
    const [fotoProfil, setFotoProfil] = useState('');
    const [updateDistributor, updateDistributorResult] = useUpdateDistributorMutation();
    useEffect(() => {
        if (data) {
            setAlamat(data.alamat);
            setNomor_telp(data.nomor_telp_cp);
            setNomor_telp_pemilik(data.nomor_telp);
            setDeskripsi(data.deskripsi);
            setLokasi([data.x, data.y]);
            setSelectedKelurahan({ id: data.lokasi_kelurahan_id, nama: data.lokasi_kelurahan.nama });
        }

        if (updateDistributorResult.isSuccess) {
            window.alert('Update data berhasil!')
        }
    }, [data, updateDistributorResult.isSuccess])
    const Markers = () => {

        const map = useMapEvents({
            click(e) {
                setLokasi([
                    e.latlng.lat,
                    e.latlng.lng
                ]);
            },
        })

        return (
            lokasi ?
                <Marker
                    key={lokasi[0]}
                    position={lokasi}
                    draggable={true}
                />
                : null
        )

    }

    const handleFotoProfil = (file) => {
        setFotoProfil(file);
    };

    const filteredKelurahan =
        getListKelurahanResult ? (
            query === ''
                ? getListKelurahanResult
                : getListKelurahanResult.filter((kelurahan) =>
                    kelurahan.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )) : []

    const HandleSubmit = (e) => {
        e.preventDefault();
        updateDistributor({ lokasi_kelurahan_id: selectedKelurahan.id, alamat: alamat, nomor_telp_cp: nomor_telp, deskripsi: deskripsi, nomor_telp: nomor_telp_pemilik, x: lokasi[0], y: lokasi[1], foto_profil: fotoProfil, id: id });
    }
    return (
        <div className='w-full '>
            <div className='w-full bg-white p-5 shadow-md'>

                <Tab.Group>
                    <Tab.List>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Informasi Perusahaan</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Pengaturan Alamat</div>
                            )}
                        </Tab>
                    </Tab.List>
                    <Tab.Panels className={`mt-5 px-4`}>
                        <Tab.Panel>
                            <div className='grid grid-cols-2 gap-16'>
                                <div className='w-full overflow-hidden mx-auto'>
                                    {data &&
                                        <img src={`${alamat_storage}/distributorProfil/${data.foto_profil}`} alt="" className=' w-full overflow-hidden' />
                                    }
                                    <div className="relative z-0 mt-4">
                                        <input
                                            type="text"
                                            required
                                            value={data ? data.nama : ''}
                                            readOnly
                                            placeholder=' '
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nama Perusahaan</label>
                                    </div>
                                    <div className='grid grid-cols-2 gap-4 mt-5'>
                                        <div className="relative z-0">
                                            <input
                                                type="text"
                                                required
                                                value={data ? data.user.name : ''}
                                                readOnly
                                                placeholder=' '
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nama Pemilik</label>
                                        </div>
                                        <div className="relative z-0">
                                            <input
                                                type="text"
                                                required
                                                value={data ? data.nomor_KTP : ''}
                                                readOnly
                                                placeholder=' '
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nomor KTP</label>
                                        </div>
                                    </div>

                                </div>
                                <form onSubmit={(e) => HandleSubmit(e)}>
                                    <p className="my-2 text-slate-500 text-lg leading-relaxed text-center font-bold">
                                        -- Edit Informasi --
                                    </p>
                                    <div className='grid grid-cols-2 gap-4'>
                                        <div className="relative z-0 mt-5">
                                            <input
                                                type="text"
                                                required
                                                value={alamat}
                                                onChange={(event) => setAlamat(event.target.value)}
                                                placeholder=' '
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Alamat*</label>
                                        </div>
                                        <div className="relative z-0 mt-5">
                                            <input
                                                type="text"
                                                required
                                                value={nomor_telp}
                                                onChange={(event) => setNomor_telp(event.target.value)}
                                                placeholder=' '
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nomor Telepon*</label>
                                        </div>
                                        <div className="relative z-0 mt-5">
                                            <input
                                                type="text"
                                                required
                                                value={nomor_telp_pemilik}
                                                onChange={(event) => setNomor_telp_pemilik(event.target.value)}
                                                placeholder=' '
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nomor Telepon Pemilik*</label>
                                        </div>

                                        <div>
                                            <p className=" text-slate-500 text-xs mt-2">Kelurahan*</p>

                                            <Combobox required value={selectedKelurahan} onChange={setSelectedKelurahan}>

                                                <div className="relative mt-1">
                                                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                                        <Combobox.Input
                                                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                            displayValue={(kelurahan) => kelurahan.nama}
                                                            onChange={(event) => setQuery(event.target.value)}
                                                        />
                                                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                            <MdUnfoldMore />

                                                        </Combobox.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                        afterLeave={() => setQuery('')}
                                                    >
                                                        <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            {filteredKelurahan.length === 0 && query !== '' ? (
                                                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                                    Nothing found.
                                                                </div>
                                                            ) : (
                                                                filteredKelurahan.map((kelurahan) => (
                                                                    <Combobox.Option
                                                                        key={kelurahan.id}
                                                                        className={({ active }) =>
                                                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                                            }`
                                                                        }
                                                                        value={kelurahan}
                                                                    >
                                                                        {({ selectedKelurahan, active }) => (
                                                                            <>
                                                                                <span
                                                                                    className={`block text-xs  ${selectedKelurahan ? 'font-medium' : 'font-normal'
                                                                                        }`}
                                                                                >
                                                                                    Kelurahan {kelurahan.nama}, Kecamatan {kelurahan.lokasi_kecamatan.nama}, {kelurahan.lokasi_kecamatan.lokasi_kotakab.nama}
                                                                                </span>
                                                                                {selectedKelurahan ? (
                                                                                    <span
                                                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                            }`}
                                                                                    >
                                                                                        <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                                    </span>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                    </Combobox.Option>
                                                                ))
                                                            )}
                                                        </Combobox.Options>

                                                    </Transition>
                                                </div>
                                            </Combobox>
                                        </div>
                                    </div>

                                    <div className="relative z-0 mt-5">
                                        <textarea
                                            type="text"
                                            required
                                            value={deskripsi}
                                            onChange={(event) => setDeskripsi(event.target.value)}
                                            placeholder=' '
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Deskripsi*</label>
                                    </div>
                                    <div>
                                        <p className=" text-slate-500 text-xs mt-5 mb-1">Foto Profil Perusahaan (kosongkan jika tidak ingin merubah)</p>
                                        <FileUploader label={"Tambahkan atau Letakkan foto Disini"} handleChange={handleFotoProfil} name="file" types={["JPG", "PNG"]} multiple={false} />
                                        <p>{fotoProfil ? `File name: ${fotoProfil.name}` : "no files uploaded yet"}</p>
                                    </div>

                                    <div className='mt-2'>
                                        <p className=" text-slate-500 text-xs mb-1">Tambah Lokasi Peta</p>

                                        <MapContainer
                                            center={lokasi}
                                            zoom={13}
                                            scrollWheelZoom={true}
                                            className="h-72 z-0">
                                            <TileLayer
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            />
                                            <Markers />
                                        </MapContainer>
                                    </div>

                                    <div >
                                        <button type="submit" className='p-2 bg-blue-500 text-white w-full mt-4 rounded-lg'>Edit</button>
                                    </div>



                                </form>

                            </div>
                        </Tab.Panel>
                        <Tab.Panel>
                        </Tab.Panel>

                    </Tab.Panels>

                </Tab.Group>
            </div>


        </div>
    )
}

export default DistributorProfile