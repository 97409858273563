import React, { Fragment, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../actions/auth';
import { getListJabatan } from '../../actions/jabatanAction';
import { getListPegawai } from '../../actions/pegawaiAction';
import { deletePegawaiJabatan, getListPegawaiJabatan } from '../../actions/pegawaiJabatan';
import { AddPegawaiJabatan } from './AddPegawai';
import { EditPegawaiJabatan } from './EditData';
import secureLocalStorage from 'react-secure-storage';
import { useDeletePegawaiJabatanMutation, useGetListPegawaiJabatanQuery } from '../../store/apis/pegawaiJabatanApi';
import { format } from 'date-fns';

const ManagePegawaiJabatan = () => {
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const {data, isFetching, error} = useGetListPegawaiJabatanQuery(id)
    const [deletePegawaiJabatan, deletePegawaiJabatanResult] = useDeletePegawaiJabatanMutation();

    const colPegawaiJabatan = [
        {
            name: 'Nama',
            selector: row => row.nama_pegawai,
            sortable: true,
        },
        {
            name: 'Jabatan',
            selector: row => row.jabatan,
            sortable: true,
        },
        {
            name: 'Tanggal Mulai',
            selector: row => format(new Date(row.tanggal_mulai),'dd/MM/yyyy'),
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idProv = record.id;
                return (
                    <Fragment>
                        <EditPegawaiJabatan data={record} />

                        <span className='mx-3'>|</span>

                        <a
                            type="submit"
                            onClick={(e) => deleteData(e, idProv)}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </a>
                    </Fragment>
                );
            },
        },
    ]

    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
                deletePegawaiJabatan(id)
        }
    }

    return (
        <div className='w-full p-5 '>
            <div className='w-full bg-white border p-5 rounded-lg '>

                <div className='grid grid-cols-4 mb-5'>
                    <div className='col-span-3 self-center'>
                        <h1 className='text-base font-bold text-blue-500 '>Data Jabatan Pegawai</h1>
                        <p className='text-slate-400'>Di bawah ini merupakan data semua Jabatan Pegawai yang telah terdaftar.</p>
                    </div>
                    <div className='place-self-end'>
                        <AddPegawaiJabatan />
                    </div>
                </div>
                {data ? (
                    <DataTable columns={colPegawaiJabatan} data={data} pagination />
                ) : isFetching ? (
                    <p>loading</p>
                ) : (
                    <p>{error ? error : "Data kosong"}</p>
                )}
            </div>


        </div>
    )
}

export default ManagePegawaiJabatan