import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const keranjangApi = createApi({
    reducerPath: 'keranjang',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getListKeranjang: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'keranjang' }];

                },
                query: (id) => {
                    return {
                        url: 'KeranjangDistributorProdukList',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            getAllListKeranjang: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'keranjang' }];

                },
                query: (id) => {
                    return {
                        url: 'KeranjangDistributorProdukAllList',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            keranjangCheckout: builder.query({
                query: ({idD, idR}) => {
                    return {
                        url: 'KeranjangCheckout',
                        method: 'GET',
                        params: { idD, idR },
                    };
                },
            }),
            addKeranjang: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'keranjang' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'KeranjangDistributorProduk',
                        body: data,
                    };
                },
            }),
            updateKeranjang: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'keranjang' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `KeranjangDistributorProduk/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteKeranjang: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'keranjang' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `KeranjangDistributorProduk/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetListKeranjangQuery, useGetAllListKeranjangQuery, useKeranjangCheckoutQuery, useAddKeranjangMutation, useUpdateKeranjangMutation, useDeleteKeranjangMutation } = keranjangApi;
export { keranjangApi };