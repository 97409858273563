import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { alamat_backend } from '../../components/api';

const subSubKategoriProdukApi = createApi({
    reducerPath: 'subSubkategoriProduk',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,
    }),
    endpoints(builder) {
        return {
            addSubSubKategoriProduk: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'subSubKategori' }];
                },
                query: (data) => {
                    const formData = new FormData();
                    formData.append('gambar', data.gambar)
                    formData.append('nama', data.nama)
                    formData.append('status', data.status)
                    formData.append('produk_sub_kategori_id', data.produk_sub_kategori_id)
                    return {
                        method: 'POST',
                        url: 'ProdukSubSubKategori',
                        body: formData,
                    };
                },
            }),
            getListSubSubKategoriProduk: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'subSubKategori' }];

                },
                query: () => {
                    return {
                        url: 'ProdukSubSubKategoriList',
                        method: 'GET',
                    };
                },
            }),
            updateSubSubKategoriProduk: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'subSubKategori' }];
                },
                query: (data) => {
                    const formData = new FormData();
                    formData.append('gambar', data.gambar)
                    formData.append('nama', data.nama)
                    formData.append('status', data.status)
                    formData.append('produk_sub_kategori_id', data.produk_sub_kategori_id)
                    formData.append('id', data.id)
                    return {
                        method: 'POST',
                        url: `ProdukSubSubKategoriUpdate`,
                        body: formData,
                    };
                },
            }),
            deleteSubSubKategoriProduk: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'subSubKategori' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `ProdukSubSubKategori/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetListSubSubKategoriProdukQuery, useAddSubSubKategoriProdukMutation, useDeleteSubSubKategoriProdukMutation, useUpdateSubSubKategoriProdukMutation } = subSubKategoriProdukApi;
export { subSubKategoriProdukApi };