import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';
import secureLocalStorage from 'react-secure-storage';

const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,
    }),

    endpoints(builder) {
        return {
            getData: builder.mutation({
                query: (token) => {
                    return {
                        method: 'POST',
                        url: 'profile',
                        body: token,
                    };
                },
                onSuccess: (result) => {
                    secureLocalStorage.setItem('userData', result);
                }
            }),

        }
    }
})

export const { useGetDataMutation } = authApi;
export { authApi };