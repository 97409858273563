import { ADD_PRODUK_BRAND, DELETE_PRODUK_BRAND, GET_LIST_PRODUK_BRAND, UPDATE_PRODUK_BRAND } from "../../actions/produkBrandAction";

const initialState = {
    getListBrandResult: false,
    getListBrandLoading: false,
    getListBrandError: false,

    addBrandResult: false,
    addBrandLoading: false,
    addBrandError: false,

    deleteBrandResult: false,
    deleteBrandLoading: false,
    deleteBrandError: false,

    updateBrandResult: false,
    updateBrandLoading: false,
    updateBrandError: false,
}


const produkBrand = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_PRODUK_BRAND:
            return {
                ...state,
                getListBrandResult: action.payload.data,
                getListBrandLoading: action.payload.loading,
                getListBrandError: action.payload.errorMessage,
            }

        case ADD_PRODUK_BRAND:
            return {
                ...state,
                addBrandResult: action.payload.data,
                addBrandLoading: action.payload.loading,
                addBrandError: action.payload.errorMessage,
            }
        case DELETE_PRODUK_BRAND:
            return {
                ...state,
                deleteBrandResult: action.payload.data,
                deleteBrandLoading: action.payload.loading,
                deleteBrandError: action.payload.errorMessage,
            }
        case UPDATE_PRODUK_BRAND:
            return {
                ...state,
                updateBrandResult: action.payload.data,
                updateBrandLoading: action.payload.loading,
                updateBrandError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default produkBrand;