import React, { Fragment } from 'react'
import DataTable from 'react-data-table-component';
import { MdDelete, MdEdit } from 'react-icons/md';
import { AddJabatan } from './AddPegawai';
import { EditJabatan } from './EditData';
import secureLocalStorage from 'react-secure-storage';
import { useDeleteJabatanMutation, useGetListJabatanQuery } from '../../store/apis/jabatanApi';

const ManageJabatan = () => {
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const {data, isFetching, error} = useGetListJabatanQuery(id);
    const [deleteJabatan, deleteJabatanResult] = useDeleteJabatanMutation();

    const colJabatan = [
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idProv = record.id;
                return (
                    <Fragment>
                        <EditJabatan data={record} />
                        <span className='mx-3'>|</span>

                        <a
                            type="submit"
                            onClick={(e) => deleteData(e, idProv)}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </a>
                    </Fragment>
                );
            },
        },
    ]

    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
                deleteJabatan(id)
        }
    }

    return (
        <div className='w-full p-5 '>
            <div className='w-full bg-white border p-5 rounded-lg '>

                <div className='grid grid-cols-4 mb-5'>
                    <div className='col-span-3 self-center'>
                        <h1 className='text-base font-bold text-blue-500 '>Data Jabatan</h1>
                        <p className='text-slate-400'>Di bawah ini merupakan data semua Jabatan yang telah terdaftar.</p>
                    </div>
                    <div className='place-self-end'>
                        <AddJabatan />
                    </div>
                </div>
                {data ? (
                    <DataTable columns={colJabatan} data={data} pagination />
                ) : isFetching ? (
                    <p>loading</p>
                ) : (
                    <p>{error ? error : "Data kosong"}</p>
                )}
            </div>


        </div>
    )
}

export default ManageJabatan