import axios from "axios";
import {alamat_backend} from "../components/api";
import  secureLocalStorage  from  "react-secure-storage";

export const GET_LIST_DISTRIBUTOR = "GET_LIST_DISTRIBUTOR"
export const GET_DETAIL_DISTRIBUTOR = "GET_DETAIL_DISTRIBUTOR"
export const ADD_DISTRIBUTOR = "ADD_DISTRIBUTOR"



const setUserData = (data) => {
    secureLocalStorage.setItem("distRetData", JSON.stringify(data));
};



const getListDistributor = () => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_LIST_DISTRIBUTOR,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'Distributor',
            timeout: 120000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_LIST_DISTRIBUTOR,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_LIST_DISTRIBUTOR,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const getDetailDistributor = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_DETAIL_DISTRIBUTOR,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'Distributor/' + id,
            timeout: 120000
        })
            .then((Response) => {
                //berhasil get api
                setUserData(Response.data);
                dispatch({
                    type: GET_DETAIL_DISTRIBUTOR,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_DETAIL_DISTRIBUTOR,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const addDistributor = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: ADD_DISTRIBUTOR,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'Distributor',
            timeout: 120000,
            data: data,
            headers: {
                "Content-Type": "multipart/form-data",
            }

        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: ADD_DISTRIBUTOR,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                console.log(error)
                dispatch({
                    type: ADD_DISTRIBUTOR,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.Response.data,
                    }
                })
            })


    }
}


export { getListDistributor, addDistributor, getDetailDistributor }