import { Tab } from '@headlessui/react';
import { format } from 'date-fns';
import React, { Fragment, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { MdDelete } from 'react-icons/md';
import { getUserData } from '../../actions/auth';
import DetailInvoice from './DetailInvoice';
import { DetailRetur, DetailStok } from './DetailStok';
import { EditPabrik, EditStok } from './EditPembelian';
import { TambahPabrik, TambahStok } from './TambahPembelian';
import { useDeletePabrikMutation, useGetListPabrikQuery } from '../../store/apis/pabrikApi';
import { useDeleteStokMutation, useGetStokQuery } from '../../store/apis/produkStokApi';
import { useDeleteStokDetailMutation, useGetStokDetailSumQuery } from '../../store/apis/produkStokDetailApi';
import secureLocalStorage from 'react-secure-storage';


const KelolaPembelian = () => {
    return (
        <div className='w-full  p-5  '>
            <div className='w-full bg-white border pb-5 rounded-lg '>

                <Tab.Group>

                    <Tab.List className='bg-blue-100 rounded-t-lg'>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Kelola Stok
                                </div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Pembelian
                                </div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Kelola Pabrik
                                </div>
                            )}
                        </Tab>
                    </Tab.List>

                    <Tab.Panels className={`mt-5 px-5`}>
                        <Tab.Panel >
                            <Stok />
                        </Tab.Panel>
                        <Tab.Panel >
                            <Pembelian />
                        </Tab.Panel>
                        <Tab.Panel >
                            <Pabrik />
                        </Tab.Panel>


                    </Tab.Panels>

                </Tab.Group>
            </div>


        </div>
    )
}


const Pabrik = () => {
    const [id, setId] = useState(null);
    const [addPabrikForm, setAddPabrikForm] = useState(false);

    const ShowPabrikModal = (e) => {
        if (addPabrikForm) {
            setAddPabrikForm(false);
        } else {
            setAddPabrikForm(true);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const userData = await getUserData();
            setId(userData.id)
        };
        fetchData();
    }, []);
    const { data, isFetching, error } = useGetListPabrikQuery(id);
    const [deletePabrik, deletePabrikResult] = useDeletePabrikMutation();

    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            deletePabrik(id);
        }
    }

    const colPabrik = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            name: 'Kota',
            selector: row => row.kota,
            sortable: true,
        },
        {
            name: 'Negara',
            selector: row => row.negara,
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idKP = record.id;
                return (
                    <Fragment>
                        <EditPabrik data={record} />

                        <span className='mx-3'>|</span>

                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, idKP, "pabrik")}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]

    return (
        <>
            {addPabrikForm && <TambahPabrik pabrikModal={ShowPabrikModal} />}

            <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Pabrik</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Pabrik yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                    <button
                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => ShowPabrikModal()}
                    >
                        Tambah Data
                    </button>

                </div>
                <div className='col-span-3' />


            </div>
            {data ? (
                <DataTable columns={colPabrik} data={data} pagination />
            ) : isFetching ? (
                <p>loading</p>
            ) : (
                <p>{error ? error : "Data kosong"}</p>
            )}
        </>
    )
}

const Pembelian = () => {
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const [addPembelianForm, setAddPembelianForm] = useState(false);

    const { data, isFetching, error } = useGetStokQuery(id);
    const [deletePembelian, deletePembelianResult] = useDeleteStokMutation();

    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            deletePembelian(id);
        }
    }

    const ShowPembelianModal = () => {
        if (addPembelianForm) {
            setAddPembelianForm(false);
        } else {
            setAddPembelianForm(true);
        }
    }

    const colStok = [
        {
            name: 'Nomor Invoice',
            selector: row => row.nomor_invoice,
            sortable: true,
        },
        {
            name: 'Tanggal Transaksi',
            selector: row => format(new Date(row.tanggal_transaksi), 'dd-MM-yyyy'),
            sortable: true,
        },
        {
            name: 'Pabrik',
            selector: row => row.pabrik,
            sortable: true,
        },
        {
            key: "Detail",
            name: "Detail",
            sortable: false,
            cell: (record) => {
                return (
                    <Fragment>
                        <DetailInvoice data={record} />
                    </Fragment>
                );
            },
        },
        {
            key: "Total Harga",
            name: "Total Harga",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.total),

        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idKP = record.id;
                return (
                    <Fragment>
                        <EditStok data={record} />

                        <span className='mx-3'>|</span>

                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, idKP, "pabrik")}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]

    return (
        <>
            {addPembelianForm && <TambahStok pembelianModal={ShowPembelianModal} />}

            <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Produk</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Produk yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                    <button
                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => ShowPembelianModal()}
                    >
                        Tambah Data
                    </button>
                </div>

                <div className='col-span-3' />

            </div>
            {data ? (
                <DataTable columns={colStok} data={data} pagination />
            ) : isFetching ? (
                <p>loading</p>
            ) : (
                <p>{error ? error : "Data kosong"}</p>
            )}
        </>
    )
}

const Stok = () => {
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const [stokModal, setStokModal] = useState(false);
    const [returModal, setReturModal] = useState(false);
    const { data, isFetching, error } = useGetStokDetailSumQuery(id);
    const [modalData, setModalData] = useState('');

    const HandleClick = (jenis, data) => {
        setModalData(data);
        if (jenis === 'stok') {
            if (stokModal) {
                setStokModal(false)
            } else {
                setStokModal(true)
            }
        } else if (jenis === 'retur') {
            if (returModal) {
                setReturModal(false)
            } else {
                setReturModal(true);
            }
        }
    }


    const colStokDS = [
        {
            name: 'Produk',
            selector: row => row.nama,
            sortable: true,
            grow: 2
        },
        {
            key: "Stok",
            name: "Stok",
            sortable: false,
            cell: (record) => {
                return (
                    <button
                        className="bg-blue-500 text-white active:bg-blue-600 uppercase rounded p-2 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:bg-blue-400"
                        type="button"
                        onClick={() => HandleClick('stok', record)}
                    >
                        {record.totalStok ? record.totalStok : 0}
                    </button>
                );
            },
        },
        {
            key: "Retur",
            name: "Retur",
            sortable: false,
            cell: (record) => {
                return (
                    <Fragment>
                        <button
                            className="bg-blue-500 text-white active:bg-blue-600 uppercase rounded p-2 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:bg-blue-400"
                            type="button"
                            onClick={() => HandleClick('retur', record)}
                        >
                            {record.totalRetur ? record.totalRetur : 0}
                        </button>
                    </Fragment>
                );
            },
        },
        {
            key: "Harga Beli",
            name: 'Harga Beli',
            cell: (record) => {
                return (
                    <div>
                        {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(record.minBeli)} - {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(record.maxBeli)}
                    </div>
                );
            },
            sortable: true,
        },
        {
            name: 'Harga Jual',
            cell: (record) => {
                return (
                    <div>
                        {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(record.minJual)} - {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(record.maxJual)}
                    </div>
                );
            },
            sortable: true,
        },
    ]

    return (
        <>
            {stokModal && <DetailStok dataVarian={modalData} stokModal={HandleClick} />}
            {returModal && <DetailRetur dataVarian={modalData} returModal={HandleClick} />}
            <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Produk</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Produk yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                </div>
                <div className='col-span-3' />

            </div>
            {data ? (data.map((item) => (
                <div className='mb-4' key={item.id}>
                    <h1 className='text-lg text-blue-500 '>{item.nama}</h1>
                    <DataTable columns={colStokDS} data={item.produk_varians} />

                </div>
            ))) : isFetching ? (
                <p>loading</p>
            ) : (
                <p>{error ? error : "Data kosong"}</p>
            )}
        </>
    )

}

export default KelolaPembelian