import React, { useState } from 'react'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";



// export const CarouselItem = ({ children, width }) => {
//     return (
//         <div className='carousel-item inline-flex item-center justify-center h-96 bg-blue-500 rounded-2xl text-white'
//             style={{ width: width }}>
//             {children}
//         </div>
//     )
// }


// const Carousel = ({ children }) => {
//     const [activeIndex, setActiveIndex] = useState(0);

//     const updateIndex = (newIndex) => {
//         if (newIndex < 0) {
//             newIndex = React.Children.count(children) - 1;

//         } else if (newIndex >= React.Children.count(children)) {
//             newIndex = 0;
//         }

//         setActiveIndex(newIndex)
//     };

//     return (
//         <div className='carousel overflow-hidden relative'>
//             <div className='inner whitespace-nowrap transition-transform duration-300 z-0'
//                 style={{ transform: `translateX(-${activeIndex * 100}%)` }}>

//                 {React.Children.map(children, (child, index) => {
//                     return React.cloneElement(child, { width: "100%" });
//                 })}

//             </div>
//             <div className='indicators flex justify-center space-x-3 relative z-10 mt-[-30px] mb-3'>

//                 {React.Children.map(children, (child, index) => {
//                     return (
//                         <button
//                             className={`${index === activeIndex ? "bg-blue-500 rounded-full w-2 h-2" : "w-2 h-2 bg-white rounded-full"}`}
//                             onClick={() => {
//                                 updateIndex(index);
//                             }}>

//                         </button>
//                     )
//                 })}
//             </div>
//             <button className="absolute top-0 bottom-0 left-0 ml-3" onClick={() => {
//                 updateIndex(activeIndex - 1);
//             }}>
//                 <MdKeyboardArrowLeft className="text-5xl text-white" />
//             </button>
//             <button className="absolute top-0 bottom-0 right-0 mr-3" onClick={() => { updateIndex(activeIndex + 1); }}>
//                 <MdKeyboardArrowRight className="text-5xl text-white" />
//             </button>
//         </div>
//     )
// }


const CarouselCom = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <>
            <Carousel 
                        responsive={responsive}
                        draggable= {true}
                        swipeable= {true}
                        infinite = { true }
                        autoPlaySpeed = {3000}
                        autoPlay= {true}
                        shouldResetAutoplay = {true}
                        showDots={true}
                        >
                <div className='bg-blue-500 overflow-hidden max-h-[416px] rounded-lg'>
                    <img className='pointer-events-none rounded-lg w-full object-cover' src="images/lake.jpg" alt=""/>
                </div>

                <div className='bg-blue-500 overflow-hidden max-h-[416px] rounded-lg'>
                    <img className='pointer-events-none rounded-lg w-full object-cover' src="images/lake.jpg" alt=""/>
                </div>

                <div className='bg-blue-500 overflow-hidden max-h-[416px] rounded-lg'>
                    <img className='pointer-events-none rounded-lg w-full object-cover' src="images/lake.jpg" alt=""/>
                </div>
                
                
            </Carousel>
        </>
    )
}


export default CarouselCom