import { ADD_JABATAN, DELETE_JABATAN, GET_LIST_JABATAN, UPDATE_JABATAN } from "../../actions/jabatanAction";

const initialState = {
    getListJabatanResult: false,
    getListJabatanLoading: false,
    getListJabatanError: false,

    addJabatanResult: false,
    addJabatanLoading: false,
    addJabatanError: false,

    updateJabatanResult: false,
    updateJabatanLoading: false,
    updateJabatanError: false,

    deleteJabatanResult: false,
    deleteJabatanLoading: false,
    deleteJabatanError: false,
}

const jabatan = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_JABATAN:
            return {
                ...state,
                getListJabatanResult: action.payload.data,
                getListJabatanLoading: action.payload.loading,
                getListJabatanError: action.payload.errorMessage,
            }

        case ADD_JABATAN:
            return {
                ...state,
                addJabatanResult: action.payload.data,
                addJabatanLoading: action.payload.loading,
                addJabatanError: action.payload.errorMessage,
            }

        case UPDATE_JABATAN:
            return {
                ...state,
                updateJabatanResult: action.payload.data,
                updateJabatanLoading: action.payload.loading,
                updateJabatanError: action.payload.errorMessage,
            }
        case DELETE_JABATAN:
            return {
                ...state,
                deleteJabatanResult: action.payload.data,
                deleteJabatanLoading: action.payload.loading,
                deleteJabatanError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default jabatan;