import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { alamat_backend } from '../../components/api';

const distributorApi = createApi({
    reducerPath: 'distributorApi',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getDistributorKota: builder.query({
                query: () => {
                    return {
                        url: 'DistributorCities',
                        method: 'GET',
                    };
                },
            }),
            getListDistributor: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'distributor' }];

                },
                query: (status) => {
                    return {
                        url: 'DistributorList',
                        method: 'GET',
                        params: { status }
                    };
                },
            }),
            getDistributorDetail: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'distributor' }];

                },
                query: (id) => {
                    return {
                        url: 'DistributorDetail',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            getDistributorProduct: builder.query({
                query: (id) => {
                    return {
                        url: 'DistributorProduct',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            updateDistributor: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'distributor' }];
                },
                query: (data) => {
                    const formData = new FormData();
                    formData.append('lokasi_kelurahan_id', data.lokasi_kelurahan_id)
                    formData.append('alamat', data.alamat)
                    formData.append('nomor_telp', data.nomor_telp)
                    formData.append('deskripsi', data.deskripsi)
                    formData.append('nama', data.nama)
                    formData.append('nomor_telp_cp', data.nomor_telp_cp)
                    formData.append('x', data.x)
                    formData.append('y', data.y)
                    formData.append('foto_profil', data.foto_profil)
                    formData.append('id', data.id)
                    return {
                        method: 'POST',
                        url: `DistributorUpdate`,
                        body: formData,
                    };
                },
            }),
            updateDistributorStatus: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'distributor' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `DistributorUpdateStatus`,
                        body: data,
                    };
                },
            }),
        };
    },
});

export const { useGetDistributorKotaQuery, useGetDistributorProductQuery, useGetDistributorDetailQuery, useGetListDistributorQuery, useUpdateDistributorMutation, useUpdateDistributorStatusMutation } = distributorApi;
export { distributorApi };