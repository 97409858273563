import React, { Fragment, useState } from 'react'
import { useAddModalTahunanMutation, useGetLaporanOmsetQuery, useGetLaporanPajakQuery, useGetLaporanQuery } from '../../store/apis/laporanApi'
import DataTable from 'react-data-table-component';
import { format } from 'date-fns';
import secureLocalStorage from 'react-secure-storage';
import { Tab } from '@headlessui/react';
import { useDeleteModalTahunanMutation, useGetListModalTahunanQuery, useGetModalTahunanQuery } from '../../store/apis/modalTahunan';
import { MdDelete } from 'react-icons/md';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { MdAssignment } from 'react-icons/md'
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const LaporanOmset = () => {
    const [year, setYear] = useState(format(new Date(), 'yyyy'));
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const modalTahunan = useGetListModalTahunanQuery(id);
    const { data, isFetching, error, refetch } = useGetLaporanOmsetQuery({ id: id, year: year });
    const [modal, setModal] = useState('');
    let nilaiStok = 0;
    const month = parseInt(format(new Date(), 'MM'), 10);
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Omset dan Laba Kotor Tahun Ini',
            },
        },
    };

    const [addModal, addModalResult] = useAddModalTahunanMutation();
    const [deleteModal, deleteModalResult] = useDeleteModalTahunanMutation()

    const deleteData = (e, id) => {
        e.preventDefault();
        deleteModal(id).unwrap().then(() => {
            refetch();
        });
    }

    const graphData = {
        labels: Array.from({ length: 12 }, (_, index) => (index + 1)),
        datasets: [
            {
                label: 'Omset',
                data: data ? data.penjualan.map((item) => item.nominal) : [],
                borderColor: 'rgb(59, 130, 246)',
                backgroundColor: 'rgb(59, 130, 246, 0.5)',
            },
            {
                label: 'Laba Kotor',
                data: data ? data.penjualan.map((item) => item.nominal * 35 / 100) : [],
                borderColor: 'rgb(239, 68, 68)',
                backgroundColor: 'rgb(239, 68, 68, 0.5)',
            }
        ],
    };

    const colUkuran = [
        {
            name: 'Tahun',
            selector: row => row.tahun,
            sortable: true,
        },
        {
            name: 'Nominal',
            selector: row => Intl.NumberFormat().format(row.nominal),
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                return (
                    <Fragment>
                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, record.id)}
                            className="text-red-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]

    const handleClick = (e) => {
        e.preventDefault();
        secureLocalStorage.setItem('data', data);
        secureLocalStorage.setItem('year', year);
        const newTab = window.open('/cetakLaporanOmset', '_blank');
        if (newTab) {
            newTab.focus();
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addModal({ tahun: year, nominal: modal, distributor_id: id, status: 'Aktif' }).unwrap().then(() => {
            modalTahunan.refetch();
        });
    }
    return (
        <div className='w-full  p-5  '>
            <div className='w-full bg-white border p-5 rounded-lg '>
                <Tab.Group>

                    <Tab.List>

                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Laporan Omset</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Atur Modal Tahunan</div>
                            )}
                        </Tab>

                    </Tab.List>
                    <Tab.Panels className={`mt-5`}>
                        <Tab.Panel>
                            {data && (
                                <>
                                    <p className='text-base text-blue-500 px-2'>Modal Tahunan</p>

                                    <div className="relative z-0 mt-4 w-1/3 px-2">
                                        <input
                                            type="number"
                                            id="floating_standard"
                                            placeholder=' '
                                            value={year}
                                            min={2000}
                                            onChange={(e) => setYear(e.target.value)}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer " />
                                        <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Tahun
                                        </label>
                                    </div>
                                    <div className='mt-6 px-2'>
                                        {year && (
                                            data.modal ? (
                                                <div>
                                                    <div className='flex justify-between'>
                                                        <div>
                                                            Modal Tahun {year}: {Intl.NumberFormat().format(data.modal.nominal)}
                                                        </div>
                                                        <div className='place-self-center'>
                                                            <button onClick={(e) => handleClick(e)} className='px-4 py-2 bg-blue-500 text-white rounded-lg'>Cetak</button>
                                                        </div>
                                                    </div>
                                                    <div className='mt-4'>
                                                        <table className='w-full text-xs'>
                                                            <thead>
                                                                <tr className='text-left border-y border-slate-500'>
                                                                    <th className='p-2 border-x border-slate-500'>Bulan</th>
                                                                    {
                                                                        Array.from({ length: 12 }, (_, index) => (
                                                                            <th key={index} className={`${month === index + 1 ? 'bg-blue-200' : ''} p-2 border-x border-slate-500`}>{index + 1}</th>
                                                                        ))
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className='text-left border-y border-slate-500'>
                                                                    <th className='p-2 border-x border-slate-500'>Visualisasi Stok Barang di gudang</th>
                                                                    {data.pembelian.map((item, index) => {
                                                                        index === 0 ? (
                                                                            data.nilaiStok ?
                                                                                (nilaiStok = data.nilaiStok.total) : (nilaiStok = 0)
                                                                        ) : (
                                                                            nilaiStok = nilaiStok + data.pembelian[index - 1].nominal - (data.penjualan[index - 1].nominal - (data.penjualan[index - 1].nominal * 35 / 100))
                                                                        )
                                                                        return <td className={`${month === index + 1 ? 'bg-blue-200' : ''} border-x p-1 border-slate-500`} >{Intl.NumberFormat().format(nilaiStok)}</td>
                                                                    })
                                                                    }
                                                                </tr>
                                                                <tr className='text-left border-y border-slate-500'>
                                                                    <th className='p-2 border-x border-slate-500'>Belanja Barang</th>
                                                                    {data.pembelian.map((item, index) =>
                                                                        <td className={`${month === index + 1 ? 'bg-blue-200' : ''} border-x p-1 border-slate-500`}>{Intl.NumberFormat().format(item.nominal)}</td>
                                                                    )}
                                                                </tr>
                                                                <tr className='text-left border-y border-slate-500'>
                                                                    <th className='p-2 border-x border-slate-500'>Persentase Belanja Barang</th>
                                                                    {data.pembelian.map((item, index) =>
                                                                        <td className={`${month === index + 1 ? 'bg-blue-200' : ''} border-x p-1 border-slate-500`} >{(item.nominal / data.modal.nominal * 100).toFixed(2)}%</td>)
                                                                    }
                                                                </tr>
                                                                <tr className='text-left border-y border-slate-500'>
                                                                    <th className='p-2 border-x border-slate-500'>Omset</th>
                                                                    {data.penjualan.map((item, index) =>
                                                                        <td className={`${month === index + 1 ? 'bg-blue-200' : ''} border-x p-1 border-slate-500`}>{Intl.NumberFormat().format(item.nominal)}</td>
                                                                    )}
                                                                </tr>
                                                                <tr className='text-left border-y border-slate-500'>
                                                                    <th className='p-2 border-x border-slate-500'>Persentase Omset</th>
                                                                    {data.penjualan.map((item, index) => {
                                                                        index > 0 ? (
                                                                            nilaiStok = nilaiStok + data.pembelian[index - 1].nominal - (data.penjualan[index - 1].nominal - (data.penjualan[index - 1].nominal * 35 / 100))
                                                                        ) : (
                                                                            data.nilaiStok ?
                                                                                (nilaiStok = data.nilaiStok.total) : (nilaiStok = 0)
                                                                        )
                                                                        return <td className={`${month === index + 1 ? 'bg-blue-200' : ''} border-x p-1 border-slate-500`} >{nilaiStok + data.pembelian[index].nominal > 0 ? ((item.nominal / (nilaiStok + data.pembelian[index].nominal)) * 100).toFixed(2) : (0).toFixed(2)}%</td>
                                                                    })
                                                                    }
                                                                </tr>
                                                                <tr className='text-left border-y border-slate-500'>
                                                                    <th className='p-2 border-x border-slate-500'>Laba Kotor</th>
                                                                    {data.penjualan.map((item, index) =>
                                                                        <td className={`${month === index + 1 ? 'bg-blue-200' : ''} border-x p-1 border-slate-500`}>{Intl.NumberFormat().format(item.nominal * 35 / 100)}</td>
                                                                    )}
                                                                </tr>
                                                                <tr className='text-left border-y border-slate-500'>
                                                                    <th className='p-2 border-x border-slate-500'>Omset - Laba Kotor</th>
                                                                    {data.penjualan.map((item, index) =>
                                                                        <td className={`${month === index + 1 ? 'bg-blue-200' : ''} border-x p-1 border-slate-500`}>{Intl.NumberFormat().format(item.nominal - (item.nominal * 35 / 100))}</td>
                                                                    )}
                                                                </tr>
                                                                <tr className='text-left border-y border-slate-500'>
                                                                    <th className='p-2 border-x border-slate-500'>Persentase Omset - Laba Kotor</th>
                                                                    {data.penjualan.map((item, index) => {
                                                                        index > 0 ? (
                                                                            nilaiStok = nilaiStok + data.pembelian[index - 1].nominal - (data.penjualan[index - 1].nominal - (data.penjualan[index - 1].nominal * 35 / 100))
                                                                        ) : (
                                                                            data.nilaiStok ?
                                                                                (nilaiStok = data.nilaiStok.total) : (nilaiStok = 0)
                                                                        )
                                                                        return <td className={`${month === index + 1 ? 'bg-blue-200' : ''} border-x p-1 border-slate-500`} >{nilaiStok + data.pembelian[index].nominal > 0 ? (((item.nominal - (item.nominal * 35 / 100)) / (nilaiStok + data.pembelian[index].nominal)) * 100).toFixed(2) : (0).toFixed(2)}%</td>
                                                                    })
                                                                    }
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            ) :
                                                <div>
                                                    <p>
                                                        Modal tahunan tahun ini belum dimasukkan. SIlahkan masukkan terlebih dahulu
                                                    </p>

                                                    <form className='flex gap-2 mt-4' onSubmit={(e) => handleSubmit(e)}>
                                                        <div className="relative z-0 w-1/3">
                                                            <input
                                                                type="number"
                                                                id="floating_standard"
                                                                placeholder=' '
                                                                value={modal}
                                                                required
                                                                onChange={(e) => setModal(e.target.value)}
                                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                                            <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                                Modal tahun {year}
                                                            </label>
                                                        </div>
                                                        <div className='place-self-center'>
                                                            <button className='p-2 bg-blue-500 text-white rounded-lg' type="submit">Tambah</button>

                                                        </div>
                                                    </form>



                                                </div>
                                        )}
                                        <div className='px-5 pb-10'>
                                            <div className='bg-white border p-5 rounded-lg '>

                                                <Line options={options} data={graphData} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Tab.Panel>
                        <Tab.Panel>
                            <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                                <div className='col-span-3 self-center'>
                                    <h1 className='text-base font-bold text-blue-500 '>Data Modal Tahunan</h1>
                                    <p className='text-slate-400'>Di bawah ini merupakan data semua Modal Tahunan yang telah terdaftar.</p>
                                </div>
                                <div className='col-span-3' />

                            </div>
                            {modalTahunan.data ? (
                                <DataTable columns={colUkuran} data={modalTahunan.data} pagination />
                            ) : modalTahunan.isFetching ? (
                                <p>loading</p>
                            ) : (
                                <p>{modalTahunan.error ? modalTahunan.error : "Data kosong"}</p>
                            )}
                        </Tab.Panel>
                    </Tab.Panels>

                </Tab.Group>

            </div>
        </div>
    )
}

export { LaporanOmset }