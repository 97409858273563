import axios from "axios";
import {alamat_backend} from "../components/api";


export const REGISTER = "REGISTER"



const register = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: REGISTER,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'register',
            timeout: 120000,
            data: data,
            headers: {
                "Content-Type": "multipart/form-data",
            }

        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: REGISTER,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: REGISTER,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error,
                    }
                })
            })


    }
}


export { register }