import { ADD_PROPINSI, DELETE_PROPINSI, GET_DATA_LIST_PROPINSI, GET_LIST_PROPINSI, UPDATE_PROPINSI } from "../../actions/propinsiAction";

const initialState = {
    getListPropinsiResult: false,
    getListPropinsiLoading: false,
    getListPropinsiError: false,

    getDataListPropinsiResult: false,
    getDataListPropinsiLoading: false,
    getDataListPropinsiError: false,

    addPropinsiResult: false,
    addPropinsiLoading: false,
    addPropinsiError: false,

    updatePropinsiResult: false,
    updatePropinsiLoading: false,
    updatePropinsiError: false,

    deletePropinsiResult: false,
    deletePropinsiLoading: false,
    deletePropinsiError: false,
}


const propinsi = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_PROPINSI:
            return {
                ...state,
                getListPropinsiResult: action.payload.data,
                getListPropinsiLoading: action.payload.loading,
                getListPropinsiError: action.payload.errorMessage,
            }
        case GET_DATA_LIST_PROPINSI:
            return {
                ...state,
                getDataListPropinsiResult: action.payload.data,
                getDataListPropinsiLoading: action.payload.loading,
                getDataListPropinsiError: action.payload.errorMessage,
            }
        case ADD_PROPINSI:
            return {
                ...state,
                addPropinsiResult: action.payload.data,
                addPropinsiLoading: action.payload.loading,
                addPropinsiError: action.payload.errorMessage,
            }
        case UPDATE_PROPINSI:
            return {
                ...state,
                updatePropinsiResult: action.payload.data,
                updatePropinsiLoading: action.payload.loading,
                updatePropinsiError: action.payload.errorMessage,
            }
        case DELETE_PROPINSI:
            return {
                ...state,
                deletePropinsiResult: action.payload.data,
                deletePropinsiLoading: action.payload.loading,
                deletePropinsiError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default propinsi;