import React, { Fragment, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux'
import { getUserData } from '../../actions/auth';
import { useDeleteFotoMutation, useGetListFotoQuery, useGetVarianFotoQuery } from '../../store/apis/produkFotoApi';
import { useGetListVarianQuery } from '../../store/apis/produkVarianApi';
import AddProductFoto from './AddProductFoto';
import { alamat_storage } from '../../components/api';

const ManageProdukFoto = () => {
    const [id, setId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const userData = await getUserData();
            setId(userData.id)
        };
        fetchData();
    }, []);
    const { data, error, isFetching } = useGetVarianFotoQuery(id)

    const colFoto = [
        {
            name: 'Nama Produk Varian',
            selector: row => row.nama,
            sortable: true,
        },
        {
            key: "Foto",
            name: "Foto",
            sortable: false,
            cell: (record) => {
                return (
                    <Fragment>
                        <DetailFoto data={record} />
                    </Fragment>
                );
            },
        },
    ]

    return (
        <div className='w-full  p-5  '>
            <div className='w-full bg-white border p-5 rounded-lg '>
                <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                    <div className='col-span-3 self-center'>
                        <h1 className='text-base font-bold text-blue-500 '>Data Produk</h1>
                        <p className='text-slate-400'>Di bawah ini merupakan data semua Produk yang telah terdaftar.</p>
                    </div>
                    <div className='place-self-end'>
                        {/* <AddProduk /> */}
                    </div>
                    <div className='col-span-3' />

                </div>
                {data ? (
                    data.map((item) => {
                        return (
                            <div key={item.id} className='mb-4 border-y'>
                                <h1 className='text-base text-blue-500'>{item.nama}</h1>
                                <DataTable columns={colFoto} data={item.produk_varians} />
                            </div>
                        )
                    })
                ) : isFetching ? (
                    <p>loading</p>
                ) : (
                    <p>{error ? error : "Data kosong"}</p>
                )}
            </div>


        </div>
    )
}


const DetailFoto = ({ data }) => {
    const [showModal, setShowModal] = useState(false);
    const link = alamat_storage+"fotoProduk/"
    const [deleteFoto, results] = useDeleteFotoMutation();

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const handleDeleteFoto = (e, id) => {
        e.preventDefault()

        deleteFoto(id, data.id)
    }


    return (
        <div>
            <button
                className="bg-blue-500 text-white active:bg-blue-600 uppercase rounded p-2 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:bg-blue-400"
                type="button"
                onClick={() => setShowModal(true)}
            >
                {data.produk_fotos.length}
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-11/12 mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Detail Foto Produk {data.nama}
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    {data ? (
                                        <div className='grid grid-cols-4 gap-4'>
                                            {data.produk_fotos.map((data) => {
                                                return (
                                                    <div key={data.id} className='relative'>
                                                        <button className='text-lg absolute right-0 top-0 bg-slate-300 px-2' onClick={(e) => handleDeleteFoto(e, data.id)}>
                                                            x
                                                        </button>
                                                        <img className='max-h-80' src={link.concat(data.path)} />
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    ) : (
                                        <p> "Data kosong"</p>
                                    )}
                                </div>
                                {/*footer*/}
                                <div className="flex gap-2 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <AddProductFoto data={data} />

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

export default ManageProdukFoto
