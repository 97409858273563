import axios from "axios";
import {alamat_backend} from "../components/api";


export const GET_LIST_PROPINSI = "GET_LIST_PROPINSI"
export const GET_DATA_LIST_PROPINSI = "GET_DATA_LIST_PROPINSI"
export const DELETE_PROPINSI = "DELETE_PROPINSI"
export const ADD_PROPINSI = "ADD_PROPINSI"
export const UPDATE_PROPINSI = "UPDATE_PROPINSI"


const getListPropinsi = () => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_LIST_PROPINSI,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'LokasiPropinsi',
            timeout: 120000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_LIST_PROPINSI,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_LIST_PROPINSI,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const getDataListPropinsi = () => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_DATA_LIST_PROPINSI,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'LokasiPropinsiDataList',
            timeout: 120000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_DATA_LIST_PROPINSI,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_DATA_LIST_PROPINSI,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const addPropinsi = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: ADD_PROPINSI,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'LokasiPropinsi',
            timeout: 120000,
            data: data
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: ADD_PROPINSI,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: ADD_PROPINSI,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })
    }
}

const updatePropinsi = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: UPDATE_PROPINSI,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'PUT',
            url: alamat_backend + 'LokasiPropinsi/' + data.id,
            timeout: 120000,
            data: data
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: UPDATE_PROPINSI,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: UPDATE_PROPINSI,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message
                    }
                })
            })
    }
}

const deletePropinsi = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: DELETE_PROPINSI,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'DELETE',
            url: alamat_backend + 'LokasiPropinsi/'+ id,
            timeout: 120000,
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: DELETE_PROPINSI,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: DELETE_PROPINSI,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })
    }
}


export { getListPropinsi, addPropinsi, getDataListPropinsi, deletePropinsi, updatePropinsi }