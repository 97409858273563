import React, { Fragment, useEffect, useState } from 'react'
import { useAddOpsiPengirimanMutation, useDeleteOpsiPengirimanMutation, useGetOpsiPengirimanListQuery, useGetOpsiPengirimanQuery, useUpdateOpsiPengirimanMutation } from '../../store/apis/opsiPengirimanApi'
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { getUserData } from '../../actions/auth';
import { MdDelete, MdEdit } from 'react-icons/md';
import { Tab } from '@headlessui/react';
import secureLocalStorage from 'react-secure-storage';
import CurrencyInput from 'react-currency-input-field';

const OpsiPengiriman = () => {
    const user = useSelector(getUserData);
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;

    const { data, isFetching, error } = useGetOpsiPengirimanListQuery(id);
    const [deleteOpsiPengiriman, deleteOpsiPengirimanResult] = useDeleteOpsiPengirimanMutation();

    const deleteData = (event, id) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            deleteOpsiPengiriman(id)
        }
    }

    const col = [
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            name: 'Harga',
            selector: row => Intl.NumberFormat().format(row.harga),
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idKP = record.id;
                return (
                    <Fragment>
                        <EditOpsiPengiriman data={record} />

                        <span className='mx-3'>|</span>

                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, idKP)}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]
    return (
        <div className='w-full  p-5  '>
            <div className='w-full bg-white border p-5 rounded-lg '>
                <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                    <div className='col-span-3 self-center'>
                        <h1 className='text-base font-bold text-blue-500 '>Data Akun Ekspedisi Pengirman</h1>
                        <p className='text-slate-400'>Di bawah ini merupakan data semua Akun Ekspedisi Pengirman beserta opsi pengirimannya yang telah terdaftar.</p>
                    </div>
                    <div className='place-self-end'>
                        <AddOpsiPengiriman id={id} />
                    </div>

                </div>
                {data ? (
                    <DataTable columns={col} data={data} pagination />
                ) : isFetching ? (
                    <p>loading</p>
                ) : (
                    <p>{error ? error : "Data kosong"}</p>
                )}
            </div>

        </div>
    )
}

const AddOpsiPengiriman = ({ id }) => {
    const [showModal, setShowModal] = useState(false);
    const [nama, setNama] = useState('');
    const [harga, setHarga] = useState('');

    const [addOpsiPengiriman, addOpsiPengirimanResult] = useAddOpsiPengirimanMutation();


    const handleSubmit = (event) => {
        event.preventDefault();

        addOpsiPengiriman({ nama: nama, status: 'Aktif', harga: harga, distributor_id: id })
        setNama('')
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    return (
        <div>
            <button
                className="bg-blue-500 text-white active:bg-blue-600 uppercase text-xs font-bold px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Tambah Opsi Pengiriman
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div
                            onSubmit={(event) => handleSubmit(event)}
                            className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <form className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Tambah Opsi Pengiriman
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0">
                                        <input
                                            type="text"
                                            placeholder=' '
                                            value={nama}
                                            required
                                            onChange={(event) => setNama(event.target.value)}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Nama Pengiriman
                                        </label>
                                    </div>
                                    <div className="relative z-0 mt-5">
                                        <CurrencyInput
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            prefix='Rp'
                                            placeholder=' '
                                            defaultValue={harga}
                                            required
                                            decimalsLimit={2}
                                            onValueChange={(value) => setHarga(value)}
                                        />
                                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Harga Pengiriman
                                        </label>
                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="submit"
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

const EditOpsiPengiriman = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [nama, setNama] = useState(props.data.nama);
    const [harga, setHarga] = useState(props.data.harga);
    const user = useSelector(getUserData);
    const id = props.data.id

    const [updateOpsiPengiriman, updateOpsiPengirimanResult] = useUpdateOpsiPengirimanMutation();


    const handleSubmit = (event) => {
        event.preventDefault();

        updateOpsiPengiriman({ nama: nama, status: 'Aktif', harga: harga, id: id })
        setNama('')
        setShowModal(false);
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    return (
        <div>
            <button
                onClick={() => setShowModal(true)}
                className="text-blue-500 text-lg cursor-pointer"
            >
                <MdEdit />
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Edit Opsi Pengiriman
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="relative z-0">
                                        <input
                                            type="text"
                                            placeholder=' '
                                            value={nama}
                                            required
                                            onChange={(event) => setNama(event.target.value)}
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Nama Pengiriman
                                        </label>
                                    </div>
                                    <div className="relative z-0 mt-5">
                                        <CurrencyInput
                                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            prefix='Rp'
                                            placeholder=' '
                                            defaultValue={harga}
                                            required
                                            decimalsLimit={2}
                                            onValueChange={(value) => setHarga(value)}
                                        />
                                        <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Harga Pengiriman
                                        </label>
                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}
export default OpsiPengiriman