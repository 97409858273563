import { format } from 'date-fns';
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { MdPrint } from 'react-icons/md';
import { useDeleteStokDetailMutation, useGetStokDetailVarianQuery } from '../../store/apis/produkStokDetailApi';
import { useGetListReturProdukVarianQuery } from '../../store/apis/returProdukApi';
import produkVarian from '../../reducers/produkVarian';

const DetailStok = ({ dataVarian, stokModal }) => {
    const [showModal, setShowModal] = useState(false);
    const { data, isFetching, error } = useGetStokDetailVarianQuery(dataVarian.id);
    const [deleteStokDetail, deleteStokDetailResult] = useDeleteStokDetailMutation();
    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const colDetail = [
        {
            name: 'Tanggal Transaksi',
            selector: row => format(new Date(row.tanggal_transaksi), 'dd/MM/yyyy'),
            sortable: true,
        },
        {
            name: 'Jumlah Beli',
            selector: row => row.jumlah_beli,
            sortable: true,
        },
        {
            name: 'Jumlah Stok',
            selector: row => row.jumlah_terkini,
            sortable: true,
        },
        {
            name: 'Harga Beli',
            selector: row => Intl.NumberFormat().format(row.harga_beli),
            sortable: true,
        },
        {
            name: 'Harga Jual',
            selector: row => Intl.NumberFormat().format(row.harga_jual),
            sortable: true,
        },
    ]



    return (
        <div>

            <div
                className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-11/12 mx-auto max-w-3xl flex content-center">
                    {/*content*/}
                    <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Detail Stok Produk {dataVarian.nama}
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => stokModal('stok', null)}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">


                            {data ? (
                                <DataTable columns={colDetail} data={data} />
                            ) : isFetching ? (
                                <p>loading</p>
                            ) : (
                                <p>{error ? error : "Data kosong"}</p>
                            )}


                        </div>
                        {/*footer*/}
                        <div className="flex gap-2 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="bg-slate-300 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:bg-slate-400"
                                type="button"
                            >
                                <div className='text-xl text-slate-500'>
                                    <MdPrint />

                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const DetailRetur = ({ dataVarian, returModal }) => {
    const [showModal, setShowModal] = useState(false);
    const { data, isFetching, error } = useGetListReturProdukVarianQuery(dataVarian.id);
    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])
    const colDetail = [
        {
            name: 'Tanggal Transaksi',
            selector: row => format(new Date(row.retur.tanggal_transaksi), 'dd/MM/yyyy'),
            sortable: true,
        },
        {
            name: 'Jumlah',
            selector: row => row.jumlah,
            sortable: true,
        },
        {
            name: 'Nominal',
            selector: row => Intl.NumberFormat().format(row.nominal),
            sortable: true,
        },
    ]



    return (
        <div>
            <div
                className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-11/12 mx-auto max-w-3xl flex content-center">
                    {/*content*/}
                    <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Detail Stok Produk {produkVarian.nama}
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => returModal('retur', null)}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">


                            {data ? (
                                <DataTable columns={colDetail} data={data} />
                            ) : isFetching ? (
                                <p>loading</p>
                            ) : (
                                <p>{error ? error : "Data kosong"}</p>
                            )}


                        </div>
                        {/*footer*/}
                        <div className="flex gap-2 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="bg-slate-300 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:bg-slate-400"
                                type="button"
                            >
                                <div className='text-xl text-slate-500'>
                                    <MdPrint />

                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { DetailStok, DetailRetur }