import React from 'react'
import { useParams } from 'react-router-dom';
import { useGetDistributorProductQuery } from '../../store/apis/distributorApi';
import { alamat_storage } from '../../components/api';
import { ProductList } from '../product/Product';
import { Tab } from '@headlessui/react';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import marker from '../../components/marker.png'

// Custom icon options
const customIcon = L.icon({
    iconUrl: marker, // Replace with your custom marker icon
    iconSize: [32, 32], // Adjust the size of the marker icon
    iconAnchor: [16, 32], // Adjust the anchor point of the marker icon
});


const Distributor = () => {
    const { id } = useParams();
    const { data, error, isFetching } = useGetDistributorProductQuery(id);

    const Markers = () => {
        return data ? (
            <Marker position={[data.x, data.y]} icon={customIcon} />
        ) : null;
    };


    return (
        <div className='content'>
            {data && (
                <>
                    <div className="flex items-center justify-center">
                        <img
                            src={`${alamat_storage}distributorProfil/${data.foto_profil}`}
                            alt="Seller Profile"
                            className="w-24 h-24 rounded-full mr-4"
                        />
                        <h1 className="text-2xl text-blue-500 font-bold">{data.nama}</h1>
                    </div>

                    <div className="mt-8 bg-white">
                        <Tab.Group>

                            <Tab.List className='bg-blue-100 rounded-t-lg'>
                                <Tab>
                                    {({ selected }) => (
                                        <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>Produk</div>
                                    )}
                                </Tab>
                                <Tab>
                                    {({ selected }) => (
                                        <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>Informasi</div>
                                    )}
                                </Tab>

                            </Tab.List>

                            <Tab.Panels className={`mt-5 px-5`}>
                                <Tab.Panel >
                                    <ProductList id={data.id} />

                                </Tab.Panel>
                                <Tab.Panel >
                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                        <div>
                                            <h1 className='text-xl text-blue-500 font-bold'>Informasi Dasar</h1>
                                            <table>
                                                <tbody>
                                                    <tr className='bg-blue-100'>
                                                        <td className='px-4'>Alamat</td>
                                                        <td className='px-2'>:</td>
                                                        <td className='px-4'>{data.alamat},
                                                            {' Kecamatan ' + data.lokasi_kelurahan.lokasi_kecamatan.nama},
                                                            {' ' + data.lokasi_kelurahan.lokasi_kecamatan.lokasi_kotakab.nama},
                                                            {' ' + data.lokasi_kelurahan.lokasi_kecamatan.lokasi_kotakab.lokasi_propinsi.nama}
                                                            {' ' + data.lokasi_kelurahan.kode_pos}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='px-4'>Nomor Telepon</td>
                                                        <td className='px-2'>:</td>
                                                        <td className='px-4'>{data.nomor_telp_cp}</td>
                                                    </tr>
                                                    <tr className='bg-blue-100'>
                                                        <td className='px-4'>Deskripsi</td>
                                                        <td className='px-2'>:</td>
                                                        <td className='px-4'>{data.deskripsi}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <h1 className='text-xl text-blue-500 font-bold'>Informasi Lokasi</h1>
                                            <MapContainer
                                                center={[data.x, data.y]}
                                                zoom={13}
                                                scrollWheelZoom={true}
                                                className="h-72 z-0">
                                                <TileLayer
                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                <Markers />
                                            </MapContainer>
                                        </div>
                                    </div>
                                </Tab.Panel>

                            </Tab.Panels>

                        </Tab.Group>
                    </div>
                </>
            )}
        </div>

    )
}

export default Distributor