import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const KategoriProdukApi = createApi({
    reducerPath: 'kategoriProduk',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,
    }),
    endpoints(builder) {
        return {
            addKategoriProduk: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Kategori' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'ProdukKategori',
                        body: data,
                    };
                },
            }),
            getListKategoriProduk: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'Kategori' }];

                },
                query: () => {
                    return {
                        url: 'ProdukKategoriList',
                        method: 'GET',
                    };
                },
            }),
            updateKategoriProduk: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Kategori' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `ProdukKategori/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteKategoriProduk: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Kategori' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `ProdukKategori/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetListKategoriProdukQuery, useAddKategoriProdukMutation, useDeleteKategoriProdukMutation, useUpdateKategoriProdukMutation } = KategoriProdukApi;
export { KategoriProdukApi };