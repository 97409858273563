import React, { Fragment, useEffect, useState } from 'react'
import { useShowDetailPengirimanQuery } from '../../store/apis/penjualanApi'
import { FileUploader } from 'react-drag-drop-files'
import { format } from 'date-fns'
import { Combobox, Disclosure, Tab, Transition } from '@headlessui/react'
import { MdCheck, MdClear, MdKeyboardArrowDown, MdUnfoldMore } from 'react-icons/md'
import { useAddReturProdukMutation, useGetListReturProdukQuery, useUpdateReturProdukMutation } from '../../store/apis/returProdukApi'
import secureLocalStorage from 'react-secure-storage'
import DataTable from 'react-data-table-component'
import { alamat_storage } from '../../components/api'

const ReturProduk = () => {
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const [status, setStatus] = useState('')
    const { data, error, isFetching } = useGetListReturProdukQuery({ id: id, status: status });
    const [updateRetur, setUpdateRetur] = useUpdateReturProdukMutation();
    const handleChange = (index) => {
        switch (index) {
            case 0:
                setStatus('')
                break;
            case 1:
                setStatus('Diajukan')
                break;
            case 2:
                setStatus('Diterima')
                break;
            case 3:
                setStatus('Selesai')
                break;
            case 4:
                setStatus('Ditolak')
                break;
            default:
                break;
        }
    }

    const HandleUpdateRetur = (id, status) => {
        if (status === 'Diterima') {
            if (window.confirm('Apakah anda yakin ingin menerima pengajuan retur produk?')) {
                updateRetur({ id: id, status: status });
            }
        } else if (status === 'Selesai') {
            if (window.confirm('Selesaikan proses retur?')) {
                updateRetur({ id: id, status: status });
            }
        } else if (status === 'Ditolak') {
            if (window.confirm('Apakah anda yakin ingin menerima pengajuan retur produk?')) {
                updateRetur({ id: id, status: status });
            }
        }
    }

    const ExpandedComponent = ({ data }) => (
        <div className='p-4'>
            <table className='w-full text-center'>

                <thead>
                    <tr>
                        <th>Jumlah Produk</th>
                        <th>Harga</th>
                        <th>Total</th>
                        <th>Alasan</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {data.retur_produks[0].jumlah}
                        </td>
                        <td>
                            {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data.retur_produks[0].nominal)}
                        </td>
                        <td>
                            {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data.retur_produks[0].nominal * data.retur_produks[0].jumlah)}
                        </td>
                        <td>
                            {data.retur_produks[0].alasan}
                        </td>
                    </tr>
                    <tr>
                        <td>Bukti</td>
                        <td colSpan={3}><img className='max-h-80' src={`${alamat_storage}/buktiRetur/${data.retur_produks[0].retur_fotos[0].path}`} alt="" /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )

    const col = [
        {
            name: 'Tanggal',
            selector: row => format(new Date(row.tanggal_transaksi), 'dd-MM-yyyy'),
            sortable: true,
        },
        {
            name: 'Nama Produk',
            selector: row => row.retur_produks[0].produk_varian.nama,
            sortable: true,
            width: 'auto',
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: 'auto',
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                if (record.status === "Diajukan") {
                    return (
                        <Fragment>
                            <button
                                type="submit"
                                onClick={(e) => HandleUpdateRetur(record.id, 'Diterima')}
                                className="text-blue-500 text-xl cursor-pointer "
                            >
                                < MdCheck />
                            </button>

                            <span className='mx-3'>|</span>

                            <button
                                type="submit"
                                onClick={(e) => HandleUpdateRetur(record.id, 'Ditolak')}
                                className="text-red-500 text-xl cursor-pointer"
                            >
                                <MdClear />
                            </button>
                        </Fragment>
                    );
                } else if (record.status === "Diterima") {
                    return (
                        <Fragment>
                            <button
                                type="submit"
                                onClick={(e) => HandleUpdateRetur(record.id, 'Selesai')}
                                className="text-white bg-blue-500 p-1 rounded-lg cursor-pointer "
                            >
                                Selesai
                            </button>
                        </Fragment>
                    )
                }
                else if (record.status === "Ditolak" || record.status === "Selesai") {
                    return (
                        <Fragment>
                            <button
                                type="submit"
                                disabled
                                onClick={(e) => HandleUpdateRetur(record.id, 'Diterima')}
                                className="text-blue-300 text-xl cursor-not-allowed"
                            >
                                < MdCheck />
                            </button>

                            <span className='mx-3'>|</span>

                            <button
                                type="submit"
                                disabled
                                onClick={(e) => HandleUpdateRetur(record.id, 'Ditolak')}
                                className="text-red-300 text-xl cursor-not-allowed"
                            >
                                <MdClear />
                            </button>
                        </Fragment>
                    );
                }
            },
        },
    ]
    return (
        <div className='w-full p-5'>
            <div className='w-full bg-white border pb-5 rounded-lg '>

                <Tab.Group onChange={(index) => handleChange(index)}>

                    <Tab.List className='bg-blue-100 rounded-t-lg'>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Semua
                                </div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Diajukan
                                </div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Diterima
                                </div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Selesai
                                </div>
                            )}
                        </Tab><Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Ditolak
                                </div>
                            )}
                        </Tab>
                    </Tab.List>

                    <Tab.Panels className={`mt-5 px-5`}>
                        {data && data.length > 0 ? (
                            <>
                                {data.map((item) => (
                                    <Disclosure key={item.id} as="div" defaultOpen="true">
                                        {({ open }) => (
                                            <>
                                                <div className="w-full flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">


                                                    <div>
                                                        <Disclosure.Button className="text-base font-bold mr-4">
                                                            {item.penjualan.retail.nama} | Invoice: {item.penjualan.nomor_invoice}
                                                        </Disclosure.Button>
                                                    </div>
                                                    <div className='flex items-center'>
                                                        {format(new Date(item.penjualan.tanggal_transaksi), 'dd-MM-yyyy')}

                                                        <Disclosure.Button >
                                                            <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                        </Disclosure.Button>

                                                    </div>
                                                </div>

                                                <Disclosure.Panel className="p-5">
                                                    {data ? (
                                                        <DataTable columns={col} data={item.returs} expandableRows expandableRowsComponent={ExpandedComponent} />
                                                    ) : (
                                                        <p> Data kosong</p>
                                                    )}

                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                ))}
                            </>) : <p className='text-lg text-center'>Belum ada transaksi</p>}
                    </Tab.Panels>

                </Tab.Group>
            </div>
        </div>
    )
}

const FormRetur = ({ returModal, id }) => {
    const { data, error, isFetching } = useShowDetailPengirimanQuery(id);
    const [tanggal, setTanggal] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [jumlah, setJumlah] = useState('');
    const [alasan, setAlasan] = useState('');
    const [bukti, setBukti] = useState('');
    const [selectedP, setSelectedP] = useState({ 'nama': 'Pilih produk' })
    const [query, setQuery] = useState('')
    const [addRetur, addReturResult] = useAddReturProdukMutation();

    const filteredProduk =
        data ? (
            query === ''
                ? data[0].penjualan_distributor_produks
                : data[0].penjualan_distributor_produks.filter((produk) =>
                    produk.produk_varian.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )) : []
    const handleChange = (file) => {
        setBukti(file);
    };

    const handleSubmit = () => {
        addRetur({ nomor_invoice: data[0].penjualan.nomor_invoice, tanggal_transaksi: tanggal, status: 'Diajukan', jumlah: jumlah, nominal: selectedP.harga, alasan: alasan, bukti: bukti, produk_varian_id: selectedP.produk_varian_id, penjualan_distributor_id: id });
        returModal();
    }

    useEffect(() => {
        if (addReturResult.isSuccess) {
            returModal();
        }
    })

    return (
        <div>
            <div
                className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[5000] outline-none focus:outline-none"
            >
                <div className="relative max-h-full w-auto mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Retur Produk
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => returModal()}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <div className="relative z-0 mt-5">
                                <input
                                    type="text"
                                    id="floating_standard"
                                    placeholder=' '
                                    value={data && data[0].penjualan.nomor_invoice}
                                    readOnly
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Nomor Invoice
                                </label>
                            </div>

                            <div className="relative z-0 mt-5">
                                <input
                                    type="date"
                                    id="floating_standard"
                                    placeholder=' '
                                    value={tanggal}
                                    onChange={(e) => setTanggal(e.target.value)}
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Tanggal
                                </label>
                            </div>

                            <div className="relative z-0 mt-5">
                                <input
                                    type="text"
                                    id="floating_standard"
                                    placeholder=' '
                                    value={alasan}
                                    onChange={(event) => setAlasan(event.target.value)}
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    alasan
                                </label>
                            </div>

                            <p className=" text-slate-500 text-xs mt-5">Produk</p>

                            <Combobox value={selectedP} onChange={setSelectedP}>

                                <div className="relative mt-1">
                                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                        <Combobox.Input
                                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                            displayValue={(produk) => (`${produk.produk_varian?.nama} | ${produk.jumlah}`)}
                                            onChange={(event) => setQuery(event.target.value)}
                                        />
                                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                            <MdUnfoldMore />

                                        </Combobox.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                        afterLeave={() => setQuery('')}
                                    >
                                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {filteredProduk.length === 0 && query !== '' ? (
                                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                    Nothing found.
                                                </div>
                                            ) : (
                                                filteredProduk.map((produk) => (
                                                    <Combobox.Option
                                                        key={produk.id}
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                                            }`
                                                        }
                                                        value={produk}
                                                    >
                                                        {({ selectedP, active }) => (
                                                            <>
                                                                <span
                                                                    className={`block truncate ${selectedP ? 'font-medium' : 'font-normal'
                                                                        }`}
                                                                >
                                                                    {produk.produk_varian.nama} | {produk.jumlah}
                                                                </span>
                                                                {selectedP ? (
                                                                    <span
                                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                            }`}
                                                                    >
                                                                        <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Combobox.Option>
                                                ))
                                            )}
                                        </Combobox.Options>

                                    </Transition>
                                </div>
                            </Combobox>

                            <div className="relative z-0 mt-5">
                                <input
                                    type="number"
                                    id="floating_standard"
                                    placeholder=' '
                                    value={jumlah}
                                    min={0}
                                    max={selectedP.jumlah && selectedP.jumlah}
                                    onChange={(event) => setJumlah(event.target.value)}
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Jumlah
                                </label>
                            </div>
                            <div className="relative z-0 mt-5">
                                <input
                                    type="number"
                                    id="floating_standard"
                                    placeholder=' '
                                    value={selectedP.harga ? selectedP.harga : 0}
                                    readOnly
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Harga Produk
                                </label>
                            </div>

                            <div>
                                <p className=" text-slate-500 text-xs mt-5 mb-1">Foto Warna</p>
                                <FileUploader label={"Tambahkan atau Letakkan foto Disini"} handleChange={handleChange} name="file" types={['JPG', 'PNG']} multiple={false} />
                                <p>{bukti ? `File name: ${bukti.name}` : "no files uploaded yet"}</p>
                            </div>
                        </div>


                        {/*footer*/}
                        <div className="flex items-center justify-end px-6 py-3 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="bg-blue-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="submit"
                                onClick={(event) => handleSubmit(event)}
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ReturProduk, FormRetur } 