import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const pegawaiJabatanApi = createApi({
    reducerPath: 'pegawaiJabatan',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getListPegawaiJabatan: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'PegawaiJabatan' }];

                },
                query: (id) => {
                    return {
                        url: 'PegawaiJabatanList',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            addPegawaiJabatan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'PegawaiJabatan' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'PegawaiJabatan',
                        body: data,
                    };
                },
            }),
            updatePegawaiJabatan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'PegawaiJabatan' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `PegawaiJabatan/${data.id}`,
                        body: data,
                    };
                },
            }),
            deletePegawaiJabatan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'PegawaiJabatan' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `PegawaiJabatan/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetListPegawaiJabatanQuery, useAddPegawaiJabatanMutation, useUpdatePegawaiJabatanMutation, useDeletePegawaiJabatanMutation } = pegawaiJabatanApi;
export { pegawaiJabatanApi };