import { ADD_PRODUK_VARIAN, DELETE_PRODUK_VARIAN, GET_LIST_PRODUK_VARIAN, UPDATE_PRODUK_VARIAN } from "../../actions/produkVarianAction"

const initialState = {
    getListVarianResult: false,
    getListVarianLoading: false,
    getListVarianError: false,

    addVarianResult: false,
    addVarianLoading: false,
    addVarianError: false,

    deleteVarianResult: false,
    deleteVarianLoading: false,
    deleteVarianError: false,

    updateVarianResult: false,
    updateVarianLoading: false,
    updateVarianError: false,
}


const produkVarian = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_PRODUK_VARIAN:
            return {
                ...state,
                getListVarianResult: action.payload.data,
                getListVarianLoading: action.payload.loading,
                getListVarianError: action.payload.errorMessage,
            }

        case ADD_PRODUK_VARIAN:
            return {
                ...state,
                addVarianResult: action.payload.data,
                addVarianLoading: action.payload.loading,
                addVarianError: action.payload.errorMessage,
            }
        case DELETE_PRODUK_VARIAN:
            return {
                ...state,
                deleteVarianResult: action.payload.data,
                deleteVarianLoading: action.payload.loading,
                deleteVarianError: action.payload.errorMessage,
            }
        case UPDATE_PRODUK_VARIAN:
            return {
                ...state,
                updateVarianResult: action.payload.data,
                updateVarianLoading: action.payload.loading,
                updateVarianError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default produkVarian;