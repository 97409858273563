import { ADD_RETAIL, GET_DETAIL_RETAIL, GET_LIST_RETAIL } from "../../actions/retailAction";

const initialState = {
    getListRetailResult: false,
    getListRetailLoading: false,
    getListRetailError: false,

    getDetailRetailResult: false,
    getDetailRetailLoading: false,
    getDetailRetailError: false,

    addRetailResult: false,
    addRetailLoading: false,
    addRetailError: false,


}


const retail = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_RETAIL:
            return {
                ...state,
                getListRetailResult: action.payload.data,
                getListRetailLoading: action.payload.loading,
                getListRetailError: action.payload.errorMessage,
            }
        case GET_DETAIL_RETAIL:
            return {
                ...state,
                getDetailRetailResult: action.payload.data,
                getDetailRetailLoading: action.payload.loading,
                getDetailRetailError: action.payload.errorMessage,
            }
        case ADD_RETAIL:
            return {
                ...state,
                addRetailResult: action.payload.data,
                addRetailLoading: action.payload.loading,
                addRetailError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default retail;