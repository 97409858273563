import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const kualitasPasarApi = createApi({
    reducerPath: 'kualitasPasar',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getKualitasPasar: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'KualitasPasar' }];

                },
                query: () => {
                    return {
                        url: 'ProdukKualitasPasaranList',
                        method: 'GET',
                    };
                },
            }),
            addKualitasPasar: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'KualitasPasar' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'ProdukKualitasPasaran',
                        body: data,
                    };
                },
            }),
            updateKualitasPasar: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'KualitasPasar' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `ProdukKualitasPasaran/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteKualitasPasar: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'KualitasPasar' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `ProdukKualitasPasaran/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetKualitasPasarQuery, useAddKualitasPasarMutation, useUpdateKualitasPasarMutation, useDeleteKualitasPasarMutation } = kualitasPasarApi;
export { kualitasPasarApi };