import React, { Fragment } from 'react'
import { MdDelete } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { useDeletePegawaiDodolanMutation, useGetPegawaiDodolanQuery } from '../../store/apis/pegawaiDodolanApi';
import { AddPegawaiDodolan } from './AddPegawai';
import { EditPegawaiDodolan } from './EditData';

const PegawaiDodolan = () => {
    const { data, isFetching, error } = useGetPegawaiDodolanQuery();
    const [deletePegawai, deletePegawaiResult] = useDeletePegawaiDodolanMutation();

    const colPegawai = [
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            name: 'NIP',
            selector: row => row.nip,
            sortable: true,
        },
        {
            name: 'Nomor HP',
            selector: row => row.nomor_hp,
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idProv = record.id;
                return (
                    <Fragment>
                        <EditPegawaiDodolan data={record} />

                        <span className='mx-3'>|</span>

                        <a
                            type="submit"
                            onClick={(e) => deleteData(e, idProv)}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </a>
                    </Fragment>
                );
            },
        },
    ]

    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            deletePegawai(id);
        }
    }

    return (
        <div className='w-full p-5 '>
            <div className='w-full bg-white border p-5 rounded-lg '>
                <div className='grid grid-cols-4 mb-5'>
                    <div className='col-span-3 self-center'>
                        <h1 className='text-base font-bold text-blue-500 '>Data Pegawai</h1>
                        <p className='text-slate-400'>Di bawah ini merupakan data semua Pegawai yang telah terdaftar.</p>
                    </div>
                    <div className='place-self-end'>
                        <AddPegawaiDodolan />
                    </div>
                </div>

                {data ? (
                    <DataTable columns={colPegawai} data={data} pagination />
                ) : isFetching ? (
                    <p>loading</p>
                ) : (
                    <p>{error ? error : "Data kosong"}</p>
                )}
            </div>


        </div>
    )
}

export default PegawaiDodolan