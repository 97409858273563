import React, { useEffect, useState } from 'react'
import { useGetLaporanQuery } from '../../store/apis/laporanApi'
import DataTable from 'react-data-table-component';
import { format } from 'date-fns';
import secureLocalStorage from 'react-secure-storage';

const CetakLaporanOmset = () => {
    const data = secureLocalStorage.getItem('data')
    const year = secureLocalStorage.getItem('year');
    let nilaiStok = 0;

    useEffect(() => {
        window.print();
        // Function to handle the beforeunload event
        const handleBeforeUnload = () => {
            // Remove the data from local storage
            secureLocalStorage.removeItem('data');
            secureLocalStorage.removeItem('year');

        };

        // Add the beforeunload event listener
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <div className='w-full  p-5  '>
            <div className='w-full bg-white p-5 rounded-lg '>
                <div>
                    <div className='flex justify-between'>
                        <div>
                            <div>
                                Modal Tahun {year}: {Intl.NumberFormat().format(data.modal.nominal)}
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <table className='w-full text-xs'>
                            <thead>
                                <tr className='text-left border-y border-slate-500'>
                                    <th className='p-2 border-x border-slate-500'>Bulan</th>
                                    <th className='p-2 border-x border-slate-500'>1</th>
                                    <th className='p-2 border-x border-slate-500'>2</th>
                                    <th className='p-2 border-x border-slate-500'>3</th>
                                    <th className='p-2 border-x border-slate-500'>4</th>
                                    <th className='p-2 border-x border-slate-500'>5</th>
                                    <th className='p-2 border-x border-slate-500'>6</th>
                                    <th className='p-2 border-x border-slate-500'>7</th>
                                    <th className='p-2 border-x border-slate-500'>8</th>
                                    <th className='p-2 border-x border-slate-500'>9</th>
                                    <th className='p-2 border-x border-slate-500'>10</th>
                                    <th className='p-2 border-x border-slate-500'>11</th>
                                    <th className='p-2 border-x border-slate-500'>12</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='text-left border-y border-slate-500'>
                                    <th className='p-2 border-x border-slate-500'>Visualisasi Stok Barang di gudang</th>
                                    {data.pembelian.map((item, index) => {
                                        index > 0 ? (
                                            nilaiStok = nilaiStok + data.pembelian[index - 1].nominal - (data.penjualan[index - 1].nominal - (data.penjualan[index - 1].nominal * 35 / 100))
                                        ) : (
                                            data.nilaiStok ?
                                                (nilaiStok = data.nilaiStok) : (nilaiStok = 0)
                                        )
                                        return <td className='border-x p-1 border-slate-500' >{Intl.NumberFormat().format(nilaiStok)}</td>
                                    })
                                    }
                                </tr>
                                <tr className='text-left border-y border-slate-500'>
                                    <th className='p-2 border-x border-slate-500'>Belanja Barang</th>
                                    {data.pembelian.map((item) =>
                                        <td className='border-x p-1 border-slate-500'>{Intl.NumberFormat().format(item.nominal)}</td>
                                    )}
                                </tr>
                                <tr className='text-left border-y border-slate-500'>
                                    <th className='p-2 border-x border-slate-500'>Persentase Belanja Barang</th>
                                    {data.pembelian.map((item, index) =>
                                        <td className='border-x p-1 border-slate-500' >{(item.nominal / data.modal.nominal * 100).toFixed(2)}%</td>)
                                    }
                                </tr>
                                <tr className='text-left border-y border-slate-500'>
                                    <th className='p-2 border-x border-slate-500'>Omset</th>
                                    {data.penjualan.map((item) =>
                                        <td className='border-x p-1 border-slate-500'>{Intl.NumberFormat().format(item.nominal)}</td>
                                    )}
                                </tr>
                                <tr className='text-left border-y border-slate-500'>
                                    <th className='p-2 border-x border-slate-500'>Persentase Omset</th>
                                    {data.penjualan.map((item, index) => {
                                        index > 0 ? (
                                            nilaiStok = nilaiStok + data.pembelian[index - 1].nominal - (data.penjualan[index - 1].nominal - (data.penjualan[index - 1].nominal * 35 / 100))
                                        ) : (
                                            data.nilaiStok ?
                                                (nilaiStok = data.nilaiStok) : (nilaiStok = 0)
                                        )
                                        return <td className='border-x p-1 border-slate-500' >{nilaiStok + data.pembelian[index].nominal > 0 ? ((item.nominal / (nilaiStok + data.pembelian[index].nominal)) * 100).toFixed(2) : (0).toFixed(2)}%</td>
                                    })
                                    }
                                </tr>
                                <tr className='text-left border-y border-slate-500'>
                                    <th className='p-2 border-x border-slate-500'>Laba Kotor</th>
                                    {data.penjualan.map((item) =>
                                        <td className='border-x p-1 border-slate-500'>{Intl.NumberFormat().format(item.nominal * 35 / 100)}</td>
                                    )}
                                </tr>
                                <tr className='text-left border-y border-slate-500'>
                                    <th className='p-2 border-x border-slate-500'>Omset - Laba Kotor</th>
                                    {data.penjualan.map((item) =>
                                        <td className='border-x p-1 border-slate-500'>{Intl.NumberFormat().format(item.nominal - (item.nominal * 35 / 100))}</td>
                                    )}
                                </tr>
                                <tr className='text-left border-y border-slate-500'>
                                    <th className='p-2 border-x border-slate-500'>Persentase Omset - Laba Kotor</th>
                                    {data.penjualan.map((item, index) => {
                                        index > 0 ? (
                                            nilaiStok = nilaiStok + data.pembelian[index - 1].nominal - (data.penjualan[index - 1].nominal - (data.penjualan[index - 1].nominal * 35 / 100))
                                        ) : (
                                            data.nilaiStok ?
                                                (nilaiStok = data.nilaiStok) : (nilaiStok = 0)
                                        )
                                        return <td className='border-x p-1 border-slate-500' >{nilaiStok + data.pembelian[index].nominal > 0 ? (((item.nominal - (item.nominal * 35 / 100)) / (nilaiStok + data.pembelian[index].nominal)) * 100).toFixed(2) : (0).toFixed(2)}%</td>
                                    })
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { CetakLaporanOmset }