import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const produkStokApi = createApi({
    reducerPath: 'produkStok',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getStok: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'stok' }];

                },
                query: (id) => {
                    return {
                        url: 'ProdukStokList',
                        method: 'GET',
                        params: { id },
                    };
                },
            }),
            addStok: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'stok' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'ProdukStok',
                        body: data,
                    };
                },
            }),
            updateStok: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'stok' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `ProdukStok/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteStok: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'stok' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `ProdukStok/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetStokQuery, useAddStokMutation, useUpdateStokMutation, useDeleteStokMutation } = produkStokApi;
export { produkStokApi };