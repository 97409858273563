import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const subKategoriProduk = createApi({
    reducerPath: 'subkategoriProduk',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,
    }),
    endpoints(builder) {
        return {
            addSubKategoriProduk: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'subKategori' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'ProdukSubKategori',
                        body: data,
                    };
                },
            }),
            getListSubKategoriProduk: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'subKategori' }];

                },
                query: () => {
                    return {
                        url: 'ProdukSubKategoriList',
                        method: 'GET',
                    };
                },
            }),
            updateSubKategoriProduk: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'subKategori' }];
                },
                query: (data) => {
                    
                    return {
                        method: 'PUT',
                        url: `ProdukSubKategori/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteSubKategoriProduk: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'subKategori' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `ProdukSubKategori/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetListSubKategoriProdukQuery, useAddSubKategoriProdukMutation, useDeleteSubKategoriProdukMutation, useUpdateSubKategoriProdukMutation } = subKategoriProduk;
export { subKategoriProduk };