import { format } from 'date-fns';
import React, { Fragment, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { MdDelete, MdPrint } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { deleteStokDetail, getListStokDetail } from '../../actions/produkStokDetail';
import { FilterTableStok } from '../../components/FilterTable';
import { TambahStokDetail } from './TambahPembelian';
import { useDeleteStokDetailMutation, useGetStokDetailStokQuery } from '../../store/apis/produkStokDetailApi';
import secureLocalStorage from 'react-secure-storage';


const DetailInvoice = (props) => {
    const [showModal, setShowModal] = useState(false);
    const { data, isFetching, error } = useGetStokDetailStokQuery(props.data.id);
    const [deleteStokDetail, deleteStokDetailResult] = useDeleteStokDetailMutation();
    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setShowModal(false)
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const handleClick = (e) => {
        e.preventDefault();
        secureLocalStorage.setItem('data', data);
        secureLocalStorage.setItem('pabrik', props.data.pabrik);
        secureLocalStorage.setItem('nomor_invoice', props.data.nomor_invoice);
        secureLocalStorage.setItem('tanggal_transaksi', props.data.tanggal_transaksi);
        secureLocalStorage.setItem('total', props.data.total);
        const newTab = window.open('/cetakInvoicePembelian', '_blank');
        if (newTab) {
            newTab.focus();
        }

    }

    const colDetail = [
        {
            name: 'Produk',
            selector: row => row.varian,
            sortable: true,
        },
        {
            name: 'Jumlah Beli',
            selector: row => row.jumlah_beli,
            sortable: true,
        },
        {
            name: 'Harga Beli',
            selector: row => new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.harga_beli),
            sortable: true,
        },
    ]

    const deleteData = (event, id) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            deleteStokDetail(id);
        }
    }



    return (
        <div>
            <button
                className="bg-blue-500 text-white active:bg-blue-600 uppercase rounded p-2 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:bg-blue-400"
                type="button"
                onClick={() => setShowModal(true)}
            >
                {props.data.detail}
            </button>
            {showModal ? (
                <>
                    <div
                        className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-11/12 mx-auto max-w-3xl flex content-center">
                            {/*content*/}
                            <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Detail Invoice
                                    </h3>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className='flex w-full justify-between'>
                                        <div>
                                            Pabrik : {props.data.pabrik}
                                        </div>
                                        <div>
                                            <div>
                                                Invoice #{props.data.nomor_invoice}
                                            </div>
                                            <div className='text-right'>
                                                {format(new Date(props.data.tanggal_transaksi), 'dd/MM/yyyy')}

                                            </div>
                                        </div>
                                    </div>

                                    {data ? (
                                        <DataTable columns={colDetail} data={data} />
                                    ) : isFetching ? (
                                        <p>loading</p>
                                    ) : (
                                        <p>{error ? error : "Data kosong"}</p>
                                    )}

                                    <div className='flex justify-between w-full p-2 mt-3 text-white bg-blue-500 text-2xl rounded-lg'>
                                        <div>
                                            Total Harga
                                        </div>
                                        <div>
                                            {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(props.data.total)}
                                        </div>
                                    </div>

                                </div>
                                {/*footer*/}
                                <div className="flex gap-2 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        onClick={(e) => handleClick(e)}
                                        className="bg-slate-300 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:bg-slate-400"
                                        type="button"
                                    >
                                        <div className='text-xl text-slate-500'>
                                            <MdPrint />

                                        </div>
                                    </button>
                                    <TambahStokDetail data={props.data} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )

}

export default DetailInvoice