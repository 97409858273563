import axios from "axios";
import {alamat_backend} from "../components/api";


export const GET_LIST_PRODUK_VARIAN = "GET_LIST_PRODUK_VARIAN"
export const DELETE_PRODUK_VARIAN = "DELETE_PRODUK_VARIAN"
export const ADD_PRODUK_VARIAN = "ADD_PRODUK_VARIAN"
export const UPDATE_PRODUK_VARIAN = "UPDATE_PRODUK_VARIAN"


const getListVarian = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_LIST_PRODUK_VARIAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'ProdukVarianList?id=' + id,
            timeout: 120000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_LIST_PRODUK_VARIAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_LIST_PRODUK_VARIAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}


const addVarian = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: ADD_PRODUK_VARIAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'ProdukVarian',
            timeout: 120000,
            data: data
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: ADD_PRODUK_VARIAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: ADD_PRODUK_VARIAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.response.data,
                    }
                })
            })
    }
}

const updateVarian = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: UPDATE_PRODUK_VARIAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'PUT',
            url: alamat_backend + 'ProdukVarian/' + data.id,
            timeout: 120000,
            data: data
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: UPDATE_PRODUK_VARIAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: UPDATE_PRODUK_VARIAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message
                    }
                })
            })
    }
}

const deleteVarian = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: DELETE_PRODUK_VARIAN,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'DELETE',
            url: alamat_backend + 'ProdukVarian/'+ id,
            timeout: 120000,
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: DELETE_PRODUK_VARIAN,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: DELETE_PRODUK_VARIAN,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })
    }
}


export { getListVarian, addVarian, deleteVarian, updateVarian }