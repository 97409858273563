import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const returProdukApi = createApi({
    reducerPath: 'returProduk',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getListReturProduk: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'ReturProduk' }];

                },
                query: ({id, status}) => {
                    return {
                        url: 'ReturProdukList',
                        method: 'GET',
                        params: { id:id, status:status }
                    };
                },
            }),
            getListReturProdukRetail: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'ReturProduk' }];

                },
                query: ({id, status}) => {
                    return {
                        url: 'ReturProdukListRetail',
                        method: 'GET',
                        params: { id:id, status:status }
                    };
                },
            }),
            getListReturProdukVarian: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'ReturProduk' }];

                },
                query: (id) => {
                    return {
                        url: 'ReturProdukListVarianRetur',
                        method: 'GET',
                        params: { id:id}
                    };
                },
            }),
            addReturProduk: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'ReturProduk' }];
                },
                query: (data) => {
                    const formData = new FormData();
                    formData.append('nomor_invoice', data.nomor_invoice);
                    formData.append('tanggal_transaksi', data.tanggal_transaksi);
                    formData.append('status', data.status);
                    formData.append('jumlah', data.jumlah);
                    formData.append('nominal', data.nominal);
                    formData.append('alasan', data.alasan);
                    formData.append('bukti', data.bukti);
                    formData.append('produk_varian_id', data.produk_varian_id);
                    formData.append('penjualan_distributor_id', data.penjualan_distributor_id);
                    return {
                        method: 'POST',
                        url: 'AddRetur',
                        body: formData,
                    };
                },
            }),
            updateReturProduk: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'ReturProduk' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: `UpdateRetur`,
                        body: data,
                    };
                },
            }),
            deleteReturProduk: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'ReturProduk' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `ReturProduk/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetReturProdukQuery, useGetListReturProdukQuery, useGetListReturProdukRetailQuery, useGetListReturProdukVarianQuery, useAddReturProdukMutation, useUpdateReturProdukMutation, useDeleteReturProdukMutation } = returProdukApi;
export { returProdukApi };