import React, { Fragment, useEffect, useState } from 'react'
import { redirect, useLocation } from 'react-router-dom'
import { useKeranjangCheckoutQuery } from '../../store/apis/keranjangApi';
import { Combobox, Transition } from '@headlessui/react';
import { MdCheck, MdUnfoldMore } from 'react-icons/md';
import { useAddPenjualanMutation } from '../../store/apis/penjualanApi';
import { format } from 'date-fns';
import { AddAlamat } from '../akun/Profile';
import { alamat_storage } from '../../components/api';

const Checkout = () => {
    const location = useLocation();
    const { data, isFetching, error } = useKeranjangCheckoutQuery(location.state)
    const [query, setQuery] = useState('')
    const [totalProduk, setTotalProduk] = useState(0);
    const [selectedAlamat, setSelectedAlamat] = useState({ 'nama': 'Alamat Kosong, silahkan masukkan alamat terlebih dahulu' })
    const [selectedPengiriman, setSelectedPengiriman] = useState('')
    const [checkout, { isSuccess }] = useAddPenjualanMutation();
    const [addAlamatForm, setAddAlamatForm] = useState(false);

    const ShowAlamatModal = (e) => {
        if (addAlamatForm) {
            setAddAlamatForm(false);
        } else {
            setAddAlamatForm(true);
        }
    }

    useEffect(() => {
        if (isSuccess) {
            window.alert("Pesanan dikirim");
            window.location.replace('/transaksiRetail');
        }
    }, [isSuccess]);

    const filteredAlamat =
        data ?
            (query === ''
                ? data.alamat
                : data.alamat.filter((alamat) =>
                    alamat.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, '')))) : ''

    const filteredPengiriman =
        data ?
            (query === ''
                ? data.opsi_pengiriman
                : data.opsi_pengiriman.filter((pengiriman) =>
                    pengiriman.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, '')))) : ''
    useEffect(() => {
        if (data) {
            setSelectedAlamat(data.alamat[0] ? data.alamat[0] : selectedAlamat);
            setSelectedPengiriman(data.opsi_pengiriman[0])
            {
                data.produk.map((data) => (
                    setTotalProduk(prevPrice => prevPrice + (data.min_jual * data.jumlah))
                ))
            }
        }
    }, [data])

    const handleSubmit = (event) => {
        event.preventDefault();
        const currentDateTime = new Date();
        checkout({ produk: data.produk, retail_id: location.state.idR, distributor_id: location.state.idD, retail_alamat_kirim_id: selectedAlamat.id, opsi_pengiriman_id: selectedPengiriman.id, tanggal_transaksi: format(currentDateTime, 'yyyy-MM-dd HH:mm:ss') })
    }
    return (
        <div className='content '>
            {data && (
                <>
                    {addAlamatForm && <AddAlamat alamatModal={ShowAlamatModal} idC={location.state} />}

                    <form onSubmit={handleSubmit}>

                        <div className='flex bg-white p-4 w-full'>
                            <div className='flex-grow'>
                                Alamat Pengiriman
                                <Combobox value={selectedAlamat} onChange={setSelectedAlamat}>

                                    <div className="relative mt-1">
                                        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                            <Combobox.Input
                                                required
                                                placeholder='Pilih atau tambah alamat pengiriman'
                                                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                displayValue={(alamat) => alamat.nama + " | " + alamat.alamat}
                                                onChange={(event) => setQuery(event.target.value)}
                                            />
                                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                <MdUnfoldMore />

                                            </Combobox.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                            afterLeave={() => setQuery('')}
                                        >
                                            <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {filteredAlamat.length === 0 && query !== '' ? (
                                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                        Nothing found.
                                                    </div>
                                                ) : (
                                                    filteredAlamat.map((alamat) => (
                                                        <Combobox.Option
                                                            key={alamat.id}
                                                            className={({ active }) =>
                                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-white-600' : 'text-gray-900'
                                                                }`
                                                            }
                                                            value={alamat}
                                                        >
                                                            {({ selected, active }) => (
                                                                <>
                                                                    <span
                                                                        className={`block truncate ${selectedAlamat ? 'font-medium' : 'font-normal'
                                                                            }`}
                                                                    >
                                                                        <div>
                                                                            {alamat.nama_penerima} | {alamat.nomor_telp_penerima}
                                                                        </div>
                                                                        <div className='font-normal text-slate-500'>
                                                                            {alamat.alamat}
                                                                        </div>
                                                                    </span>
                                                                    {selected ? (
                                                                        <span
                                                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 text-blue-500`}
                                                                        >
                                                                            <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                        </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Combobox.Option>
                                                    ))
                                                )}
                                            </Combobox.Options>

                                        </Transition>
                                    </div>
                                </Combobox>
                            </div>
                            <button type='button' className='mt-5 rounded-lg p-2 text-white  bg-blue-500' onClick={(e) => ShowAlamatModal(e)}>Tambah</button>

                        </div>
                        <div className='bg-white p-4 w-full mt-4'>

                            <div className='grid grid-cols-8 p-5 text-center bg-blue-100'>
                                <p className='col-span-5 text-left'>
                                    Produk
                                </p>
                                <p>Harga Produk</p>
                                <p>Jumlah Barang</p>
                                <p>Harga Total</p>
                            </div>
                            {data.produk.map((data) => (

                                <div key={data.id} className='h-32 grid grid-cols-8 justify-between text-center items-center border-b'>

                                    <div className='flex space-x-2 items-center h-32 col-span-5'>
                                        {/* <input type="checkbox"
                                                        // checked={checkedData.includes(data.id)}
                                                        // onChange={() => handleDataChange(data.id)}
                                                        className='accent-blue-500 w-4 h-4' /> */}
                                        <img src={`${alamat_storage}fotoProduk/${data.foto}`} className='h-4/5' />
                                        <p>{data.nama}</p>
                                    </div>

                                    <p>Rp{Intl.NumberFormat("id-ID").format(data.min_jual)}</p>
                                    <div>
                                        <p>{data.jumlah}</p>
                                    </div>
                                    <p>Rp{Intl.NumberFormat("id-ID").format(data.min_jual * data.jumlah)}</p>


                                </div>
                            ))}
                        </div>
                        <div className='bg-white p-4 w-full mt-4'>
                            Opsi Pengiriman
                            <Combobox value={selectedPengiriman} onChange={setSelectedPengiriman}>

                                <div className="relative mt-1">
                                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                        <Combobox.Input
                                            required
                                            placeholder='Pilih metode pengiriman. Jika kosong, hubungi distributor untuk menambahkan data opsi pengiriman'
                                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                            displayValue={(alamat) => alamat.nama + " | " + alamat.harga}
                                            onChange={(event) => setQuery(event.target.value)}
                                        />
                                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                            <MdUnfoldMore />

                                        </Combobox.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                        afterLeave={() => setQuery('')}
                                    >
                                        <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {filteredPengiriman.length === 0 && query !== '' ? (
                                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                    Nothing found.
                                                </div>
                                            ) : (
                                                filteredPengiriman.map((pengiriman) => (
                                                    <Combobox.Option
                                                        key={pengiriman.id}
                                                        className={({ active }) =>
                                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-white-600' : 'text-gray-900'
                                                            }`
                                                        }
                                                        value={pengiriman}
                                                    >
                                                        {({ selected, active }) => (
                                                            <>
                                                                <span
                                                                    className={`block truncate ${selectedPengiriman ? 'font-medium' : 'font-normal'
                                                                        }`}
                                                                >
                                                                    <div>
                                                                        {pengiriman.nama}
                                                                    </div>
                                                                    <div className='font-normal text-slate-500'>
                                                                        {pengiriman.harga}
                                                                    </div>
                                                                </span>
                                                                {selected ? (
                                                                    <span
                                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 text-blue-500`}
                                                                    >
                                                                        <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Combobox.Option>
                                                ))
                                            )}
                                        </Combobox.Options>

                                    </Transition>
                                </div>
                            </Combobox>
                        </div>
                        <div className='bg-white p-4 w-full mt-4 '>
                            <div className='w-full flex justify-end p-3 border-b-2'>
                                <div className='flex justify-between gap-4'>
                                    <div>
                                        <p>Sub total produk</p>
                                        <p>Biaya Pengiriman</p>
                                        <p>Total Harga</p>
                                    </div>
                                    <div className='text-end'>
                                        <p>Rp{Intl.NumberFormat("id-ID").format(totalProduk)}</p>
                                        <p>{data && data.opsi_pengiriman.length>0? <>Rp{Intl.NumberFormat("id-ID").format(selectedPengiriman.harga)}</>: ''}</p>
                                        <p className='text-blue-500 text-xl'>{data && data.opsi_pengiriman.length? <>Rp{Intl.NumberFormat("id-ID").format(totalProduk + selectedPengiriman.harga)}</>:''}</p>

                                    </div>

                                </div>
                            </div>
                            <div className='p-3 justify-end flex w-full'>
                                <button type="submit" className='py-2 px-12 bg-blue-500 text-white rounded-lg'>Checkout</button>

                            </div>
                        </div>
                    </form>
                </>
            )}
        </div>
    )
}

export default Checkout
