import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const lokasiPropinsiApi = createApi({
    reducerPath: 'lokasiPropinsi',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getPropinsi: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'Propinsi' }];

                },
                query: () => {
                    return {
                        url: 'LokasiPropinsi',
                        method: 'GET',
                    };
                },
            }),
            getListPropinsi: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'Propinsi' }];

                },
                query: (id) => {
                    return {
                        url: 'LokasiPropinsiList',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            addPropinsi: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Propinsi' }];
                },
                query: (data) => {
                   
                    return {
                        method: 'POST',
                        url: 'LokasiPropinsi',
                        body: data,
                    };
                },
            }),
            updatePropinsi: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Propinsi' }];
                },
                query: (data) => {
                    
                    return {
                        method: 'PUT',
                        url: `LokasiPropinsi/${data.id}`,
                        body: data,
                    };
                },
            }),
            deletePropinsi: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Propinsi' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `LokasiPropinsi/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetPropinsiQuery, useGetListPropinsiQuery, useAddPropinsiMutation, useUpdatePropinsiMutation, useDeletePropinsiMutation } = lokasiPropinsiApi;
export { lokasiPropinsiApi };