import React, { useState } from 'react'
import FilterProduct from '../../components/FilterProduct'
import { MdArrowLeft, MdArrowRight, MdLocationOn, MdOutlineShoppingCart, MdShoppingCart } from "react-icons/md";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card, CardBody, CardFooter, CardHeader, ImageHeader } from 'react-simple-card';
import { useGetListSubKategoriProdukQuery } from '../../store/apis/subKategoriProdukApi';
import { useGetRestrictedListFotoQuery, useGetUnrestrictedListFotoQuery } from '../../store/apis/produkFotoApi';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useGetListProductQuery } from '../../store/apis/produkApi';
import { NextButton, PageButton, Pagination, PrevButton } from "react-headless-pagination";
import { alamat_storage } from '../../components/api';
import { useGetListSubSubKategoriProdukQuery } from '../../store/apis/subSubKategoriProdukApi';



const Product = () => {
    const { data, error, isFetching } = useGetUnrestrictedListFotoQuery()
    return (
        <>
            {data ? (data.map((produk) => {
                return (
                    <div key={produk.id} className='w-full flex flex-col text-left rounded-lg border relative bg-white'>
                        <div className='group relative rounded-t-lg h-2/3'>
                            <img
                                src={alamat_storage+"fotoProduk/" + produk.path}
                                className="rounded-t-lg mx-auto h-32 object-cover"
                                alt=''
                            />
                        </div>
                        <Link className="p-4" to={`/productDetail/${produk.idP}`}>
                            <h5 className="text-base font-medium ">{produk.nama}</h5>
                            <p className='text-blue-500 text-base font-bold mt-1'>Rp<span className='text-xl'>{Intl.NumberFormat("de-DE").format(produk.label_harga)}</span></p>
                            <div className='mt-4 flex gap-1'>
                                <MdLocationOn className='text-lg text-slate-400' /> <span className=' text-xs text-slate-400 -ml-1'>{produk.kota}</span>

                            </div>
                            <button className='bg-blue-500 text-white p-1 w-full mt-4 rounded-lg '>Beli</button>

                        </Link>
                    </div>
                )
            })) : isFetching ?
                (<div>
                    Loading
                </div>)
                : <div>Terjadi Kesalahan</div>
            }

        </>
    )
}
const ProductRes = () => {
    const { data, error, isFetching } = useGetRestrictedListFotoQuery()
    return (
        <>
            {data ? (data.map((produk) => {
                return (
                    <div key={produk.id} className='w-full flex flex-col text-left rounded-lg border relative bg-white'>
                        <div className='group relative rounded-t-lg h-2/3'>
                            <img src={alamat_storage+"fotoProduk/" + produk.path} className="rounded-t-lg h-32 mx-auto " alt='' />
                        </div>
                        <Link className="p-4" to={`/productDetail/${produk.idP}`}>
                            <h5 className="text-base font-medium ">{produk.nama}</h5>
                            <div className='mt-4 flex gap-1'>
                                <MdLocationOn className='text-lg text-slate-400' /> <span className=' text-xs text-slate-400 -ml-1'>{produk.kota}</span>

                            </div>
                            <button className='bg-blue-500 text-white p-1 w-full mt-4 rounded-lg '>Beli</button>

                        </Link>
                    </div>
                )
            })) : isFetching ?
                (<div>
                    Loading
                </div>)
                : <div>Terjadi Kesalahan</div>
            }

        </>
    )
}

const ProductCategory = () => {
    const { data, error, isFetching } = useGetListSubSubKategoriProdukQuery()

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 6
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };

    return (
        <>
            <Carousel responsive={responsive}
                draggable={true}
                swipeable={true}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                slidesToSlide={2}
                shouldResetAutoplay={true}>
                {data ? (data.map((kategori) => {
                    return (
                        <div key={kategori.id} className='bg-white rounded-lg mr-4 h-36'>
                            <Link to={`/product?kategori=${kategori.id}`}>
                                <img className='rounded=t-lg h-3/4 mx-auto' src={`${alamat_storage}gambarSubSubKategori/${kategori.gambar}`} alt="" />
                                <p className='text-base mt-2 text-center'>{kategori.nama}</p>
                            </Link>

                        </div>)
                })) : isFetching ?
                    (<div>
                        Loading
                    </div>)
                    : <div>Terjadi Kesalahan</div>
                }

            </Carousel>
        </>
    )
}
const Product1 = (props) => {

    return (
        <div className='w-full flex flex-col text-left rounded-lg border relative bg-white'>
            <div className='group relative rounded-t-lg h-2/3'>
            <img
              src={props.img}
              className="rounded-t-lg w-full object-cover"
              alt=''
            />
          </div>
            <Link to={`/productDetail/${props.id}`} className="p-6">
                <h5 className="text-base font-medium ">{props.name}</h5>
                <p className="text-slate-500 text-xs">
                    {props.brand}
                </p>
                <p className='text-lg text-blue-500 font-bold mt-5'>{Intl.NumberFormat().format(props.harga)}</p>
                <MdLocationOn className='text-lg text-slate-500 inline-block' /> <span className='text-xs text-slate-500 -ml-1'>{props.lokasi}</span>
            </Link>
        </div>
    )
}


const ProductList = ({ id }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryParamValue = queryParams.get('q');
    const queryParamKat = queryParams.get('kategori');
    const [kategori, setKategori] = useState(queryParamKat ? queryParamKat : '');
    const [min, setMin] = useState(0)
    const [max, setMax] = useState('')
    const [kota, setKota] = useState('');
    const [page, setPage] = useState(0);

    const [q, setQ] = useState(queryParamValue ? queryParamValue : '')
    const { data, error, isFetching } = useGetListProductQuery({ page: page + 1, kategori: kategori, kota: kota, min: min, max: max, id: id, q: q });

    const HandleKategori = (id) => {
        setKategori(id)
    }

    const HandleKota = (id) => {
        setKota(id)
    }

    const HandleMin = (val) => {
        setMin(val)
    }

    const HandleMax = (val) => {
        setMax(val)
    }

    return (
        <div className='w-full flex flex-col gap-4 '>

            <div className='w-full'>
                <FilterProduct
                    handleKategori={HandleKategori}
                    handleKota={HandleKota}
                    handleMin={HandleMin}
                    handleMax={HandleMax}
                />
            </div>
            <div className='w-full'>
                <div className='grid grid-cols-2 sm:grid-cols-3  lg:grid-cols-4 xl:grid-cols-5 gap-5'>
                    {data ? (data.data.map((produk) => {
                        return (
                            <Product1 key={produk.id} img={`${alamat_storage}fotoProduk/${produk.path}`} name={produk.nama} brand={produk.brand} harga={produk.label_harga} lokasi={produk.kota} id={produk.id} />

                        )
                    })) : isFetching ?
                        (<div>
                            Loading
                        </div>)
                        : <div>Terjadi Kesalahan</div>
                    }

                </div>
                {data ? (
                    <>
                        <Pagination
                            className="flex items-center w-full h-10 text-sm select-none"
                            currentPage={page}
                            edgePageCount={2}
                            middlePagesSiblingCount={1}
                            setCurrentPage={(page) => setPage(page)}
                            totalPages={data.last_page}
                            truncableClassName="w-10 px-0.5 text-center"
                            truncableText="..."
                        >
                            <PrevButton className="flex items-center mr-2 text-slate-500 hover:text-blue-600 dark:hover:text-blue-500 focus:outline-none opacity-50">
                                <MdArrowLeft
                                    className="mr-3"
                                    size={20}
                                />
                                Previous
                            </PrevButton>
                            <div className="flex items-center justify-center flex-grow list-none">
                                <PageButton
                                    activeClassName="bg-primary-50 dark:bg-opacity-0 text-primary-600 dark:text-blue-500"
                                    className="flex items-center justify-center h-10 w-10 rounded-full cursor-pointer"
                                    inactiveClassName="text-slate-500"
                                />
                            </div>
                            <NextButton className="flex items-center mr-2 text-slate-500 hover:text-blue-600 dark:hover:text-blue-500 focus:outline-none cursor-pointer">
                                Next
                                <MdArrowRight
                                    className="ml-3"
                                    size={20}
                                />
                            </NextButton>
                        </Pagination>
                    </>)
                    : isFetching ? (
                        <div>
                            Loading
                        </div>)
                        : <div>Terjadi Kesalahan</div>
                }

            </div>
        </div>
    )
}

export { Product, ProductList, Product1, ProductCategory, ProductRes };
