import React, { useState } from 'react'
import { MdAccountTree, MdArrowLeft, MdAssessment, MdAttachMoney, MdBook, MdBookmark, MdFilterVintage, MdHome, MdImage, MdLocationPin, MdLogout, MdManageAccounts, MdMoney, MdOutlineAllInbox, MdOutlineDeliveryDining, MdPayment, MdPeople, MdSettings, MdShoppingBag, MdShoppingCart } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Outlet } from 'react-router-dom';
import { getToken, getUser, getUserData, logoutUser } from '../actions/auth';
import { alamat_storage } from './api';

const SidebarAkun = () => {
    const dispatch = useDispatch()
    const token = useSelector(getToken);

    const logout = (e) => {

        e.preventDefault();

        dispatch(logoutUser(token));
    }
    const classs = "flex items-center py-4 px-6 h-12 text-gray-700 text-ellipsis hover:text-blue-500 transition duration-300 ease-in-out";
    const activeClass = "flex items-center py-4 px-6 h-12 font-bold text-blue-500 text-ellipsis hover:text-blue-500 transition duration-300 ease-in-out"
    return (
        <div className='content'>

            <div className='block md:flex gap-x-5 w-full'>
                <div className="w-full md:w-60 h-full shadow-md bg-white p-5 mb-4 md:mb-0">
                    <ul className="grid grid-cols-3 md:block">
                        <li className="">
                            <NavLink
                                to="/profile"
                                className={({ isActive }) =>
                                    isActive ? activeClass : classs
                                }>
                                Pengaturan Akun
                            </NavLink>
                        </li>
                        <li className="">
                            <NavLink
                                to="/keranjang"
                                className={({ isActive }) =>
                                    isActive ? activeClass : classs
                                }>
                                Keranjang
                            </NavLink>
                        </li>
                        <li className="">
                            <NavLink
                                to="/transaksiRetail"
                                className={({ isActive }) =>
                                    isActive ? activeClass : classs
                                }>
                                Transaksi
                            </NavLink>
                        </li>
                        <li className="">
                            <NavLink
                                to="/PembayaranRetail"
                                className={({ isActive }) =>
                                    isActive ? activeClass : classs
                                }>
                                Pembayaran
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <Outlet />
            </div>
        </div>

    )
}

const SidebarAdmin = () => {
    const [open, setOpen] = useState(true);

    const classs = "flex gap-x-4 w-full h-full p-2 rounded-md";
    const activeClass = "flex gap-x-4 w-full h-full p-2 bg-white rounded-md"
    const dispatch = useDispatch()
    const token = useSelector(getToken);

    const logout = (e) => {

        e.preventDefault();

        dispatch(logoutUser(token));
    }
    return (

        <div className="flex">
            <div className={` ${open ? "w-72" : "w-20 "} bg-slate-200 h-screen p-5  pt-8 duration-300 fixed left-0 z-50`}>
                <MdArrowLeft
                    className={`absolute cursor-pointer duration-500 -right-3 top-9 w-7 border-dark-purple border-2 rounded-full text-3xl text-blue-500 bg-white  ${!open && "rotate-180 "}`}
                    onClick={() => setOpen(!open)} />
                <div className="flex gap-x-4 items-center">
                    <img src="images/img1.jpg" alt='Foto Profil'
                        className={`w-20 cursor-pointer duration-500 ${open && "rotate-[360deg]"}`} />
                    <h1 className={`text-blue-500 origin-left font-medium text-xl duration-200 ${!open && "scale-0"}`}>
                        Admin
                    </h1>
                </div>
                <ul className="py-6 h-full relative">
                    <div className='overflow-y-scroll overflow-x-hidden overflow-x-visible h-4/5 pb-10 scrollbar-hide'>
                        <li className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                            <NavLink
                                to="/adminHome"
                                className={({ isActive }) =>
                                    isActive ? activeClass : classs
                                }>
                                <MdHome className='text-2xl' />
                                <span className={`${!open && "hidden"} origin-left duration-200`}>
                                    Dashboard
                                </span>
                            </NavLink>

                        </li>
                        <li className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center mt-2`}>
                            <NavLink
                                to="/pegawaiDodolan"
                                className={({ isActive }) =>
                                    isActive ? activeClass : classs
                                }>
                                <MdManageAccounts className='text-2xl' />
                                <span className={`${!open && "hidden"} origin-left duration-200`}>
                                    Pegawai
                                </span>
                            </NavLink>
                        </li>

                        
                            <li className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center mt-2`}>
                                <NavLink
                                    to="/kelolaAkun"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdPeople className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Kelola Akun
                                    </span>
                                </NavLink>
                            </li>
                        <div className='border-t-2 border-slate-300'>
                            <li className={` ${!open && "place-content-center "} flex  rounded-md text-blue-500 items-center gap-x-4 mt-2`}>
                                <span className={`${!open && "pl-0 text-[10px]"}  text-center origin-left duration-200`}>
                                    Transaksi
                                </span>
                            </li>
                            <li className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/pembayaranDistributorAdmin"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdPayment className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Pembayaran Distributor
                                    </span>
                                </NavLink>
                            </li>
                            <li className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/laporanPemasukan"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdBook className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Laporan
                                    </span>
                                </NavLink>
                            </li>
                            <li className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/blacklistRetail"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdBook className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Blacklist Retail
                                    </span>
                                </NavLink>
                            </li>
                        </div>
                        <div className='border-t-2 border-slate-300'>
                            <li className={` ${!open && "place-content-center "} flex  rounded-md text-blue-500 items-center gap-x-4 mt-2`}>
                                <span className={`${!open && "pl-0 text-[10px]"}  text-center origin-left duration-200`}>
                                    Produk
                                </span>
                            </li>
                            <li className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/kelolaLokasi"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdLocationPin className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Kelola Lokasi
                                    </span>
                                </NavLink>

                            </li>
                            <li title='Kualitas Pasaran'
                                className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/kelolaKualitasPasaran"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdAttachMoney className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Kualitas Pasaran
                                    </span>
                                </NavLink>
                            </li>
                            <li className={`flex rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/kelolaKelompokProduk"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdBookmark className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Kelola Kelompok Produk
                                    </span>
                                </NavLink>

                            </li>
                        </div>
                    </div>

                    <li className={`flex w-full absolute bottom-12 rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                        <button
                            onClick={(e) => logout(e)}
                            className="flex gap-x-4 w-full p-2"
                        >
                            <MdLogout className='text-2xl' />
                            <span className={`${!open && "hidden"} origin-left duration-200`}>
                                Log Out
                            </span>
                        </button>

                    </li>
                </ul>
            </div>
            <div className={`${open ? "mr-72" : "mr-20 "} duration-300`}>

            </div>
            <Outlet />
        </div>
    )
}

const SidebarDistributor = () => {
    const [open, setOpen] = useState(true);

    const classs = "flex gap-x-4 w-full h-full p-2 rounded-md";
    const activeClass = "flex gap-x-4 w-full h-full p-2 bg-white rounded-md"
    const userData = useSelector(getUser);

    const dispatch = useDispatch();
    const logout = (e) => {

        e.preventDefault();


        dispatch(logoutUser(localStorage.getItem('token')));
    }
    return (

        <div className="flex">
            <div className={` ${open ? "w-72" : "w-20 "} bg-slate-200 h-screen p-5  pt-8 duration-300 fixed left-0 z-50`}>
                <MdArrowLeft
                    className={`absolute cursor-pointer duration-500 -right-3 top-9 w-7 border-dark-purple border-2 rounded-full text-3xl text-blue-500 bg-white  ${!open && "rotate-180 "}`}
                    onClick={() => setOpen(!open)} />
                <div className="flex gap-x-4 items-center">
                    <img src={`${alamat_storage}/distributorProfil/${userData ? userData[0].foto_profil : ''}`} alt='Foto Profil'
                        className={`h-16 object-cover cursor-pointer duration-500 ${open && "rotate-[360deg]"}`} />
                    <h1 className={`text-blue-500 origin-left font-medium text-xl duration-200 ${!open && "scale-0"}`}>
                        {userData ? userData[0].nama : ''}
                    </h1>
                    <NavLink to="/distributorProfile">
                        <MdSettings className={`duration-200 ${!open && "scale-0"}`} />
                    </NavLink>
                </div>
                <ul className="pt-6 relative h-full">
                    <div className='overflow-y-scroll overflow-x-hidden overflow-x-visible h-4/5 pb-10 scrollbar-hide'>
                        <li title='Dashboard'
                            className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>

                            <NavLink
                                to="/DistributorHome"
                                className={({ isActive }) =>
                                    isActive ? activeClass : classs
                                }>
                                <MdHome className='text-2xl' />
                                <span className={`${!open && "hidden"} origin-left duration-200`}>
                                    Dashboard
                                </span>
                            </NavLink>
                        </li>

                        <div className='border-y-2 border-slate-300'>
                            <li className={` ${!open && "place-content-center "} flex  rounded-md text-blue-500 items-center gap-x-4 mt-2`}>
                                <span className={`${!open && "pl-0 text-[10px]"}  text-center origin-left duration-200`}>
                                    Pegawai
                                </span>
                            </li>

                            <li title='Pegawai'
                                className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>

                                <NavLink
                                    to="/kelolaPegawai"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdPeople className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Pegawai
                                    </span>
                                </NavLink>
                            </li>
                            <li title='Jabatan'
                                className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>

                                <NavLink
                                    to="/kelolaJabatan"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdManageAccounts className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Jabatan
                                    </span>
                                </NavLink>
                            </li>
                            <li title='Jabatan Pegawai'
                                className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>

                                <NavLink
                                    to="/kelolaJabatanPegawai"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdAccountTree className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Jabatan Pegawai
                                    </span>
                                </NavLink>
                            </li>
                        </div>

                        <div className='border-y-2 border-slate-300'>
                            <li className={` ${!open && "place-content-center "} flex  rounded-md text-blue-500 items-center gap-x-4 mt-2`}>
                                <span className={`${!open && "pl-0 text-[10px]"}  text-center origin-left duration-200`}>
                                    Produk
                                </span>
                            </li>

                            <li title='Penjualan'
                                className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/kelolaOpsiPengiriman"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdOutlineDeliveryDining className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Opsi Pengiriman
                                    </span>
                                </NavLink>

                            </li>

                            <li className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}
                                title="Produk">
                                <NavLink
                                    to="/kelolaProduk"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdOutlineAllInbox className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Produk
                                    </span>
                                </NavLink>
                            </li>
                            <li title='Brand'
                                className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/kelolaBrand"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdShoppingBag className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Brand
                                    </span>
                                </NavLink>
                            </li>
                            <li title='Warna dan Ukuran'
                                className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/kelolaWarnaUkuran"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdFilterVintage className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Warna & Ukuran
                                    </span>
                                </NavLink>
                            </li>
                            <li title='Foto Produk'
                                className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/kelolaFotoProduk"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdImage className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Foto Produk
                                    </span>
                                </NavLink>
                            </li>

                        </div>

                        <div className='border-y-2 border-slate-300'>
                            <li className={` ${!open && "place-content-center "} flex  rounded-md text-blue-500 items-center gap-x-4 mt-2`}>
                                <span className={`${!open && "pl-0 text-[10px]"}  text-center origin-left duration-200`}>
                                    Transaksi
                                </span>
                            </li>
                            <li title='Pembelian'
                                className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/kelolaPembelian"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdShoppingCart className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Kelola Pembelian
                                    </span>
                                </NavLink>

                            </li>
                            <li title='Penjualan'
                                className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/kelolaPenjualan"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdShoppingBag className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Kelola Penjualan
                                    </span>
                                </NavLink>

                            </li>
                            <li title='Penjualan'
                                className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/PembayaranRetailDist"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdAttachMoney className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Pembayaran Retail
                                    </span>
                                </NavLink>

                            </li>
                            <li title='Penjualan'
                                className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/pembayaranDistributor"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdMoney className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Pembayaran Distributor
                                    </span>
                                </NavLink>

                            </li>
                        </div>
                        <div className='border-y-2 border-slate-300'>
                            <li className={` ${!open && "place-content-center "} flex  rounded-md text-blue-500 items-center gap-x-4 mt-2`}>
                                <span className={`${!open && "pl-0 text-[10px]"}  text-center origin-left duration-200`}>
                                    Laporan
                                </span>
                            </li>

                            <li title='Penjualan'
                                className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/laporanTransaksiDistributor"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdAssessment className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Laporan Transaksi
                                    </span>
                                </NavLink>

                            </li>
                            <li title='Penjualan'
                                className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/laporanPerhitunganPajak"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdAssessment className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Laporan Perhitungan Pajak
                                    </span>
                                </NavLink>
                            </li>
                            <li title='Penjualan'
                                className={`flex  rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                                <NavLink
                                    to="/laporanOmset"
                                    className={({ isActive }) =>
                                        isActive ? activeClass : classs
                                    }>
                                    <MdAssessment className='text-2xl' />
                                    <span className={`${!open && "hidden"} origin-left duration-200`}>
                                        Perhitungan Omset
                                    </span>
                                </NavLink>
                            </li>

                        </div>

                    </div>

                    <li title='Log Out'
                        className={`flex w-full absolute bottom-12 rounded-md cursor-pointer hover:bg-white text-blue-500  items-center gap-x-4 mt-2`}>
                        <button
                            onClick={(e) => logout(e)}
                            className="flex gap-x-4 w-full p-2"
                        >
                            <MdLogout className='text-2xl' />
                            <span className={`${!open && "hidden"} origin-left duration-200`}>
                                Log Out
                            </span>
                        </button>

                    </li>
                </ul>
            </div>
            <div className={`${open ? "mr-72" : "mr-20 "} duration-300`}>

            </div>
            <Outlet />

        </div>
    )
}

export { SidebarAkun, SidebarAdmin, SidebarDistributor }