import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getToken, getUser, logoutUser } from '../actions/auth'
import { getDetailDistributor } from '../actions/distributorAction';
import { getDetailRetail } from '../actions/retailAction';
import secureLocalStorage from 'react-secure-storage';
import UserMessage from '../Pages/UserMessage';

const AuthRequirement = () => {
    const token = useSelector(getToken);
    const location = useLocation();

    return (
        token
            ? <Outlet />
            : <Navigate to="/Login" state={{ from: location }} replace />
    )
}


const AdminRoleCheck = (props) => {
    const userData = getUser();
    const location = useLocation();


    return (
        userData.user_role === props.role && props.role === "Admin"
            ? <Outlet />
            : userData.user_role === "Retail" ? <Navigate to="/profile" state={{ from: location }} replace />
                : userData.user_role === "Distributor" ? <Navigate to="/kelolaPegawai" state={{ from: location }} replace={true} />
                    : <Navigate to="/Login" state={{ from: location }} replace />

    )
}

const DistRoleCheck = (props) => {
    const userData = getUser();
    const location = useLocation();

    const dispatch = useDispatch()
    if (userData.user_role === props.role && props.role === 'Distributor') {
        dispatch(getDetailDistributor(userData.id));
    }

    return (
        userData.user_role === props.role && props.role === "Distributor" && userData[0].status === 'Aktif'
            ? <Outlet />
            : userData.user_role === props.role && props.role === "Distributor" && userData[0].status !== 'Aktif' ? <UserMessage role={userData.user_role} status={userData[0].status} nama={userData[0].nama} />
                : userData.user_role === "Retail" ? <Navigate to="/profile" state={{ from: location }} replace />
                    : userData.user_role === "Admin" ? <Navigate to="/kelolaAkun" state={{ from: location }} replace={true} />
                        : <Navigate to="/Login" state={{ from: location }} replace />

    )
}

const RetailRoleCheck = (props) => {
    const userData = getUser();
    const location = useLocation();

    const dispatch = useDispatch()
    useEffect(() => {
        if (userData.user_role === props.role && props.role === 'Retail') {
            dispatch(getDetailRetail(userData.id))
        }
    }, [dispatch])


    return (
        userData.user_role === props.role && props.role === "Retail" && userData[0].status === 'Aktif'
            ? <Outlet />
            : userData.user_role === props.role && props.role === "Retail" && userData[0].status !== 'Aktif' ? <UserMessage role = {userData.user_role} status={userData[0].status} nama={userData[0].nama} />
                : userData.user_role === "Distributor" ? <Navigate to="/kelolaPegawai" state={{ from: location }} replace />
                    : userData.user_role === "Admin" ? <Navigate to="/kelolaAkun" state={{ from: location }} replace={true} />
                        : <Navigate to="/Login" state={{ from: location }} replace />

    )
}


const StatusCheck = () => {
    const [isLoading, setIsLoading] = useState(true);
    const userStatus = JSON.parse(secureLocalStorage.getItem('distRetData'));
    const status = userStatus ? userStatus.status : null;
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(logoutUser(localStorage.getItem('token')));
    };

    useEffect(() => {
        // Simulate a delay of 1 second before setting the loading state to false
        const delay = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(delay); // Clear the timeout when the component is unmounted
    }, []);

    if (isLoading) {
        return <p>Loading...</p>; // Render a loading state or fallback UI
    }

    if (status === 'Aktif') {
        return <Outlet />;
    } else {
        logout();
        return null; // You can choose to render a fallback UI or leave it empty
    }

}


export { AuthRequirement, AdminRoleCheck, RetailRoleCheck, DistRoleCheck, StatusCheck }