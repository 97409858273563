import axios from "axios";
import {alamat_backend} from "../components/api";


export const GET_LIST_KABKOT = "GET_LIST_KABKOT"
export const GET_DATA_LIST_KABKOT = "GET_DATA_LIST_KABKOT"
export const DELETE_KABKOT = "DELETE_KABKOT"
export const ADD_KABKOT = "ADD_KABKOT"
export const UPDATE_KABKOT = "UPDATE_KABKOT"


const getListKabkot = () => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_LIST_KABKOT,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'LokasiKotKabList',
            timeout: 120000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_LIST_KABKOT,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                console.log("gagal", error.message)
                dispatch({
                    type: GET_LIST_KABKOT,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const getDataListKabkot = () => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_DATA_LIST_KABKOT,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'LokasiKotKabDataList',
            timeout: 120000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_DATA_LIST_KABKOT,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                console.log("gagal", error.message)
                dispatch({
                    type: GET_DATA_LIST_KABKOT,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const addKabkot = (data) => {
    console.log("action")
    return (dispatch) => {

        //loading
        dispatch({
            type: ADD_KABKOT,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'LokasiKotKab',
            timeout: 120000,
            data: data
        })
            .then((Response) => {
                console.log(Response.data)
                //berhasil get api
                dispatch({
                    type: ADD_KABKOT,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                console.log("gagal", error.message)
                dispatch({
                    type: ADD_KABKOT,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const updateKabkot = (data) => {
    console.log("action")
    return (dispatch) => {

        //loading
        dispatch({
            type: UPDATE_KABKOT,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'PUT',
            url: alamat_backend + 'LokasiKotKab/' + data.id,
            timeout: 120000,
            data: data
        })
            .then((Response) => {
                console.log(Response.data)
                //berhasil get api
                dispatch({
                    type: UPDATE_KABKOT,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                console.log("gagal", error.message)
                dispatch({
                    type: UPDATE_KABKOT,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const deleteKabkot = (id) => {
    console.log("action")
    return (dispatch) => {

        //loading
        dispatch({
            type: DELETE_KABKOT,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'DELETE',
            url: alamat_backend + 'LokasiKotKab/'+id,
            timeout: 120000,
        })
            .then((Response) => {
                dispatch({
                    type: DELETE_KABKOT,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                console.log("gagal", error.message)
                dispatch({
                    type: DELETE_KABKOT,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

export { getListKabkot, addKabkot, getDataListKabkot, deleteKabkot, updateKabkot }