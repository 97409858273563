import { ADD_PRODUK_STOK_DETAIL, DELETE_PRODUK_STOK_DETAIL, GET_LIST_PRODUK_STOK_DETAIL, GET_LIST_PRODUK_STOK_DETAIL_SUM, UPDATE_PRODUK_STOK_DETAIL } from "../../actions/produkStokDetail"

const initialState = {
    getListStokDetailResult: false,
    getListStokDetailLoading: false,
    getListStokDetailError: false,

    getListStokDetailSumResult: false,
    getListStokDetailSumLoading: false,
    getListStokDetailSumError: false,

    addStokDetailResult: false,
    addStokDetailLoading: false,
    addStokDetailError: false,

    deleteStokDetailResult: false,
    deleteStokDetailLoading: false,
    deleteStokDetailError: false,

    updateStokDetailResult: false,
    updateStokDetailLoading: false,
    updateStokDetailError: false,
}


const produkStokDetail = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_PRODUK_STOK_DETAIL:
            return {
                ...state,
                getListStokDetailResult: action.payload.data,
                getListStokDetailLoading: action.payload.loading,
                getListStokDetailError: action.payload.errorMessage,
            }
        case GET_LIST_PRODUK_STOK_DETAIL_SUM:
            return {
                ...state,
                getListStokDetailSumResult: action.payload.data,
                getListStokDetailSumLoading: action.payload.loading,
                getListStokDetailSumError: action.payload.errorMessage,
            }

        case ADD_PRODUK_STOK_DETAIL:
            return {
                ...state,
                addStokDetailResult: action.payload.data,
                addStokDetailLoading: action.payload.loading,
                addStokDetailError: action.payload.errorMessage,
            }
        case DELETE_PRODUK_STOK_DETAIL:
            return {
                ...state,
                deleteStokDetailResult: action.payload.data,
                deleteStokDetailLoading: action.payload.loading,
                deleteStokDetailError: action.payload.errorMessage,
            }
        case UPDATE_PRODUK_STOK_DETAIL:
            return {
                ...state,
                updateStokDetailResult: action.payload.data,
                updateStokDetailLoading: action.payload.loading,
                updateStokDetailError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default produkStokDetail;