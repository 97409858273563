import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const ModalTahunanApi = createApi({
    reducerPath: 'ModalTahunan',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getModalTahunan: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'ModalTahunan' }];

                },
                query: () => {
                    return {
                        url: 'ModalTahunan',
                        method: 'GET',
                    };
                },
            }),
            getListModalTahunan: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'ModalTahunan' }];

                },
                query: (id) => {
                    return {
                        url: 'ModalTahunanList',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            addModalTahunan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'ModalTahunan' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'ModalTahunan',
                        body: data,
                    };
                },
            }),
            updateModalTahunan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'ModalTahunan' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `ModalTahunan/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteModalTahunan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'ModalTahunan' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `ModalTahunan/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetModalTahunanQuery, useGetListModalTahunanQuery, useAddModalTahunanMutation, useUpdateModalTahunanMutation, useDeleteModalTahunanMutation } = ModalTahunanApi;
export { ModalTahunanApi };