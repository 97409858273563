import axios from "axios";
import {alamat_backend} from "../components/api";


export const GET_LIST_SUB_KATEGORI_PRODUK = "GET_LIST_SUB_KATEGORI_PRODUK"
export const DELETE_SUB_KATEGORI_PRODUK = "DELETE_SUB_KATEGORI_PRODUK"
export const ADD_SUB_KATEGORI_PRODUK = "ADD_SUB_KATEGORI_PRODUK"
export const UPDATE_SUB_KATEGORI_PRODUK = "UPDATE_SUB_KATEGORI_PRODUK"


const getListSubKategori = () => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_LIST_SUB_KATEGORI_PRODUK,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'ProdukSubKategoriList',
            timeout: 120000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_LIST_SUB_KATEGORI_PRODUK,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_LIST_SUB_KATEGORI_PRODUK,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}

const addSubKategori = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: ADD_SUB_KATEGORI_PRODUK,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'ProdukSubKategori',
            timeout: 120000,
            data: data,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: ADD_SUB_KATEGORI_PRODUK,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: ADD_SUB_KATEGORI_PRODUK,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })
    }
}

const updateSubKategori = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: UPDATE_SUB_KATEGORI_PRODUK,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'PUT',
            url: alamat_backend + 'ProdukSubKategori/' + data.id,
            timeout: 5000,
            data: data
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: UPDATE_SUB_KATEGORI_PRODUK,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: UPDATE_SUB_KATEGORI_PRODUK,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.response.data
                    }
                })
            })
    }
}


const deleteSubKategori = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: DELETE_SUB_KATEGORI_PRODUK,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'DELETE',
            url: alamat_backend + 'ProdukSubKategori/' + id,
            timeout: 120000,
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: DELETE_SUB_KATEGORI_PRODUK,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: DELETE_SUB_KATEGORI_PRODUK,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })
    }
}


export { getListSubKategori, addSubKategori, deleteSubKategori, updateSubKategori }