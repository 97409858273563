import React, { useEffect, useState } from 'react'
import { getToken, logoutUser } from '../actions/auth';
import { useDispatch, useSelector } from 'react-redux';

const UserMessage = ({ role, nama, status }) => {
    const dispatch = useDispatch()
    const token = useSelector(getToken);
    const [isDispatched, setDispatched] = useState(false);

    useEffect(() => {
        if (role === 'Distributor' && !isDispatched) {
            dispatch(logoutUser(token));
            setDispatched(true);
        }
    }, [role, isDispatched, dispatch, token]);

    const logout = () => {
        if (role === 'Retail') {
            dispatch(logoutUser(token));
        } else {
            window.location.replace('/Login')
        }
    }

    return (
        <div className='w-screen h-screen flex justify-center items-center'>
            <div className=' w-80 h-80 bg-white drop-shadow-xl  p-8'>

                <div>
                    <h1 className='text-3xl text-blue-500 font-bold text-center mb-8'>Halo, {nama}</h1>
                    <div className='text-justify'>
                        Akun anda sedang dalam proses verifikasi admin dengan status: <span className='text-blue-500 font-bold'> {status}</span>,
                        Silahkan hubungi admin Konditail jika ada kendala
                    </div>
                    <button onClick={() => logout()} className=' text-blue-500 mt-8 rounded-lg' >
                        Kembali ke halaman login
                    </button>
                </div>


            </div>

        </div >
    )
}

export default UserMessage