import React, { Fragment, useState } from 'react'
import { useGetDetailPengaduanQuery, useGetPengaduanAdminQuery, useUpdatePengaduanMutation } from '../../store/apis/blacklistApi';
import { format } from 'date-fns';
import DataTable from 'react-data-table-component';
import { Tab } from '@headlessui/react';
import ContactPopup from '../../components/ContactPopup';

const BlackListRetail = () => {
    const [status, setStatus] = useState('Dikirim');
    const { data, isFetching, error } = useGetPengaduanAdminQuery(status);
    const [detailModal, setDetailModal] = useState(false);
    const [distributor, setDistributor] = useState('');
    const [id, setId] = useState('');
    const [retail, setRetail] = useState('');
    const [tel, setTel] = useState('');
    const [contactPopup, setcontactPopup] = useState(false);

    const HandleContact = (tel) => {
        if (contactPopup) {
            setTel('');
            setcontactPopup(false);
        } else {
            setTel(tel);
            setcontactPopup(true);
        }
    }

    const handleChange = (index) => {
        switch (index) {
            case 0:
                setStatus('Dikirim')
                break;
            case 1:
                setStatus('Diterima')
                break;
            case 2:
                setStatus('Dicabut')
                break;
            case 3:
                setStatus('Ditolak')
                break;
            default:
                break;
        }
    }

    const HandleClick = (retail, distributor, id) => {

        if (detailModal) {
            setDistributor('');
            setRetail('');
            setId('');
            setDetailModal(false);
        } else {
            setDistributor(distributor);
            setRetail(retail);
            setId(id);
            setDetailModal(true);
        }

    }
    const col = [
        {
            name: 'Tanggal',
            selector: row => format(new Date(row.datetime), 'dd-MM-yyyy'),
            sortable: true,
        },
        {
            name: 'Retail',
            selector: row => row.retail.nama,
            sortable: true,
            cell: (record) => {
                return (
                    <Fragment>
                        <button
                            onClick={() => HandleContact(record.retail.nomor_telp)}
                            className='text-blue-500'
                        >
                            {record.retail.nama}
                        </button>
                    </Fragment>
                );
            },
        },
        {
            name: 'Distributor',
            selector: row => row.distributor.nama,
            sortable: true,
            width: 'auto',
            cell: (record) => {
                return (
                    <Fragment>
                        <button
                            onClick={() => HandleContact(record.distributor.nomor_telp)}
                            className='text-blue-500'
                        >
                            {record.distributor.nama}
                        </button>
                    </Fragment>
                );
            },
        },
        {
            name: "Alasan",
            sortable: false,
            selector: row => row.alasan,
            grow: 5,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                return (
                    <Fragment>
                        <button
                            type="submit"
                            onClick={() => HandleClick(record.retail, record.distributor, record.id)}
                            className="text-blue-500 cursor-pointer"
                        >
                            Lihat Detail
                        </button>
                    </Fragment>
                );
            },
        },
    ]
    return (
        <div className='w-full  p-5  '>
            <div className='w-full bg-white border p-5 rounded-lg '>
                {contactPopup && <ContactPopup tel={tel} contactModal={HandleContact} />}
                {data && (
                    detailModal && <BlackListRetailDetail detailModal={HandleClick} retail={retail} distributor={distributor} status={status} id={id} />
                )}
                <Tab.Group onChange={(index) => handleChange(index)}>

                    <Tab.List>

                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Diajukan</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Diterima</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Ditarik</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500' : ''}`}>Ditolak</div>
                            )}
                        </Tab>

                    </Tab.List>
                    <Tab.Panels className={`mt-5`}>
                        <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                            <div className='col-span-3 self-center'>
                                <h1 className='text-base font-bold text-blue-500 '>Data Blacklist Retail</h1>
                                <p className='text-slate-400'>Di bawah ini merupakan data blacklist retail yang {status}.</p>
                            </div>
                            <div className='col-span-3' />

                        </div>
                        {data ? (
                            <DataTable columns={col} data={data} pagination />
                        ) : isFetching ? (
                            <p>loading</p>
                        ) : (
                            <p>{error ? error : "Data kosong"}</p>
                        )}
                    </Tab.Panels>
                </Tab.Group>
            </div>

        </div>
    )
}


const BlackListRetailDetail = ({ detailModal, retail, distributor, status, id }) => {
    const { data, isFetching, error } = useGetDetailPengaduanQuery({ idR: retail.id, idD: distributor.id });
    const [updatePengaduan] = useUpdatePengaduanMutation();
    const currentDate = new Date();

    const HandleSubmit = (e, status) => {
        e.preventDefault();

        updatePengaduan({ id: id, status: status })
        detailModal()
    }

    const col = [
        {
            name: 'Nomor Invoice',
            cell: (record) => {
                return (
                    <Fragment>
                        {record.penjualan.nomor_invoice}
                    </Fragment>
                );
            },
            grow: 3,
            sortable: true,
        },
        {
            name: "Tanggal Jatuh Tempo",
            sortable: false,
            cell: (record) => {
                return (
                    <Fragment>
                        {Math.round((new Date(record.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) <= 7 && Math.round((new Date(record.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) >= 0 ? (
                            <div className='text-amber-500'>
                                {format(new Date(record.tanggal_jatuh_tempo), 'dd-MM-yyyy')}
                            </div>
                        ) : Math.round((new Date(record.tanggal_jatuh_tempo) - currentDate) / (1000 * 60 * 60 * 24)) < 0 ? (
                            <div className='text-red-500'>
                                {format(new Date(record.tanggal_jatuh_tempo), 'dd-MM-yyyy')}
                            </div>
                        ) :
                            <div>
                                {format(new Date(record.tanggal_jatuh_tempo), 'dd-MM-yyyy')}
                            </div>
                        }
                    </Fragment>
                );
            },
        },
        {
            name: 'Total Tagihan',
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.totalTagihan),
            sortable: true,
            grow: 2,
        },
        {
            name: 'Total Terbayar',
            grow: 2,
            cell: (record) => {
                return (
                    <Fragment>
                        {record.totalTerbayar ? record.totalTerbayar >= record.totalTagihan ? (
                            <div className='text-green-500'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(record.totalTerbayar)}</div>
                        ) : Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(record.totalTerbayar) : 0}
                    </Fragment>
                );
            },
            sortable: true,
            width: 'auto',
        },
    ]

    return (
        <div
            className="bg-slate-500/25 h-screen justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[5000] outline-none focus:outline-none"
        >
            <div className="relative h-full mx-auto">
                {/*content*/}
                <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-xl font-semibold">
                            Detail Pengaduan
                        </h3>
                        <button
                            className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => detailModal()}
                        >
                            <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                x
                            </span>
                        </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                        <div>
                            <div>
                                Daftar transaksi yang telah dilakukan
                            </div>
                        </div>
                        {data ? (
                            <DataTable columns={col} data={data} />
                        ) : isFetching ? (
                            <p>loading</p>
                        ) : (
                            <p>{error ? error : "Data kosong"}</p>
                        )}
                    </div>
                    {/* footer */}
                    <div className="flex gap-2 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        {status === 'Dikirim' && (
                            <>
                                <button
                                    className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={(event) => HandleSubmit(event, 'Diterima')}
                                >
                                    Terima
                                </button>
                                <button
                                    className="bg-red-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={(event) => HandleSubmit(event, 'Ditolak')}
                                >
                                    Tolak
                                </button>
                            </>
                        )}
                        {status === 'Diterima' && (
                            <>
                                <button
                                    className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={(event) => HandleSubmit(event, 'Dicabut')}
                                >
                                    Cabut Blacklist
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>

        </div>
    )
}


export default BlackListRetail