import axios from "axios";
import {alamat_backend} from "../components/api";


export const GET_LIST_PRODUK_STOK = "GET_LIST_PRODUK_STOK"
export const DELETE_PRODUK_STOK = "DELETE_PRODUK_STOK"
export const ADD_PRODUK_STOK = "ADD_PRODUK_STOK"
export const UPDATE_PRODUK_STOK = "UPDATE_PRODUK_STOK"


const getListStok = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_LIST_PRODUK_STOK,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'ProdukStokList?id=' + id,
            timeout: 120000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_LIST_PRODUK_STOK,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_LIST_PRODUK_STOK,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}


const addStok = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: ADD_PRODUK_STOK,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'ProdukStok',
            timeout: 120000,
            data: data,
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: ADD_PRODUK_STOK,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: ADD_PRODUK_STOK,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.response.data,
                    }
                })
            })
    }
}

const updateStok = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: UPDATE_PRODUK_STOK,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'PUT',
            url: alamat_backend + 'ProdukStok/'+ data.id,
            timeout: 120000,
            data: data,
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: UPDATE_PRODUK_STOK,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: UPDATE_PRODUK_STOK,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error
                    }
                })
            })
    }
}

const deleteStok = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: DELETE_PRODUK_STOK,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'DELETE',
            url: alamat_backend + 'ProdukStok/'+ id,
            timeout: 120000,
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: DELETE_PRODUK_STOK,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: DELETE_PRODUK_STOK,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })
    }
}


export { getListStok, addStok, deleteStok, updateStok }