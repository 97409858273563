import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { alamat_backend } from '../../components/api';

const laporanApi = createApi({
    reducerPath: 'laporan',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getLaporan: builder.query({
                query: ({ start, end, id }) => {
                    return {
                        url: 'LaporanTransaksiDistributor',
                        method: 'GET',
                        params: { start: start, end: end, id: id }
                    };
                },
            }),
            getLaporanAdmin: builder.query({
                query: ({ start, end, id }) => {
                    return {
                        url: 'LaporanPemasukanAdmin',
                        method: 'GET',
                        params: { start: start, end: end}
                    };
                },
            }),
            getLaporanPajak: builder.query({
                query: ({ start, end, id }) => {
                    return {
                        url: 'LaporanPerhitunganPajak',
                        method: 'GET',
                        params: { start: start, end: end, id: id }
                    };
                },
            }),
            getLaporanOmset: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'omset' }];

                },
                query: ({ id, year }) => {
                    return {
                        url: 'LaporanOmset',
                        method: 'GET',
                        params: { id: id, year: year }
                    };
                },
            }),
            getDataDashboardDistributor: builder.query({
                query: (id) => {
                    return {
                        url: 'DashboardDistributor',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            getDataDashboardAdmin: builder.query({
                query: (id) => {
                    return {
                        url: 'DashboardAdmin',
                        method: 'GET',
                    };
                },
            }),
            addModalTahunan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'omset' }];
                },
                query: (data) => {

                    return {
                        method: 'POST',
                        url: 'ModalTahunan',
                        body: data,
                    };
                },
            }),
        };
    },
});

export const { useGetLaporanQuery, useGetLaporanPajakQuery, useGetLaporanAdminQuery, useGetDataDashboardDistributorQuery, useGetDataDashboardAdminQuery, useGetLaporanOmsetQuery, useAddModalTahunanMutation } = laporanApi;
export { laporanApi };