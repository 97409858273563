import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const produkBrandApi = createApi({
    reducerPath: 'produkBrand',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getListBrand: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'Brand' }];

                },
                query: (id) => {
                    return {
                        url: 'ProdukBrandList',
                        method: 'GET',
                        params: { id }
                    };
                },
            }),
            addBrand: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Brand' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'ProdukBrand',
                        body: data,
                    };
                },
            }),
            updateBrand: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Brand' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `ProdukBrand/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteBrand: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Brand' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `ProdukBrand/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetListBrandQuery, useAddBrandMutation, useUpdateBrandMutation, useDeleteBrandMutation } = produkBrandApi;
export { produkBrandApi };