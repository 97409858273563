import { Combobox, RadioGroup, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react'
import { useGetListSubKategoriProdukQuery } from '../store/apis/subKategoriProdukApi';
import { useGetUkuranQuery } from '../store/apis/produkUkuranApi';
import { useGetWarnaQuery } from '../store/apis/produkWarnaApi';
import { MdCheck, MdUnfoldMore } from 'react-icons/md';
import { useGetDistributorKotaQuery } from '../store/apis/distributorApi';
import { useGetListSubSubKategoriProdukQuery } from '../store/apis/subSubKategoriProdukApi';

const FilterProduct = ({handleKategori, handleKota, handleMin, handleMax}) => {
    const [min, setMin] = useState('');
    const [max, setMax] = useState('');
    const [selectedCategory, setSelectedCategory] = useState({ 'nama': 'Pilih Kategori' })
    const [selectedKota, setSelectedKota] = useState({ 'nama': 'Pilih Kota' })
    const [query, setQuery] = useState('')
    const categoryResult = useGetListSubSubKategoriProdukQuery().data;
    const citiesResult = useGetDistributorKotaQuery().data;

    const HandleCategory = (val) => {
        setSelectedCategory(val);
        handleKategori(val.id)
    }

    const HandleKota = (val) => {
        setSelectedKota(val);
        handleKota(val.id);
    }

    const HandleMin = (val) => {
        setMin(val);
        handleMin(val);
    }

    const HandleMax = (val) => {
        setMax(val);
        handleMax(val);
    }
    
    const filteredKategori =
        categoryResult ?
            (query === ''
                ? categoryResult
                : categoryResult.filter((kategori) =>
                    kategori.nama
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .includes(query.toLowerCase().replace(/\s+/g, ''))
                )):[];

    const filteredCities =
    citiesResult?
        (query === ''
            ? citiesResult
            : citiesResult.filter((kota) =>
                kota.nama
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )):[]




    return (
        <div className='w-full gap-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 bg-white p-4 rounded-lg'>
            <div>
                <p className=" text-slate-500  ">kategori</p>

                <Combobox value={selectedCategory} onChange={HandleCategory}>

                    <div className="relative mt-1">
                        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-500 sm:text-sm">
                            <Combobox.Input
                                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                displayValue={(kategori) => kategori.nama}
                                onChange={(event) => setQuery(event.target.value)}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <MdUnfoldMore />

                            </Combobox.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setQuery('')}
                        >
                            <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredKategori.length === 0 && query !== '' ? (
                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                        Nothing found.
                                    </div>
                                ) : (
                                    filteredKategori.map((kategori) => (
                                        <Combobox.Option
                                            key={kategori.id}
                                            className={({ active }) =>
                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-500 text-white' : 'text-gray-900'
                                                }`
                                            }
                                            value={kategori}
                                        >
                                            {({ selectedCategory, active }) => (
                                                <>
                                                    <span
                                                        className={`block truncate ${selectedCategory ? 'font-medium' : 'font-normal'
                                                            }`}
                                                    >
                                                        {kategori.nama}
                                                    </span>
                                                    {selectedCategory ? (
                                                        <span
                                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-blue-500'
                                                                }`}
                                                        >
                                                            <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Combobox.Option>
                                    ))
                                )}
                            </Combobox.Options>

                        </Transition>
                    </div>
                </Combobox>
            </div>
            <div>
                <p className=" text-slate-500 ">Lokasi</p>

                <Combobox value={selectedKota} onChange={HandleKota}>

                    <div className="relative mt-1">
                        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-500 sm:text-sm">
                            <Combobox.Input
                                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                displayValue={(kota) => kota.nama}
                                onChange={(event) => setQuery(event.target.value)}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <MdUnfoldMore />

                            </Combobox.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setQuery('')}
                        >
                            <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredCities.length === 0 && query !== '' ? (
                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                        Nothing found.
                                    </div>
                                ) : (
                                    filteredCities.map((kota) => (
                                        <Combobox.Option
                                            key={kota.id}
                                            className={({ active }) =>
                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-500 text-white' : 'text-gray-900'
                                                }`
                                            }
                                            value={kota}
                                        >
                                            {({ selectedKota, active }) => (
                                                <>
                                                    <span
                                                        className={`block truncate ${selectedKota ? 'font-medium' : 'font-normal'
                                                            }`}
                                                    >
                                                        {kota.nama}
                                                    </span>
                                                    {selectedKota ? (
                                                        <span
                                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-blue-500'
                                                                }`}
                                                        >
                                                            <MdCheck className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Combobox.Option>
                                    ))
                                )}
                            </Combobox.Options>

                        </Transition>
                    </div>
                </Combobox>
            </div>
            <div>
                <p className=" text-slate-500 mb-1">Batas Harga</p>

                <div className='flex gap-4'>

                    <div className="relative z-0 ">
                        <input
                            type="number"
                            value={min}
                            onChange={(event) => HandleMin(event.target.value)}
                            placeholder='Min'
                            className="bg-white rounded-lg block py-2 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                    </div>
                    <div>
                        ___
                    </div>
                    <div className="relative z-0">
                        <input
                            type="number"
                            value={max}
                            onChange={(event) => HandleMax(event.target.value)}
                            placeholder='Max'
                            className="bg-white block rounded-lg py-2 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterProduct