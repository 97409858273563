import { ADD_SUB_KATEGORI_PRODUK, DELETE_SUB_KATEGORI_PRODUK, GET_LIST_SUB_KATEGORI_PRODUK, UPDATE_SUB_KATEGORI_PRODUK } from "../../actions/subKategoriProdukAction";

const initialState = {
    getListSubKategoriResult: false,
    getListSubKategoriLoading: false,
    getListSubKategoriError: false,

    addSubKategoriResult: false,
    addSubKategoriLoading: false,
    addSubKategoriError: false,

    updateSubKategoriResult: false,
    updateSubKategoriLoading: false,
    updateSubKategoriError: false,

    deleteSubKategoriResult: false,
    deleteSubKategoriLoading: false,
    deleteSubKategoriError: false,
}


const subKategori = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_SUB_KATEGORI_PRODUK:
            return {
                ...state,
                getListSubKategoriResult: action.payload.data,
                getListSubKategoriLoading: action.payload.loading,
                getListSubKategoriError: action.payload.errorMessage,
            }

        case ADD_SUB_KATEGORI_PRODUK:
            return {
                ...state,
                addSubKategoriResult: action.payload.data,
                addSubKategoriLoading: action.payload.loading,
                addSubKategoriError: action.payload.errorMessage,
            }

        case UPDATE_SUB_KATEGORI_PRODUK:
            return {
                ...state,
                updateSubKategoriResult: action.payload.data,
                updateSubKategoriLoading: action.payload.loading,
                updateSubKategoriError: action.payload.errorMessage,
            }
        case DELETE_SUB_KATEGORI_PRODUK:
            return {
                ...state,
                deleteSubKategoriResult: action.payload.data,
                deleteSubKategoriLoading: action.payload.loading,
                deleteSubKategoriError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default subKategori;