import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const pegawaiDodolanApi = createApi({
    reducerPath: 'pegawaiDodolan',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getPegawaiDodolan: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'Pegawai' }];

                },
                query: () => {
                    return {
                        url: 'PegawaiDodolan',
                        method: 'GET',
                    };
                },
            }),
            addPegawaiDodolan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Pegawai' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'PegawaiDodolan',
                        body: data,
                    };
                },
            }),
            updatePegawaiDodolan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Pegawai' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `PegawaiDodolan/${data.id}`,
                        body: data,
                    };
                },
            }),
            deletePegawaiDodolan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'Pegawai' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `PegawaiDodolan/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetPegawaiDodolanQuery, useAddPegawaiDodolanMutation, useUpdatePegawaiDodolanMutation, useDeletePegawaiDodolanMutation } = pegawaiDodolanApi;
export { pegawaiDodolanApi };