import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { alamat_backend } from '../../components/api';

const produkApi = createApi({
    reducerPath: 'produk',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            getListProduct: builder.query({

                query: (data) => {
                    return {
                        url: `ProdukListData`,
                        method: 'GET',
                        params: { page: data.page, kategori: data.kategori, kota: data.kota, min: data.min, max: data.max, id: data.id, q: data.q },
                    };
                },
            }),

            getProductList: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'produk' }];

                },
                query: (id) => {
                    return {
                        url: 'ProdukList',
                        method: 'GET',
                        params: { id },
                    };
                },
            }),

            getProductDetail: builder.query({
                query: (id) => {
                    return {
                        url: 'ProdukDetail',
                        method: 'GET',
                        params: { id },
                    };
                },
            }),

            addProduct: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'produk' }];
                },
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'Produk',
                        body: data,
                    };
                },
            }),
            updateProduct: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'produk' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `Produk/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteProduct: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'produk' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `Produk/${id}`,
                    };
                },
            }),

            addVarian: builder.mutation({
                invalidatesTags: (result, error, data) => {
                    return [{ type: 'produk' }];
                },
                query: (data) => {
                    const formData = new FormData();
                    formData.append('nama', data.nama);
                    formData.append('deskripsi', data.deskripsi);
                    formData.append('status', data.status);
                    formData.append('produk_warna_id', data.produk_warna_id);
                    formData.append('produk_id', data.produk_id);
                    formData.append('produk_ukuran_id', data.produk_ukuran_id);
                    formData.append('path', data.path);
                    return {
                        method: 'POST',
                        url: 'ProdukVarian',
                        body: formData,
                    };
                },
            }),
            updateVarian: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'produk' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `ProdukVarian/${data.id}`,
                        body: data,
                    };
                },
            }),
            deleteVarian: builder.mutation({
                invalidatesTags: (result, error, id) => {
                    return [{ type: 'produk' }];
                },
                query: (id) => {
                    return {
                        method: 'DELETE',
                        url: `ProdukVarian/${id}`,
                    };
                },
            }),
        };
    },
});

export const { useGetListProductQuery, useGetProductListQuery, useGetProductDetailQuery, useAddProductMutation, useDeleteProductMutation, useUpdateProductMutation, useAddVarianMutation, useUpdateVarianMutation, useDeleteVarianMutation } = produkApi;
export { produkApi };