import { Combobox, Disclosure, Tab, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useGetListCountPenjualanDistributorQuery, useGetListPenjualanDistributorQuery, useKirimProdukMutation, usePengirimanSelesaiMutation, useShowDetailPengirimanQuery, useShowStokSettingQuery, useUpdatePenjualanMutation, useUpdatePenjualanStatusMutation } from '../../store/apis/penjualanApi';
import secureLocalStorage from 'react-secure-storage';
import { MdBlock, MdCheck, MdClear, MdDelete, MdKeyboardArrowDown, MdPrint, MdUnfoldMore } from 'react-icons/md';
import { format } from 'date-fns';
import { FilterByStatus } from '../../components/FilterTable';
import DataTable from 'react-data-table-component';
import CurrencyInput from 'react-currency-input-field';
import html2canvas from 'html2canvas';
import { FileUploader } from 'react-drag-drop-files';
import { useGetListPegawaiQuery } from '../../store/apis/pegawaiApi';
import { useDetailPembayaranQuery, useGetListPembayaranRetailDistributorQuery } from '../../store/apis/pembayaranRetailApi';
import { useGetPenjualanSerahTerimaQuery } from '../../store/apis/penjualanSerahTerimaApi';
import { alamat_storage } from '../../components/api';
import { FormRetur, ReturProduk } from './ReturProduk';
import ContactPopup from '../../components/ContactPopup';
import RetailPopup from '../../components/RetailPopup';

const KelolaPenjualan = () => {
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const [status, setStatus] = useState('Dipesan')
    const { data } = useGetListPenjualanDistributorQuery({ id: id, status: status })
    const countPenj = useGetListCountPenjualanDistributorQuery(id)

    console.log(countPenj)
    const handleChange = (index) => {
        switch (index) {
            case 0:
                setStatus('Dipesan')
                break;
            case 1:
                setStatus('Diterima')
                break;
            case 2:
                setStatus('Dikirim')
                break;
            case 3:
                setStatus('Selesai')
                break;

            case 4:
                setStatus('Ditolak')
                break;

            default:
                break;
        }
    }
    return (
        <div className='w-full p-5'>
            <div className='w-full bg-white border pb-5 rounded-lg '>

                <Tab.Group onChange={(index) => handleChange(index)}>

                    <Tab.List className='bg-blue-100 rounded-t-lg'>
                        <Tab>
                            {({ selected }) => (
                                <div className={`relative border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Pesanan Masuk {countPenj.data && countPenj.data.Dipesan && <span className='w-6 h-6 absolute z-50 -top-2.5 -right-2.5 text-white rounded-full bg-red-400'>{countPenj.data.Dipesan}</span>}
                                </div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`relative border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Pesanan Diterima {countPenj.data && countPenj.data.Diterima && <span className='w-6 h-6 absolute z-50 -top-2.5 -right-2.5 text-white rounded-full bg-red-400'>{countPenj.data.Diterima}</span>}
                                </div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`relative border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Pesanan Dikirim {countPenj.data && countPenj.data.Dikirim && <span className='w-6 h-6 absolute z-50 -top-2.5 -right-2.5 text-white rounded-full bg-red-400'>{countPenj.data.Dikirim}</span>}
                                </div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`relative border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Selesai {countPenj.data && countPenj.data.Selesai && <span className='w-6 h-6 absolute z-50 -top-2.5 -right-2.5 text-white rounded-full bg-red-400'>{countPenj.data.Selesai}</span>}
                                </div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`relative border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Pesanan Ditolak {countPenj.data && countPenj.data.Ditolak && <span className='w-6 h-6 absolute z-50 -top-2.5 -right-2.5 text-white rounded-full bg-red-400'>{countPenj.data.Ditolak}</span>}
                                </div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`relative border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Pengembalian Produk
                                </div>
                            )}
                        </Tab>
                    </Tab.List>

                    <Tab.Panels className={`mt-5 px-5`}>
                        {data && (
                            <>
                                <Tab.Panel >
                                    <Pesanan data={data} />
                                </Tab.Panel>
                                <Tab.Panel >
                                    <Diterima data={data} />
                                </Tab.Panel>
                                <Tab.Panel >
                                    <Dikirim data={data} />
                                </Tab.Panel>
                                <Tab.Panel >
                                    <Selesai data={data} />
                                </Tab.Panel>
                                <Tab.Panel >
                                    <Ditolak data={data} />
                                </Tab.Panel>
                                <Tab.Panel >
                                    <ReturProduk />
                                </Tab.Panel>
                            </>
                        )}
                    </Tab.Panels>

                </Tab.Group>
            </div>


        </div>
    )
}

const Pesanan = ({ data }) => {
    const [updatePenjualan, updatePenjualanResult] = useUpdatePenjualanMutation();
    const [updateStatus, updateStatusResult] = useUpdatePenjualanStatusMutation();
    const [terima, setTerima] = useState(false);
    const [dataItem, setDataItem] = useState('');
    const HandleChange = (value, id) => {
        updatePenjualan({ id: id, harga: value })
    }
    const HandleSubmit = (event, item, status) => {
        if (status === 'Ditolak') {
            updateStatus({ id: item.id, status: status })
        } else {
            if (terima) {
                setDataItem('');
                setTerima(false);
            } else {
                setDataItem(item);
                setTerima(true);
            }
        }
    }

    const [tel, setTel] = useState('');
    const [contactPopup, setcontactPopup] = useState(false);

    const HandleContact = (tel) => {
        if (contactPopup) {
            setTel('');
            setcontactPopup(false);
        } else {
            setTel(tel);
            setcontactPopup(true);
        }
    }

    const [retail, setRetail] = useState('');
    const [retailPopup, setRetailPopup] = useState(false);

    const HandleRetail = (data) => {
        if (retailPopup) {
            setRetail('');
            setRetailPopup(false);
        } else {
            setRetail(data);
            setRetailPopup(true);
        }
    }

    const col = [
        {
            name: 'Nama Produk',
            selector: row => row.produk_varian.nama,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Jumlah',
            selector: row => row.jumlah,
            sortable: true,
            width: 'auto',
        },
        {
            key: "Harga Produk",
            name: "Harga Produk",
            sortable: false,
            cell: (record) => {
                return (
                    <Fragment>
                        <CurrencyInput
                            className='border border-solid border-slate border-2 w-full'
                            prefix='Rp'
                            defaultValue={record.harga}
                            decimalsLimit={2}
                            onValueChange={(value) => HandleChange(value, record.id)}
                        />
                    </Fragment>
                );
            },

        },
        {
            key: "Total Harga",
            name: "Total Harga",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.harga * row.jumlah),

        },
    ]
    return (
        <>
            <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Pesanan</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Pesanan yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                </div>
                <div className='col-span-3' />

            </div>
            {data && data.length > 0 ? (
                <>
                    {data.map((item) => (
                        <div key={item.id}>
                            {contactPopup && <ContactPopup tel={tel} contactModal={HandleContact} />}
                            {retailPopup && <RetailPopup data={retail} retailModal={HandleRetail} />}

                            {terima && <SettingStok modalShow={HandleSubmit} item={dataItem} />}

                            <Disclosure key={item.id} as="div" defaultOpen="false">

                                {({ open }) => (
                                    <>
                                        <div className="w-full flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">


                                            <div>
                                                {/* <input type="checkbox"
                                                checked={checkedDistributors.includes(distributor.distributor_id)}
                                                onChange={() => handleDistributorChange(distributor.distributor_id)}
                                                className='w-4 h-4 accent-blue-500 mr-2' /> */}
                                                <Disclosure.Button className="text-base font-bold mr-4">
                                                    {item.penjualan.retail.nama} | Invoice: {item.penjualan.nomor_invoice}
                                                </Disclosure.Button>
                                                <div>
                                                    {format(new Date(item.penjualan.tanggal_transaksi), 'dd-MM-yyyy')}

                                                </div>
                                            </div>

                                            <div className='flex items-center'>
                                                <button onClick={(event) => HandleSubmit(event, item, "Diterima")} className='py-1 px-2 bg-blue-500 text-white rounded' type="">Terima</button>

                                                <button onClick={(event) => HandleSubmit(event, item, "Ditolak")} className='mx-2 py-1 px-2 bg-red-500 text-white rounded' type="">Tolak</button>

                                                <Disclosure.Button >
                                                    <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                </Disclosure.Button>

                                            </div>
                                        </div>

                                        <Disclosure.Panel className="p-5">
                                            {data ? (
                                                <DataTable columns={col} data={item.penjualan_distributor_produks} />
                                            ) : (
                                                <p> Data kosong</p>
                                            )}
                                            <div className='grid grid-cols-5'>
                                                <div className='col-span-4 px-6 text-right font-bold'>
                                                    Biaya Pengiriman
                                                </div>
                                                <div className='px-2 '>
                                                    {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.opsi_pengiriman.harga)}
                                                </div>

                                            </div>

                                            <div className='mt-2 p-5 h-32 border-b bg-blue-100 grid grid-cols-1 content-beetween '>
                                                <div className='flex justify-between text-center items-center'>
                                                    <div >
                                                    </div>
                                                    <p className='text-lg text-blue-500'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.penjualan_distributor_produks.reduce((sum, item) => sum + item.jumlah * item.harga, 0))}</p>
                                                </div>
                                                <div className='flex place-content-end gap-4 text-center items-center bottom-0'>
                                                    <button onClick={() => HandleRetail(item.penjualan.retail)} className='p-2 bg-blue-500 text-white mt-4 rounded-lg'>Detail Pembeli</button>
                                                    <button onClick={() => HandleContact(item.penjualan.retail.nomor_telp)} className='p-2 bg-blue-500 text-white mt-4 rounded-lg'>Hubungi Pembeli</button>
                                                </div>


                                            </div>

                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>

                    ))}
                </>) : <p className='text-lg text-center'>Belum ada transaksi</p>}
        </>
    )
}

const Diterima = ({ data }) => {
    const [updatePenjualan, updatePenjualanResult] = useUpdatePenjualanStatusMutation();
    const [submit, setSubmit] = useState(false);
    const [dataId, setDataId] = useState('');
    const [tel, setTel] = useState('');
    const [contactPopup, setcontactPopup] = useState(false);

    const HandleContact = (tel) => {
        if (contactPopup) {
            setTel('');
            setcontactPopup(false);
        } else {
            setTel(tel);
            setcontactPopup(true);
        }
    }

    const [retail, setRetail] = useState('');
    const [retailPopup, setRetailPopup] = useState(false);

    const HandleRetail = (data) => {
        if (retailPopup) {
            setRetail('');
            setRetailPopup(false);
        } else {
            setRetail(data);
            setRetailPopup(true);
        }
    }

    const HandleSubmit = (id) => {
        if (submit) {
            setDataId('');
            setSubmit(false);
        } else {
            setDataId(id);
            setSubmit(true);
        }
        // updatePenjualan({ id: id, status: status })
    }


    const col = [
        {
            name: 'Nama Produk',
            selector: row => row.produk_varian.nama,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Jumlah',
            selector: row => row.jumlah,
            sortable: true,
            width: 'auto',
        },
        {
            key: "Harga Produk",
            name: "Harga Produk",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.harga),

        },
        {
            key: "Total Harga",
            name: "Total Harga",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.harga * row.jumlah),

        },
    ]
    return (
        <>

            <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Pesanan</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Pesanan yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                </div>
                <div className='col-span-3' />

            </div>
            {data && data.length > 0 ? (
                <>
                    {data.map((item) => (

                        <div key={item.id}>
                            {contactPopup && <ContactPopup tel={tel} contactModal={HandleContact} />}
                            {retailPopup && <RetailPopup data={retail} retailModal={HandleRetail} />}
                            {submit && <SettingKirimProduk modalShow={HandleSubmit} id={dataId} />}

                            <Disclosure as="div" defaultOpen="true">
                                {({ open }) => (
                                    <>
                                        <div className="w-full flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">


                                            <div>
                                                {/* <input type="checkbox"
                                                checked={checkedDistributors.includes(distributor.distributor_id)}
                                                onChange={() => handleDistributorChange(distributor.distributor_id)}
                                                className='w-4 h-4 accent-blue-500 mr-2' /> */}
                                                <Disclosure.Button className="text-base font-bold mr-4">
                                                    {item.penjualan.retail.nama} | Invoice: {item.penjualan.nomor_invoice}
                                                </Disclosure.Button>
                                                <div>
                                                    {format(new Date(item.penjualan.tanggal_transaksi), 'dd-MM-yyyy')}

                                                </div>
                                            </div>

                                            <div className='flex items-center'>
                                                <button onClick={() => HandleSubmit(item.id)} className='py-1 px-4 bg-blue-500 text-white rounded mr-2' type="">Kirim</button>


                                                <Disclosure.Button >
                                                    <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                </Disclosure.Button>

                                            </div>
                                        </div>

                                        <Disclosure.Panel className="p-5">
                                            {data ? (
                                                <DataTable columns={col} data={item.penjualan_distributor_produks} />
                                            ) : (
                                                <p> Data kosong</p>
                                            )}
                                            <div className='grid grid-cols-5'>
                                                <div className='col-span-4 px-6 text-right font-bold'>
                                                    Biaya Pengiriman
                                                </div>
                                                <div className='px-2 '>
                                                    {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.opsi_pengiriman.harga)}
                                                </div>

                                            </div>

                                            <div className='mt-2 p-5 h-32 border-b bg-blue-100 grid grid-cols-1 content-beetween '>
                                                <div className='flex justify-between text-center items-center'>
                                                    <div >
                                                    </div>
                                                    <p className='text-lg text-blue-500'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.penjualan_distributor_produks.reduce((sum, item) => sum + item.jumlah * item.harga, 0))}</p>
                                                </div>
                                                <div className='flex place-content-end gap-4 text-center items-center bottom-0'>
                                                    <button onClick={() => HandleRetail(item.penjualan.retail)} className='p-2 bg-blue-500 text-white mt-4 rounded-lg'>Detail Pembeli</button>
                                                    <button onClick={() => HandleContact(item.penjualan.retail.nomor_telp)} className='p-2 bg-blue-500 text-white mt-4 rounded-lg'>Hubungi Pembeli</button>
                                                </div>


                                            </div>


                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>

                    ))}
                </>) : <p className='text-lg text-center'>Belum ada transaksi</p>}
        </>
    )
}
const Dikirim = ({ data }) => {
    const [detail, setDetail] = useState(false);
    const [form, setForm] = useState(false);
    const [dataId, setDataId] = useState('')
    const [inv, setInv] = useState('')

    const [tel, setTel] = useState('');
    const [contactPopup, setcontactPopup] = useState(false);

    const HandleContact = (tel) => {
        if (contactPopup) {
            setTel('');
            setcontactPopup(false);
        } else {
            setTel(tel);
            setcontactPopup(true);
        }
    }

    const [retail, setRetail] = useState('');
    const [retailPopup, setRetailPopup] = useState(false);

    const HandleRetail = (data) => {
        if (retailPopup) {
            setRetail('');
            setRetailPopup(false);
        } else {
            setRetail(data);
            setRetailPopup(true);
        }
    }

    const HandleSubmit = (id) => {
        if (detail) {
            setDataId('');
            setDetail(false);
        } else {
            setDataId(id);
            setDetail(true);
        }
        // updatePenjualan({ id: id, status: status })
    }

    const HandleButton = (id, inv) => {
        if (form) {
            setDataId('');
            setInv('');
            setForm(false);
        } else {
            setDataId(id);
            setInv(inv);
            setForm(true);
        }
        // updatePenjualan({ id: id, status: status })
    }

    const col = [
        {
            name: 'Nama Produk',
            selector: row => row.produk_varian.nama,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Jumlah',
            selector: row => row.jumlah,
            sortable: true,
            width: 'auto',
        },
        {
            key: "Harga Produk",
            name: "Harga Produk",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.harga),

        },
        {
            key: "Total Harga",
            name: "Total Harga",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.harga * row.jumlah),

        },
    ]
    return (
        <>
            <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Pesanan</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Pesanan yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                </div>
                <div className='col-span-3' />

            </div>
            {data && data.length > 0 ? (
                <>
                    {data.map((item) => (
                        <div key={item.id}>
                            {contactPopup && <ContactPopup tel={tel} contactModal={HandleContact} />}
                            {retailPopup && <RetailPopup data={retail} retailModal={HandleRetail} />}
                            {detail && <DetailPengiriman modalShow={HandleSubmit} id={dataId} />}
                            {form && <PengirimanSelesai modalShow={HandleButton} id={dataId} inv={inv} />}

                            <Disclosure as="div" defaultOpen="true">

                                {({ open }) => (
                                    <>
                                        <div className="w-full flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">


                                            <div>
                                                {/* <input type="checkbox"
                                                checked={checkedDistributors.includes(distributor.distributor_id)}
                                                onChange={() => handleDistributorChange(distributor.distributor_id)}
                                                className='w-4 h-4 accent-blue-500 mr-2' /> */}
                                                <Disclosure.Button className="text-base font-bold mr-4">
                                                    {item.penjualan.retail.nama} | No: {item.penjualan.nomor_invoice}
                                                </Disclosure.Button>
                                                <div>
                                                    <button onClick={() => HandleSubmit(item.id)} type="btn" className='text-blue-500'>Lihat Detail Pengiriman</button>
                                                </div>
                                            </div>
                                            <div className='flex items-center gap-3'>
                                                {format(new Date(item.penjualan.tanggal_transaksi), 'dd-MM-yyyy')}
                                                <button onClick={() => HandleButton(item.id, item.penjualan.nomor_invoice)} type="btn" className='py-1 px-4 bg-blue-500 text-white rounded mr-2'>Selesai</button>

                                                <Disclosure.Button >
                                                    <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                </Disclosure.Button>


                                            </div>
                                        </div>

                                        <Disclosure.Panel className="p-5">
                                            {data ? (
                                                <DataTable columns={col} data={item.penjualan_distributor_produks} />
                                            ) : (
                                                <p> Data kosong</p>
                                            )}
                                            <div className='grid grid-cols-5'>
                                                <div className='col-span-4 px-6 text-right font-bold'>
                                                    Biaya Pengiriman
                                                </div>
                                                <div className='px-2 '>
                                                    {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.opsi_pengiriman.harga)}
                                                </div>

                                            </div>

                                            <div className='mt-2 p-5 h-32 border-b bg-blue-100 grid grid-cols-1 content-beetween '>
                                                <div className='flex justify-between text-center items-center'>
                                                    <div >
                                                    </div>
                                                    <p className='text-lg text-blue-500'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.penjualan_distributor_produks.reduce((sum, item) => sum + item.jumlah * item.harga, 0))}</p>
                                                </div>
                                                <div className='flex place-content-end gap-4 text-center items-center bottom-0'>
                                                    <button onClick={() => HandleRetail(item.penjualan.retail)} className='p-2 bg-blue-500 text-white mt-4 rounded-lg'>Detail Pembeli</button>
                                                    <button onClick={() => HandleContact(item.penjualan.retail.nomor_telp)} className='p-2 bg-blue-500 text-white mt-4 rounded-lg'>Hubungi Pembeli</button>
                                                </div>


                                            </div>


                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>

                    ))}
                </>) : <p className='text-lg text-center'>Belum ada transaksi</p>}
        </>
    )
}
const Selesai = ({ data }) => {
    const [detail, setDetail] = useState(false);
    const [dataId, setDataId] = useState('')
    const serahTerima = useGetPenjualanSerahTerimaQuery(data.id);
    const [retur, setRetur] = useState(false);
    const [returResult, setReturResult] = useState(false);

    const [tel, setTel] = useState('');
    const [contactPopup, setcontactPopup] = useState(false);

    const HandleContact = (tel) => {
        if (contactPopup) {
            setTel('');
            setcontactPopup(false);
        } else {
            setTel(tel);
            setcontactPopup(true);
        }
    }

    const [retail, setRetail] = useState('');
    const [retailPopup, setRetailPopup] = useState(false);

    const HandleRetail = (data) => {
        if (retailPopup) {
            setRetail('');
            setRetailPopup(false);
        } else {
            setRetail(data);
            setRetailPopup(true);
        }
    }

    const HandleSubmit = (id) => {
        if (detail) {
            setDataId('');
            setDetail(false);
        } else {
            setDataId(id);
            setDetail(true);
        }
        // updatePenjualan({ id: id, status: status })
    }

    useEffect(() => {
        if (returResult) {
            window.location.replace('/returProduk');
        }
    }, [returResult])

    const Success = () => {
        setReturResult(true);
    }
    const HandleClick = (id) => {
        if (retur) {
            setDataId('');
            setRetur(false);
        } else {
            setDataId(id);
            setRetur(true);
        }
        // updatePenjualan({ id: id, status: status })
    }
    const col = [
        {
            name: 'Nama Produk',
            selector: row => row.produk_varian.nama,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Jumlah',
            selector: row => row.jumlah,
            sortable: true,
            width: 'auto',
        },
        {
            key: "Harga Produk",
            name: "Harga Produk",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.harga),

        },
        {
            key: "Total Harga",
            name: "Total Harga",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.harga * row.jumlah),

        },
    ]
    return (
        <>
            <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>

                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Pesanan</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Pesanan yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                </div>
                <div className='col-span-3' />

            </div>
            {data && data.length > 0 ? (
                <>
                    {data.map((item) => (

                        <div key={item.id}>
                            {contactPopup && <ContactPopup tel={tel} contactModal={HandleContact} />}
                            {retailPopup && <RetailPopup data={retail} retailModal={HandleRetail} />}
                            {detail && <DetailPengiriman modalShow={HandleSubmit} id={dataId} />}
                            {retur && <FormRetur returModal={HandleClick} id={dataId} success={Success} />}

                            <Disclosure as="div" defaultOpen="true">

                                {({ open }) => (
                                    <>
                                        <div className="w-full flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">


                                            <div>
                                                <Disclosure.Button className="text-base font-bold mr-4">
                                                    {item.penjualan.retail.nama} | No: {item.penjualan.nomor_invoice}
                                                </Disclosure.Button>
                                                {serahTerima.data && (
                                                    <div>
                                                        Tanggal barang sampai: {format(new Date(serahTerima.data[0].tanggal), 'dd-MM-yyyy')}
                                                        <a href={`${alamat_storage}/fotoSerahTerima/${serahTerima.data[0].foto}`} target='_blank' className='ml-2 text-blue-500 font-bold'>(lihat Bukti)</a>
                                                    </div>
                                                )}
                                                <div>
                                                    <button onClick={() => HandleSubmit(item.id)} type="btn" className='text-blue-500'>Lihat Detail Pengiriman</button>
                                                </div>
                                            </div>
                                            <div className='flex items-center gap-3'>
                                                {format(new Date(item.penjualan.tanggal_transaksi), 'dd-MM-yyyy')}

                                                <Disclosure.Button >
                                                    <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                </Disclosure.Button>


                                            </div>
                                        </div>

                                        <Disclosure.Panel className="p-5">
                                            {data ? (
                                                <DataTable columns={col} data={item.penjualan_distributor_produks} />
                                            ) : (
                                                <p> Data kosong</p>
                                            )}
                                            <div className='grid grid-cols-5'>
                                                <div className='col-span-4 px-6 text-right font-bold'>
                                                    Biaya Pengiriman
                                                </div>
                                                <div className='px-2 '>
                                                    {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.opsi_pengiriman.harga)}
                                                </div>

                                            </div>

                                            <div className='mt-2 p-5 h-32 border-b bg-blue-100 grid grid-cols-1 content-beetween '>
                                                <div className='flex justify-between text-center items-center'>
                                                    <div >
                                                    </div>
                                                    <p className='text-lg text-blue-500'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.penjualan_distributor_produks.reduce((sum, item) => sum + item.jumlah * item.harga, 0))}</p>
                                                </div>
                                                <div className='flex place-content-end gap-4 text-center items-center bottom-0'>
                                                    <button onClick={() => HandleRetail(item.penjualan.retail)} className='p-2 bg-blue-500 text-white mt-4 rounded-lg'>Detail Pembeli</button>
                                                    <button onClick={() => HandleContact(item.penjualan.retail.nomor_telp)} className='p-2 bg-blue-500 text-white mt-4 rounded-lg'>Hubungi Pembeli</button>
                                                </div>


                                            </div>


                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>

                    ))}
                </>) : <p className='text-lg text-center'>Belum ada transaksi</p>}
        </>
    )
}
const Ditolak = ({ data }) => {
    console.log(data)
    const [tel, setTel] = useState('');
    const [contactPopup, setcontactPopup] = useState(false);

    const HandleContact = (tel) => {
        if (contactPopup) {
            setTel('');
            setcontactPopup(false);
        } else {
            setTel(tel);
            setcontactPopup(true);
        }
    }

    const [retail, setRetail] = useState('');
    const [retailPopup, setRetailPopup] = useState(false);

    const HandleRetail = (data) => {
        if (retailPopup) {
            setRetail('');
            setRetailPopup(false);
        } else {
            setRetail(data);
            setRetailPopup(true);
        }
    }
    const col = [
        {
            name: 'Nama Produk',
            selector: row => row.produk_varian.nama,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Jumlah',
            selector: row => row.jumlah,
            sortable: true,
            width: 'auto',
        },
        {
            key: "Harga Produk",
            name: "Harga Produk",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.harga),

        },
        {
            key: "Total Harga",
            name: "Total Harga",
            sortable: false,
            selector: row => Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(row.harga * row.jumlah),

        },
    ]
    return (
        <>
            <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Pesanan</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Pesanan yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                </div>
                <div className='col-span-3' />

            </div>
            {data && data.length > 0 ? (
                <>
                    {data.map((item) => (
                        <div key={item.id}>
                            {contactPopup && <ContactPopup tel={tel} contactModal={HandleContact} />}
                            {retailPopup && <RetailPopup data={retail} retailModal={HandleRetail} />}
                            <Disclosure as="div" defaultOpen="true">
                                {({ open }) => (
                                    <>
                                        <div className="w-full flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">


                                            <div>
                                                {/* <input type="checkbox"
                                                checked={checkedDistributors.includes(distributor.distributor_id)}
                                                onChange={() => handleDistributorChange(distributor.distributor_id)}
                                                className='w-4 h-4 accent-blue-500 mr-2' /> */}
                                                <Disclosure.Button className="text-base font-bold mr-4">
                                                    {item.penjualan.retail.nama} | Invoice: {item.penjualan.nomor_invoice}
                                                </Disclosure.Button>
                                            </div>
                                            <div className='flex items-center'>
                                                {format(new Date(item.penjualan.tanggal_transaksi), 'dd-MM-yyyy')}

                                                <Disclosure.Button >
                                                    <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                </Disclosure.Button>

                                            </div>
                                        </div>

                                        <Disclosure.Panel className="p-5">
                                            {data ? (
                                                <DataTable columns={col} data={item.penjualan_distributor_produks} />
                                            ) : (
                                                <p> Data kosong</p>
                                            )}
                                            <div className='grid grid-cols-5'>
                                                <div className='col-span-4 px-6 text-right font-bold'>
                                                    Biaya Pengiriman
                                                </div>
                                                <div className='px-2 '>
                                                    {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.opsi_pengiriman.harga)}
                                                </div>

                                            </div>

                                            <div className='mt-2 p-5 h-32 border-b bg-blue-100 grid grid-cols-1 content-beetween '>
                                                <div className='flex justify-between text-center items-center'>
                                                    <div >
                                                    </div>
                                                    <p className='text-lg text-blue-500'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.penjualan_distributor_produks.reduce((sum, item) => sum + item.jumlah * item.harga, 0))}</p>
                                                </div>
                                                <div className='flex place-content-end gap-4 text-center items-center bottom-0'>
                                                    <button onClick={() => HandleRetail(item.penjualan.retail)} className='p-2 bg-blue-500 text-white mt-4 rounded-lg'>Detail Pembeli</button>
                                                    <button onClick={() => HandleContact(item.penjualan.retail.nomor_telp)} className='p-2 bg-blue-500 text-white mt-4 rounded-lg'>Hubungi Pembeli</button>
                                                </div>


                                            </div>


                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>
                    ))}
                </>) : <p className='text-lg text-center'>Belum ada transaksi</p>}
        </>
    )
}
const SettingStok = ({ modalShow, item }) => {
    const { data, isFetching, error } = useShowStokSettingQuery(item.id);
    const [stokUpdateData, setStokUpdateData] = useState({});
    const [kirimProduk, kirimProdukResult] = useKirimProdukMutation();
    const [tanggal_jatuh_tempo, setTanggal_jatuh_tempo] = useState(item.tanggal_jatuh_tempo);

    console.log(data)
    const handleSubmit = () => {
        if (window.confirm("Pastikan anda telah memeriksa jumlah produk yang ingin anda kirim terlebih dahulu")) {
            kirimProduk({ id: item.id, tanggal_jatuh_tempo: format(new Date(tanggal_jatuh_tempo), 'yyyy-MM-dd'), stok: stokUpdateData.flat() })
            modalShow();
        }
    }

    useEffect(() => {
        if (data) {
            const stokData = data[0].penjualan_distributor_produks.map((data) =>
                data.produk_varian.produk_stok_details.map((stokDetail) => ({
                    id: stokDetail.id,
                    jumlah_terkini: 0,
                }))
            );
            setStokUpdateData(stokData);
        }
    }, [data])


    const updateStokUpdateData = (index1, index2, jumlah) => {
        setStokUpdateData(prevState => {
            const newData = [...prevState];
            newData[index1][index2] = {
                ...newData[index1][index2],
                jumlah_terkini: jumlah,
            };
            return newData;
        });
        console.log(stokUpdateData);
    }
    return (
        <div
            className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
            <div className="relative h-full mx-auto max-w-3xl">
                {/*content*/}
                <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-xl font-semibold">
                            Data Pengiriman Produk
                        </h3>
                        <button
                            className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => modalShow()}
                        >
                            <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                x
                            </span>
                        </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                        <p className='text-base'>Silahkan masukkan detail stok yang ingin dikirim terlebih dahulu</p>
                        {data && (
                            <>
                                <Disclosure as="div" defaultOpen='true'>
                                    {({ open }) => (
                                        <>
                                            <div className="w-full flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">


                                                <div>
                                                    <Disclosure.Button className="text-base font-bold mr-4">
                                                        Detail Pesanan
                                                    </Disclosure.Button>
                                                </div>

                                                <div className='flex items-center'>
                                                    <Disclosure.Button >
                                                        <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                    </Disclosure.Button>

                                                </div>
                                            </div>

                                            <Disclosure.Panel className="p-5">
                                                <div className='grid grid-cols-2 justify-between'>
                                                    <div>
                                                        Tanggal Pesan
                                                    </div>
                                                    <div>
                                                        {format(new Date(data[0].penjualan.tanggal_transaksi), 'dd-MM-yyyy')}
                                                    </div>
                                                    <div>
                                                        Tanggal Jatuh Tempo
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="date"
                                                            id="floating_standard"
                                                            placeholder=' '
                                                            value={tanggal_jatuh_tempo}
                                                            min={data[0].penjualan.tanggal_transaksi}
                                                            onChange={((e) => setTanggal_jatuh_tempo(e.target.value))}
                                                            className="block px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                                    </div>
                                                    <div>
                                                        Nama Tempat
                                                    </div>
                                                    <div>
                                                        {data[0].retail_alamat_kirim.nama}
                                                    </div>
                                                    <div>
                                                        Nama Penerima
                                                    </div>
                                                    <div>
                                                        {data[0].retail_alamat_kirim.nama_penerima}
                                                    </div>
                                                    <div>
                                                        Nomor Telepon Penerima
                                                    </div>
                                                    <div>
                                                        {data[0].retail_alamat_kirim.nomor_telp_penerima}
                                                    </div>
                                                    <div>
                                                        Alamat
                                                    </div>
                                                    <div>
                                                        {data[0].retail_alamat_kirim.alamat}
                                                    </div>
                                                    <div>
                                                        Link Google Map
                                                    </div>
                                                    <div>
                                                        <a target='_blank' className='text-blue-500 truncate ' href={`http://www.google.com/maps/place/${data[0].retail_alamat_kirim.x},${data[0].retail_alamat_kirim.y}`}>
                                                            Silahkan klik link ini
                                                        </a>
                                                    </div>
                                                    <div>
                                                        Opsi Pengiriman
                                                    </div>
                                                    <div>
                                                        {data[0].opsi_pengiriman.nama}
                                                    </div>
                                                    <div>
                                                        Biaya Pengiriman
                                                    </div>
                                                    <div>
                                                        {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data[0].opsi_pengiriman.harga)}
                                                    </div>
                                                </div>
                                                <div className='grid grid-cols-3 gap-x-2'>
                                                </div>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                                {data[0].penjualan_distributor_produks.map((item, index1) => (
                                    <Disclosure key={item.id} as="div">
                                        {({ open }) => (
                                            <>
                                                <div className="w-full flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">


                                                    <div>
                                                        <Disclosure.Button className="text-base font-bold mr-4">
                                                            {item.produk_varian.nama} | {item.jumlah}
                                                        </Disclosure.Button>
                                                    </div>

                                                    <div className='flex gap-4'>
                                                        <div>
                                                            Harga: {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.harga)}
                                                        </div>
                                                        <div>
                                                            PPN 11%: {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.harga * 11 / 100)}
                                                        </div>
                                                        <div>
                                                            Biaya Admin: {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.harga * 0.2 / 100)}
                                                        </div>
                                                    </div>

                                                    <div className='flex items-center'>
                                                        <Disclosure.Button >
                                                            <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                        </Disclosure.Button>

                                                    </div>
                                                </div>

                                                <Disclosure.Panel className="p-5">
                                                    <div className='grid grid-cols-4 font-bold mb-2'>
                                                        <div className='border-r-2 border-slate-500 px-2'>
                                                            Tanggal stok masuk
                                                        </div>
                                                        <div className='border-r-2 border-slate-500 px-2'>
                                                            Total stok sekarang
                                                        </div>
                                                        <div className='border-r-2 border-slate-500 px-2'>
                                                            Harga Beli
                                                        </div>
                                                        <div className='px-2'>
                                                            Jumlah kirim
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-4'>
                                                        {item.produk_varian.produk_stok_details.map((stok, index2) => (
                                                            stok.jumlah_terkini > 0 && (
                                                                <>
                                                                    <div className='border-r-2 border-slate-500 px-2'>
                                                                        {format(new Date(stok.produk_stok.tanggal_transaksi), 'dd/MM/yyyy')}
                                                                    </div>
                                                                    <div className='border-r-2 border-slate-500 px-2'>
                                                                        {stok.jumlah_terkini}
                                                                    </div>
                                                                    {item.harga < stok.harga_beli ? (
                                                                        <div className='border-r-2 border-slate-500 px-2 text-red-500'>
                                                                            {Intl.NumberFormat().format(stok.harga_beli)}
                                                                        </div>
                                                                    ) : (
                                                                        <div className='border-r-2 border-slate-500 px-2'>
                                                                            {Intl.NumberFormat().format(stok.harga_beli)}
                                                                        </div>
                                                                    )}
                                                                    <div className='px-2'>
                                                                        <input type="number" max={stok.jumlah_terkini} onChange={(event) => updateStokUpdateData(index1, index2, event.target.value)} defaultValue={0} />
                                                                    </div>
                                                                </>
                                                            )
                                                        ))}
                                                    </div>
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                ))}
                            </>)}
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                            className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={(event) => handleSubmit(event)}
                        >
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
const SettingKirimProduk = ({ modalShow, id }) => {
    const { data, isFetching, error } = useShowDetailPengirimanQuery(id);
    const [updateStatus, updateStatusResult] = useUpdatePenjualanStatusMutation();


    const HandleSubmit = (event, item, status) => {
        updateStatus({ id: id, status: 'Dikirim' })
        modalShow();
    }
    return (
        <div
            className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
            {data && (
                <div className="relative h-full mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Detail Pengiriman Produk
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => modalShow()}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div id='printArea' className="relative p-6 flex-auto">
                            <div className='flex gap-4 font-bold mb-2'>
                                <div>
                                    Nomor Pesanan: {data[0].penjualan.nomor_invoice}
                                </div>
                            </div>
                            <div className='grid grid-cols-2 justify-between gap-4'>
                                <div>
                                    <div className='flex gap-1 font-bold '>
                                        <div>
                                            Penerima:
                                        </div>
                                        <div>
                                            {data[0].retail_alamat_kirim.nama_penerima}
                                        </div>
                                    </div>
                                    <div>
                                        {data[0].retail_alamat_kirim.nomor_telp_penerima}
                                    </div>
                                    <div>
                                        {data[0].retail_alamat_kirim.alamat},
                                        {' kelurahan ' + data[0].retail_alamat_kirim.lokasi_kelurahan.nama},
                                        {' Kecamatan ' + data[0].retail_alamat_kirim.lokasi_kelurahan.lokasi_kecamatan.nama},
                                        {' ' + data[0].retail_alamat_kirim.lokasi_kelurahan.lokasi_kecamatan.lokasi_kotakab.nama},
                                        {' ' + data[0].retail_alamat_kirim.lokasi_kelurahan.lokasi_kecamatan.lokasi_kotakab.lokasi_propinsi.nama}
                                        {' ' + data[0].retail_alamat_kirim.lokasi_kelurahan.kode_pos}
                                    </div>
                                </div>
                                <div>
                                    <div className='flex gap-1 font-bold '>
                                        <div>
                                            Pengirim:
                                        </div>
                                        <div>
                                            {data[0].distributor.nama}
                                        </div>
                                    </div>
                                    <div>
                                        {data[0].distributor.nomor_telp}
                                    </div>
                                    <div>
                                        {data[0].distributor.alamat},
                                        {' Kelurahan ' + data[0].distributor.lokasi_kelurahan.nama},
                                        {' Kecamatan ' + data[0].distributor.lokasi_kelurahan.lokasi_kecamatan.nama},
                                        {' ' + data[0].distributor.lokasi_kelurahan.lokasi_kecamatan.lokasi_kota_kab.nama},
                                        {' ' + data[0].distributor.lokasi_kelurahan.lokasi_kecamatan.lokasi_kota_kab.lokasi_propinsi.nama}
                                        {' ' + data[0].distributor.lokasi_kelurahan.kode_pos}
                                    </div>
                                </div>
                            </div>
                            <div className='border-t mt-4 py-4 border-black w-full'>
                                <div className='w-full'>
                                    <table className='w-full'>
                                        <thead className='border-b'>
                                            <tr className='text-left'>
                                                <th className='pb-4 px-4'>Nama Produk</th>
                                                <th className='pb-4 px-4'>Harga Produk</th>
                                                <th className='pb-4 px-4'>Jumlah</th>
                                                <th className='pb-4 px-4'>Total Harga</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data[0].penjualan_distributor_produks.map((data) => (
                                                <tr key={data.id} className='border-t'>
                                                    <td className='py-2 px-4'>{data.produk_varian.nama}</td>
                                                    <td className='py-2 px-4'> {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data.harga)}</td>
                                                    <td className='py-2 px-4'>{data.jumlah}</td>
                                                    <td className='py-2 px-4'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data.harga * data.jumlah)}</td>
                                                </tr>
                                            ))}
                                            <tr key={data.id} className='border-t'>
                                                <td colSpan={3} className='py-2 px-4'>Biaya Pengiriman</td>
                                                <td className='py-2 px-4'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data[0].opsi_pengiriman.harga)}</td>
                                            </tr>
                                            <tr key={data.id} className='border-t'>
                                                <td colSpan={3} className='py-2 px-4 text-right font-bold'>Total Tagihan</td>
                                                <td className='py-2 px-4 font-bold'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data[0].totalTagihan + data[0].opsi_pengiriman.harga)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* footer */}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(event) => HandleSubmit(event)}
                            >
                                Kirim
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}
const DetailPengiriman = ({ modalShow, id, }) => {
    const { data, isFetching, error } = useShowDetailPengirimanQuery(id);

    const Print = (e) => {
        e.preventDefault();
        secureLocalStorage.setItem('data', data);
        const newTab = window.open('/cetakStrukPengiriman', '_blank');
        if (newTab) {
            newTab.focus();
        }
    }

    return (
        <div
            className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
            {data && (
                <div className="relative h-full mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Detail Pengiriman Produk
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => modalShow()}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div id='printArea' className="relative p-6 flex-auto">
                            <div className='flex gap-4 font-bold mb-2'>
                                <div>
                                    Nomor Pesanan: {data[0].penjualan.nomor_invoice}
                                </div>
                            </div>
                            <div className='grid grid-cols-2 justify-between gap-4'>
                                <div>
                                    <div className='flex gap-1 font-bold '>
                                        <div>
                                            Penerima:
                                        </div>
                                        <div>
                                            {data[0].retail_alamat_kirim.nama_penerima}
                                        </div>
                                    </div>
                                    <div>
                                        {data[0].retail_alamat_kirim.nomor_telp_penerima}
                                    </div>
                                    <div>
                                        {data[0].retail_alamat_kirim.alamat},
                                        {' kelurahan ' + data[0].retail_alamat_kirim.lokasi_kelurahan.nama},
                                        {' Kecamatan ' + data[0].retail_alamat_kirim.lokasi_kelurahan.lokasi_kecamatan.nama},
                                        {' ' + data[0].retail_alamat_kirim.lokasi_kelurahan.lokasi_kecamatan.lokasi_kotakab.nama},
                                        {' ' + data[0].retail_alamat_kirim.lokasi_kelurahan.lokasi_kecamatan.lokasi_kotakab.lokasi_propinsi.nama}
                                        {' ' + data[0].retail_alamat_kirim.lokasi_kelurahan.kode_pos}
                                    </div>
                                </div>
                                <div>
                                    <div className='flex gap-1 font-bold '>
                                        <div>
                                            Pengirim:
                                        </div>
                                        <div>
                                            {data[0].distributor.nama}
                                        </div>
                                    </div>
                                    <div>
                                        {data[0].distributor.nomor_telp}
                                    </div>
                                    <div>
                                        {data[0].distributor.alamat},
                                        {' Kecamatan ' + data[0].distributor.lokasi_kelurahan.lokasi_kecamatan.nama},
                                        {' ' + data[0].distributor.lokasi_kelurahan.lokasi_kecamatan.lokasi_kota_kab.nama},
                                        {' ' + data[0].distributor.lokasi_kelurahan.lokasi_kecamatan.lokasi_kotakab.lokasi_propinsi.nama}
                                        {' ' + data[0].distributor.lokasi_kelurahan.kode_pos}
                                    </div>
                                </div>
                            </div>
                            <div className='border-t mt-4 py-4 border-black w-full'>
                                <div className='w-full'>
                                    <table className='w-full'>
                                        <thead className='border-b'>
                                            <tr className='text-left'>
                                                <th className='pb-4 px-4'>Nama Produk</th>
                                                <th className='pb-4 px-4'>Harga Produk</th>
                                                <th className='pb-4 px-4'>Jumlah</th>
                                                <th className='pb-4 px-4'>Total Harga</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data[0].penjualan_distributor_produks.map((data) => (
                                                <tr key={data.id} className='border-t'>
                                                    <td className='py-2 px-4'>{data.produk_varian.nama}</td>
                                                    <td className='py-2 px-4'> {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data.harga)}</td>
                                                    <td className='py-2 px-4'>{data.jumlah}</td>
                                                    <td className='py-2 px-4'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data.harga * data.jumlah)}</td>
                                                </tr>
                                            ))}
                                            <tr key={data.id} className='border-t'>
                                                <td colSpan={3} className='py-2 px-4'>Biaya Pengiriman</td>
                                                <td className='py-2 px-4'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data[0].opsi_pengiriman.harga)}</td>
                                            </tr>
                                            <tr key={data.id} className='border-t'>
                                                <td colSpan={3} className='py-2 px-4 text-right font-bold'>Total Tagihan</td>
                                                <td className='py-2 px-4 font-bold'>{Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data[0].totalTagihan + data[0].opsi_pengiriman.harga)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* footer */}
                        <div className="flex gap-2 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                onClick={(e) => Print(e)}
                                className="bg-slate-300 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:bg-slate-400"
                                type="button"
                            >
                                <div className='text-xl text-slate-500'>
                                    <MdPrint />

                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}

const PengirimanSelesai = ({ modalShow, id, inv }) => {
    const tgl = useRef('');
    const [foto, setFoto] = useState('');
    const [selectedP, setSelectedP] = useState({ 'nama': 'Pilih Pegawai' })
    const [query, setQuery] = useState('')
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const idD = userData ? userData.id : null;
    const [pengirimanSelesai, pengirimanSelesaiResult] = usePengirimanSelesaiMutation()
    const handleFoto = (file) => {
        setFoto(file);
    };


    const handleSubmit = (event) => {
        const date = new Date(tgl.current.value)
        pengirimanSelesai({ nomor_faktur: inv, tanggal: format(date, 'yyyy-MM-dd'), foto: foto, status: 'Selesai', penjualan_distributor_id: id, pegawai_id: selectedP.id })
        modalShow();
    }

    return (
        <div
            className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
            <div className="relative h-full mx-auto max-w-3xl">
                {/*content*/}
                <form
                    onSubmit={(event) => handleSubmit(event)}

                    className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-xl font-semibold">
                            Data Pesanan
                        </h3>
                        <button
                            className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => modalShow()}
                        >
                            <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                x
                            </span>
                        </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                        <div className="relative z-0 mt-2">
                            <input
                                type="text"
                                value={inv}
                                placeholder=' '
                                readOnly={true}
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                            <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nomor Invoice</label>
                        </div>
                        <div className="relative z-0 mt-5">
                            <input
                                type="date"
                                id="floating_standard"
                                required
                                placeholder=' '
                                ref={tgl}
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                            <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                Tanggal Sampai
                            </label>
                        </div>
                        <div>
                            <p className=" text-slate-500 text-xs mt-5 mb-1">Foto Bukti Pengiriman</p>
                            <FileUploader required label={"Tambahkan atau Letakkan foto Disini"} handleChange={handleFoto} name="file" types={["JPG", "PNG"]} multiple={false} />
                            <p>{foto ? `File name: ${foto.name}` : "no files uploaded yet"}</p>
                        </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                            className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="submit"
                        >
                            Save Changes
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default KelolaPenjualan