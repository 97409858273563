import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const produkVarianApi = createApi({
    reducerPath: 'produkVarian',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,
    }),
    endpoints(builder) {
        return {
            getListVarian: builder.query({
                query: (id) => {
                    return {
                        url: '/ProdukVarianList',
                        params: {
                            id: id,
                        },
                        method: 'GET',
                    };
                },
            }),
            getListAllVarian: builder.query({
                query: (id) => {
                    return {
                        url: '/ProdukVarianListAll',
                        params: {
                            id: id,
                        },
                        method: 'GET',
                    };
                },
            }),

            getListVarianDropdown: builder.query({
                query: (id) => {
                    return {
                        url: '/ProdukVarianDropdown',
                        params: {
                            id: id,
                        },
                        method: 'GET',
                    };
                },
            }),
        };
    },
});

export const { useGetListVarianQuery, useGetListAllVarianQuery, useGetListVarianDropdownQuery } = produkVarianApi;
export { produkVarianApi };