import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {alamat_backend} from '../../components/api';

const blacklistApi = createApi({
    reducerPath: 'blacklist',
    baseQuery: fetchBaseQuery({
        baseUrl: alamat_backend,

    }),
    endpoints(builder) {
        return {
            Lapor: builder.mutation({
                query: (data) => {
                    return {
                        method: 'POST',
                        url: 'BlacklistRetail',
                        body: data,
                    };
                },
            }),
            getPengaduanAdmin: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'pengaduan' }];

                },
                query: (status) => {
                    return {
                        url: 'BlacklistRetailList',
                        method: 'GET',
                        params: { status },
                    };
                },
            }),
            getDetailPengaduan: builder.query({
                providesTags: (result, error) => {
                    return [{ type: 'pengaduan' }];

                },
                query: ({idR, idD}) => {
                    return {
                        url: 'BlacklistRetailListDetail',
                        method: 'GET',
                        params: { idR:idR, idD:idD},
                    };
                },
            }),

            updatePengaduan: builder.mutation({
                invalidatesTags: (result, error) => {
                    return [{ type: 'pengaduan' }];
                },
                query: (data) => {
                    return {
                        method: 'PUT',
                        url: `BlacklistRetail/${data.id}`,
                        body: data,
                    };
                },
            }),
            getRetail: builder.query({
                query: (id) => {
                    return {
                        url: 'GetBlacklistedRetail',
                        method: 'GET',
                        params: { id },
                    };
                },
            }),
        };
    },
});

export const { useLaporMutation, useGetPengaduanAdminQuery, useGetDetailPengaduanQuery, useUpdatePengaduanMutation, useGetRetailQuery } = blacklistApi;
export { blacklistApi };