import { ADD_PRODUK_FOTO, DELETE_PRODUK_FOTO, GET_LIST_DATA_PRODUK_FOTO, GET_LIST_PRODUK_FOTO, UPDATE_PRODUK_FOTO } from "../../actions/produkFotoAction"

const initialState = {
    getListFotoResult: false,
    getListFotoLoading: false,
    getListFotoError: false,

    getListFotoDataResult: false,
    getListFotoDataLoading: false,
    getListFotoDataError: false,

    addFotoResult: false,
    addFotoLoading: false,
    addFotoError: false,

    deleteFotoResult: false,
    deleteFotoLoading: false,
    deleteFotoError: false,

    updateFotoResult: false,
    updateFotoLoading: false,
    updateFotoError: false,
}


const produkFoto = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_PRODUK_FOTO:
            return {
                ...state,
                getListFotoResult: action.payload.data,
                getListFotoLoading: action.payload.loading,
                getListFotoError: action.payload.errorMessage,
            }
        case GET_LIST_DATA_PRODUK_FOTO:
            return {
                ...state,
                getListFotoDataResult: action.payload.data,
                getListFotoDataLoading: action.payload.loading,
                getListFotoDataError: action.payload.errorMessage,
            }

        case ADD_PRODUK_FOTO:
            return {
                ...state,
                addFotoResult: action.payload.data,
                addFotoLoading: action.payload.loading,
                addFotoError: action.payload.errorMessage,
            }
        case DELETE_PRODUK_FOTO:
            return {
                ...state,
                deleteFotoResult: action.payload.data,
                deleteFotoLoading: action.payload.loading,
                deleteFotoError: action.payload.errorMessage,
            }
        case UPDATE_PRODUK_FOTO:
            return {
                ...state,
                updateFotoResult: action.payload.data,
                updateFotoLoading: action.payload.loading,
                updateFotoError: action.payload.errorMessage,
            }
        default:
            return state;
    }
}

export default produkFoto;