import { Tab } from '@headlessui/react'
import React, { Fragment } from 'react'
import DataTable from 'react-data-table-component';
import { MdDelete } from 'react-icons/md';
import { AddCategory, AddSubCategory, AddSubSubCategory } from './AddProductCategory';
import { EditCategory, EditSubCategory, EditSubSubCategory } from './EditProductCategory';
import { useDeleteSubKategoriProdukMutation, useGetListSubKategoriProdukQuery } from '../../store/apis/subKategoriProdukApi';
import { useDeleteKategoriProdukMutation, useGetListKategoriProdukQuery } from '../../store/apis/kategoriProdukApi';
import { useDeleteSubSubKategoriProdukMutation, useGetListSubSubKategoriProdukQuery } from '../../store/apis/subSubKategoriProdukApi';
import { alamat_storage } from '../../components/api';

const ManageProductCategory = () => {

    return (
        <div className='w-full p-5 '>
            <div className='w-full bg-white border pb-5 rounded-lg '>

                <Tab.Group>

                    <Tab.List className='bg-blue-100 rounded-t-lg'>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>Kategori Produk</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>Sub Kategori Produk</div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>Sub Sub Kategori Produk</div>
                            )}
                        </Tab>
                    </Tab.List>

                    <Tab.Panels className={`mt-5 px-5`}>
                        <Tab.Panel >
                            <ProductCategory />
                        </Tab.Panel>
                        <Tab.Panel >
                            <ProductSubCategory />
                        </Tab.Panel>

                        <Tab.Panel >
                            <ProductSubSubCategory />
                        </Tab.Panel>

                    </Tab.Panels>

                </Tab.Group>
            </div>


        </div>
    )
}

const ProductCategory = () => {
    const { data, error, isFetching } = useGetListKategoriProdukQuery()
    const [deleteKategori, results] = useDeleteKategoriProdukMutation();

    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            deleteKategori(id);
        }
    }

    const colCat = [
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idCat = record.id;
                return (
                    <Fragment>
                        <EditCategory data={record} />

                        <span className='mx-3'>|</span>

                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, idCat)}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]


    return (
        <>
            <div className='grid grid-cols-4 mb-5'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Kategori Produk</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Kategori Produk yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                    <AddCategory />
                </div>



            </div>
            {data ? (
                <DataTable columns={colCat} data={data} pagination />
            ) : isFetching ? (
                <p>loading</p>
            ) : (
                <p>{error ? error : "Data kosong"}</p>
            )}

        </>
    )
}

const ProductSubCategory = () => {
    const { data, error, isFetching } = useGetListSubKategoriProdukQuery()
    const [deleteSubKategori, results] = useDeleteSubKategoriProdukMutation();

    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            deleteSubKategori(id)
        }
    }
    const colSubCat = [
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            name: 'Kategori',
            selector: row => row.kategori,
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idCat = record.id;
                return (
                    <Fragment>
                        <EditSubCategory data={record} />

                        <span className='mx-3'>|</span>

                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, idCat)}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]
    return (
        <>
            <div className='grid grid-cols-4 mb-5'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Sub Kategori Produk</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Sub Kategori Produk yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                    <AddSubCategory />
                </div>

            </div>
            {data ? (
                <DataTable columns={colSubCat} data={data} pagination />
            ) : isFetching ? (
                <p>loading</p>
            ) : (
                <p>{error ? error : "Data kosong"}</p>
            )}

        </>
    )
}

const ProductSubSubCategory = () => {
    const { data, error, isFetching } = useGetListSubSubKategoriProdukQuery()
    const [deleteSubSubKategori, results] = useDeleteSubSubKategoriProdukMutation();

    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            deleteSubSubKategori(id);
        }
    }

    const colSubSubCat = [
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            name: 'Sub Kategori',
            selector: row => row.subKategori,
            sortable: true,
        },
        {
            name: 'Gambar',
            selector: row => row.nama,
            sortable: true,
            cell: (record) => {
                return(
                    <div>
                        <a className='text-blue-500' target='_blank' href={`${alamat_storage}gambarSubSubKategori/${record.gambar}`}>Link</a>
                    </div>
                )
            }
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idCat = record.id;
                return (
                    <Fragment>
                        <EditSubSubCategory data={record} />

                        <span className='mx-3'>|</span>

                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, idCat)}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]
    return (
        <>
            <div className='grid grid-cols-4 mb-5'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Sub Sub Kategori Produk</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Sub Sub Kategori Produk yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                    <AddSubSubCategory />
                </div>
            </div>
            {data ? (
                <DataTable columns={colSubSubCat} data={data} pagination />
            ) : isFetching ? (
                <p>loading</p>
            ) : (
                <p>{error ? error : "Data kosong"}</p>
            )}

        </>
    )
}


export default ManageProductCategory