import React, { useEffect, useState } from 'react'
import { format } from 'date-fns';
import secureLocalStorage from 'react-secure-storage';

const CetakLaporanPemasukan = () => {
    const data = secureLocalStorage.getItem('data')
    const start = secureLocalStorage.getItem('start');
    const end = secureLocalStorage.getItem('end');

    useEffect(() => {
        window.print();
        // Function to handle the beforeunload event
        const handleBeforeUnload = () => {
            // Remove the data from local storage
            secureLocalStorage.removeItem('data');
            secureLocalStorage.removeItem('start');
            secureLocalStorage.removeItem('end');
            
        };

        // Add the beforeunload event listener
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <div className='w-full  p-5  '>
            <div className='w-full bg-white p-5 rounded-lg '>
                <div className='border-b border-slate-300 pb-2 text-center'>
                    <div className=' self-center'>
                        <h1 className='text-base font-bold text-slate-500'>Laporan Pemasukan</h1>
                        <p className='text-slate-400'>{format(new Date(start), 'dd-MM-yyyy')} - {format(new Date(end), 'dd-MM-yyyy')}</p>
                    </div>
                </div>

                <div className='grid grid-cols-4'>
                        <div className=' px-4 border-slate-500 py-2 border font-bold'>
                            Tanggal
                        </div>
                        <div className='px-4 border-slate-500 py-2 border col-span-2 font-bold'>
                            Deskripsi
                        </div>
                        <div className='text-right px-4 border-slate-500 py-2 border font-bold'>
                            Nominal
                        </div>

                    </div>
                {data.map((item) => 
                    <div className='grid grid-cols-4'>
                        <div className=' px-4 border-slate-500 py-2 border'>
                            {format(new Date(item.tanggal), 'dd-MM-yyyy')}
                        </div>
                        <div className='px-4 border-slate-500 py-2 border col-span-2'>
                            {item.nama}
                        </div>
                        <div className='text-right px-4 border-slate-500 py-2 border'>
                            {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(item.nominal) }
                        </div>

                    </div>
                )}

                <div className='grid grid-cols-4'>
                    <div className='col-span-3 px-4 border border-slate-500 text-right text-lg'>
                        Total
                    </div>
                    <div className='text-right px-4 border border-slate-500 text-lg'>
                        {Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(data.reduce((sum, item) => sum + item.nominal, 0))}
                    </div>

                </div>
            </div>
        </div>
    )
}

export { CetakLaporanPemasukan }