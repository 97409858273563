import axios from "axios";
import {alamat_backend} from "../components/api";


export const GET_LIST_PRODUK = "GET_LIST_PRODUK"
export const DELETE_PRODUK = "DELETE_PRODUK"
export const ADD_PRODUK = "ADD_PRODUK"
export const UPDATE_PRODUK = "UPDATE_PRODUK"


const getListProduk = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: GET_LIST_PRODUK,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'GET',
            url: alamat_backend + 'ProdukList?id=' + id,
            timeout: 5000
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: GET_LIST_PRODUK,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: GET_LIST_PRODUK,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })


    }
}


const addProduk = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: ADD_PRODUK,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'POST',
            url: alamat_backend + 'Produk',
            timeout: 5000,
            data: data
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: ADD_PRODUK,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: ADD_PRODUK,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error,
                    }
                })
            })
    }
}

const updateProduk = (data) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: UPDATE_PRODUK,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'PUT',
            url: alamat_backend + 'Produk/' + data.id,
            timeout: 5000,
            data: data
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: UPDATE_PRODUK,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: UPDATE_PRODUK,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message
                    }
                })
            })
    }
}

const deleteProduk = (id) => {
    return (dispatch) => {

        //loading
        dispatch({
            type: DELETE_PRODUK,
            payload: {
                loading: true,
                data: false,
                errorMessage: false,
            }
        })

        //get API
        axios({
            method: 'DELETE',
            url: alamat_backend + 'Produk/'+ id,
            timeout: 5000,
        })
            .then((Response) => {
                //berhasil get api
                dispatch({
                    type: DELETE_PRODUK,
                    payload: {
                        loading: false,
                        data: Response.data,
                        errorMessage: false,
                    }
                })
            })
            .catch((error) => {
                //gagal get api
                dispatch({
                    type: DELETE_PRODUK,
                    payload: {
                        loading: false,
                        data: false,
                        errorMessage: error.message,
                    }
                })
            })
    }
}


export { getListProduk, addProduk, deleteProduk, updateProduk }