import { Disclosure, Tab } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react'
import secureLocalStorage from 'react-secure-storage';
import { MdCheck, MdClear, MdKeyboardArrowDown, } from 'react-icons/md';
import { format } from 'date-fns';
import DataTable from 'react-data-table-component';
import { useGetListPembayaranDistributorDistributorQuery, useUpdatePembayaranDistributorMutation } from '../../store/apis/pembayaranDistributorApi';
import { FileUploader } from 'react-drag-drop-files';
import { alamat_storage } from '../../components/api';

const PembayaranDistributor = () => {
    const [status, setStatus] = useState('Belum Lunas');

    const handleChange = (index) => {
        switch (index) {
            case 0:
                setStatus('Belum Lunas')
                break;
            case 1:
                setStatus('Lunas')
                break;
            default:
                break;
        }
    }

    return (
        <div className='w-full p-5'>
            <div className='w-full bg-white border pb-5 rounded-lg '>

                <Tab.Group onChange={(index) => handleChange(index)}>

                    <Tab.List className='bg-blue-100 rounded-t-lg'>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Belum Lunas
                                </div>
                            )}
                        </Tab>
                        <Tab>
                            {({ selected }) => (
                                <div className={`border-4 border-transparent p-2 ${selected ? 'border-b-blue-500 text-blue-500 bg-white rounded-t-lg' : ''}`}>
                                    Lunas
                                </div>
                            )}
                        </Tab>
                    </Tab.List>

                    <Tab.Panels className={`mt-5 px-5`}>
                        <Tagihan status={status} />
                    </Tab.Panels>

                </Tab.Group>
            </div>


        </div>
    )

}
const Tagihan = ({ status }) => {
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const { data, isFetching, error } = useGetListPembayaranDistributorDistributorQuery(id)
    const [formBayar, setFormBayar] = useState(false);
    const [itemData, setItemData] = useState('');
    const HandleSubmit = (event, item) => {
        if (formBayar) {
            setItemData('');
            setFormBayar(false);
        } else {
            setItemData(item);
            setFormBayar(true);
        }
    }
    const col = [
        {
            name: 'Tanggal Tagihan',
            selector: row => format(new Date(row.datetime), 'dd-MM-yyyy'),
            sortable: true,
        },
        {
            name: 'Nominal',
            selector: row => Intl.NumberFormat().format(row.pembayaran_distributor_details[0].nominal),
            sortable: true,
        },
        {
            name: "Status",
            sortable: false,
            selector: row => row.status,

        },
    ]
    return (
        <>
            {isFetching && <>Loading</>}
            {error && <>Terjadi kesalahan</>}
            {data &&
                <>
                    {status === 'Belum Lunas' ? (data.filter((row) => row.totalTagihan > row.totalTerbayar).length > 0 ?
                        (data.filter((row) =>
                            row.totalTagihan > row.totalTerbayar
                        ).map((item) => (
                            <div key={item.id}>
                                {formBayar && <FormBayar modalShow={HandleSubmit} itemData={itemData} />}

                                {item.totalTagihan ? (
                                    <Disclosure as="div" defaultOpen="true">

                                        {({ open }) => (
                                            <>
                                                <div className="w-full flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">


                                                    <div>
                                                        <Disclosure.Button className="text-base font-bold mr-4 text-left">
                                                            Invoice Tagihan : {item.nomor_invoice_distributor}
                                                        </Disclosure.Button>
                                                        <p>Penjualan No: {item.penjualan.nomor_invoice}</p>
                                                        <p>Pembeli: {item.penjualan.retail.nama}</p>

                                                    </div>
                                                    <div>
                                                        <div className='flex items-center'>
                                                            {item.totalTerbayar ?Intl.NumberFormat().format(item.totalTerbayar) : 0} / {Intl.NumberFormat().format(item.totalTagihan)}
                                                            <Disclosure.Button >
                                                                <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                            </Disclosure.Button>

                                                        </div>
                                                        <div className=' flex'>
                                                            <button
                                                                onClick={(e) => HandleSubmit(e, item)}
                                                                className='py-1 px-4 bg-blue-500 text-white rounded mx-auto mt-2' type="">Bayar</button>

                                                        </div>
                                                    </div>
                                                </div>

                                                <Disclosure.Panel className="p-5">
                                                    <DataTable columns={col} data={item.pembayaran_distributors} />
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                ) : <></>}

                            </div>
                        ))) : <p className='text-lg text-center'>Belum ada transaksi</p>) :
                        data.filter((row) => row.totalTagihan === row.totalTerbayar).length > 0 ?
                            (data.filter((row) =>
                                row.totalTagihan === row.totalTerbayar
                            ).map((item) => (
                                <div key={item.id}>
                                    {formBayar && <FormBayar modalShow={HandleSubmit} itemData={itemData} />}

                                    {item.totalTagihan ? (
                                        <Disclosure as="div" defaultOpen="true">

                                            {({ open }) => (
                                                <>
                                                    <div className="w-full flex text-blue-500 border-b border-slate-500 justify-between p-5 items-center">


                                                        <div>
                                                            <Disclosure.Button className="text-base font-bold mr-4 text-left">
                                                                Invoice Tagihan : {item.nomor_invoice_distributor}
                                                            </Disclosure.Button>
                                                            <p>Penjualan No: {item.penjualan.nomor_invoice}</p>
                                                            <p>Pembeli: {item.penjualan.retail.nama}</p>

                                                        </div>
                                                        <div>
                                                            <div className='flex items-center'>
                                                                {item.totalTerbayar ? Intl.NumberFormat().format(item.totalTerbayar) : 0} / {Intl.NumberFormat().format(item.totalTagihan)}
                                                                <Disclosure.Button >
                                                                    <MdKeyboardArrowDown className={`text-lg ${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`} />
                                                                </Disclosure.Button>

                                                            </div>
                                                            <div className=' flex'>
                                                                <button
                                                                    onClick={(e) => HandleSubmit(e, item)}
                                                                    className='py-1 px-4 bg-blue-500 text-white rounded mx-auto mt-2' type="">Bayar</button>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <Disclosure.Panel className="p-5">
                                                        <DataTable columns={col} data={item.pembayaran_distributors} />
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    ) : <></>}

                                </div>
                            ))) : <p className='text-lg text-center'>Belum ada transaksi</p>}

                </>}
        </>
    )
}


const FormBayar = ({ modalShow, itemData }) => {
    const [selectedRows, setSelectedRows] = useState(false);
    const [tanggal, setTanggal] = useState('');
    const [path_bukti_faktur, setPath_bukti_faktur] = useState('')
    const [nomor_faktur, setNomor_faktur] = useState('');
    const [updatePembayaran, updatePembayaranResult] = useUpdatePembayaranDistributorMutation();
    const rowDisabledCriteria = row => (row.status === 'Dikirim' || row.status === 'Diterima');

    const handleFile = (file) => {
        setPath_bukti_faktur(file);
    };

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
    };


    const HandleSubmit = (event) => {
        const temp = selectedRows.map(row => ({ id: row.id, idD: row.pembayaran_distributor_details[0].id }));
        updatePembayaran({ ids: temp, tanggal: format(new Date(tanggal), 'yyyy-MM-dd'), nomor_faktur: nomor_faktur, path_bukti_faktur: path_bukti_faktur });
        modalShow()
    }

    const col = [
        {
            name: 'Tanggal Tagihan',
            selector: row => format(new Date(row.datetime), 'dd-MM-yyyy'),
            sortable: true,
        },
        {
            name: 'Nominal',
            selector: row => Intl.NumberFormat().format(row.pembayaran_distributor_details[0].nominal),
            sortable: true,
        },
        {
            name: "Status",
            sortable: false,
            selector: row => row.status,

        },
        {
            name: "Bukti",
            sortable: false,
            cell: (record) => {
                return (
                    <Fragment>
                        {record.pembayaran_distributor_details[0].path_bukti_faktur? <a className='text-blue-500' target='_blank' href={`${alamat_storage}fotoFakturDistributor/${record.pembayaran_distributor_details[0].path_bukti_faktur}`}>Link</a>:<>Pembayaran belum dilakukan / diterima</>}
                    </Fragment>
                );
            },
        }
    ]
    return (
        <div
            className="bg-slate-500 h-screen bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
            {itemData && (
                <div className="relative h-full mx-auto max-w-3xl">
                    {/*content*/}
                    <form onSubmit={(event) => HandleSubmit(event)} className="p-3 border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Form Pembayaran
                            </h3>
                            <button
                                className="p-1 ml-auto border-0 text-slate-500 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => modalShow()}
                            >
                                <span className="text-slate-500 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div id='printArea' className="relative p-6 flex-auto">
                            <div className='grid grid-cols-2 justify-between'>
                                <div>
                                    <div className='font-bold '>
                                        pembayaran yang diterima
                                    </div>
                                    <div>
                                        {itemData.totalTerbayar ? Intl.NumberFormat().format(itemData.totalTerbayar) : 0}
                                    </div>
                                </div>
                                <div>
                                    <div className='font-bold '>
                                        Pembayaran yang harus dilunasi
                                    </div>
                                    <div>
                                        {Intl.NumberFormat().format(itemData.totalTagihan)}
                                    </div>
                                </div>

                            </div>
                            <div className='border-t mt-4 py-4 border-black w-full'>
                                <div className='w-full'>
                                    <div className='grid grid-cols-2 gap-4'>
                                        <div className="relative z-0 mt-5">
                                            <input
                                                type="text"
                                                value={nomor_faktur}
                                                required
                                                onChange={(e) => setNomor_faktur(e.target.value)}
                                                placeholder=' '
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                Nomor Faktur
                                            </label>
                                        </div>
                                        <div className="relative z-0 mt-5">
                                            <input
                                                type="date"
                                                id="floating_standard"
                                                placeholder=' '
                                                required
                                                value={tanggal}
                                                onChange={(e) => setTanggal(e.target.value)}
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
                                            <label htmlFor="floating_standard" className="absolute text-slate-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                                Tanggal Transer/Bayar
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <p className=" text-slate-500 text-xs mt-5 mb-1">Foto Warna</p>
                                        <FileUploader required label={"Tambahkan atau Letakkan foto Disini"} handleChange={handleFile} name="file" types={["JPG", "PNG"]} multiple={false} />
                                        <p>{path_bukti_faktur ? `File name: ${path_bukti_faktur.name}` : "no files uploaded yet"}</p>
                                    </div>

                                    <p className=" text-slate-500 text-xs mt-5 mb-1">Pilih tagihan yang ingin dibayar</p>

                                    <DataTable
                                        columns={col}
                                        data={itemData.pembayaran_distributors}
                                        selectableRows
                                        onSelectedRowsChange={handleChange}
                                        selectableRowDisabled={rowDisabledCriteria}

                                    />
                                </div>
                            </div>
                        </div>
                        {/* footer */}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="submit"
                            >
                                Kirim
                            </button>
                        </div>
                    </form>
                </div>
            )}

        </div>
    )
}




export default PembayaranDistributor