import React, { Fragment, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { MdDelete } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { getUserData } from '../../actions/auth'
import { deleteUkuran, getListUkuran } from '../../actions/produkUkuranAction'
import { deleteWarna, getListWarna } from '../../actions/produkWarnaAction'
import { AddUkuran, AddWarna } from './AddProductDist'
import { EditUkuran, EditWarna } from './EditProductDist'
import { useDeleteUkuranMutation, useGetListUkuranQuery } from '../../store/apis/produkUkuranApi'
import { useDeleteWarnaMutation, useGetListWarnaQuery } from '../../store/apis/produkWarnaApi'
import secureLocalStorage from 'react-secure-storage'

const ManageWarnaUkuran = () => {
    return (
        <div className='w-full  p-5  '>
            <div className='w-full bg-white border p-5 rounded-lg '>

                <Ukuran />

                <Warna />
            </div>


        </div>
    )

}

const Warna = () => {
    const user = useSelector(getUserData);
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const { data, error, isFetching } = useGetListWarnaQuery(id)
    const [deleteWarna, results] = useDeleteWarnaMutation();
    const [addWarnaForm, setAddWarnaForm] = useState(false);

    const ShowWarnaModal = (e) => {
        if (addWarnaForm) {
            setAddWarnaForm(false);
        } else {
            setAddWarnaForm(true);
        }
    }

    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            deleteWarna(id)
        }
    }


    const colWarna = [
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idKP = record.id;
                return (
                    <Fragment>
                        <EditWarna data={record} />

                        <span className='mx-3'>|</span>

                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, idKP)}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]
    return (
        <>
            {addWarnaForm && <AddWarna warnaModal={ShowWarnaModal} />}

            <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Warna Produk</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Warna Produk yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                    <button
                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => ShowWarnaModal()}
                    >
                        Tambah Data
                    </button>
                </div>
                <div className='col-span-3' />

            </div>
            {data ? (
                <DataTable columns={colWarna} data={data} pagination />
            ) : isFetching ? (
                <p>loading</p>
            ) : (
                <p>{error ? error : "Data kosong"}</p>
            )}
        </>
    )
}

const Ukuran = () => {
    const user = useSelector(getUserData);
    const userData = JSON.parse(secureLocalStorage.getItem('distRetData'))
    const id = userData ? userData.id : null;
    const { data, error, isFetching } = useGetListUkuranQuery(id)
    const [deleteUkuran, results] = useDeleteUkuranMutation();
    const [addUkuranForm, setAddUkuranForm] = useState(false);

    const ShowUkuranModal = (e) => {
        if (addUkuranForm) {
            setAddUkuranForm(false);
        } else {
            setAddUkuranForm(true);
        }
    }

    const deleteData = (event, id, data) => {
        event.preventDefault();
        if (window.confirm("Apakah anda yakin ingin menghapus data?")) {
            deleteUkuran(id)
        }
    }


    const colUkuran = [
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
        },
        {
            key: "action",
            name: "Action",
            sortable: false,
            cell: (record) => {
                const idKP = record.id;
                return (
                    <Fragment>
                        <EditUkuran data={record} />

                        <span className='mx-3'>|</span>

                        <button
                            type="submit"
                            onClick={(e) => deleteData(e, idKP)}
                            className="text-blue-500 text-lg cursor-pointer"
                        >
                            <MdDelete />
                        </button>
                    </Fragment>
                );
            },
        },
    ]
    return (
        <>
            {addUkuranForm && <AddUkuran ukuranModal={ShowUkuranModal} />}

            <div className='grid grid-cols-4 gap-y-4 border-b border-slate-300 pb-2'>
                <div className='col-span-3 self-center'>
                    <h1 className='text-base font-bold text-blue-500 '>Data Ukuran Produk</h1>
                    <p className='text-slate-400'>Di bawah ini merupakan data semua Ukuran Produk yang telah terdaftar.</p>
                </div>
                <div className='place-self-end'>
                    <button
                        className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => ShowUkuranModal()}
                    >
                        Tambah Data
                    </button>
                </div>
                <div className='col-span-3' />

            </div>
            {data ? (
                <DataTable columns={colUkuran} data={data} pagination />
            ) : isFetching ? (
                <p>loading</p>
            ) : (
                <p>{error ? error : "Data kosong"}</p>
            )}
        </>
    )
}


export default ManageWarnaUkuran